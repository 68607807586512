import { IonIcon, IonRouterLink } from '@ionic/react';
import { useTranslation } from 'react-i18next';
interface ContainerProps {
  id?: string;
  title?: string;
  //type?: 'WATER' | 'GAS' | 'ELECTRICITY' | 'HEAT';
  type?: string;
  ShowHide?: string;
  singleLink?: string;
  consumptionPointTitle?: string;
  user?: string;
  consumptionPointId?: string;
  userId?: string;
  className?: string;
  onClickCard?: () => void;
  icon?: string;
}

const LocationCard: React.FC<ContainerProps> = ({
  id,
  title,
  type, //water, gas, electricity
  ShowHide,
  singleLink,
  consumptionPointTitle,
  user,
  consumptionPointId,
  userId,
  className,
  onClickCard,
  icon,
}) => {


  const { t } = useTranslation();

  return (
    <div id={id} className={`${ShowHide}`} datatype={type}>
      <div className={`p-4 mb-4 text-gray-55 dark:text-white rounded-2xl bg-gradient-to-r w-full relative dark:bg-gray-800 ${className}`}>
        <IonRouterLink routerLink={singleLink} routerDirection="forward" className="block h-full">
          <div className="flex items-center justify-between mb-4 relative">
            <div className="flex items-center">
              {icon ? (
                <div className="shadow-md rounded-md flex items-center justify-center w-10 h-10 mr-2 bg-white">
                  <img className="" width="22" src={icon ? `data:image/jpeg;base64, ${icon}` : '/assets/no-image.jpg'} />
                </div>
              ) : (
                <div className={`shadow-md rounded-md flex items-center justify-center w-10 h-10 mr-2 bg-white`}>
                  {type === 'WATER' && (
                    <IonIcon
                      src="/assets/icon/ic-watter-blue.svg"
                      class="ios hydrated text-xl"
                    ></IonIcon>
                  )}
                  {type === 'ELECTRICITY' && (
                    <IonIcon
                      src="/assets/icon/ic-lightning-yellow.svg"
                      class="ios hydrated text-xl"
                    ></IonIcon>
                  )}
                  {type === 'GAS' && (
                    <IonIcon
                      src="/assets/icon/ic-gas-green.svg"
                      class="ios hydrated text-xl"
                    ></IonIcon>
                  )}
                  {type === 'HEAT' && (
                    <IonIcon
                      src="/assets/icon/ic-heat.svg"
                      class="ios hydrated text-xl"
                    ></IonIcon>
                  )}
                </div>
              )}
              <div className="text-xl font-bold text-gray-55 dark:text-white">
                <div>{title}</div>
                <div className="text-xs font-normal text-gray-50">
                  {type === 'WATER' && t('dashboard.tabs.water')}
                  {type === 'ELECTRICITY' && t('dashboard.tabs.electricity')}
                  {type === 'HEAT' && t('dashboard.tabs.heat')}
                  {type === 'GAS' && t('dashboard.tabs.gas')}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between mb-3">
            <div className="w-6/12">
              <div className="text-gray-50 text-sm">{consumptionPointTitle}</div>
              <div className="text-gray-55 dark:text-white font-bold">{consumptionPointId}</div>
            </div>
            <div className="w-6/12">
              <div className="text-gray-50 text-sm">{user}</div>
              <div className="text-gray-55 dark:text-white font-bold">{userId}</div>
            </div>
          </div>

        </IonRouterLink>
      </div >
    </div >
  );
};
export default LocationCard;
