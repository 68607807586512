import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonIcon,
  IonRouterLink,
} from '@ionic/react';

import { chevronBackOutline } from 'ionicons/icons';
import DemoHeaderBar from './DemoHeaderBar';

interface ContainerProps {
  title?: string;
  titleColor?: string;
  backLink?: string;
  bgColor?: string;
  className?: string;
  rightIcon?: boolean;
  style?: any;
  onClickCard?: () => void;
}

const Header: React.FC<ContainerProps> = ({
  title,
  titleColor,
  backLink,
  bgColor,
  className,
  rightIcon,
  style,
  onClickCard,
}) => {
  return (
    <IonHeader mode="ios" className={`bg-white dark:bg-gray-900 ion-no-border pt-2 ${className}`} color={bgColor} style={style}>
      <IonToolbar color={bgColor}>
        <DemoHeaderBar />
        <div className='flex items-center relative mt-2'>
          <IonButtons slot="start" className="h-10">
            {backLink ? (
              <IonRouterLink
                routerLink={backLink}
                routerDirection="back"
              >
                <IonIcon
                  src="/assets/icon/ic-left-arrow.svg"
                  class="ios hydrated text-base mr-2 ml-3 flex items-center"
                ></IonIcon>
              </IonRouterLink>
            ) : (
              <IonBackButton
                className="h-4"
                mode="md"
                defaultHref={backLink}
                icon={chevronBackOutline}
                class="text-gray-10"
              />
            )}
          </IonButtons>

          <IonTitle
            size="large"
            className={`text-2xl font-bold ${titleColor}`}
          >
            {title}
          </IonTitle>
          {rightIcon && (
            <button
              className="absolute top-0 right-0 mt-3 mr-4"
              onClick={onClickCard}
            >
              <IonIcon
                src="/assets/icon/ic-more-one.svg"
                class="ios hydrated text-3xl"
              ></IonIcon>
            </button>
          )}
        </div>
      </IonToolbar>
    </IonHeader>
  );
};
export default Header;
