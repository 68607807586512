import { Api } from '../api';

type inputParams = {
  fileId: string;
};

export type GetKeyAccountsResponse = {
  token: string;
  phone: string | null;
  email: string | null;
};

export const getDocumentsDownloadLink = async (params: inputParams): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('GET', `app/client-building/user-download-link/${params.fileId}`, true); 
    return response;
  } catch (error) {
    throw error;
  }
};
