import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { changePassword } from '../../data/user/change-password.api';
import { Redirect, useHistory } from 'react-router-dom';
import { useState } from 'react';
import Header from './../../components/Header';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';

type FormErrors = {
  oldpassword: string[] | undefined;
  newpassword: string[] | undefined;
  confirmpassword: string[] | undefined;
};

const PasswordChange: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [oldpassword, setOldPassword] = useState('');
  const [newpassword, setNewPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<FormErrors>({
    oldpassword: undefined,
    newpassword: undefined,
    confirmpassword: undefined
  });
  const [successPasswordChange, setsuccessPasswordChange] = useState<boolean>(false);
  const [errorPasswordChange, seterrorPasswordChange] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordSecond, setShowPasswordSecond] = useState(false);

  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,25}$/;


  // Change Password form
  const postChangePassword = async () => {
    //Check if confirm password match new password 
    if (newpassword === confirmpassword && newpassword !== '' && confirmpassword !== '' && oldpassword !== '' && newpassword.match(passwordRegex)) {
      setErrors({
        ...errors,
        oldpassword: undefined,
        newpassword: undefined,
        confirmpassword: undefined
      });

      changePassword(oldpassword, newpassword)
        .then((response: any) => {
          // success
          setsuccessPasswordChange(true);
          seterrorPasswordChange(false);
          history.push('/auth/profile');
          console.log(response);
        })
        .catch((error) => {
          // failure
          console.log(error.response.error.message);
          setErrors({
            ...errors,
            oldpassword: ['Old password is required'],
            newpassword: ['New/Confirm password not match'],
            confirmpassword: ['New/Confirm password not match'],
          });
          setsuccessPasswordChange(false);
          ; seterrorPasswordChange(true);
        });
    } else {
      setErrors({
        ...errors,
        oldpassword: ['Old password is required'],
        newpassword: ['New/Confirm password not match'],
        confirmpassword: ['New/Confirm password not match'],
      });
    }
  };

  //Handle Old password input
  const handleOldPasswordChange = (e: any) => {
    if (e.target.value === '') {
      setErrors({
        ...errors,
        oldpassword: ['Old password is required'],
      });
    } else {
      setErrors({
        ...errors,
        oldpassword: undefined,
      });
    }
    setOldPassword(e.target.value);
  };

  //Handle new password input
  const handleNewPasswordChange = (e: any) => {
    if (e.target.value !== '' && e.target.value.match(passwordRegex)) {
      setErrors({
        ...errors,
        newpassword: undefined,
      });
      seterrorPasswordChange(false);
    } else {
      setErrors({
        ...errors,
        newpassword: ['New password is required'],
      });
      seterrorPasswordChange(true);
    }
    setNewPassword(e.target.value);
  };

  //Handle confirm password input
  const handleConfirmPassword = (e: any) => {
    if (e.target.value !== '' && newpassword === e.target.value) {
      setErrors({
        ...errors,
        confirmpassword: undefined,
      });
    } else {
      setErrors({
        ...errors,
        confirmpassword: ['Confirm password is required'],
      });
    }
    setConfirmPassword(e.target.value);
  };

  /** Show hide password */
  const handleShowPassword = (e: any) => {
    setShowPassword(!showPassword)
  }
  /** Show hide password second */
  const handleShowPasswordSecond = (e: any) => {
    setShowPasswordSecond(!showPasswordSecond)
  }

  return (
    <IonPage>
      <Header titleColor="text-gray-20 dark:text-white text-center" backLink="/auth/profile" title={t('auth.accountSettings.title')} />
      <IonContent fullscreen>
        <div className="">
          <div className="text-gray-55 dark:text-white font-bold text-base px-4 mt-8">{t('auth.accountSettings.changePasswordLabel')}</div>
          <div className="flex justify-center px-4 mt-10">
            <div className="w-full">
              <div className="py-2">
                <Input
                  type="password"
                  label={t('auth.accountSettings.oldPasswordPlaceholder')}
                  error={errors.oldpassword}
                  value={oldpassword}
                  onChange={handleOldPasswordChange}
                />
              </div>
              <div className="py-2 relative">
                <Input
                  type={showPassword ? "text" : "password"}
                  label={t('auth.accountSettings.newPasswordPlaceholder')}
                  error={errors.newpassword}
                  value={newpassword}
                  onChange={handleNewPasswordChange}
                />
                <IonIcon onClick={handleShowPassword} class="ios hydrated absolute right-0 top-0 z-10 mt-6 mr-2 text-gray-20 dark:text-white" icon={showPassword ? eyeOutline : eyeOffOutline} />
              </div>
              <div className="py-2 relative">
                <Input
                  type={showPasswordSecond ? "text" : "password"}
                  label={t('auth.accountSettings.confirmPasswordPlaceholder')}
                  error={errors.confirmpassword}
                  value={confirmpassword}
                  onChange={handleConfirmPassword}
                />
                <IonIcon onClick={handleShowPasswordSecond} class="ios hydrated absolute right-0 top-0 z-10 mt-6 mr-2 text-gray-20 dark:text-white" icon={showPasswordSecond ? eyeOutline : eyeOffOutline} />
              </div>

              <div className={`text-blue-5 mt-6 text-center ${successPasswordChange !== true && "hidden"}`}>{t('auth.accountSettings.changePasswordMessage')}</div>

              <div className={`text-red-5 mt-6 text-center ${errorPasswordChange !== true && "hidden"}`}>{t('auth.accountSettings.changePasswordErrorMessage')}</div>

              <IonButton onClick={postChangePassword} color="blue" className="mt-16 w-full normal-case font-semibold h-12 text-base">{t('auth.accountSettings.cta')}</IonButton>

            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default PasswordChange;
