import { Api } from '../api';


export type GetKeyAccountsResponse = {
  token: string;
  phone: string | null;
  email: string | null;
};

export const transferOldApplicationData = async (
  email: string,
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('POST', `app/account/transfer-old-application-data-for-email?email=${email}`, true, { 
    });
    return response;
  } catch (error) {
    throw error;
  }
};
