import { IonRouterLink, IonTitle } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Preferences } from '@capacitor/preferences';

interface ContainerProps {
  onClickTabAdd: () => void;
  onClickTabChangeView: () => void;
  list: boolean;
  slider: boolean;
}

const DashFilter: React.FC<ContainerProps> = ({
  onClickTabAdd,
  onClickTabChangeView,
  list,
  slider
}) => {
  const { t } = useTranslation();
  const [theme, setTheme] = useState<string>('light');


  Preferences.get({ key: 'theme' }).then((storage: any) => {
    if (storage.value === "dark") {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  });

  const logo = theme == "dark" ? "infohabita_logo-white.svg" : "infohabita_logo.svg";

  return (
    <div className="flex justify-between">
      <IonTitle
        slot="start"
        size="small"
        className=""
      >
        <IonRouterLink
          className=""
          routerLink="/dashboardmain"
        >
          <div className="flex items-center w-32">
            <img width="110" height="30" src={`/assets/${logo}`} />
            {/*<img width="24" height="24" src="/assets/logo.svg" />*/}
            {/*<div className="text-xl text-gray-55 dark:text-white ml-2">{t('general.logodiv>*/}
          </div>
        </IonRouterLink>
      </IonTitle>
      <div className="flex items-center justify-end w-28">
        <IonRouterLink
          className="demo-hide-cont"
          routerLink="/auth/register-step-1-a"
        >
          <img width="24" height="24" src="/assets/icon/icon-plus.svg" />
        </IonRouterLink>

        <div className="px-2" onClick={onClickTabChangeView}>
          {slider && (
            <img width="24" height="24" src="/assets/icon/icon-card.svg" />
          )}
          {list && (
            <img width="24" height="24" src="/assets/icon/icon-slider.svg" />
          )}

        </div>
      </div>
    </div>
  );
};
export default DashFilter;
