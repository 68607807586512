import {
  IonContent,
  IonPage,
  IonModal,
  useIonActionSheet,
  IonSlides,
  IonSlide,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';
import { useHistory } from 'react-router-dom';
import ArrowLeftSlider from '../../components/buttons/arrowLeftSlider';
import ArrowRightSlider from '../../components/buttons/arrowRightSlider';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DashCardSingle from '../../components/dashboardSingle/DashCardSingle';
import DashTabs from '../../components/DashTabs';
import HeaderWithIcon from '../../components/HeaderWithicon';
import DeletePlaceModal from '../../components/modals/deletePlace';
import EditPlaceModal from '../../components/modals/editPlace';
import { getSingleClientPoint } from '../../data/client-points/clientPointSingle.api';
import { getDailyConsumption } from '../../data/client-points/dailyConsumption.api';
import { getWeeklyConsumption } from '../../data/client-points/weeklyConsumption.api';
import { getMonthlyConsumption } from '../../data/client-points/monthlyConsumption.api';
import { getYearlyConsumption } from '../../data/client-points/yearlyConsumption.api';
import { deleteClientPoint } from '../../data/client-points/deleteClientPoint.api';
import { getProviders } from '../../data/client-points/providers.api';
import { getCurrentUser } from '../../data/user/current-user.api';
import { getlastReadout } from '../../data/client-points/lastReadout.api';
import ChartDaily from '../../components/dashboardSingle/chartDaily';
import ChartWeekly from '../../components/dashboardSingle/chartWeekly';
import ChartYearly from '../../components/dashboardSingle/chartYearly';
import DateCard from '../../components/DateCard';
import ReportPlaceModal from '../../components/modals/reportPlace';
import SubmitStatusModal from '../../components/modals/submitStatus';
import LocationCard from '../../components/LocationCard';
import Loader from '../../components/Loader';
import { Preferences } from '@capacitor/preferences';
import { useIonRouter } from '@ionic/react';
import ChartYearlySum from '../../components/dashboardSingle/chartYearlySum';

type CurrentUserlData = {
  isAuthenticated: boolean;
  id: string;
  userName: string;
  email: string;
};

type ProvidersData = {
  id: number;
  name: string;
  utilityTypeId: number;
  logo: string;
};

type SingleClientPoint = {
  utilityHasReadoutReporting: boolean;
  clientBuildingId: number;
  clientBuildingPartId: number;
  clientPointActive: boolean;
  id: number;
  clientPointId: number;
  creationTime: string;
  creatorId: string;
  lastModificationTime: string;
  lastModifierId: string;
  isDeleted: boolean;
  deleterId: string;
  deletionTime: string;
  code: string;
  customerCode: string;
  externalId: string;
  name: string;
  address: string;
  clientPointDescription: string;
  utilityId: number;
  clientPointCode: string;
  clientPointCustomerCode: string;
  userId: string;
  clientPointTypeId: number;
  utilityTypeName: string;
  dailyReadingFrequency: boolean;
  hourlyReadingFrequency: boolean;
  monthlyReadingFrequency: boolean;
  yearlyReadingFrequency: boolean;
  utilityUnit: string;
  clientPointManaged: boolean;
  consumptionStatus: number;
  hasAlarms: boolean;
  alarmsTitle: string;
  alarmsDescription: string;
  utilityNoDecimalNumbers: boolean;
  readoutList: [
    {
      value: string;
      value2: string;
      valueTime: string;
      valueTimeWeekName: string;
      valueTimeMonthName: string;
    }
  ];
};

type DailyConsumption = {
  startTime: string;
  endTime: string;
  startTimeWeekName: string;
  valueTimeYear: string;
  readoutList: [
    {
      value: string;
      value2: string;
      valueTime: string;
      valueTimeWeekName: string;
      valueTimeMonthName: string;
    }
  ];
  yearSumValues: [
    {
      value: string;
      value2: string;
    }
  ];
  value: string;
  value2: string;
};
type DailyConsumptionDay = {
  measuringPointId: string;
  period: string;
  utilityTypeName: string;
};

type ReadoutList = {
  value: string;
  value2: string;
  valueTime: string;
  valueTimeWeekName: string;
  valueTimeMonthName: string;
};
type ReadoutListMax = {
  value: string;
  value2: string;
};

const SingleDash: React.FC = (props: any) => {
  const ionRouter = useIonRouter();
  //Get id from url
  const { t } = useTranslation();
  const history = useHistory();
  const [dataLoad, setDataLoad] = useState<boolean>(false);
  const [dataLoadHeader, setDataLoadHeader] = useState<boolean>(false);
  const [dataLoadTop, setDataLoadTop] = useState<boolean>(false);
  const [dataLoadChart, setDataLoadChart] = useState<boolean>(false);
  const [dataChartLoad, setDataChartLoad] = useState<boolean>(false);
  const sliderDaily = useRef<HTMLIonSlidesElement>(null);
  const sliderWeekly = useRef<HTMLIonSlidesElement>(null);
  const sliderMonthly = useRef<HTMLIonSlidesElement>(null);
  const sliderYearly = useRef<HTMLIonSlidesElement>(null);
  const [activeItem, setactiveItem] = useState<number>();
  const [filteredItem, setfilteredItem] = useState<string>('');
  const [present, dismiss] = useIonActionSheet();
  const [errors, setErrors] = useState<string[]>([]);
  const [place, setPlace] = useState('');
  const [date, setDate] = useState('');
  const [lastReadoutVal, setLastReadoutVal] = useState<number>();
  const [lastReadoutVal2, setLastReadoutVal2] = useState<number>();
  const [pageId, setPageId] = useState('');
  const [Providers, setProviders] = useState<ProvidersData[]>([]);
  const [singleClientPoint, setSingleClientPoint] =
    useState<SingleClientPoint>();
  const [currentUserId, setcurrentUserId] = useState<CurrentUserlData>();
  //Daily consumption for last 3 days
  const [dailyConsumption, setdailyConsumption] = useState<DailyConsumption[]>(
    []
  );
  const [dailyConsumptionDay, setdailyConsumptionDay] =
    useState<DailyConsumptionDay>();
  const [maxDayVal, setMaxDayVal] = useState<number>(0);
  //Weekly consumption for last 3 weeks
  const [weeklyConsumption, setweeklyConsumption] = useState<
    DailyConsumption[]
  >([]);
  const [weeklyConsumptionDay, setweeklyConsumptionDay] =
    useState<DailyConsumptionDay>();
  const [maxWeekVal, setMaxWeekVal] = useState<number>(0);
  //Monthly consumption for last 3 months
  const [monthlyConsumption, setmonthlyConsumption] = useState<
    DailyConsumption[]
  >([]);
  const [monthlyConsumptionDay, setmonthlyConsumptionDay] =
    useState<DailyConsumptionDay>();
  const [maxMonthVal, setMaxMonthVal] = useState<number>(0);
  //Yearly consumption for last 3 years
  const [yearlyConsumption, setyearlyConsumption] = useState<
    DailyConsumption[]
  >([]);
  const [yearlyConsumptionDay, setyearlyConsumptionDay] =
    useState<DailyConsumptionDay>();
  const [maxYearVal, setMaxYearVal] = useState<number>(0);
  //Yearly consumption SUM for last 3 years
  const [yearlyConsumptionSum, setyearlyConsumptionSum] = useState<
    DailyConsumption[]
  >([]);
  const [yearlyConsumptionDaySum, setyearlyConsumptionDaySum] =
    useState<DailyConsumptionDay>();
  const [maxYearValSum, setMaxYearValSum] = useState<number>(0);

  //Modals
  const [showModal, setShowModal] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalReport, setShowModalReport] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showEditPlace, setShowEditPlace] = useState<number>();
  const [showSubmitStatus, setShowSubmitStatus] = useState(false);

  useIonViewDidEnter(() => { //useEffect
    //console.log('Change id: ', props.match.params.id);
    onSuccessEdit(props.match.params.id);
  }, [props.match.params.id]);

  //Delete Client point
  const handleDeleteClientPoint = async (id: string) => {
    //Delete client point from api:
    deleteClientPoint({ id: id })
      .then((response) => {
        // success
        //console.log('Deleted: ', id);
        //console.log(response);
        setShowModalDelete(false);
        //redirect to dashboard
        history.push('/dashboard');
      })
      .catch((error) => {
        // failure
        console.log(error);
      });
  };

  //Get daily data for chart from api
  const getDailyData = (id: string) => {
    //Daily consumption data from api
    getDailyConsumption({ id })
      .then((response) => {
        //console.log(response);
        const dailyData = response.periodList.map((item: DailyConsumption) => {
          return {
            ...item,
            readoutList: item.readoutList.map((values: ReadoutList) => {
              return {
                ...values,
                valueTime: new Date(values.valueTime).getHours(),
                value: Number(values.value).toFixed(3).toString(),
                value2: Number(values.value2).toFixed(3).toString(),
              };
            }),
          };
        });
        const list = response.periodList.map((item: DailyConsumption) => {
          return item.readoutList.map((values: ReadoutList) => {
            return {
              ...values,
              value: Number(values.value).toFixed(3),
              value2: Number(values.value2).toFixed(3),
            };
          });
        });
        setdailyConsumption(dailyData);
        setdailyConsumptionDay(response);

        //Get max Day Value
        const mergedDay = list.flat();
        const maxDayValueArray = mergedDay.map((values: ReadoutList) => {
          return {
            value: Number(values.value),
            value2: Number(values.value2),
          };
        });
        const DayValue = maxDayValueArray.map(
          (value: { value: any }) => value.value
        );
        const maxDayValue2 = maxDayValueArray.map(
          (value2: { value2: any }) => value2.value2
        );
        const mergeDay = DayValue.concat(maxDayValue2);
        const maxDayValue = Math.max(...mergeDay);
        setMaxDayVal(maxDayValue);

        //Remove loader
        setDataChartLoad(true)
      })
      .catch((error) => console.log(error));
  };

  //Get weekly data for chart when clicking on tab
  const getWeeklyData = (id: string) => {
    //Add loader
    setDataChartLoad(false)
    //Weekly consumption data from api
    getWeeklyConsumption({ id })
      .then((response) => {
        //console.log(response);
        const weeklyData = response.periodList.map((item: DailyConsumption) => {
          return {
            ...item,
            readoutList: item.readoutList.map((values: ReadoutList) => {
              return {
                ...values,
                valueTime: new Date(values.valueTime).getDate(),
                valueTimeWeekName:
                  values.valueTimeWeekName == 'Monday'
                    ? t('general.days.mon')
                    : values.valueTimeWeekName == 'Tuesday'
                      ? t('general.days.tue')
                      : values.valueTimeWeekName == 'Wednesday'
                        ? t('general.days.wed')
                        : values.valueTimeWeekName == 'Thursday'
                          ? t('general.days.thu')
                          : values.valueTimeWeekName == 'Friday'
                            ? t('general.days.fri')
                            : values.valueTimeWeekName == 'Saturday'
                              ? t('general.days.sat')
                              : values.valueTimeWeekName == 'Sunday'
                                ? t('general.days.sun')
                                : '',
                value: Number(values.value).toFixed(3).toString(),
                value2: Number(values.value2).toFixed(3).toString(),
              };
            }),
          };
        });

        const list = response.periodList.map((item: DailyConsumption) => {
          return item.readoutList.map((values: ReadoutList) => {
            return {
              ...values,
              value: Number(values.value).toFixed(3),
              value2: Number(values.value2).toFixed(3),
            };
          });
        });
        setweeklyConsumption(weeklyData);
        setweeklyConsumptionDay(response);
        //console.log(weeklyData);

        //Get max Week Value
        const mergedWeek = list.flat();
        const maxWeekValueArray = mergedWeek.map((values: ReadoutList) => {
          return {
            value: Number(values.value),
            value2: Number(values.value2),
          };
        });
        const WeekValue = maxWeekValueArray.map(
          (value: { value: any }) => value.value
        );
        const maxWeekValue2 = maxWeekValueArray.map(
          (value2: { value2: any }) => value2.value2
        );
        const mergeWeek = WeekValue.concat(maxWeekValue2);
        const maxWeekValue = Math.max(...mergeWeek);
        setMaxWeekVal(maxWeekValue);
        //Remove loader
        setDataChartLoad(true)
      })
      .catch((error) => console.log(error));
  };

  //Get monthly data for chart when clicking on tab
  const getMonthlyData = (id: string) => {
    //Add loader
    setDataChartLoad(false)
    //Monthly consumption data from api
    getMonthlyConsumption({ id })
      .then((response) => {
        //console.log(response);
        const monthlyData = response.periodList.map(
          (item: DailyConsumption) => {
            return {
              ...item,
              readoutList: item.readoutList.map((values: ReadoutList) => {
                return {
                  ...values,
                  valueTime: new Date(values.valueTime).getDate(),
                  value: Number(values.value).toFixed(3).toString(),
                  value2: Number(values.value2).toFixed(3).toString(),
                };
              }),
            };
          }
        );
        const list = response.periodList.map((item: DailyConsumption) => {
          return item.readoutList.map((values: ReadoutList) => {
            return {
              ...values,
              value: Number(values.value).toFixed(3),
              value2: Number(values.value2).toFixed(3),
            };
          });
        });
        setmonthlyConsumption(monthlyData);
        setmonthlyConsumptionDay(response);

        //Get max Month Value
        const mergedMonth = list.flat();
        const maxMonthValueArray = mergedMonth.map((values: ReadoutList) => {
          return {
            value: Number(values.value),
            value2: Number(values.value2),
          };
        });
        const MonthValue = maxMonthValueArray.map(
          (value: { value: any }) => value.value
        );
        const maxMonthValue2 = maxMonthValueArray.map(
          (value2: { value2: any }) => value2.value2
        );
        const mergeMonth = MonthValue.concat(maxMonthValue2);
        const maxMonthValue = Math.max(...mergeMonth);
        setMaxMonthVal(maxMonthValue);
        //Remove loader
        setDataChartLoad(true)
      })
      .catch((error) => console.log(error));
  };

  //Get yearly data for chart when clicking on tab
  const getYearlyData = (id: string) => {
    //Add loader
    setDataChartLoad(false)
    //Yearly consumption data from api
    getYearlyConsumption({ id })
      .then((response) => {
        //console.log(response);
        const yearlyData = response.periodList.map((item: DailyConsumption) => {
          return {
            ...item,
            readoutList: item.readoutList.map((values: ReadoutList) => {
              return {
                ...values,
                valueTime: new Date(values.valueTime).getMonth() + 1,
                valueTimeMonthName:
                  new Date(values.valueTime).getMonth() == 0
                    ? t('general.months.jan')
                    : new Date(values.valueTime).getMonth() == 1
                      ? t('general.months.feb')
                      : new Date(values.valueTime).getMonth() == 2
                        ? t('general.months.mar')
                        : new Date(values.valueTime).getMonth() == 3
                          ? t('general.months.apr')
                          : new Date(values.valueTime).getMonth() == 4
                            ? t('general.months.may')
                            : new Date(values.valueTime).getMonth() == 5
                              ? t('general.months.jun')
                              : new Date(values.valueTime).getMonth() == 6
                                ? t('general.months.jul')
                                : new Date(values.valueTime).getMonth() == 7
                                  ? t('general.months.aug')
                                  : new Date(values.valueTime).getMonth() == 8
                                    ? t('general.months.sep')
                                    : new Date(values.valueTime).getMonth() == 9
                                      ? t('general.months.oct')
                                      : new Date(values.valueTime).getMonth() == 10
                                        ? t('general.months.nov')
                                        : new Date(values.valueTime).getMonth() == 11
                                          ? t('general.months.dec')
                                          : '',
                value: Number(values.value).toFixed(3).toString(),
                value2: Number(values.value2).toFixed(3).toString(),
              };
            }),
          };
        });
        const list = response.periodList.map((item: DailyConsumption) => {
          return item.readoutList.map((values: ReadoutList) => {
            return {
              ...values,
              value: Number(values.value).toFixed(3),
              value2: Number(values.value2).toFixed(3),
            };
          });
        });
        setyearlyConsumption(yearlyData);
        setyearlyConsumptionDay(response);

        const yearlyDataSum = response.periodList.map((item: DailyConsumption) => {
          return {
            ...item,
            readoutList: item.readoutList.map((values: ReadoutList) => {
              return {
                ...values,
                valueTime: new Date(values.valueTime).getMonth() + 1,
                valueTimeYear: values.valueTime,
                value: Number(values.value).toFixed(3).toString(),
                value2: Number(values.value2).toFixed(3).toString(),
              };
            }),
            yearSumValues: [
              {
                valueTimeMonthName: new Date(item.startTime).getFullYear(),
                value: item.readoutList.map((sum: any) => sum.value).reduce((a: any, b: any) => a + b).toFixed(2),
                value2: item.readoutList.map((sum: any) => sum.value2).reduce((a: any, b: any) => a + b).toFixed(2)
              },
            ],
            value: item.readoutList.map((sum: any) => sum.value).reduce((a: any, b: any) => a + b).toFixed(2),
            value2: item.readoutList.map((sum: any) => sum.value2).reduce((a: any, b: any) => a + b).toFixed(2),
            valueTimeMonthName: new Date(item.startTime).getFullYear(),
          };
        });

        setyearlyConsumptionSum(yearlyDataSum);
        setyearlyConsumptionDaySum(yearlyDataSum);

        //Get max Month Value
        const mergedYear = list.flat();
        const maxYearValueArray = mergedYear.map((values: ReadoutList) => {
          return {
            value: Number(values.value),
            value2: Number(values.value2),
          };
        });
        const YearValue = maxYearValueArray.map(
          (value: { value: any }) => value.value
        );
        const maxYearValue2 = maxYearValueArray.map(
          (value2: { value2: any }) => value2.value2
        );
        const mergeYear = YearValue.concat(maxYearValue2);
        const maxYearValue = Math.max(...mergeYear);
        setMaxYearVal(maxYearValue);
        //Remove loader
        setDataChartLoad(true)
      })
      .catch((error) => console.log(error));
  };

  //Check if data exist for particular day
  /*const BeforeYesterdayUndefined = dailyConsumptionBeforeYesterday[0] === undefined && "0";
    const YesterdayUndefined = dailyConsumptionYesterday[0] === undefined && "0";
    const TodayUndefined = dailyConsumptionToday[0] === undefined && "0";
    //Check if data exist for particular week
    const BeforeLastWeekUndefined = weeklyConsumptionBeforeLastWeek[0] === undefined && "0";
    const LastWeekUndefined = weeklyConsumptionLastWeek[0] === undefined && "0";
    const ThisWeekUndefined = weeklyConsumption[0] === undefined && "0";
    //Check if data exist for particular month
    const BeforeLastMonthUndefined = monthlyConsumptionBeforeLastMonth[0] === undefined && "0";
    const LastMonthUndefined = monthlyConsumptionLastMonth[0] === undefined && "0";
    const ThisMonthUndefined = monthlyConsumption[0] === undefined && "0";
    //Check if data exist for particular year
    const BeforeLastYearUndefined = yearlyConsumptionBeforeLastYear[0] === undefined && "0";
    const LastYearUndefined = yearlyConsumptionLastYear[0] === undefined && "0";
    const ThisYearUndefined = yearlyConsumption[0] === undefined && "0";*/

  console.log(singleClientPoint)

  const activeItemClass = 'bg-blue-5 text-white shadow-md';
  const data = {
    tabs: [
      {
        id: 'day',
        title: t('general.hour'),
        className: `rounded-2xl py-1 px-3 text-center inline-block`,
        OutherClass: `${singleClientPoint?.hourlyReadingFrequency == false && 'hidden'
          }`,
      },
      {
        id: 'week',
        title: t('general.day'),
        className: `flex items-center rounded-2xl py-1 px-3 text-center`,
        OutherClass: `px-2 ${singleClientPoint?.dailyReadingFrequency == false && 'hidden'
          }`,
      },
      {
        id: 'month',
        title: t('general.month'),
        className: `flex items-center rounded-2xl py-1 px-3 text-center`,
        OutherClass: `px-2 ${singleClientPoint?.monthlyReadingFrequency == false && 'hidden'
          }`,
      },
      {
        id: 'year',
        title: t('general.year'),
        className: `flex items-center rounded-2xl py-1 px-3 text-center justify-end`,
        OutherClass: `${singleClientPoint?.yearlyReadingFrequency == false && 'hidden'
          }`,
      },
    ],
  };

  //Edit/delete Card
  const onClickCard = (index: number) => {
    present({
      mode: 'ios',
      buttons: [
        {
          text: t('dashboard.ctaEdit'),
          cssClass: 'important-text-gray-20',
          handler: () => {
            setShowModalEdit(true);
            setShowEditPlace(index);
          },
        },
        {
          text: t('dashboard.reportAnIssue'),
          cssClass: 'important-text-gray-20',
          handler: () => {
            setShowModalReport(true);
            setShowEditPlace(index);
          },
        },
        {
          text: t('dashboard.ctaDelete'),
          role: 'destructive',
          handler: () => {
            setShowModalDelete(true);
            setShowEditPlace(index);
            //console.log(index);
          },
        },
        {
          text: t('dashboard.ctaCancel'),
          cssClass: 'important-text-gray-20',
          role: 'cancel',
        },
      ],
    });
  };

  //Delete card
  const tabAction = (index: number) => {
    //console.log(index);
  };
  const tabActionDelete = (index: number) => {
    setShowModalDelete(true);
    //console.log(index);
  };

  const tabActionNotify = (index: number) => {
    //console.log(index);
  };

  //Tabs filtering:
  const onClickTab = (index: number, id: string) => {
    setactiveItem(index);
    setfilteredItem(id);
    //Get data from api when click on tab
    if (id == 'week') {
      //Add loader
      setDataChartLoad(false)
      getWeeklyData(props.match.params.id);
    } else if (id == 'month') {
      //Add loader
      setDataChartLoad(false)
      //getMonthlyData(props.match.params.id);
      getYearlyData(props.match.params.id);
    } else if (id == 'year') {
      //Add loader
      setDataChartLoad(false)
      getYearlyData(props.match.params.id);
    }
  };

  //Slider options:
  const slideOpts = {
    initialSlide: 2,
    speed: 400,
  };

  //Slider button navigation:
  const swipeNextDaily = () => {
    sliderDaily.current?.slideNext();
  };
  const swipePrevDaily = () => {
    sliderDaily.current?.slidePrev();
  };
  const swipeNextWeekly = () => {
    sliderWeekly.current?.slideNext();
  };
  const swipePrevWeekly = () => {
    sliderWeekly.current?.slidePrev();
  };
  const swipeNextMonthly = () => {
    sliderMonthly.current?.slideNext();
  };
  const swipePrevMonthly = () => {
    sliderMonthly.current?.slidePrev();
  };
  const swipeNextYearly = () => {
    sliderYearly.current?.slideNext();
  };
  const swipePrevYearly = () => {
    sliderYearly.current?.slidePrev();
  };



  useIonViewWillEnter(() => {
    setDataLoad(false)
    setDataLoadHeader(false)
    setDataLoadTop(false)
    setDataLoadChart(false)
    //console.log(id)
    // onSuccessEdit();
  }, []);

  //Edit client point success
  const onSuccessEdit = (id: string) => {
    //Single client point data from api
    getSingleClientPoint({ id })
      .then((response) => {
        //console.log(response);
        setSingleClientPoint(response);

        //Data loaded
        setDataLoadHeader(true)
        setDataLoadTop(true)
        setTimeout(() => { setDataLoad(true) }, 2000);

        //Set active tab
        if (response.hourlyReadingFrequency == true) {
          getDailyData(id);
          setactiveItem(0);
          const timer = setTimeout(() => {
            setfilteredItem('day');
            //Data loaded Chart
            setDataLoadChart(true)
          }, 500);
          return () => clearTimeout(timer);
        }
        if (
          response.dailyReadingFrequency == true &&
          response.hourlyReadingFrequency == false
        ) {
          getWeeklyData(id);
          setactiveItem(1);
          const timer = setTimeout(() => {
            setfilteredItem('week');
          }, 500);
          return () => clearTimeout(timer);
        }
        if (
          response.monthlyReadingFrequency == true &&
          response.dailyReadingFrequency == false &&
          response.hourlyReadingFrequency == false
        ) {
          getYearlyData(id);
          setactiveItem(2);
          const timer = setTimeout(() => {
            setfilteredItem('month');
          }, 500);
          return () => clearTimeout(timer);
        }
        if (
          response.yearlyReadingFrequency == true &&
          response.monthlyReadingFrequency == false &&
          response.hourlyReadingFrequency == false &&
          response.dailyReadingFrequency == false
        ) {
          getYearlyData(id);
          setactiveItem(3);
          const timer = setTimeout(() => {
            setfilteredItem('year');
          }, 500);
          return () => clearTimeout(timer);
        }
      })
      .catch((error) => {
        // failure
        console.log(error);
        //Remove token if error
        Preferences.remove({ key: 'token' });
        Preferences.clear();
      });

    //Get providers
    getProviders()
      .then((response) => {
        setProviders(response);
        //console.log(response);
      })
      .catch((error) => console.log(error));

    //Get current user
    getCurrentUser()
      .then((response) => {
        //console.log(response);
        setcurrentUserId(response);
      })
      .catch((error) => console.log(error));

    //Get last Readout Date
    getlastReadout({ id })
      .then((response) => {
        setDate(response.periodList[0].startTime);
        setLastReadoutVal(response.periodList[0].readoutList[0].value);
        setLastReadoutVal2(response.periodList[0].readoutList[0].value2);
        //console.log(response);
      })
      .catch((error) => console.log(error));




  };

  //Submit status
  const onClickSubmitStatus = () => {
    setShowSubmitStatus(true);
  };


  const handleSubmitStatus = async (id: string) => {
    //console.log('Submit status: ', id);
  };

  const sendReporthandle = (index: any) => {
    setShowModalReport(true);
    setShowEditPlace(index);
  }
  const removehandle = (index: any) => {
    setShowModalDelete(true);
    setShowEditPlace(index);
    //console.log(index);
  }

  //console.log(currentProvider);

  //Current provider
  const currentpPovider = Providers.filter((items) => items.id.toString() === singleClientPoint?.utilityId.toString()).map((items: ProvidersData) => {
    return {
      ...items,
    }
  });

  //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/dashboardmain/")
    });
  });

  return (
    <IonPage>

      <>
        {dataLoadHeader ? (
          <HeaderWithIcon
            onClickCard={() => onClickCard(0)}
            title={singleClientPoint?.clientPointDescription}
            backLink={`/dashboardmain/client-building/${singleClientPoint?.clientBuildingId}/part/${singleClientPoint?.clientBuildingPartId ? singleClientPoint?.clientBuildingPartId : "0"}/slide/${props.match.params.slide ? props.match.params.slide : 0}`}
            titleColor="text-gray-55 dark:text-white text-left dark:bg-gray-900"
            bgColor="white"
            type={singleClientPoint?.utilityTypeName}
          />
        ) : (
          <div className="relative top-0 left-0 w-full h-12 flex items-center justify-center">
            <Loader />
          </div>
        )}
        <IonContent fullscreen color="white">
          {dataLoadTop ? (
            <DashCardSingle
              NoDecimalNumbers={singleClientPoint?.utilityNoDecimalNumbers}
              sendReporthandle={() => sendReporthandle(singleClientPoint?.clientPointId)}
              hasReadout={singleClientPoint?.utilityHasReadoutReporting}
              date={
                singleClientPoint?.readoutList.length.toString() != '0'
                  ? singleClientPoint?.readoutList[0] !== null
                    ? date
                    : ''
                  : ''
              }
              type={singleClientPoint?.utilityTypeName}
              //measure={singleClientPoint?.readoutList[0] !== undefined ? singleClientPoint?.readoutList[0].vif.unit : ''}
              /*measure={
                singleClientPoint?.utilityTypeName === 'ELECTRICITY'
                  ? 'kWh'
                  : singleClientPoint?.utilityTypeName === 'HEAT'
                    ? 'kW'
                    : 'm3'
              }*/
              measure={singleClientPoint?.utilityUnit}
              lowLabel={singleClientPoint?.utilityTypeName}
              highLabel={singleClientPoint?.utilityTypeName}
              lowNum={
                lastReadoutVal != null
                  ? Number(lastReadoutVal)
                    .toFixed(3)
                    .toString()
                  : 'hidden'
              }
              highNum={
                lastReadoutVal2 != null
                  ? Number(lastReadoutVal2)
                    .toFixed(3)
                    .toString()
                  : 'hidden'
              }
              lowRate={t('dashboard.lowRateLabel')}
              highRate={t('dashboard.highRateLabel')}
              onClickSubmitStatus={() => onClickSubmitStatus()}
              className="bg-gray-45"
              consumptionStatus={singleClientPoint?.consumptionStatus}
              hasAlarms={singleClientPoint?.hasAlarms}
              alarmsDescription={singleClientPoint?.alarmsDescription}
              alarmsTitle={singleClientPoint?.alarmsTitle}
            />
          ) : (
            <div className="relative top-0 left-0 w-full h-12 flex items-center justify-center">
              <Loader />
            </div>
          )}

          <div className="flex items-center justify-between p-4 text-sm text-gray-10">
            {data.tabs.map((tab, index) => {
              return (
                <DashTabs
                  id={tab.id}
                  key={index}
                  image=""
                  title={tab.title}
                  className={`${tab.className} ${activeItem === index ? activeItemClass : ''
                    }`}
                  OutherClass={tab.OutherClass}
                  onClickTab={() => onClickTab(index, tab.id)}
                />
              );
            })}
          </div>


          <div className="h-96 mb-4">

            <div id="1" className={`relative ${filteredItem === 'day' ? '' : 'hidden'}`}>
              {dataChartLoad ? (
                <>
                  <div className="absolute flex justify-between w-full px-5 mt-1">
                    <ArrowLeftSlider onClick={() => swipePrevDaily()} className="" />
                    <ArrowRightSlider onClick={() => swipeNextDaily()} className="" />
                  </div>

                  <IonSlides pager={false} options={slideOpts} ref={sliderDaily}>
                    {dailyConsumption.map((data, i) => (
                      <IonSlide key={`${i.toString()}-daily`}>
                        <div className="w-full mt-2">
                          {data.startTime !== '' && (
                            <DateCard
                              getDay={true}
                              getMonth={true}
                              getYear={true}
                              date={data.startTime}
                              className="text-sm text-gray-55 dark:text-gray-15"
                            />
                          )}
                          <ChartDaily
                            chartData={data.readoutList}
                            chartQty={
                              (dailyConsumptionDay?.utilityTypeName == 'ELECTRICITY')
                                ? true
                                : false
                            }
                            chartType={dailyConsumptionDay?.utilityTypeName}
                            maxValue={maxDayVal}
                          />
                        </div>
                      </IonSlide>
                    ))}
                  </IonSlides>
                </>
              ) : (
                <div className="absolute mt-32 top-0 left-0 w-full h-full flex items-center justify-center">
                  <Loader />
                </div>
              )}
            </div>

            <div id="2" className={filteredItem === 'week' ? '' : 'hidden'}>
              {dataChartLoad ? (
                <>
                  <div className="absolute flex justify-between w-full px-5 mt-2">
                    <ArrowLeftSlider onClick={() => swipePrevWeekly()} className="" />
                    <ArrowRightSlider onClick={() => swipeNextWeekly()} className="" />
                  </div>
                  <IonSlides pager={false} options={slideOpts} ref={sliderWeekly}>
                    {weeklyConsumption.map((data, i) => (
                      <IonSlide key={`${i.toString()}-weekly`}>
                        <div className="w-full mt-2">
                          <div className="flex items-center justify-center">
                            {data.startTime !== '' && (
                              <DateCard
                                getDay={true}
                                getMonth={true}
                                getYear={true}
                                date={data.startTime}
                                className="text-sm text-gray-55 dark:text-gray-15"
                              />
                            )}
                            <span className="w-8 text-center text-black-5">-</span>
                            {data.startTime !== '' && (
                              <DateCard
                                getDay={true}
                                getMonth={true}
                                getYear={true}
                                date={data.endTime}
                                className="text-sm text-gray-55 dark:text-gray-15"
                              />
                            )}
                          </div>
                          <ChartWeekly
                            chartData={data.readoutList}
                            chartQty={
                              (weeklyConsumptionDay?.utilityTypeName == 'ELECTRICITY')
                                ? true
                                : false
                            }
                            chartType={weeklyConsumptionDay?.utilityTypeName}
                            maxValue={maxWeekVal}
                          />
                        </div>
                      </IonSlide>
                    ))}
                  </IonSlides>
                </>
              ) : (
                <div className="absolute mt-32 top-0 left-0 w-full h-full flex items-center justify-center">
                  <Loader />
                </div>
              )}
            </div>

            <div id="3" className={filteredItem === 'month' ? '' : 'hidden'}>
              {dataChartLoad ? (
                <>
                  <div className="absolute flex justify-between w-full px-5 mt-2">
                    <ArrowLeftSlider onClick={() => swipePrevMonthly()} className="" />
                    <ArrowRightSlider onClick={() => swipeNextMonthly()} className="" />
                  </div>
                  <IonSlides pager={false} options={slideOpts} ref={sliderMonthly}>
                    {yearlyConsumption.map((data, i) => (
                      <IonSlide key={`${i.toString()}-monthly`}>
                        <div className="w-full mt-2">
                          <div className="flex items-center justify-center">
                            {data.startTime !== '' && (
                              <DateCard
                                getDay={false}
                                getMonth={false}
                                getYear={true}
                                date={data.startTime}
                                className="text-sm text-gray-55 dark:text-gray-15"
                              />
                            )}
                          </div>
                          <ChartYearly
                            chartData={data.readoutList}
                            chartQty={
                              (yearlyConsumptionDay?.utilityTypeName == 'ELECTRICITY')
                                ? true
                                : false
                            }
                            chartType={yearlyConsumptionDay?.utilityTypeName}
                            maxValue={maxYearVal}
                          />
                        </div>
                      </IonSlide>
                    ))}
                  </IonSlides>
                </>
              ) : (
                <div className="absolute mt-32 top-0 left-0 w-full h-full flex items-center justify-center">
                  <Loader />
                </div>
              )}
            </div>

            <div id="4" className={filteredItem === 'year' ? '' : 'hidden'}>
              {dataChartLoad ? (
                <>
                  {/* <div className="absolute flex justify-between w-full px-5 mt-2">
                    <ArrowLeftSlider onClick={() => swipePrevYearly()} className="" />
                    <ArrowRightSlider onClick={() => swipeNextYearly()} className="" />
              </div>*/}
                  <IonSlides pager={false} options={slideOpts} ref={sliderYearly}>

                    <IonSlide key={`1-yearly`}>
                      <div className="w-full mt-2">
                        <div className="flex items-center justify-center">
                          {/*yearlyConsumptionSum[0].startTime !== '' && (
                            <DateCard
                              getDay={false}
                              getMonth={false}
                              getYear={true}
                              date={yearlyConsumptionSum[0].startTime}
                              className="text-sm text-gray-55 dark:text-gray-15"
                            />
                          )*/}
                        </div>
                        <ChartYearlySum
                          chartData={yearlyConsumptionSum}
                          chartQty={
                            (yearlyConsumptionDay?.utilityTypeName == 'ELECTRICITY')
                              ? true
                              : false
                          }
                          chartType={yearlyConsumptionDay?.utilityTypeName}
                          maxValue={maxYearVal}
                        />
                      </div>
                    </IonSlide>

                  </IonSlides>
                </>
              ) : (
                <div className="absolute mt-32 top-0 left-0 w-full h-full flex items-center justify-center">
                  <Loader />
                </div>
              )}
            </div>
          </div>


          {dataLoadTop ? (
            <div className="px-2">
              <LocationCard
                id="1"
                title={singleClientPoint?.clientPointDescription != null
                  ? singleClientPoint?.clientPointDescription.toString()
                  : currentpPovider[0]?.name}
                type={singleClientPoint?.utilityTypeName}
                ShowHide=""
                consumptionPointTitle={t('auth.register.consumptionPointTitle')}
                user={t('auth.register.payer')}
                consumptionPointId={singleClientPoint?.clientPointCode.toString()}
                userId={singleClientPoint?.clientPointCustomerCode.toString()}
                className="bg-gray-45 my-5"
                icon={currentpPovider[0]?.logo}
              />
            </div>
          ) : (
            <div className="relative top-0 left-0 w-full h-12 flex items-center justify-center">
              <Loader />
            </div>
          )}

          <div className="flex justify-center flex-wrap demo-hide-cont">
            {/*
            !singleClientPoint?.clientPointManaged && (
              <button
                onClick={() => removehandle(singleClientPoint?.clientPointId)}
                className="text-red-5 mb-4 w-full"
              >
                {t('dashboard.remove')}
              </button>
            )*/}
          </div>

          <button
            onClick={() => sendReporthandle(singleClientPoint?.clientPointId)}
            className="text-gray-600 mb-8 w-full dark:text-white mt-4 text-sm font-bold"
          >
            {t('dashboard.reportIssue.wrongCalculation')} <span className='text-blue-40'>{t('dashboard.reportAnIssue')}</span>
          </button>


        </IonContent>

        {
          //<IonFooter mode="ios" className="ion-no-border" color="graybg">}
          //<FooterTabs tabAction={() => tabAction(0)} tabActionDelete ={() => tabActionDelete(1)} tabActionNotify = {() => tabActionNotify(2)} />
          //</IonFooter>
        }

        <EditPlaceModal
          onDidDismiss={() => setShowModalEdit(false)}
          isOpen={showModalEdit}
          cssClass={showEditPlace === 0 ? '' : 'hidden'}
          key={singleClientPoint?.clientPointId.toString()}
          id={`${singleClientPoint?.clientPointId.toString()}-edit`}
          selectProvider={singleClientPoint?.utilityId.toString()}
          inputkoda={singleClientPoint?.clientPointCode.toString()}
          page="single"
          inputkodaUser={singleClientPoint?.clientPointCustomerCode.toString()}
          inputPlace={
            singleClientPoint?.clientPointDescription != null
              ? singleClientPoint?.clientPointDescription.toString()
              : ''
          }
          //onClick= {() => handleEditPoint(singleClientPoint?.clientPointId.toString() ?? '')}
          onCancelEdit={() => setShowModalEdit(false)}
          ProvidersList={Providers}
          onSuccessEdit={() => onSuccessEdit(props.match.params.id)}
        />

        <IonModal isOpen={showModalDelete}>
          <DeletePlaceModal
            key={singleClientPoint?.clientPointId.toString()}
            cssClass=""
            id={`${singleClientPoint?.clientPointId.toString()}-delete`}
            onClick={() =>
              handleDeleteClientPoint(
                singleClientPoint?.clientPointId.toString() || ''
              )
            }
            onCancelDelete={() => setShowModalDelete(false)}
          />
          {/*
          <DeletePlaceModal cssClass="" id="0" onClick= {() => setShowModalDelete(false)} onCancelDelete= {() => setShowModalDelete(false)}/>
          */}
        </IonModal>

        <IonModal key="report-modal" isOpen={showModalReport}>
          <ReportPlaceModal
            onDidDismiss={() => setShowModalReport(false)}
            key={`${singleClientPoint?.clientPointId.toString()}-report`}
            cssClass=""
            id={`${singleClientPoint?.clientPointId.toString()}`}
            /*onClick={() =>
              handleReportClientPoint(clientPoint.clientPointId.toString())
            }*/
            onCancelReport={() => setShowModalReport(false)}
          />
        </IonModal>

        <IonModal key="submit-status-modal" isOpen={showSubmitStatus}>
          <SubmitStatusModal
            key={`${singleClientPoint?.clientPointId.toString()}-submit`}
            cssClass=""
            id={`${singleClientPoint?.clientPointId.toString()}`}
            onCancelSubmit={() => setShowSubmitStatus(false)}
            highRateShow={singleClientPoint?.utilityTypeName == "ELECTRICITY" ? true : false}
            meter={singleClientPoint?.utilityUnit}
            lastLowRate={lastReadoutVal ? lastReadoutVal : 0}
            lastHighRate={lastReadoutVal2 ? lastReadoutVal2 : 0}
            NoDecimalNumbers={singleClientPoint?.utilityNoDecimalNumbers}
          />
        </IonModal>

      </>

    </IonPage >
  );
};

export default SingleDash;
