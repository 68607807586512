import {
  IonContent,
  IonPage,
  IonButton,
  IonToolbar,
  IonFooter,
} from '@ionic/react';
import { useState } from 'react';
import Header from './../../components/Header';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';
import HeaderNoBack from '../../components/HeaderWithoutBackBut';
import { utilityRequest } from '../../data/client-points/utilityRequest';
import { Redirect, useHistory } from 'react-router';
import { useIonRouter } from '@ionic/react';

type FormErrors = {
  koda: string[] | undefined;
};

function RegisterProvider() {
  const ionRouter = useIonRouter();
  const { t } = useTranslation();
  const history = useHistory();
  const [errors, setErrors] = useState<FormErrors>({
    koda: undefined,
  });
  const [koda, setProvider] = useState('');

  //Handle provider input
  const handleInputproviderChange = (e: any) => {
    if (e.target.value === '') {
      setErrors({
        ...errors,
        koda: ['Provider is required'],
      });
    } else {
      setErrors({
        ...errors,
        koda: undefined,
      });
    }
    setProvider(e.target.value);
  };

  const ProviderRegistration = () => {

    if (
      koda !== ''
    ) {
      utilityRequest(
        koda
      )
        .then((response: any) => {
          // success
          //Set empty strings to storage
          console.log('Provider register');
          //console.log(response);
          history.push('/auth/register-provider-thankyou');
        })
        .catch((error) => {
          // failure
          console.log(error);
        });
    } else {
      setErrors({
        koda: ['Provider is required'],
      });
    }

  };

   //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/auth/login")
    });
  });


  return (
    <IonPage>
      <Header
        title={t('auth.register.providersTitle')}
        backLink=""
        titleColor="text-black-5 dark:text-white text-center"
        rightIcon={false}
      />
      <IonContent fullscreen>
        <div className="flex justify-center p-4 pt-4">
          <div className="w-full">
            <div className="mt-2 mb-6 text-sm text-gray-5 hidden">
              {/*t('auth.register.providersText')*/}
            </div>
            <div className="py-2">
              <Input
                type="text"
                label={t('auth.register.provider_suggest_label')}
                error={errors.koda}
                value={koda}
                onChange={handleInputproviderChange}
              />
            </div>
          </div>
        </div>
      </IonContent>

      <IonFooter mode="ios" className="p-4 ion-no-border">
        <IonToolbar>
          <IonButton
            shape="round"
            color="orange"
            className="w-full h-12 text-base font-semibold normal-case"
            onClick={ProviderRegistration}
          >
            {t('auth.register.cta_provider_suggest')}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
}

export default RegisterProvider;
