import MessageListItem from '../components/MessageListItem';
import { useState } from 'react';
import { Message, getMessages } from '../data/messages';
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRouterLink,
  useIonViewWillEnter,
} from '@ionic/react';
import { Redirect } from 'react-router';

const Home: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);

  useIonViewWillEnter(() => {
    const msgs = getMessages();
    setMessages(msgs);
  });

  const refresh = (e: CustomEvent) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };

  //Testing purpose
  return <Redirect to="/auth/login" /> 

  return (
    <IonPage id="home-page">
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={refresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <div className="flex items-end justify-center h-full bg-gradient-to-b from-blue-5 to-blue-10"> 
            <div className="w-56">
              <div className="flex justify-center">
                  <img src="/assets/icon/logo-white.svg"/>
                </div>
                <div className="flex justify-center my-5">
                  <img src="/assets/icon/logo-text-white.svg"/>
                </div>
                <div className="mt-40 mb-20">
                  <img className="mx-auto" src="/assets/icon/loader-white.svg" />
                </div>
                <div className="flex flex-col h-full px-4 text-center">
                     <IonRouterLink className="text-white" routerLink="/auth/login">Login</IonRouterLink>
                </div>
            </div>
          </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;
