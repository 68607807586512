import { useTranslation } from "react-i18next";

interface ContainerProps {
  volume?: any | null;
}

const VolumeSensorCard: React.FC<ContainerProps> = ({
  volume

}) => {
  const { t } = useTranslation();

  //Water volume sensor
  const volumeWidth = (volume.value.toFixed(2) <= 20) ? "20%" : (volume.value.toFixed(2) > 20 && volume.value.toFixed(2) <= 40) ? "40%" :
    (volume.value.toFixed(2) > 40 && volume.value.toFixed(2) <= 60) ? "60%" : (volume.value.toFixed(2) > 60 && volume.value.toFixed(2) <= 80) ? "80%" : "100%";

  return (
    <div className="relative bg-gray-200 h-6 w-full rounded-full">
      <div
        className="bg-gradient-to-r from-blue-25 to-blue-5 h-6 rounded-full w-full relative"
        style={{
          width: volumeWidth
        }}
      >
        <div className="absolute left-0 top-0 h-6 bg-gradient-to-r from-blue-5 to-blue-25 rounded-full text-sm font-bold flex items-center justify-center w-full">
          {(volume.value.toFixed(2) <= 20) && (
            <span className="bg-blue-5 ml-auto rounded-full px-2 inline-flex h-6 items-center whitespace-nowrap">0 - 20 %</span>
          )}
          {(volume.value.toFixed(2) > 20 && volume.value.toFixed(2) <= 40) && (
            <span className="bg-blue-5 ml-auto rounded-full px-2 inline-flex h-6 items-center whitespace-nowrap">21 - 40 %</span>
          )}
          {(volume.value.toFixed(2) > 40 && volume.value.toFixed(2) <= 60) && (
            <span className="bg-blue-5 ml-auto rounded-full px-2 inline-flex h-6 items-center whitespace-nowrap">41 - 60 %</span>
          )}
          {(volume.value.toFixed(2) > 60 && volume.value.toFixed(2) <= 80) && (
            <span className="bg-blue-5 ml-auto rounded-full px-2 inline-flex h-6 items-center whitespace-nowrap">61 - 80 %</span>
          )}
          {(volume.value.toFixed(2) >= 81) && (
            <span className="bg-blue-5 ml-auto rounded-full px-2 inline-flex h-6 items-center whitespace-nowrap">81 - 100 %</span>
          )}
        </div>
      </div>
    </div>
  );
};
export default VolumeSensorCard;
