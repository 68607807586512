import { useState } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
  IonAvatar,
  IonModal,
  useIonActionSheet
} from '@ionic/react';
import { deleteClientPoint } from '../data/client-points/deleteClientPoint.api';
import { getClientPoints } from '../data/client-points/clientPoints.api';
import { getProviders } from '../data/client-points/providers.api';
import DashCard from './../components/DashCard'; //type: WATER, GAS, ELECTRICITY, HEAT
import EditPlaceModal from './../components/modals/editPlace';
import DeletePlaceModal from './../components/modals/deletePlace';
import { useTranslation } from 'react-i18next';
import ReportPlaceModal from '../components/modals/reportPlace';
import EULogos from '../components/EULogos';
import DashTabsNoIcons from '../components/DashTabsNoIcons';
import InvoiceCard from '../components/InvoiceCard';
import DocumentCard from '../components/DocumentCard';

type ProvidersData = {
  id: number;
  name: string;
  utilityTypeId: number;
  logo: string;
};

type ClientPoint = {
  clientPointDescription: string;
  clientPointTypeName: string;
  dailyReadingFrequency: boolean;
  utilityTypeName: 'WATER' | 'GAS' | 'ELECTRICITY' | 'HEAT';
  clientPointId: number;
  utilityName: string;
  utilityId: number;
  clientPointCode: string;
  clientPointCustomerCode: string;
  utilityNoDecimalNumbers: boolean;
  readoutList: [
    {
      value: string;
      value2: string;
      valueTime: string;
      valueTimeWeekName: string;
    }
  ];
};

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const [present, dismiss] = useIonActionSheet();

  const [activeItem, setactiveItem] = useState<number>(0);
  const [filteredItem, setfilteredItem] = useState<string>('services');
  const [Providers, setProviders] = useState<ProvidersData[]>([]);
  const [clientPoints, setClientPoints] = useState<ClientPoint[]>([]);

  //Modals
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalReport, setShowModalReport] = useState(false);
  const [showEditPlace, setShowEditPlace] = useState<number>();

  //Delete Client point
  const handleDeleteClientPoint = async (id: string) => {
    //Delete client point from api:
    deleteClientPoint({ id: id })
      .then((response) => {
        // success
        console.log('Deleted', id);
        //console.log(response);
        setShowModalDelete(false);
        //Refresh client points
        onSuccessEdit();
      })
      .catch((error) => {
        // failure
        console.log(error);
      });
  };


  useIonViewWillEnter(() => {
    //Get clientpoints
    onSuccessEdit();

    //Get providers
    getProviders()
      .then((response) => {
        setProviders(response);
        //console.log(response);
      })
      .catch((error) => console.log(error));
  });

  const refresh = (e: CustomEvent) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };

  const activeItemClass = 'bg-blue-40 text-white shadow-md';

  const data = {
    tabs: [
      {
        id: 'services',
        title: t('dashboard.tabs.storitve'),
        className: 'rounded-2xl py-1 px-3 text-center inline-block',
        OutherClass: '',
        new: false
      },
      {
        id: 'invoices',
        title: t('dashboard.tabs.poloznice'),
        className: 'flex items-center rounded-2xl py-1 px-3 text-center',
        OutherClass: 'px-1',
        new: true
      },
      {
        id: 'documents',
        title: t('dashboard.tabs.dokumenti'),
        className: 'flex items-center rounded-2xl py-1 px-3 text-center',
        OutherClass: 'px-1',
        new: false
      },
    ],
  };

  //Tabs filtering:
  const onClickTab = (index: number, id: string) => {
    setactiveItem(index);
    setfilteredItem(id);
  };

  //Edit/delete Card
  const onClickCard = (index: number) => {
    present({
      mode: 'ios',
      buttons: [
        {
          text: t('dashboard.ctaEdit'),
          cssClass: 'important-text-gray-20',
          handler: () => {
            setShowEditPlace(index);
            if (index) {
              setShowModalEdit(true);
            }
          },
        },
        {
          text: t('dashboard.reportAnIssue'),
          cssClass: 'important-text-gray-20',
          handler: () => {
            setShowModalReport(true);
            setShowEditPlace(index);
          },
        },
        {
          text: t('dashboard.ctaDelete'),
          role: 'destructive',
          handler: () => {
            setShowModalDelete(true);
            setShowEditPlace(index);
          },
        },
        {
          text: t('dashboard.ctaCancel'),
          cssClass: 'important-text-gray-20',
          role: 'cancel',
        },
      ],
    });
  };


  //On success edit Client Point
  const onSuccessEdit = () => {
    let isMounted = true; // track whether component is mounted 
    getClientPoints()
      .then((response) => {
        if (isMounted) {
          setClientPoints(response);
          console.log(response)
        }
      })
      .catch((error) => console.log(error));
    return () => {
      // clean up
      isMounted = false;
    };
  }

  //console.log(clientPoints);

  return (
    <IonPage id="dashboard">
      <IonHeader mode="ios" className="ion-no-border pt-2 bg-gray-45">
        <IonToolbar color="graybg">
          <IonAvatar className="flex items-center" slot="end">
            <IonRouterLink
              className="flex items-center justify-center w-8 h-8 rounded-lg bg-gray-25"
              routerLink="/auth/profile"
            >
              <img width="20" height="20" src="/assets/icon/ic-avatar.svg" />
            </IonRouterLink>
          </IonAvatar>
          <IonTitle
            slot="start"
            size="small"
            className="flex items-center text-2xl font-bold text-left text-gray-20"
          >
            {t('dashboard.title')}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="graybg">
        <div className="w-full overflow-x-auto">
          <div className="w-auto flex items-center justify-between text-sm p-4 text-gray-10">
            {data.tabs.map((tab, index) => {
              return (
                <DashTabsNoIcons
                  id={tab.id}
                  key={tab.id}
                  title={tab.title}
                  newItem={tab.new}
                  className={`${tab.className} ${activeItem === index ? activeItemClass : ''
                    }`}
                  OutherClass={tab.OutherClass}
                  onClickTab={() => onClickTab(index, tab.id)}
                />
              );
            })}
          </div>
        </div>
        <div className="p-4">
          <EULogos />
        </div>

        {/** TO DO: Connect with data */}
        <InvoiceCard
          id="1"
          title="Račun št. 1059129503"
          cost="56,98 eur"
          paid={true}
          ShowHide={
            filteredItem === 'invoices'
              ? ''
              : 'hidden'
          }
        />
        <InvoiceCard
          id="2"
          title="Račun št. 1059129503"
          cost="56,98 eur"
          paid={false}
          ShowHide={
            filteredItem === 'invoices'
              ? ''
              : 'hidden'
          }
        />

        {/** Documents */}
        {filteredItem === 'documents' && (
          <div>
            <div className="px-4 mb-2 font-bold text-gray-55">Stanovanje</div>
            <DocumentCard
              id="1"
              title="Najmena pogodba_2021.pdf"
            />
            <DocumentCard
              id="2"
              title="Pogodba_2020.pdf"
            />
            <div className="px-4 mb-2 font-bold text-gray-55 mt-8">Blok</div>
            <DocumentCard
              id="1"
              title="Hišni red.pdf"
            />
            <DocumentCard
              id="2"
              title="Požarni red.pdf"
            />
            <DocumentCard
              id="2"
              title="Zapisnik seje_12.sept2021.pdf"
            />
          </div>
        )}

        {clientPoints.map((clientPoint) => (
          <DashCard
            NoDecimalNumbers={clientPoint.utilityNoDecimalNumbers}
            key={clientPoint.clientPointId.toString()}
            id={clientPoint.clientPointId.toString()}
            onClickCard={() => onClickCard(clientPoint.clientPointId)}
            singleLink={`/dashboardmain/single/${clientPoint.clientPointId}`}
            title={clientPoint.clientPointDescription}
            type={clientPoint.utilityTypeName}
            //measure={clientPoint.readoutList[0] !== undefined ? clientPoint.readoutList[0].vif.unit : ''}
            measure={
              clientPoint?.utilityTypeName === 'ELECTRICITY' ? 'kWh' : clientPoint?.utilityTypeName === 'HEAT' ? 'kW' : 'm3'
            }
            lowLabel={clientPoint.utilityTypeName}
            highLabel={clientPoint.utilityTypeName}
            lowNum={
              clientPoint.readoutList.length.toString() !== "0" ? (
                clientPoint.readoutList[0].value !== null ?
                  Number(clientPoint.readoutList[0].value).toFixed(2).toString()
                  : "hidden"
              ) : "hidden"}
            highNum={
              clientPoint.readoutList.length.toString() !== "0" ? (
                clientPoint.readoutList[0].value2 !== null
                  ? Number(clientPoint.readoutList[0].value2).toFixed(2).toString()
                  : "hidden"
              ) : "hidden"}
            ShowHide={
              filteredItem === clientPoint.utilityTypeName ||
                filteredItem === 'services'
                ? ''
                : 'hidden'
            }
            lastMonth={t('dashboard.lastMonthLabel')}
            lowRate={t('dashboard.lowRateLabel')}
            highRate={t('dashboard.highRateLabel')}
          />
        ))}

        <IonRouterLink
          routerLink="/dashboard/add-place-step-1"
          className="fixed bottom-0 right-0 z-50 flex items-center justify-center w-12 h-12 mb-4 mr-4 rounded-3xl bg-gray-20"
        >
          <img src="/assets/icon/ic-plus-white.svg" />
        </IonRouterLink>

        {clientPoints
          .filter((clientPoint) => clientPoint.clientPointId === showEditPlace)
          .map((clientPoint) => (
            <EditPlaceModal
              onDidDismiss={() => setShowModalEdit(false)}
              isOpen={showModalEdit}
              key={`${clientPoint.clientPointId.toString()}-edit`}
              cssClass={
                showEditPlace === clientPoint.clientPointId
                  ? 'opacity-100'
                  : 'hidden opacity-0'
              }
              id={`${clientPoint.clientPointId.toString()}-edit`}
              selectProvider={clientPoint.utilityId.toString()}
              inputkoda={clientPoint.clientPointCode.toString()}
              inputkodaUser={clientPoint.clientPointCustomerCode.toString()}
              inputPlace={clientPoint.clientPointDescription}
              //onClick={() => handleEditPoint(clientPoint.clientPointId.toString())}
              onCancelEdit={() => setShowModalEdit(false)}
              ProvidersList={Providers}
              onSuccessEdit={() => onSuccessEdit()}
            />
          ))}

        <IonModal key="delete-modal" isOpen={showModalDelete}>
          {clientPoints.map((clientPoint) => (
            <DeletePlaceModal
              key={`${clientPoint.clientPointId.toString()}-delete`}
              cssClass={
                showEditPlace === clientPoint.clientPointId
                  ? 'opacity-100'
                  : 'hidden opacity-0'
              }
              id={`${clientPoint.clientPointId.toString()}-delete`}
              onClick={() =>
                handleDeleteClientPoint(clientPoint.clientPointId.toString())
              }
              onCancelDelete={() => setShowModalDelete(false)}
            />
          ))}
        </IonModal>

        <IonModal key="report-modal" isOpen={showModalReport}>
          {clientPoints.map((clientPoint) => (
            <ReportPlaceModal
              onDidDismiss={() => setShowModalReport(false)}
              key={`${clientPoint.clientPointId.toString()}-report`}
              cssClass={
                showEditPlace === clientPoint.clientPointId
                  ? 'opacity-100'
                  : 'hidden opacity-0'
              }
              id={`${clientPoint.clientPointId.toString()}`}
              /*onClick={() =>
                handleReportClientPoint(clientPoint.clientPointId.toString())
              }*/
              onCancelReport={() => setShowModalReport(false)}
            />
          ))}
        </IonModal>

      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
