import {
  IonAvatar,
} from '@ionic/react';

import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useTranslation } from 'react-i18next';

interface ContainerProps {
  chartData?: any;
  sumData?: any;
  sumDataTwo?: any;
  chartQty: boolean; // true or false
  chartType?: string; //"WATER" | "ELECTRICITY" | "GAS"
  maxValue: number
}

const ChartYearlySum: React.FC<ContainerProps> = ({
  chartData,
  chartQty,
  chartType,
  maxValue,
  sumData,
  sumDataTwo
}) => {
  const { t } = useTranslation();


  const renderColorfulLegendText = (value: string, entry: any) => {
    return <span className="text-gray-50 text-xs">{value}</span>;
  };

  const chartColorOne = chartType === "WATER" ? "#007FFF" : chartType === "ELECTRICITY" ? "#FBDB14" : chartType === "GAS" ? "#48BF84" : "#F25C54";
  const chartColorTwo = chartType === "WATER" ? "#007FFF" : chartType === "ELECTRICITY" ? "#FFAC0E" : chartType === "GAS" ? "#48BF84" : "#F25C54";

  const datakey = 'valueTimeMonthName';

  const chartUnit = chartType === "ELECTRICITY" ? "kWh" : chartType === "HEAT" ? "kW" : "m3";

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload) {
      return (
        <div className="custom-tooltip bg-blue-5 p-2 rounded text-sm">
          <p className="intro">{chartType === "ELECTRICITY" && (t('dashboard.lowRateLabel'))}{chartType === "WATER" && (t('dashboard.consumptionLabel'))} {payload[0].payload.value.replace(".", ",")} {chartUnit}</p>
          {chartType === "ELECTRICITY" && (
            <p className="desc">{t('dashboard.highRateLabel')} {payload[0].payload.value2.replace(".", ",")} {chartUnit}</p>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="mt-2">
      <ResponsiveContainer width="100%" height={350}>
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey={datakey}
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 10, fill: '#718096' }}
            interval={0}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 10, fill: '#718096' }}
            domain={[0, maxValue]}
          />
          <Tooltip content={<CustomTooltip />} />

          <Legend
            formatter={renderColorfulLegendText}
            iconType="circle"
            iconSize={16}
          />

          <Bar
            dataKey="value"
            name={chartType === "ELECTRICITY" ? t('dashboard.lowRateLabel') : t('dashboard.consumptionLabel')}
            stackId="a"
            fill={chartColorTwo}
            radius={(chartQty === true) ? [0, 0, 25, 25] : [25, 25, 25, 25]}
          />
          {(chartQty === true) && (
            <Bar
              dataKey="value2"
              name={t('dashboard.highRateLabel')}
              stackId="a"
              fill={chartColorOne}
              radius={[25, 25, 0, 0]}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartYearlySum;
