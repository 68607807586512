import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Capacitor } from '@capacitor/core';
import {
  IonAlert,
  IonApp,
  IonRouterOutlet,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import AddPlacePageStep1 from './pages/dashboard/addPlaceStep1';
import AddPlacePageStep2 from './pages/dashboard/addPlaceStep2';
import AddPlacePageStep3 from './pages/dashboard/addPlaceStep3';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import RegisterStep2 from './pages/auth/RegisterStep2';
import RegisterStep3 from './pages/auth/RegisterStep3';
import RegisterStep4 from './pages/auth/RegisterStep4';
import RegisterProvider from './pages/auth/RegisterProvider';
import Profile from './pages/auth/Profile';
import Password from './pages/auth/Password';
import PasswordChange from './pages/auth/PasswordChange';
import UsernameChange from './pages/auth/UsernameChange';
import ViewMessage from './pages/ViewMessage';
import Provider from './pages/Provider';
import React, { useEffect, useState } from 'react';
import AuthRoute from './AuthRoute';
import '@ionic/react/css/structure.css';
import { SplashScreen } from '@capacitor/splash-screen';
import AppToast from './components/AppToast';

import { isPlatform, setupIonicReact } from '@ionic/react';
setupIonicReact({});

/** Splash screen  */
import { Preferences } from '@capacitor/preferences';

// Hide the splash (you should do this on app launch)
async function splash() {
  await SplashScreen.show({
    showDuration: 2000,
    autoHide: true
  });
}
splash();

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
// import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';

import './theme/tailwind.css';

/* Theme variables */
import './theme/variables.css';
import SingleDash from './pages/dashboard/singleDash';
import { AuthContext } from './context/Auth';
import { useHistory } from 'react-router-dom';
import { RegistrationForm, RegistrationContext } from './context/Registration';
import i18n from './services/i18n';
import DashboardMain from './pages/DashboardMain';
import ErrorPage from './pages/ErrorPage';
import RegisterStep1A from './pages/auth/RegisterStep1A';
import RegisterStep1B from './pages/auth/RegisterStep1B';
import RegisterStep1C from './pages/auth/RegisterStep1C';
import RegisterStep1D from './pages/auth/RegisterStep1D';
import RegisterProviderThankyou from './pages/auth/RegisterProviderThankyou';
import RegisterStep1E from './pages/auth/RegisterStep1E';
import ClientBuilding from './pages/ClientBuilding';
import RegisterStep1BMultiple from './pages/auth/RegisterStep1BMultiple';
import RegisterStep1CMultiple from './pages/auth/RegisterStep1CMultiple';
import RegisterStep1AMultiple from './pages/auth/RegisterStep1AMultiple';
import AppUrlListener from './components/AppUrlListener';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import Notifications from './pages/Notifications';
import SingleNotification from './pages/SingleNotification';
import Contact from './pages/auth/Contact';


const App: React.FC = () => {
  const [authToken, setAuthToken] = useState('');
  const [appToast, setAppToast] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const [redirectToLogin, setRedirectToLogin] = useState(false)


  useEffect(() => {
    async function fetch() {
      await Preferences.get({ key: 'token' }).then((storage: any) => {
        setAuthToken(storage.value ?? '');
        //Redirect to login
        if (storage.value === null) {
          setRedirectToLogin(true)
        }
      });
      //Get and set theme from storage
      await Preferences.get({ key: 'theme' }).then((storageTheme: any) => {
        if (storageTheme.value === null || storageTheme.value.toString() === "") {
          //If storage "theme" not exist set theme to detect
          //
          /*prefersDark.matches
            ? Storage.set({
              key: 'theme',
              value: 'dark',
            })
            : */
            Preferences.set({
            key: 'theme',
            value: 'light',
          });

          Preferences.get({ key: 'theme' }).then((storageTheme: any) => {
            //If storage "theme" exist set theme
            document.body?.classList.add(storageTheme.value ?? '');
            document.body.parentElement?.classList.add(storageTheme.value ?? '');
          });
          //Redirect to login
          setRedirectToLogin(true)
        } else {
          //console.log("Not null: ", storageTheme.value)
          document.body.parentElement?.classList.add(storageTheme.value);
          document.body.classList.add(storageTheme.value);
        }
      });
      //Get and set language from storage
      await Preferences.get({ key: 'language' }).then((storageLang: any) => {
        if (storageLang.value === null || storageLang.value.toString() === "") {
          //If storage "language" not exist set language to en
          Preferences.set({
            key: 'language',
            value: 'sl',
          });
          Preferences.get({ key: 'language' }).then((storage: any) => {
            //If storage "language" exist set language
            i18n.changeLanguage(storage.value ?? '');
          });
          //Redirect to login
        } else {
          //If storage "language" exist set language
          i18n.changeLanguage(storageLang.value ?? '');
        }
      });
      //Set api version in storage
      await Preferences.set({
        key: 'version',
        value: '1.8.0',
      });
      //Get and set demo from storage
      await Preferences.get({ key: 'demo' }).then((storageDemo: any) => {
       //console.log("Demo", storageDemo)
        if (storageDemo.value !== null) {
          if (!document.body.parentElement?.classList.contains('demo')) {
            document.body.parentElement?.classList.add('demo');
          }
          if (!document.body.classList.contains('demo')) {
            document.body.classList.add('demo');
          }
        } else {
          if (document.body.parentElement?.classList.contains('demo')) {
            document.body.parentElement?.classList.remove('demo');
          }
          if (document.body.classList.contains('demo')) {
            document.body.classList.remove('demo');
          }
        }

      });


    }
    fetch();

    if (
      Capacitor.getPlatform() === 'web' &&
      /Android|ios|Safari|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      setAppToast(true);
    }


    //Logout after 48 hours
    /*Storage.get({ key: 'loginTime' }).then((storage) => {
      const currentTime = new Date()
      //If storage loginTime
      if (storage.value !== null) {
        if ((Number(storage.value) + (48 * 60 * 60 * 1000)) < Date.parse(currentTime.toString())) {
          //if ((Number(storage.value) + (2 * 1000)) < Date.parse(currentTime.toString())) {
          //Logout
          Storage.remove({ key: 'token' });
          setAuthToken('');
          //history.push('/auth/login');
        }
      } else {
        //Logout
        Storage.remove({ key: 'token' });
        setAuthToken('');
        history.push('/auth/login');
      }
    });*/
  }, []);

  const setToken = (data: string) => {
    Preferences.set({
      key: 'token',
      value: data,
    });
    setAuthToken(data);
  };



  return (
    <AuthContext.Provider value={{ authToken, setAuthToken: setToken }}>
      {appToast && <AppToast onClose={() => setAppToast(false)} />}
      <IonApp class={cx('ios ion-page', appToast ? 'mt-16' : 'mt-0')}>
        <IonReactRouter>
          {redirectToLogin && (
            <Redirect to="/auth/login" />
          )}
          <AppUrlListener></AppUrlListener>
          <IonRouterOutlet>
            <Route path="/auth/login" exact={true}>
              <Login />
            </Route>
            <Route path="/" exact={true}>
              <Redirect to="/home" />
            </Route>
            <Route path="/home" exact={true}>
              <Home />
            </Route>
            <Route path="/errorpage" exact={true}>
              <ErrorPage />
            </Route>
            <AuthRoute path="/dashboard" component={Dashboard} exact={true} />
            <Route
              path="/dashboardmain"
              component={DashboardMain}
              exact={true}
            />
            <Route
              path="/dashboardmain/:slide"
              component={DashboardMain}
              exact={true}
            />
            <Route
              path="/dashboardmain/client-building/:id/part/:part"
              component={ClientBuilding}
              exact={true}
            />
            <Route
              path="/dashboardmain/client-building/:id/part/:part/slide/:slide"
              component={ClientBuilding}
              exact={true}
            />
            <Route
              path="/dashboard/add-place-step-1/clientbuildingid/:buildingid/clientbuildingpartid/:partid"
              component={AddPlacePageStep1}
              exact={true}
            />
            <Route
              path="/dashboard/add-place-step-2/clientbuildingid/:buildingid/clientbuildingpartid/:partid"
              component={AddPlacePageStep2}
              exact={true}
            />
            <Route
              path="/dashboard/add-place-step-3/clientbuildingid/:buildingid/clientbuildingpartid/:partid"
              component={AddPlacePageStep3}
              exact={true}
            />
            <Route path="/dashboardmain/single/:id" component={SingleDash} />
            <Route path="/message/:id">
              <ViewMessage />
            </Route>
            <Route path="/dashboardmain/single/:id/slide/:slide" component={SingleDash} />
            <Route path="/message/:id">
              <ViewMessage />
            </Route>
            <Route path="/provider" exact={true}>
              <Provider />
            </Route>
            <Route path="/notifications" exact={true}>
              <Notifications />
            </Route>
            <Route
              path="/notifications/:id"
              component={SingleNotification}
              exact={true}
            />
            <Route path="/auth/register" exact={true}>
              <Register />
            </Route>
            <Route path="/auth/register-step-1-a" exact={true}>
              <RegisterStep1A />
            </Route>
            <Route
              path="/auth/register-step-1-a-multiple/buildingid/:buildingid/addressid/:addressid/hasbuildingparts/:hasbuildingparts/hasapartments/:hasapartments/clientbuildingid/:clientbuildingid/clientbuildingpartid/:partid"
              component={RegisterStep1AMultiple}
              exact={true}
            />
            <Route
              path="/auth/register-step-1-b/buildingid/:buildingid/addressid/:addressid/hasbuildingparts/:hasbuildingparts/hasapartments/:hasapartments/clientbuildingid/:clientbuildingid/clientbuildingpartid/:partid"
              component={RegisterStep1B}
              exact={true}
            />
            <Route
              path="/auth/register-step-1-b-multiple/buildingid/:buildingid/addressid/:addressid/hasbuildingparts/:hasbuildingparts/hasapartments/:hasapartments/clientbuildingid/:clientbuildingid/clientbuildingpartid/:partid"
              component={RegisterStep1BMultiple}
              exact={true}
            />
            <Route
              path="/auth/register-step-1-c-multiple/buildingid/:buildingid/addressid/:addressid/hasbuildingparts/:hasbuildingparts/hasapartments/:hasapartments/clientbuildingid/:clientbuildingid/clientbuildingpartid/:partid"
              component={RegisterStep1CMultiple}
              exact={true}
            />
            <Route
              path="/auth/register-step-1-c/buildingid/:buildingid/addressid/:addressid/hasbuildingparts/:hasbuildingparts/hasapartments/:hasapartments/clientbuildingid/:clientbuildingid/clientbuildingpartid/:partid"
              component={RegisterStep1C}
              exact={true}
            />
            <Route
              path="/auth/register-step-1-d/buildingid/:buildingid/addressid/:addressid/hasbuildingparts/:hasbuildingparts/hasapartments/:hasapartments/clientbuildingid/:clientbuildingid/clientbuildingpartid/:partid"
              component={RegisterStep1D}
              exact={true}
            />
            <Route
              path="/auth/register-step-1-e/buildingid/:buildingid/addressid/:addressid/hasbuildingparts/:hasbuildingparts/hasapartments/:hasapartments/clientbuildingid/:clientbuildingid/clientbuildingpartid/:partid/clientpoint/:clientpointid"
              component={RegisterStep1E}
              exact={true}
            />
            <Route path="/auth/register-step-2" exact={true}>
              <RegisterStep2 />
            </Route>
            <Route path="/auth/register-step-3" exact={true}>
              <RegisterStep3 />
            </Route>
            <Route path="/auth/register-step-4" exact={true}>
              <RegisterStep4 />
            </Route>
            <Route path="/auth/register-provider" exact={true}>
              <RegisterProvider />
            </Route>
            <Route path="/auth/register-provider-thankyou" exact={true}>
              <RegisterProviderThankyou />
            </Route>
            <Route path="/auth/profile" exact={true}>
              <Profile />
            </Route>
            <Route path="/auth/password" exact={true}>
              <Password />
            </Route>
            <Route path="/auth/password-change" exact={true}>
              <PasswordChange />
            </Route>
            <Route path="/auth/contact" exact={true}>
              <Contact />
            </Route>
            <Route path="/auth/username-change" exact={true}>
              <UsernameChange />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </AuthContext.Provider>
  );
};

export default App;