import {
  IonButton,
  IonRouterLink,
  IonHeader,
  IonToolbar,
  IonTitle,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

interface ContainerProps {
  onClick: () => void;
  onCancelDelete: () => void;
  id?: string;
  cssClass?: string;
}

const DeleteProfileModal: React.FC<ContainerProps> = ({
  onClick,
  onCancelDelete,
  id,
  cssClass,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cssClass} id={id}>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonTitle>{t('auth.accountSettings.deleteProfile')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div>
        <div className="p-4 mt-2 text-lg text-gray-5 text-center">
          {t('auth.accountSettings.areYouSure')}
        </div>
        <div className="p-4 mt-0">
          <IonButton
            onClick={onClick}
            shape="round"
            color="danger"
            className="w-full h-12 text-base font-semibold normal-case"
          >
            {t('dashboard.ctaDelete')}
          </IonButton>
          <IonRouterLink
            onClick={onCancelDelete}
            className="block w-full h-12 p-6 text-base text-center normal-case text-gray-5"
          >
            {t('dashboard.ctaCancel')}
          </IonRouterLink>
        </div>
      </div>
    </div>
  );
};

export default DeleteProfileModal;
