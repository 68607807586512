import { Api } from '../api'; 

export type ClientPointActions =
  | { type: 'FETCH' }
  | { type: 'REJECT' }
  | { type: 'CANCEL' }
  | { type: 'RESOLVE' };

  export const defaultClientPointState = { status: 'idle' };


export const sendFileToUserEmail = async (
  fileId?: number

): Promise<any> => { 
  const api = new Api();

  try {
    const response = await api.request('POST', `app/client-building/send-file-to-user-email/${fileId}`, true, { 
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};