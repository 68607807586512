import { InAppBrowser, InAppBrowserOptions } from "@ionic-native/in-app-browser";
import { IonRouterLink } from "@ionic/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  id?: string;
  newItem?: boolean;
  date?: string;
  subtitle?: string;
  title?: string;
  description: string;
  externalLink?: string;
  setStatus: Function;
}



const NotifiyCard: React.FC<ContainerProps> = ({
  id,
  newItem,
  date,
  subtitle,
  title,
  description,
  externalLink,
  setStatus
}) => {
  const { t } = useTranslation();
  const [active, setaActive] = useState(false);

  const options: InAppBrowserOptions = {
    zoom: 'no',
    location: 'yes',
    toolbar: 'yes',
    hidden: 'no',
    hideurlbar: 'no',
    toolbarposition: 'top',
    toolbarcolor: '#000000',
    closebuttoncolor: '#ffffff',
    hidenavigationbuttons: 'no',
    navigationbuttoncolor: "#ffffff"
    //closebuttoncaption: t('auth.register.close')
  }

  const externalLinkFilter = externalLink ? (externalLink.includes("http") ? externalLink : "https://" + externalLink) : "";
  const websiteUrl: string = externalLink ? externalLinkFilter : ""

  const handleUrlClick = (e: any) => {
    setStatus(id);
    e.preventDefault();
    const browser = InAppBrowser.create(websiteUrl, '_self', options)
    browser?.on('loadstop').subscribe((e: any) => {
      browser.insertCSS({ code: "body{color: red; padding: 10mm;" });
      //console.log('loadstop DONE!')
    });
  }

  if (externalLink && externalLink.includes("http")) {
    //console.log("Includes")
  } else {
    //console.log("Not includes")
  }


  return (
    <div className="bg-white dark:text-white dark:bg-gray-800 rounded-lg p-4 mb-2">

      {externalLink ? (
        <>
          <button onClick={handleUrlClick} className="block w-full text-left">
            <span className='text-sm text-gray-600 dark:text-white mb-2 flex items-center'>{newItem && (<span className='inline-block w-3 h-3 bg-red-5 rounded-full mr-4'></span>)}{moment(date).date() + "-" + (moment(date).month() + 1) + "-" + moment(date).year()}<span className="ml-4">{title}</span></span>
            <span className='block text-base text-gray-800 dark:text-white font-bold mb-2'>{subtitle}</span>
            <span className='block text-sm text-gray-600 dark:text-white' dangerouslySetInnerHTML={{ __html: description?.slice(0, 180) }}></span>
          </button>
          <button onClick={handleUrlClick} className="inline-flex items-center text-gray-500 dark:text-gray-100 bg-gray-45 dark:bg-gray-700 rounded-lg text-xs py-1 px-2 mt-3">
            <span className="mr-2">{externalLink.replace('http://', '').replace('https://', '')}</span><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.17285 3.82825H11.8297V9.4851" stroke="#A0AEC0" />
              <path d="M11.6099 4.04834L3.45444 12.2038" stroke="#A0AEC0" />
            </svg>
          </button>
        </>
      ) : (
        <IonRouterLink onClick={() => setStatus(id)} routerLink={`/notifications/${id}`} routerDirection="forward">
          <div className='text-sm text-gray-600 dark:text-white mb-2 flex items-center'>{newItem && (<span className='inline-block w-3 h-3 bg-red-5 rounded-full mr-4'></span>)}{moment(date).date() + "-" + (moment(date).month() + 1) + "-" + moment(date).year()}<span className="ml-4">{title}</span></div>
          <div className='text-base text-gray-800 dark:text-white font-bold mb-2'>{subtitle}</div>
          <div className='text-sm text-gray-600 dark:text-white' dangerouslySetInnerHTML={{ __html: description?.slice(0, 180) }}></div>
        </IonRouterLink>
      )}

    </div>
  );
};
export default NotifiyCard;
