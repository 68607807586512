import {
  IonContent,
  IonPage,
  IonButton,
  IonToolbar,
  IonFooter,
  IonIcon,
  useIonViewWillEnter,
  IonThumbnail,
  IonImg,
  IonModal,
  useIonViewDidEnter,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStorage, setStorage} from '../../data/user/register.api';
import { getProviders } from '../../data/client-points/providers.api';
import Input from '../../components/form/Input';
import StepsHeaderWithBack from '../../components/StepsHeaderWithBack';
import UnitCard from '../../components/UnitCard';
import { getBill } from '../../data/client-points/bill.api';
import BillingCodesModal from '../../components/modals/billingCodes';
import { getBuildingSingle } from '../../data/client-points/getBuildingSingle.api';
import { addClientPointForBuilding } from '../../data/client-points/addClientPointForBuilding.api';
import { getClientBuildingSingle } from '../../data/client-points/clientBuildingSingle.api';
import Loader from '../../components/Loader';
import { Preferences } from '@capacitor/preferences';
import { useIonRouter } from '@ionic/react';

type FormErrors = {
  locationCode: string[] | undefined;
  clientCode: string[] | undefined;
  place: string[] | undefined;
};

type ProvidersData = {
  id: number;
  name: string;
  utilityTypeId: number;
  logo: string;
};

type BillData = {
  data: string,
  message: string
};

type BuildingData = {
  addressId: number;
  buildingId: number;
  buildingNumber: number;
  distance: number;
  gpsLat: number;
  gpsLon: number;
  hasApartments: boolean;
  hasBuildingParts: boolean;
  houseNumber: string;
  municipality: string;
  post: string;
  settelment: string;
  street: string;
  description: string;
  address: string;
};

type CurrentUserlData = {
  isAuthenticated: boolean,
  id: string,
  userName: string,
  email: string,
};


const RegisterStep1D: React.FC = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [provider, setProvider] = useState();
  const [errors, setErrors] = useState<FormErrors>({
    locationCode: undefined,
    clientCode: undefined,
    place: undefined,
  });
  const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buildingId, setBuildingId] = useState('');
  const [clientBuildingId, setClientBuildingId] = useState('');
  const [buildingAddressId, setBuildingAddressId] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [buildingType, setBuildingType] = useState('');
  const [hasApartments, setHasApartments,] = useState(false);
  const [hasBuildingParts, setHasBuildingParts] = useState(false);
  const [buildingPartId, setBuildingPartId] = useState('');
  const [buildingClientPartId, setBuildingClientPartId] = useState('');
  const [buildingPartName, setBuildingPartName] = useState('');
  const [buildingPartType, setBuildingPartType] = useState('');
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [place, setPlace] = useState('');
  const [unit, setUnit] = useState('');
  const [Bill, setBill] = useState<BillData>();
  const [currentUserId, setcurrentUserId] = useState<CurrentUserlData>();
  const [dataLoad, setDataLoad] = useState<boolean>(false);

  const [Providers, setProviders] = useState<ProvidersData[]>([]);
  const [building, setBuilding] = useState();
  const [active, setActive] = useState<number>(0);
  const ionRouter = useIonRouter();


  //const [name, setName] = useState('');

  const [buildingData, setBuildingData] = useState<BuildingData>();
  const [clientBuildingData, setClientBuildingData] = useState<BuildingData>();

  const [errorMeter, setErrorMeter] = useState(false);

  const user = [
    username,
    email,
    password,
    buildingId,
    clientBuildingId,
    buildingAddressId,
    buildingName,
    buildingType,
    hasApartments,
    hasBuildingParts,
    buildingPartId,
    buildingClientPartId,
    buildingPartName,
    buildingPartType,
    provider,
    locationCode,
    clientCode,
    place,
  ];

  //Current provider
  const currentpPovider = Providers.filter((items: any) => items.id === provider).map((items: ProvidersData) => {
    return {
      ...items,
    }
  });

  const id = 0;
  const creationTime = new Date().toISOString();
  const creatorId = currentUserId?.id;
  const lastModificationTime = new Date().toISOString();
  const lastModifierId = currentUserId?.id;
  const isDeleted = true;
  const deleterId = currentUserId?.id;
  const deletionTime = new Date().toISOString();
  const code = locationCode;
  const customerCode = clientCode;
  const externalId = '';
  const name = "";
  const address = '';
  const description = place;
  const utilityId = provider;
  const userId = currentUserId?.id;
  const clientPointTypeId = 0;
  const clientbuildingId = props.match.params.clientbuildingid;
  const clientbuildingPartId = props.match.params.partid

  const handleNextStep = (buildingId: any, addressId: any, hasApartments: any, hasBuildingParts: any, clientBuildingId: any, partid: any, clientPointId: any) => {
    console.log(place)
    // Check if selected fields are correct
    if (locationCode !== '' && clientCode !== '' && place !== '' && locationCode.length > 0 && clientCode.length > 0) {
      //Set user data to local storage
      setStorage("user", user);

      //console.log(clientBuildingId)

      /*setData({
          ...data,
          provider: provider,
          locationCode: locationCode,
          clientCode: clientCode,
          place: place,
        });*/

      addClientPointForBuilding(
        id,
        creationTime,
        creatorId,
        lastModificationTime,
        lastModifierId,
        isDeleted,
        deleterId,
        deletionTime,
        code,
        customerCode,
        externalId,
        name,
        address,
        description,
        utilityId,
        userId,
        clientbuildingId,
        clientbuildingPartId
      )
        .then((response: any) => {
          // success
          //Set empty strings to storage
          setStorage("user", user);
          if (response?.error) {
            setErrorMeter(true);
          } else {
            //console.log('Client point added!');
            //console.log(response);
            setErrorMeter(false);
            history.push(`/auth/register-step-1-e/buildingid/${buildingId}/addressid/${addressId}/hasbuildingparts/${hasApartments}/hasapartments/${hasBuildingParts}/clientbuildingid/${clientBuildingId}/clientbuildingpartid/${partid}/clientpoint/${clientPointId}`);
          }
        })
        .catch((error) => {
          // failure
          console.log(error);
          //history.push('/dashboard');
          setErrorMeter(true);
          setErrors({
            ...errors,
            locationCode: ['Location code is required'],
            clientCode: ['Client code is required'],
          });
        });
    } else {
      setErrorMeter(true);
      setErrors({
        ...errors,
        locationCode: ['Location code is required'],
        clientCode: ['Client code is required'],
        place: ['Place is required'],
      });
      return;
    }

  };


  useIonViewWillEnter(() => {
    setDataLoad(false);

    //Clear inputs
    setLocationCode("");
    setclientCode("");
    //setPlace("");

  }, [props]);

  useIonViewDidEnter(() => {

    const abortController = new AbortController();
    const signal = abortController.signal;
    //Get local stored user data
    getStorage('user').then((value) => {
      setUsername(value[0]);
      setEmail(value[1]);
      setPassword(value[2]);
      setBuildingId(value[3]);
      setClientBuildingId(value[4]);
      setBuildingAddressId(value[5]);
      setBuildingName(value[6]);
      setBuildingType(value[7]);
      setHasApartments(value[8]);
      setHasBuildingParts(value[9]);
      setBuildingPartId(value[10]);
      setBuildingClientPartId(value[11]);
      setBuildingPartName(value[12]);
      setBuildingPartType(value[13]);
      setProvider(value[14]);
      //setLocationCode(value[15]);
      //setclientCode(value[16]);
      //setPlace(value[17]);
      setLocationCode("");
      setclientCode("");
      //setPlace("");
      //console.log(value); 
    });

    //Get providers
    /*getProviders()
      .then((response) => {
        setProviders(response);
        //console.log(response);
      })
      .catch((error) => console.log(error));*/

    //Get local stored user data
    Preferences.get({ key: 'user' }).then((storage: any) => {
      const getPlaceData = JSON.parse(storage.value || '{}');
      setProvider(getPlaceData[14]);

      //Get providers
      getProviders()
        .then((response) => {
          //console.log(response)
          setProviders(response);

          //Set place title
          response.filter((items: any) => items.id === (Number(getPlaceData[14]))).map((item: any, index: any) => {
            if (item.utilityTypeId.toString() === "1") {
              setPlace(t('dashboard.tabs.water'));
            } else if (item.utilityTypeId.toString() === "2") {
              setPlace(t('dashboard.tabs.gas'));
            } else if (item.utilityTypeId.toString() === "3") {
              setPlace(t('dashboard.tabs.heat'));
            } else if (item.utilityTypeId.toString() === "4") {
              setPlace(t('dashboard.tabs.electricity'));
            } else {
              setPlace("");
            }
          })
        })
        .catch((error) => console.log(error));


      if (getPlaceData[15] == null) {
        setLocationCode('');
      } else {
        setLocationCode(getPlaceData[15]);
      }
      if (getPlaceData[16] == null) {
        setclientCode('');
      } else {
        setclientCode(getPlaceData[16]);
      }
      //setPlace(getPlaceData[17]);
      //console.log(getPlaceData);

      //Get bill image from api
      getBill({ id: getPlaceData[14].toString() })
        .then((response) => {
          //console.log(response);
          setBill(response.data);
        })
        .catch((error) => console.log(error));


    });



    //Get single building
    if (props.match.params.buildingid & props.match.params.addressid) {
      onLoad(props.match.params.buildingid, props.match.params.addressid, props.match.params.clientbuildingid);
    }

  }, [, props.match.params.location, props.match.params.buildingid, props.match.params.addressid, props.match.params.clientbuildingid]);



  //Handle Location code
  const handleInputLocationCodeChange = (e: any) => {
    setErrorMeter(false);
    if (e.target.value !== '' && e.target.value.length > 0) {
      setErrors({
        ...errors,
        locationCode: undefined,
      });
    } else {
      setErrors({
        ...errors,
        locationCode: ['Location code is required'],
      });
    }
    setLocationCode(e.target.value)
  };

  //Handle Client code
  const handleInputClientCodeChange = (e: any) => {
    setErrorMeter(false);
    if (e.target.value !== '' && e.target.value.length > 0) {
      setErrors({
        ...errors,
        clientCode: undefined,
      });
    } else {
      setErrors({
        ...errors,
        clientCode: ['Client code is required'],
      });
    }
    setclientCode(e.target.value)
  };

  useIonViewWillEnter(() => {

  });

  //Handle place input
  const handleInputPlaceChange = (e: any) => {
    if (e.target.value !== '') {
      setErrors({
        ...errors,
        place: undefined,
      });
    } else {
      setErrors({
        ...errors,
        place: ['Place is required'],
      });
    }
    setPlace(e.target.value);
  };

  //On Load
  const onLoad = (buildingid: any, addressid: any, id: string) => {
    let isMounted = true; // track whether component is mounted 
    //console.log(buildingid)
    if (props.match.params.clientbuildingid.toString() != "0") {
      //Get single client building
      getClientBuildingSingle({ id })
        .then((response) => {
          if (isMounted) {
            //console.log(response);
            setClientBuildingData(response);
          }
        })
        .catch((error) => console.log(error));
    }

    //Get single building
    getBuildingSingle(buildingid, addressid)
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setBuildingData(response);
          setDataLoad(true);
        }
      })
      .catch((error) => console.log(error));

    return () => {
      // clean up
      isMounted = false;
    };

  };

  const buildType = buildingData?.hasBuildingParts || buildingData?.hasApartments ? t('auth.register.union') : t('auth.register.house');

  //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/dashboardmain")
    });
  });


  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="p-4 flex pt-8 justify-center rounded-t-2xl bg-white dark:bg-gray-900">
          <div className="w-full mb-5">
            <StepsHeaderWithBack title={t('auth.register.step1ATitleMeter')} backLink={`/auth/register-step-1-c/buildingid/${buildingId}/addressid/${buildingAddressId}/hasbuildingparts/false/hasapartments/false/clientbuildingid/${clientBuildingId}/clientbuildingpartid/0`} />
            {dataLoad ? (
              <>
                <UnitCard
                  //title={`${clientBuildingData ? clientBuildingData?.description : buildType}`}
                  title={buildingPartName ? buildingPartName : buildingName}
                  address={`${clientBuildingData ? clientBuildingData?.address : buildingData?.street + '' + buildingData?.houseNumber + ', ' + buildingData?.post}`}
                  type={buildingData?.hasBuildingParts && buildingData?.hasApartments ? "SKYSCRAPER" : buildingData?.hasBuildingParts && !buildingData?.hasApartments ? "HOMEMULTIPLE" : "HOME"}
                  mapLat={buildingData?.gpsLat.toString()}
                  mapLong={buildingData?.gpsLon.toString()}
                />

                {/*} <div className="mt-8 text-lg font-bold text-black-5">{t('auth.register.consumptionPoint')} {buildingPartName ? buildingPartName : buildingName}</div>*/}

                <div className="mt-4 mb-2 text-black-5 font-bold">{t('auth.register.provider')}</div>

                {provider && (
                  Providers.filter((items) => items.id === (Number(provider))).map((item, index) => {
                    return (
                      <div key={index} className="flex items-center">
                        <IonThumbnail slot="start">
                          <IonImg src={item.logo ? `data:image/jpeg;base64, ${item.logo}` : '/assets/no-image.jpg'} className="w-12 h-12" />
                        </IonThumbnail>
                        <div className="text-gray-50 ml-6">{item.name}</div>
                      </div>
                    );
                  })
                )}

                <div className="my-4 text-black-5 dark:text-white font-bold flex items-center">
                  <div>{t('auth.register.meter_details')}</div>
                  <IonIcon
                    src={"/assets/icon/ic-help.svg"}
                    class="ios hydrated text-3xl w-4 h-4 ml-3"
                    onClick={() => setShowModal(true)}
                  ></IonIcon>
                </div>

                {/*<div className="py-2 mt-6">
                  <Input
                    type="text"
                    label={t('auth.register.placePlaceholder')}
                    error={errors.place}
                    value={place}
                    onChange={handleInputPlaceChange}
                  />
                </div>*/}

                <div className="py-2">
                  <Input
                    type="text"
                    label={t('auth.register.clientLocationCodePlaceholder')}
                    error={errors.locationCode}
                    value={locationCode ? locationCode : ""}
                    onChange={handleInputLocationCodeChange}
                  />
                </div>
                <div className="py-2">
                  <Input
                    type="text"
                    label={t('auth.register.clientCodePlaceholder')}
                    error={errors.clientCode}
                    value={clientCode ? clientCode : ""}
                    onChange={handleInputClientCodeChange}
                  />
                </div>
                {errorMeter && (
                  <div className="mt-8 text-red-500 text-center">{t('auth.register.error_adding_meter')}</div>
                )}
              </>
            ) : (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <Loader />
              </div>
            )}


          </div>
        </div>
        <IonModal isOpen={showModal}>
          <BillingCodesModal
            onDidDismiss={() => setShowModal(false)}
            id="1"
            /*onClick={() =>
              handleReportClientPoint(clientPoint.clientPointId.toString())
            }*/
            onCancelModal={() => setShowModal(false)}
            data={Bill}
          />
        </IonModal>
      </IonContent>
      <IonFooter mode="ios" className="ion-no-border p-4 bg-white dark:bg-gray-900">
        <IonToolbar>
          <IonButton onClick={() => handleNextStep(props.match.params.buildingid, props.match.params.addressid, props.match.params.hasbuildingparts, props.match.params.hasapartments, props.match.params.clientbuildingid, props.match.params.partid, provider)} color={(locationCode !== '' && clientCode !== '' && place !== '' && locationCode.length > 0 && clientCode.length > 0) ? "blue" : "graybgbut"} className="w-full normal-case font-semibold h-12 text-base"><span className={`${(locationCode !== '' && clientCode !== '' && place !== '' && locationCode.length > 0 && clientCode.length > 0) ? "text-white" : "dark:text-gray-900 text-gray-400"} `}>{t('auth.register.add')}</span></IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
}

export default RegisterStep1D;
