import { Api } from '../api'; 

export type ClientPointActions =
  | { type: 'FETCH' }
  | { type: 'REJECT' }
  | { type: 'CANCEL' }
  | { type: 'RESOLVE' };

  export const defaultClientPointState = { status: 'idle' };

export type ClientPointState = {
  status: string;
};


export const reportIssueBuilding = async (
  clientBuildingId?: number,
  clientBuildingPartId?: number,
  gursAddressId?: number,
  gursBuildingId?: number,
  message?: string,
  picture?: string,
): Promise<any> => { 
  const api = new Api();

  try {
    const response = await api.request('POST', 'app/client-building/report-issue', true, { 
      clientBuildingId,
      clientBuildingPartId,
      gursAddressId,
      gursBuildingId,
      message,
      picture,
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};