import { Api } from '../api';

//Send contact message
export const emailForNews = async (
  email: string,
  gursAddressId: number
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('POST', 'app/account/email-for-infotim-news', true, {
      email,
      gursAddressId
    });
    return response;
  } catch (error) {
    throw error;
  }
};
