import {
  IonContent,
  IonPage,
  IonIcon,
  useIonViewWillEnter,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStorage, setStorage} from '../../data/user/register.api';
import StepsHeader from '../../components/StepsHeader';
import InputSearch from '../../components/form/InputSearch';
import { searchBuildings } from '../../data/client-points/searchBuildings';
import { getCurrentUser } from '../../data/user/current-user.api';
import Loader from '../../components/Loader';
import { useIonRouter } from '@ionic/react';

type FormErrors = {
  search: string[] | undefined;
};

type HouseData = {
  addressId: number;
  buildingId: number;
  buildingNumber: number;
  distance: number;
  gpsLat: number;
  gpsLon: number;
  hasApartments: boolean;
  hasBuildingParts: boolean;
  houseNumber: string;
  municipality: string;
  post: string;
  settelment: string;
  street: string;
};
type ProfileData = {
  id: string;
  email: string;
  userName: string;
};

type CurrentUserlData = {
  isAuthenticated: boolean;
  id: string;
  userName: string;
  email: string;
};

const RegisterStep1A: React.FC = () => {
  const ionRouter = useIonRouter();
  const { t } = useTranslation();
  const history = useHistory();
  const [provider, setProvider] = useState();
  const [errors, setErrors] = useState<FormErrors>({
    search: undefined,
  });
  const [Profile, setProfile] = useState<ProfileData>();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buildingId, setBuildingId] = useState('');
  const [clientBuildingId, setClientBuildingId] = useState('');
  const [buildingAddressId, setBuildingAddressId] = useState('');
  const [hasApartments, setHasApartments,] = useState(false);
  const [hasBuildingParts, setHasBuildingParts] = useState(false);
  const [buildingPartId, setBuildingPartId] = useState('');
  const [buildingPartName, setBuildingPartName] = useState('');
  const [buildingPartType, setBuildingPartType] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [buildingType, setBuildingType] = useState('');
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [place, setPlace] = useState('');
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [countBuildings, setCountBuildings] = useState<[]>([]);
  const [currentUserId, setcurrentUserId] = useState<CurrentUserlData>();
  const [dataLoad, setDataLoad] = useState<boolean>(true);
  const [timer, setTimer] = useState()

  const [unit, setUnit] = useState('');

  const [buldingResults, setBuldingResults] = useState<HouseData[]>([]);
  const [typingTimeout, setTypingTimeout] = useState(0);



  const user = [
    username,
    email,
    password,
    buildingId,
    clientBuildingId,
    buildingAddressId,
    buildingName,
    buildingType,
    hasApartments,
    hasBuildingParts,
    buildingPartId,
    buildingPartName,
    buildingPartType,
    provider,
    locationCode,
    clientCode,
    place,
  ];

  const idAdd = countBuildings.length + 1;
  const gursBuildingId = 123;
  const gursAddressId = 123;
  const description = buildingName;
  const creationTime = new Date().toISOString().toString();
  const creatorId = currentUserId?.id;
  const lastModificationTime = new Date().toISOString();
  const lastModifierId = currentUserId?.id;
  const isDeleted = true;
  const deleterId = currentUserId?.id;
  const deletionTime = new Date().toISOString();
  const userId = currentUserId?.id;

  let isMounted = true; // track whether component is mounted 

  useIonViewWillEnter(() => {

    //Clear input
    setUnit("");
    setBuldingResults([]);

    //Clear storage
    setStorage('user', ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);

    const abortController = new AbortController();
    const signal = abortController.signal;
    //Get local stored user data
    getStorage('user').then((value) => {
      setUsername(value[0]);
      setEmail(value[1]);
      setPassword(value[2]);
      setBuildingId(value[3]);
      setClientBuildingId(value[4]);
      setBuildingAddressId(value[5]);
      setBuildingName(value[6]);
      setBuildingType(value[7]);
      setHasApartments(value[8]);
      setHasBuildingParts(value[9]);
      setBuildingPartId(value[10]);
      setBuildingPartName(value[11]);
      setBuildingPartType(value[12]);
      setProvider(value[13]);
      setLocationCode(value[14]);
      setclientCode(value[15]);
      setPlace(value[16]);
      //console.log(value); 
    });

    //Get units
    /*getProviders()
      .then((response) => {
        setUnits(response);
        console.log(Providers);
      })
      .catch((error) => console.log(error));*/

    getCurrentUser()
      .then((response) => {
        //console.log(response);
        if (isMounted) {
          setProfile(response);
          setLoggedIn(true)
        }

      })
      .catch((error) => console.log(error));

    //Get all client buildings
    /*getClientBuildings()
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setCountBuildings(response);
        }
      })
      .catch((error) => console.log(error));*/


    if (isLoggedIn) {
      if (isMounted) {
        setStorage('user', [Profile?.email, Profile?.email, '', '', '', '', '', '', '', '', '']);
      }
    }

    return () => {
      // clean up
      isMounted = false;
    };

  }, []);

  const handleNextStep = (buildingId: any, addressId: any, hasBuildingParts: any, hasApartments: any, clientbuildingid: any, clientbuildpart: any) => {
    if (isMounted) {
      //Set building id
      setBuildingId(buildingId);
      setBuildingAddressId(addressId);
      setHasApartments(hasApartments);
      setHasBuildingParts(hasBuildingParts);

      const buildType = hasApartments && hasBuildingParts || hasBuildingParts && !hasApartments ? t('auth.register.union') : t('auth.register.house');
      setBuildingName(buildType);


      if (hasApartments && hasBuildingParts || hasBuildingParts && !hasApartments) {
        history.push(`/auth/register-step-1-a-multiple/buildingid/${buildingId}/addressid/${addressId}/hasbuildingparts/${hasApartments}/hasapartments/${hasBuildingParts}/clientbuildingid/${clientbuildingid}/clientbuildingpartid/${clientbuildpart}`);
      } else {
        history.push(`/auth/register-step-1-b/buildingid/${buildingId}/addressid/${addressId}/hasbuildingparts/false/hasapartments/false/clientbuildingid/${clientbuildingid}/clientbuildingpartid/${clientbuildpart}`);
      }
    }
    return () => {
      // clean up
      isMounted = false;
    };

  };


  //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/dashboardmain")
    });
  });

  const handleInputSearchChange = (e: any) => {
    if (isMounted) {
      setDataLoad(false);
      if (e.target.value !== '' && e.target.value.length > 1) {
        setErrors({
          ...errors,
          search: undefined,
        });

        //Handle Search buildings
        const doSearch = () => {
          //console.log('start search')
          searchBuildings(
            e.target.value,
          )
            .then((response: any) => {
              // success
              //console.log("Status Submitted " + e.target.value)
              console.log(response);
              setBuldingResults(response);
              setDataLoad(true);
            })
            .catch((error) => {
              // failure
              console.log(error);
            });
        };

        if (typingTimeout) {
          clearTimeout(typingTimeout);
        }

        // Start searching after 1 seconds
        setTypingTimeout(+global.setTimeout(doSearch, 1000));

        setUnit(e.target.value);


        //console.log(e.target.value)

      } else {
        setErrors({
          ...errors,
          search: ['Search is required'],
        });
      }
      setUnit(e.target.value)
    }
    return () => {
      // clean up
      isMounted = false;
    };
  };




  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="p-4 flex pt-8 justify-center rounded-t-2xl bg-white dark:bg-gray-900">
          <div className="w-full mb-5">
            <StepsHeader title={t('auth.register.step1ATitleFind')} />

            <div className="py-2">
              <InputSearch
                type="text"
                label={t('auth.register.searchLocation')}
                error={errors.search}
                value={unit}
                onChange={handleInputSearchChange}
                onPaste={handleInputSearchChange}
              />
            </div>

            {dataLoad ? (
              buldingResults[0] ? (
                <>
                  <div className="mt-8 font-bold text-black-5 dark:text-white">{t('auth.register.results')}</div>
                  {buldingResults.map((building, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => handleNextStep(building.buildingId, building.addressId, building.hasBuildingParts, building.hasApartments, 0, 0)}
                        className="flex items-center py-4 border-b border-gray-45 dark:border-gray-5 mt-4">
                        <IonIcon
                          src={building.hasApartments && building.hasBuildingParts ? "/assets/icon/ic-union.svg" : building.hasBuildingParts && !building.hasApartments ? "/assets/icon/ic-business.svg" : "/assets/icon/ic-house.svg"}
                          class="ios hydrated text-xl mr-4"
                        ></IonIcon>
                        <div className="text-sm text-gray-55 dark:text-white"><strong>{building.street} {building.houseNumber}</strong>, {building.post}</div>
                      </div>
                    );
                  })}
                </>
              ) : (
                unit && (
                  <div className="flex items-center justify-center h-full w-full"><div className="">{t('auth.register.noResults')} </div> <div className="font-bold ml-2"> {unit}</div></div>
                )
              )

            ) : (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <Loader />
              </div>
            )}


          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default RegisterStep1A;
