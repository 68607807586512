import { Api } from '../api';

type inputParams = {
  id: string;
};

export type GetKeyAccountsResponse = {
  token: string;
  phone: string | null;
  email: string | null;
};

export const deleteBuilding = async (params: inputParams): Promise<any> => { 
  const api = new Api();
  try {
    const response = await api.request('DELETE', `app/client-building/client-building/${params.id}`, true);
    return response; 
  } catch (error) {
    throw error;
  }
};