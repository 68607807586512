import { Api } from '../api';

//Register
export const setArticleStatus = async (
  articleId: string,
  isRead: boolean,
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('POST', 'app/client-article/set-article-status', true, {
      articleId,
      isRead,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
