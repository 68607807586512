import {
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonButton,
  IonRouterLink,
  IonToolbar,
  IonFooter,
  IonList,
  IonRadioGroup,
  IonRadio,
  useIonViewWillEnter,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderNoBack from '../../components/HeaderWithoutBackBut';
import StepsWithBack from '../../components/StepsWithBackButton';
import { getStorage, setStorage} from '../../data/user/register.api';
import { getProviders } from '../../data/client-points/providers.api';

type FormErrors = {
  provider: string[] | undefined;
};

type ProvidersData = {
  id: number;
  name: string;
  utilityTypeId: number;
  logo: string;
};

const RegisterStep2: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [provider, setProvider] = useState();
  const [errors, setErrors] = useState<FormErrors>({
    provider: undefined,
  });
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [place, setPlace] = useState('');

  const [Providers, setProviders] = useState<ProvidersData[]>([]);

  const user = [username, email, password, provider, locationCode, clientCode, place];



  useIonViewWillEnter(() => { //useEffect
    const abortController = new AbortController();
    const signal = abortController.signal;
    //Get local stored user data
    getStorage('user').then((value) => {
      setUsername(value[0]);
      setEmail(value[1]);
      setPassword(value[2]);
      setProvider(value[3]);
      setLocationCode(value[4]);
      setclientCode(value[5]);
      setPlace(value[6]);
      //console.log(value); 
    });

    //Get providers
    getProviders()
      .then((response) => {
        setProviders(response);
        console.log(Providers);
      })
      .catch((error) => console.log(error));

  }, []);

  const handleNextStep = () => {
    // Chek if selected fields are correct
    if (provider !== null) {
      //Set user data to local storage
      setStorage("user", user);
      history.push('/auth/register-step-3');
    } else {
      setErrors({
        ...errors,
        provider: ['Provider is required'],
      });
      return;
    }

  };


  //Check if provider is selected
  const onIonChange = (e: any) => {
    if (e.target.value !== null) {
      setErrors({
        ...errors,
        provider: undefined,
      });
    } else {
      setErrors({
        ...errors,
        provider: ['Provider is required'],
      });
    }
    setProvider(e.detail.value)
  }


  return (
    <IonPage>
      <IonContent fullscreen>
        <HeaderNoBack title={t('auth.register.title')} />
        <div className="p-4 flex pt-8 justify-center rounded-t-2xl bg-white">
          <div className="w-full mb-5">
            <StepsWithBack title={t('auth.register.step2Title')} currentStep="2" backHref="/auth/register" totalStep="4" />
            <div className="text-sm text-gray-5 mt-2 mb-5">
              {t('auth.register.step2Text')} <IonRouterLink className="font-bold underline ml-1" routerLink="/auth/register-provider">{t('general.here')}</IonRouterLink>.
            </div>

            <IonList mode="md">
              <IonRadioGroup value={provider} onIonChange={onIonChange}>
                {Providers.map((provider) => (
                  <IonItem key={provider.id} lines="none">
                    <IonLabel><div className="flex items-center"><img className="mr-6" width="44" height="44" src={provider.logo ? `data:image/jpeg;base64, ${provider.logo}` : '/assets/no-image.jpg'} />{provider.name}</div></IonLabel>
                    <IonRadio slot="start" value={provider.id} />
                  </IonItem>
                ))}
              </IonRadioGroup>
            </IonList>

          </div>
        </div>
      </IonContent>

      <IonFooter mode="ios" className="ion-no-border p-4 bg-white">
        <IonToolbar>
          <IonButton onClick={handleNextStep} shape="round" color="primary" className="w-full normal-case font-semibold h-12 text-base">{t('auth.register.cta')}</IonButton>
        </IonToolbar>
      </IonFooter>

    </IonPage>
  );
}

export default RegisterStep2;
