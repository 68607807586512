import { Redirect, useHistory } from 'react-router-dom';
import { useState } from 'react';
import {
  IonContent,
  IonPage,
  IonButton,
  IonRouterLink,
  useIonViewWillEnter,
  IonAlert,
  IonIcon,
} from '@ionic/react';
import Input from '../../components/form/Input';
import { useAuth } from '../../context/Auth';
import login from '../../data/login';
import { useTranslation } from 'react-i18next';
import EULogos from '../../components/EULogos';
import { resendConfirmationEmail } from '../../data/user/resendConfirmationEmail';
import HeaderLogoNotLoggedIn from '../../components/HeaderLogoNotLoggedIn';
import { useIonRouter } from '@ionic/react';
import { Preferences } from '@capacitor/preferences';
import { App } from '@capacitor/app';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
//Push notifications
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { deviceToken } from '../../data/user/device-token'
import { getCurrentUserAccount } from '../../data/user/current-user-account.api';

type FormErrors = {
  username: string[] | undefined;
  password: string[] | undefined;
};

const Login: React.FC = () => {
  const { t } = useTranslation();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(true);
  const [successEmailSent, setSuccessEmailSent] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { authToken, setAuthToken } = useAuth();
  const [version, setVersion] = useState<string>('');
  const [errors, setErrors] = useState<FormErrors>({
    username: undefined,
    password: undefined,
  });
  const [showAlert, setShowAlert] = useState(false);
  const ionRouter = useIonRouter();
  const [showPassword, setShowPassword] = useState(false);

  useIonViewWillEnter(() => {
    fetch();
  }, []);

  function fetch() {
    Preferences.get({ key: 'version' }).then((storage: any) => {
      setVersion(storage.value);
    });
    Preferences.remove({ key: 'demo' });
    setUsername('');
    setPassword('');
    setIsConfirmed(true);
    setSuccessEmailSent(false);
    setErrors({
      username: undefined,
      password: undefined,
    });

  }

  // Login form
  const postLogin = async () => {
    login(username, password)
      .then((response: any) => {

        getCurrentUserAccount()
          .then(async(response) => {
            //console.log(response);
            //Set push notifications
            if (response.pushNotifications === true) {
              if (Capacitor.isNativePlatform()) {
                await PushNotifications.requestPermissions();
                await PushNotifications.register();
                FCM.subscribeTo({ topic: 'general' }).catch((err) =>
                  console.log(err)
                );

                //Token
                PushNotifications.addListener('registration', (token) => {
                  console.log('Push registration success, token: ' + token, token.value);

                  //Update user token:
                  deviceToken(
                    token.value
                  )
                    .then((response) => {
                      // success
                      console.log(response);
                      console.log("Token updated");
                      console.log('Token updated test, token: ' + token.value);
                    })
                    .catch((error) => {
                      // failure
                      console.log(error);
                      console.log("Token not updated");
                      console.log('Token not updated test, token: ' + token.value);
                    });

                });
              }
            }
          })
          .catch((error) => console.log(error));

        setLoggedIn(true);
        setAuthToken(response.access_token);

        //Set api version in storage
        Preferences.set({
          key: 'version',
          value: '1.8.0',
        });
         //Set language in storage
         Preferences.set({
          key: 'language',
          value: 'sl',
        });
        //Set theme in storage
        Preferences.set({
          key: 'theme',
          value: 'light',
        });
      })
      .catch((error: any) => {
        //console.log(error.response)
        //If email is not confirmed yet
        if (
          error.response.error_description ===
          'You are not allowed to login! Your account is inactive or needs to confirm your email/phone number.'
        ) {
          setIsConfirmed(false);
        } else {
          setIsConfirmed(true);
        }

        setErrors({
          username: ['Username/password combination is wrong'],
          password: ['Username/password combination is wrong'],
        });
      });
  };

  //Resend email
  const postResendEmail = () => {
    resendConfirmationEmail(username)
      .then((response: any) => {
        setSuccessEmailSent(true);
        //console.log(response);
        //console.log("success");
        setErrors({
          username: undefined,
          password: undefined,
        });
      })
      .catch((error: any) => {
        console.log(error);
        setSuccessEmailSent(false);
        //console.log("error");
      });
  };

  //Handle username/email input:
  const handleInputUsernameChange = (e: any) => {
    /* if (e.target.value === '') {
       setErrors({
         ...errors,
         username: ['Username is required'],
       });
     } else {
       setErrors({
         ...errors,
         username: undefined,
       });
     }*/
    setErrors({
      ...errors,
      username: undefined,
    });
    setUsername(e.target.value);
  };

  //Handle password input:
  const handleInputPasswordChange = (e: any) => {
    /* if (e.target.value === '') {
       setErrors({
         ...errors,
         password: ['Password is required'],
       });
     } else {
       setErrors({
         ...errors,
         password: undefined,
       });
     }*/
    setErrors({
      ...errors,
      password: undefined,
    });
    setPassword(e.target.value);
  };

  //Go to next field when hit enter on username input field
  const handleNext = (event: any) => {
    if (event.key.toLowerCase() === 'enter') {
      const nextSibling =
        document.querySelector<HTMLInputElement>(`input[name=password]`);
      // If found, focus the next field
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
  };

  //Submit form when hit enter on password input field
  const handleEnter = (event: any) => {
    if (event.key.toLowerCase() === 'enter') {
      const nextSibling = postLogin();
      // If found, submit
      if (nextSibling !== null) {
        nextSibling;
      }
    }
  };

  const handleLoginAgain = () => {
    setSuccessEmailSent(false);
    setIsConfirmed(true);
  };

  if (isLoggedIn || authToken.length > 0) {
    return <Redirect to="/dashboardmain" />;
  }

  //Ask for exit app on android hardware back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      setShowAlert(true);
    });
  });

  /** Show hide password */
  const handleShowPassword = (e: any) => {
    setShowPassword(!showPassword)
  }

  return (
    <IonPage color="primary">
      <IonContent fullscreen={true}>
        <div className="px-4 pt-16 pb-10 -mb-4 bg-gradient-to-r from-blue-5 to-blue-25 dark:from-gray-900 dark:to-gray-700">
          <HeaderLogoNotLoggedIn />

          <div className="text-3xl font-bold text-white bg-transparent border-none">
            {t('auth.login.title')}
          </div>
        </div>
        <div className="items-center justify-center p-4 bg-white dark:bg-gray-900 rounded-t-2xl">
          <div className="w-full">
            <div className="flex items-center justify-between mt-2 mb-4">
              <div className="text-xl font-bold text-gray-55 dark:text-white">
                {isConfirmed
                  ? t('auth.login.subTitle')
                  : t('auth.login.notConfirmed')}
              </div>
            </div>
            <div className="py-2 overflow-hidden">
              <Input
                name="username"
                type="text"
                label={t('auth.login.usernameLabel')}
                error={errors.username}
                value={username}
                onChange={handleInputUsernameChange}
                enterKeyHint="next"
                onKeyDown={handleNext}
              />
            </div>
            <div className="py-2 relative">
              <Input
                name="password"
                type={showPassword ? "text" : "password"}
                label={t('auth.login.passwordLabel')}
                error={errors.password}
                value={password}
                onChange={handleInputPasswordChange}
                enterKeyHint="enter"
                onKeyDown={handleEnter}
              />
              <IonIcon onClick={handleShowPassword} class="ios hydrated absolute right-0 top-0 z-10 mt-6 mr-2 text-gray-20 dark:text-white" icon={showPassword ? eyeOutline : eyeOffOutline} />
            </div>

            <div className="text-right">
              <IonRouterLink
                className="text-sm font-bold text-gray-55 dark:text-white"
                routerLink="/auth/password"
              >
                {t('auth.login.forgottenPassword')}
              </IonRouterLink>
            </div>
            {(errors.username || errors.password) && (
              <div className='text-red-500 text-center text-sm mt-4'>
                {isConfirmed ? (
                  <span>{t('auth.login.wrongLogin')}</span>
                ) : (
                  <span>{t('auth.login.notConfirmed')}</span>
                )}


              </div>
            )}
            {isConfirmed ? (
              <IonButton
                onClick={postLogin}
                color="blue"
                className="w-full h-12 mt-6 text-base font-semibold normal-case"
              >
                {t('auth.login.cta')}
              </IonButton>
            ) : successEmailSent ? (
              <>
                <IonButton
                  color="blue"
                  className="w-full h-12 mt-6 text-base font-semibold normal-case"
                >
                  {t('auth.login.resendEmailSuccess')}
                </IonButton>
                <button
                  onClick={handleLoginAgain}
                  className="block mx-auto mt-4 text-sm font-bold text-center text-blue-40"
                >
                  {t('auth.login.resendEmailSuccessLogin')}
                </button>
              </>
            ) : (
              <IonButton
                onClick={postResendEmail}
                color="blue"
                className="w-full h-12 mt-6 text-base font-semibold normal-case"
              >
                {t('auth.login.resendEmail')}
              </IonButton>
            )}

            <div className="mt-4 text-sm text-center text-gray-55 dark:text-white">
              <div>
                {t('auth.login.dontHaveAccount')}{' '}
                <IonRouterLink
                  className="font-bold text-blue-40"
                  routerLink="/auth/register"
                >
                  {t('auth.login.register')}
                </IonRouterLink>
                <div className="mt-4">
                  {t('auth.accountSettings.versionLabel')} {version}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 text-sm text-center text-gray-55">
            <IonRouterLink
              className="font-bold text-gray-50"
              routerLink="/provider"
            >
              {t('auth.login.areYouProvider')}
            </IonRouterLink>
          </div>
          <div className="mt-6">
            <EULogos />
          </div>
        </div>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          cssClass='my-custom-class'
          header={t('general.alertTitle')}
          message={t('general.alertDescription')}
          buttons={[
            {
              text: t('general.dismiss'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: blah => {
                console.log('Confirm Cancel: blah');
              }
            },
            {
              text: t('auth.register.close'),
              handler: () => {
                App.exitApp();
              }
            }
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

export default Login;
