import { IonHeader, IonToolbar, IonTitle } from '@ionic/react';

interface ContainerProps {
  title: string;
}

const HeaderNoBack: React.FC<ContainerProps> = ({ title }) => {
  return (
    <div className="px-4 pt-20 pb-6 bg-blue-5 dark:bg-gray-900">
      <div className="text-3xl font-bold text-white border-none bg-blue-5 dark:bg-gray-900">
        {title}
      </div>
    </div>
  );
};
export default HeaderNoBack;
