import {
  IonContent,
  IonPage,
  IonButton,
} from '@ionic/react';
import { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Header from './../../components/Header';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';
import HeaderNoBack from '../../components/HeaderWithoutBackBut';
import SubTitleWithBack from '../../components/SubTitleWithBack';
import { forgotPassword } from '../../data/user/forgot-password.api';
import EULogos from '../../components/EULogos';
import HeaderLogoNotLoggedIn from '../../components/HeaderLogoNotLoggedIn';
import { useIonRouter } from '@ionic/react';

type FormErrors = {
  email: string[] | undefined;
};

const Password: React.FC = () => {
  const ionRouter = useIonRouter();
  const { t } = useTranslation();
  const history = useHistory();
  const [errors, setErrors] = useState<FormErrors>({
    email: undefined,
  });
  const [email, setEmail] = useState('');
  const [successPasswordChange, setsuccessPasswordChange] = useState<boolean>(false);

  // The regular exprssion to validate the email pattern
  // It may not be 100% perfect but can catch most email pattern errors and assures that the form is mostly right  
  const emailRegex = /\S+@\S+\.\S+/;

  // Forgot password form
  const postForgotPassword = async () => {
    if (email != "" && emailRegex.test(email)) {
      forgotPassword(email)
        .then((response: any) => {
          setsuccessPasswordChange(true)
          //console.log(response);
        })
        .catch((error: any) => {
          console.log(error);
          setsuccessPasswordChange(false)
          setErrors({
            email: ['Email is required'],
          });
        });
    } else {
      setErrors({
        email: ['Email is required'],
      });
      setsuccessPasswordChange(false)
    }
  };

  //Handle email input:
  const handleInputEmailChange = (e: any) => {
    setsuccessPasswordChange(false)
    if (e.target.value !== '' && emailRegex.test(email)) {
      setErrors({
        ...errors,
        email: undefined,
      });
    } else {
      setErrors({
        ...errors,
        email: ['Email is required'],
      });
    }
    setEmail(e.target.value);
  };

  //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/auth/login")
    });
  });

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="px-4 pt-16 pb-10 bg-gradient-to-r from-blue-5 to-blue-25 dark:from-gray-900 dark:to-gray-700  -mb-4">
          <HeaderLogoNotLoggedIn />
          <div className="text-3xl font-bold text-white border-none bg-transparent">
            {t('auth.register.title')}
          </div>
        </div>
        <div className="pt-4 bg-white dark:bg-gray-900 rounded-t-2xl">
          <SubTitleWithBack title={t('auth.login.requestPassword')} backHref="/auth/login" />
          <div className="flex justify-center px-4 mt-10">
            <div className="w-full">
              <div className="py-2">
                <Input
                  type="email"
                  label={t('auth.accountSettings.emailPlaceholder')}
                  error={errors.email}
                  value={email}
                  onChange={handleInputEmailChange}
                />
              </div>
              <div className={`text-blue-40 mt-6 text-center ${successPasswordChange !== true && "hidden"}`}>{t('auth.register.passwordChangeYouMessage')}</div>
              <IonButton onClick={postForgotPassword} color="blue" className="mt-16 w-full normal-case font-semibold h-12 text-base">{t('auth.accountSettings.ctaRequestChange')}</IonButton>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Password;
