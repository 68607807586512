import {
  IonContent,
  IonPage,
  IonButton,
  IonRouterLink,
  IonToolbar,
  IonFooter,
  useIonViewWillEnter,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useState } from 'react';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';
import HeaderNoBack from '../../components/HeaderWithoutBackBut';
import StepsWithBack from '../../components/StepsWithBackButton';
import { useRegistration } from '../../context/Registration';
import {
  register,
  getStorage,
  setStorage,
  removeStorage,
} from '../../data/user/register.api';

type FormErrors = {
  place: string[] | undefined;
};

const RegisterStep4: React.FC = () => {
  const { t } = useTranslation();
  const [place, setPlace] = useState('');
  const { data, setData } = useRegistration();
  const [errors, setErrors] = useState<FormErrors>({
    place: undefined,
  });
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [provider, setProvider] = useState('');
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [successRegistration, setsuccessRegistration] =
    useState<boolean>(false);
  const [errorRegistration, seterrorRegistration] = useState<boolean>(false);
  const [errorMessageFromApi, seterrorMessageFromApi] = useState('');

  const user = [
    username,
    email,
    password,
    provider,
    locationCode,
    clientCode,
    place,
  ];
  const appName = 'eMRDashboard_App';


  useIonViewWillEnter(() => { //useEffect

    //Get local stored user data
    getStorage('user').then((value) => {
      setUsername(value[0]);
      setEmail(value[1]);
      setPassword(value[2]);
      setProvider(value[3]);
      setLocationCode(value[4]);
      setclientCode(value[5]);
      setPlace(value[6] ?? '');
      console.log(value);
    });

  }, []);

  const handleNextStep = () => {
    // Chek if selected fields are correct
    if (place !== '') {
      //Set user data to local storage
      setStorage('user', user);

      setData({
        ...data,
        username: email,
        email: email,
        password: password,
        provider: provider,
        locationCode: locationCode,
        clientCode: clientCode,
        description: place,
      });

      register(
        username,
        email,
        password,
        appName,
        locationCode,
        clientCode,
        place,
        provider
      )
        .then(async (response: any) => {
          // success
          setsuccessRegistration(true);
          seterrorRegistration(false);
          //Remove user data from storage
          await removeStorage('user');
          //console.log(response);
        })
        .catch((error) => {
          // failure
          setsuccessRegistration(false);
          seterrorRegistration(true);
          seterrorMessageFromApi(error.response.error.message);
          console.log(error);
        });
    } else {
      setErrors({
        ...errors,
        place: ['Place is required'],
      });
      return;
    }
  };

  //Handle place input
  const handleInputPlaceChange = (e: any) => {
    if (e.target.value !== '') {
      setErrors({
        ...errors,
        place: undefined,
      });
    } else {
      setErrors({
        ...errors,
        place: ['Place is required'],
      });
    }
    setPlace(e.target.value);
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <HeaderNoBack title={t('auth.register.title')} />
        <div className="flex justify-center p-4 pt-4 bg-white rounded-t-2xl">
          <div className="w-full">
            <StepsWithBack
              title={t('auth.register.step4Title')}
              backHref="/auth/register-step-3"
              currentStep="4"
              totalStep="4"
            />
            <div className="mt-2 text-sm text-gray-5">
              {t('auth.register.step4Text')}
            </div>
            <div className="py-2 mt-6">
              <Input
                type="text"
                label={t('auth.register.placePlaceholder')}
                error={errors.place}
                value={place}
                onChange={handleInputPlaceChange}
              />
            </div>
            <div
              className={`text-blue-5 mt-6 text-center ${successRegistration !== true && 'hidden'
                }`}
            >
              {t('auth.register.thankYouMessage')}{' '}
              <IonRouterLink
                className="underline text-blue-5"
                routerLink="/auth/login"
              >
                {t('auth.register.thankYouMessageLinkText')}.
              </IonRouterLink>
            </div>
            <div
              className={`text-red-5 mt-6 text-center ${errorRegistration !== true && 'hidden'
                }`}
            >
              {/*t('auth.register.errorMessage')*/ errorMessageFromApi}
            </div>
          </div>
        </div>
      </IonContent>
      <IonFooter mode="ios" className="p-4 bg-white ion-no-border">
        <IonToolbar>
          <IonButton
            onClick={handleNextStep}
            shape="round"
            color="primary"
            className="w-full h-12 text-base font-semibold normal-case"
          >
            {t('auth.register.ctaLast')}
          </IonButton>
          <IonRouterLink className="text-gray-5" routerLink="/auth/register">
            <div className="mt-6 text-center">
              {t('auth.register.ctAddMore')}
            </div>
          </IonRouterLink>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default RegisterStep4;
