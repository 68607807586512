import {
  IonButtons,
  IonBackButton,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';

interface ContainerProps {
    title: string;
    totalStep: string;
    currentStep: string;
    backHref: string;
  }

const StepsWithBack: React.FC<ContainerProps> = ({
    title,
    totalStep,
    currentStep,
    backHref
  }) => {
    return (
      <div className="flex items-center justify-between mb-4">
        <IonButtons slot="start">
          <IonBackButton
            className="h-4"
            mode="md"
            defaultHref={backHref}
            icon={chevronBackOutline}
            class="text-gray-10 -ml-3"
          />
        </IonButtons>
        <div className="text-gray-55 font-bold text-xl">{title}</div>
        <div className="text-gray-10">{currentStep} / {totalStep}</div>
    </div>
    );
};
export default StepsWithBack;
