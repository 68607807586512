import { IonButton, IonContent, IonIcon, IonPopover, IonRouterLink } from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConsumptionCard from './dashboardSingle/ConsumptionCard';
interface ContainerProps {
  id?: string;
  title?: string;
  //type?: 'WATER' | 'GAS' | 'ELECTRICITY' | 'HEAT';
  type?: string;
  measure?: string;
  lowNum?: string;
  highNum?: string;
  low?: boolean;
  high?: boolean;
  lowLabel?: string;
  highLabel?: string;
  ShowHide?: string;
  lastMonth?: string;
  lowRate?: string;
  highRate?: string;
  singleLink?: string;
  consumptionStatus?: number;
  hasAlarms?: boolean;
  alarmsTitle?: string;
  alarmsDescription?: string;
  onClickCard: () => void;
  NoDecimalNumbers?: boolean;
}

const DashCard: React.FC<ContainerProps> = ({
  id,
  title,
  type, //water, gas, electricity
  measure,
  lowNum,
  highNum,
  low,
  high,
  lowLabel,
  highLabel,
  ShowHide,
  lastMonth,
  lowRate,
  highRate,
  singleLink,
  onClickCard,
  consumptionStatus,
  hasAlarms,
  alarmsTitle,
  alarmsDescription,
  NoDecimalNumbers
}) => {
  const ClassWawtter = 'from-blue-5 to-blue-25';
  const ClassGas = 'from-green-5 to-green-10';
  const ClassElectricity = 'from-yellow-15 to-yellow-20';
  const ClassHeat = 'from-red-15 to-red-20';

  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState();

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  return (
    <div id={id} className={`p-4 ${ShowHide}`} datatype={type}>
      <div className={`p-5 text-gray-55 dark:text-white rounded-2xl bg-gradient-to-r bg-white dark:bg-gray-800 w-full relative ${hasAlarms && "border-2 border-red-5"}`}>
        <button onClick={onClickCard} className="absolute right-0 top-0 z-40 mt-4 mr-2">
          <IonIcon
            src="/assets/icon/ic-more-one.svg"
            class="ios hydrated text-3xl"
          ></IonIcon>
        </button>
        {hasAlarms && (
          <>
            <button
              style={{ outline: "none" }}
              className="absolute right-0 top-0 z-40 mt-4 mr-11"
              onClick={(e: any) => {
                e.persist();
                setShowPopover({ showPopover: true, event: e });
              }}>
              <IonIcon
                src="/assets/icon/icon-alarm.svg"
                class="ios hydrated text-3xl"
              ></IonIcon>
            </button>
            <IonPopover
              show-backdrop={false}
              event={popoverState.event}
              isOpen={popoverState.showPopover}
              onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
              className="popover-custom"
              arrow={false}
            >
              <IonContent>
                <div className='p-4 bg-gray-800 text-white text-2xs'>
                  <div className='font-bold mb-1 text-xs'>{alarmsTitle}</div>
                  <div>{alarmsDescription}</div>
                </div>
              </IonContent>
            </IonPopover>
          </>
        )}

        <IonRouterLink routerLink={singleLink} routerDirection="forward" className="w-11/12 block h-full">
          <div className="flex items-center justify-between mb-10 relative">
            <div className="flex items-center">
              <div
                className={`shadow-md rounded-md flex items-center justify-center w-8 h-8 mr-2 bg-gradient-to-r ${type === 'WATER' && ClassWawtter} 
                  ${type === 'ELECTRICITY' && ClassElectricity} 
                  ${type === 'HEAT' && ClassHeat} 
                  ${type === 'GAS' && ClassGas}`}
              >
                {type === 'WATER' && (
                  <IonIcon
                    src="/assets/icon/ic-watter-white.svg"
                    class="ios hydrated text-xl"
                  ></IonIcon>
                )}
                {type === 'ELECTRICITY' && (
                  <IonIcon
                    src="/assets/icon/ic-lightning-white.svg"
                    class="ios hydrated text-xl"
                  ></IonIcon>
                )}
                {type === 'GAS' && (
                  <IonIcon
                    src="/assets/icon/ic-gas-white.svg"
                    class="ios hydrated text-xl"
                  ></IonIcon>
                )}
                {type === 'HEAT' && (
                  <IonIcon
                    src="/assets/icon/ic-heat-white.svg"
                    class="ios hydrated text-xl"
                  ></IonIcon>
                )}
              </div>
              <div className="text-xl font-bold text-gray-55 dark:text-white">
                {title}
              </div>
            </div>
          </div>
          <div className="mb-4 text-sm text-gray-50">{lastMonth}</div>
          <div className="flex justify-between mb-3 items-center">
            <div>
              {lowNum !== "hidden" ? (
                <span className="text-3xl font-bold text-gray-55 dark:text-white">{NoDecimalNumbers ? Number(lowNum).toFixed(0) : lowNum?.replace(".", ",")}</span>
              ) : <span className="text-3xl font-bold text-gray-55 dark:text-white">0</span>}
              <span className="ml-2 text-sm text-gray-50">{measure}</span>
              {lowLabel !== "WATER" && highNum !== "hidden" && (
                <div className="mt-1 text-xs text-gray-50">{NoDecimalNumbers ? Number(lowRate).toFixed(0) : lowRate?.replace(".", ",")}</div>
              )}
            </div>
            {highLabel !== "WATER" && highNum !== "hidden" && (
              <div className="text-right">
                <span className="text-3xl font-bold text-gray-55 dark:text-white">
                  {NoDecimalNumbers ? Number(highNum).toFixed(0) : highNum?.replace(".", ",")}
                </span>
                <span className="ml-2 text-sm text-gray-50">{measure}</span>
                {highLabel !== "WATER" && (
                  <div className="mt-1 text-xs text-left text-gray-50">
                    {NoDecimalNumbers ? Number(highRate).toFixed(0) : highRate?.replace(".", ",")}
                  </div>
                )}
              </div>
            )}
          </div>
        </IonRouterLink>

        {highLabel === "WATER" && consumptionStatus !== 0 && consumptionStatus !== null && (
          <div className={`flex justify-end z-40 relative`}>
            {consumptionStatus === 1 && (
              <ConsumptionCard
                consumptionStatus={1}
              />
            )}
            {consumptionStatus === 2 && (
              <ConsumptionCard
                consumptionStatus={2}
              />
            )}
            {consumptionStatus === 3 && (
              <ConsumptionCard
                consumptionStatus={3}
              />
            )}
          </div>
        )}

      </div>
    </div>
  );
};
export default DashCard;
