import { IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';


interface Props {
  onClose?: Function;
}

const AppToast = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const redirect = () => {
    window.location.href = `ihabitapp://app.mojhab.si${window.location.pathname}`;
    setTimeout(() => {
      if (
        Capacitor.getPlatform() === 'web' &&
        /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
      ) {
        window.location.href = `https://play.google.com/store/apps/details?id=${process.env.REACT_APP_PACKAGE_NAME}`;
      } else {
        window.location.href = `https://itunes.apple.com/us/app/appname/${process.env.REACT_APP_IOS_PACKAGE_ID}`;
      }


    }, 200);
  };
  return (
    <div className="flex items-center px-4 py-2 bg-white border-b max-h-16 border-b-gray-5">
      <a className="flex items-center text-black-5" onClick={redirect}>
        <img src="/assets/logo.svg" className="w-12 h-12 rounded-lg" />
        <h2 className="ml-4 text-sm font-bold text-black-5">{(Capacitor.getPlatform() === 'web' &&
          /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? t('general.appToast.copy') : t('general.appToast.copyios')}</h2>
      </a>
      <IonIcon
        icon={close}
        class="ion hydrated text-2xl right-0 pl-4 pr-2"
        onClick={() => (onClose ? onClose() : '')}
      ></IonIcon>
    </div>
  );
};
export default AppToast;
