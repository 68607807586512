import {
  IonContent,
  IonPage,
  IonButton,
  IonToolbar,
  IonFooter,
  useIonViewWillEnter,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useState} from 'react';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';
import HeaderNoBack from '../../components/HeaderWithoutBackBut';
import StepsWithBack from '../../components/StepsWithBackButton';
import { getStorage, setStorage } from '../../data/user/register.api';
import { getBill } from '../../data/client-points/bill.api';

type FormErrors = {
  locationcode: string[] | undefined;
  clientcode: string[] | undefined;
};
type BillData = {
  data: string,
  message: string
};

const RegisterStep3: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [errors, setErrors] = useState<FormErrors>({
    locationcode: undefined,
    clientcode: undefined,
  });
  const [Bill, setBill] = useState<BillData>();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [provider, setProvider] = useState('');
  const [place, setPlace] = useState('');

  const user = [username, email, password, provider, locationCode, clientCode, place];



  useIonViewWillEnter(() => { //useEffect

    //Get local stored user data
    getStorage('user').then((value) => {
      setUsername(value[0]);
      setEmail(value[1]);
      setPassword(value[2]);
      setProvider(value[3]);
      setLocationCode(value[4] ?? '');
      setclientCode(value[5] ?? '');
      setPlace(value[6]);
      //console.log(value); 

      //Get bill image from api
      getBill({ id: value[3].toString() })  //TO DO: Allow api to get data when user is also not logged in
        .then((response) => {
          console.log(response);
          setBill(response);
        })
        .catch((error) => console.log(error));

    });


  }, []);

  const handleNextStep = () => {
    // Chek if selected fields are correct
    if (locationCode !== '' && clientCode !== '' && locationCode.length > 3 && clientCode.length > 1) {
      //Set user data to local storage
      setStorage("user", user);
      history.push('/auth/register-step-4');
    } else {
      setErrors({
        ...errors,
        locationcode: ['Location code is required'],
        clientcode: ['Client code is required'],
      });
      return;
    }

  };


  //Handle Location code
  const handleInputLocationCodeChange = (e: any) => {
    if (e.target.value !== '' && e.target.value.length > 3) {
      setErrors({
        ...errors,
        locationcode: undefined,
      });
    } else {
      setErrors({
        ...errors,
        locationcode: ['Location code is required'],
      });
    }
    setLocationCode(e.target.value)
  };

  //Handle Client code
  const handleInputClientCodeChange = (e: any) => {
    if (e.target.value !== '' && e.target.value.length > 1) {
      setErrors({
        ...errors,
        clientcode: undefined,
      });
    } else {
      setErrors({
        ...errors,
        clientcode: ['Client code is required'],
      });
    }
    setclientCode(e.target.value)
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <HeaderNoBack title={t('auth.register.title')} />
        <div className="p-4 flex pt-4 rounded-t-2xl justify-center bg-white">
          <div className="w-full">
            <StepsWithBack title={t('auth.register.step3Title')} backHref="/auth/register-step-2" currentStep="3" totalStep="4" />
            <div className="py-2">
              <Input
                type="text"
                label={t('auth.register.clientLocationCodePlaceholder')}
                error={errors.locationcode}
                value={locationCode}
                onChange={handleInputLocationCodeChange}
              />
            </div>
            <div className="py-2">
              <Input
                type="text"
                label={t('auth.register.clientCodePlaceholder')}
                error={errors.clientcode}
                value={clientCode}
                onChange={handleInputClientCodeChange}
              />
            </div>
            <div className="font-bold text-sm text-gray-5 mt-8">{t('auth.register.step3TextBold')}</div>
            <div className="text-sm text-gray-5 mt-2">{Bill?.message}</div>
            {Bill?.data != null ? (
              <div className="mt-8"><img className="mx-auto" src={`data:image/jpeg;base64, ${Bill?.data}`} /></div>
            ) : (
              <div className="mt-8"><img className="mx-auto" src="/assets/poloznica.png" /></div>
            )
            }
          </div>
        </div>
      </IonContent>

      <IonFooter mode="ios" className="ion-no-border p-4 bg-white">
        <IonToolbar>
          <IonButton onClick={handleNextStep} shape="round" color="primary" className="w-full normal-case font-semibold h-12 text-base">{t('auth.register.cta')}</IonButton>
        </IonToolbar>
      </IonFooter>

    </IonPage>
  );
}

export default RegisterStep3;
