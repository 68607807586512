import {
  IonContent,
  IonPage,
  IonButton,
  IonToolbar,
  IonFooter,
  useIonViewWillEnter,
} from '@ionic/react';
import { useState} from 'react';
import { useHistory } from 'react-router';
import Header from './../../components/Header';
import Steps from './../../components/Steps';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';
import { getCurrentUser } from '../../data/user/current-user.api';
import {
  setStorage,
} from '../../data/user/register.api';
import { addClientPointForBuilding } from '../../data/client-points/addClientPointForBuilding.api';
import { Preferences } from '@capacitor/preferences';

type FormErrors = {
  place: string[] | undefined;
};

type CurrentUserlData = {
  isAuthenticated: boolean,
  id: string,
  userName: string,
  email: string,
};

const AddPlacePageStep3: React.FC = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errors, setErrors] = useState<FormErrors>({
    place: undefined,
  });
  const [currentUserId, setcurrentUserId] = useState<CurrentUserlData>();
  const [place, setPlace] = useState('');
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [provider, setProvider] = useState();
  const [dataLoad, setDataLoad] = useState<boolean>(false);

  const id = 0;
  const creationTime = new Date().toISOString();
  const creatorId = currentUserId?.id;
  const lastModificationTime = new Date().toISOString();
  const lastModifierId = currentUserId?.id;
  const isDeleted = true;
  const deleterId = currentUserId?.id;
  const deletionTime = new Date().toISOString();
  const code = locationCode;
  const customerCode = clientCode;
  const externalId = '';
  const name = '';
  const address = '';
  const description = place;
  const utilityId = provider;
  const userId = currentUserId?.id;
  const clientPointTypeId = 0;
  const clientBuildingId = props.match.params.buildingid;
  const clientBuildingPartId = props.match.params.partid;

  const placeData = [provider, locationCode, clientCode, place];

  useIonViewWillEnter(() => {
    //Get local stored user data
    Preferences.get({ key: 'place' }).then((storage: any) => {
      const getPlaceData = JSON.parse(storage.value || '{}');
      setProvider(getPlaceData[0]);
      setLocationCode(getPlaceData[1]);
      setclientCode(getPlaceData[2]);
      if (getPlaceData[3] == null) {
        setPlace('');
      } else {
        setPlace(getPlaceData[3]);
      }
      console.log(getPlaceData);
    });


    //Get current user
    getCurrentUser()
      .then((response) => {
        //console.log(response);
        setcurrentUserId(response);
      })
      .catch((error) => console.log(error));

  });

  const handleNextStep = () => {
    // Chek if selected fields are correct
    if (place !== '') {
      //Set user data to local storage
      setStorage('place', placeData);

      /*setData({
          ...data,
          provider: provider,
          locationCode: locationCode,
          clientCode: clientCode,
          place: place,
        });*/

      addClientPointForBuilding(
        id,
        creationTime,
        creatorId,
        lastModificationTime,
        lastModifierId,
        isDeleted,
        deleterId,
        deletionTime,
        code,
        customerCode,
        externalId,
        name,
        address,
        description,
        utilityId,
        userId,
        clientBuildingId,
        clientBuildingPartId
      )
        .then((response: any) => {
          // success
          //Set empty strings to storage
          setStorage('place', ['', '', '', '']);
          console.log('Client point added!');
          console.log(response);
          //history.push(`/dashboardmain/client-building/${props.match.params.buildingid}/part/${props.match.params.partid}`);
        })
        .catch((error) => {
          // failure
          console.log(error);
          //history.push('/dashboard');
        });
    } else {
      setErrors({
        ...errors,
        place: ['Place is required'],
      });
      return;
    }
  };

  //Handle place input
  const handleInputPlaceChange = (e: any) => {
    if (e.target.value !== '') {
      setErrors({
        ...errors,
        place: undefined,
      });
    } else {
      setErrors({
        ...errors,
        place: ['Place is required'],
      });
    }
    setPlace(e.target.value);
  };

  //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/dashboardmain/")
    });
  });

  return (
    <IonPage>
      <Header
        title={t('dashboard.addPlace.title')}
        backLink={`/dashboard/add-place-step-1/clientbuildingid/${props.match.params.buildingid}/clientbuildingpartid/${props.match.params.partid}`}
        titleColor="text-black-5 text-center"
        rightIcon={false}
      />
      <IonContent fullscreen>
        <div className="p-4 h-full flex pt-4 justify-center">
          <div className="w-full">
            <Steps
              title={t('auth.register.step4Title')}
              currentStep="3"
              divider={t('general.of')}
              totalStep="3"
            />
            <div className="text-sm text-gray-5 mt-2">
              {t('auth.register.step4Text')}
            </div>
            <div className="py-2 mt-6">
              <Input
                type="text"
                label={t('auth.register.placePlaceholder')}
                error={errors.place}
                value={place}
                onChange={handleInputPlaceChange}
              />
            </div>
          </div>
        </div>
      </IonContent>

      <IonFooter mode="ios" className="ion-no-border p-4">
        <IonToolbar>
          <IonButton
            onClick={handleNextStep}
            shape="round"
            color="primary"
            className="w-full normal-case font-semibold h-12 text-base"
          >
            {t('auth.register.ctaLast')}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default AddPlacePageStep3;
