import React from 'react';
import { useState } from 'react';
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonPage,
  IonButton,
  IonRouterLink,
  useIonViewWillEnter,
} from '@ionic/react';

import {
  barChartOutline,
  trash,
  notificationsOutline
} from 'ionicons/icons';
import { Preferences } from '@capacitor/preferences';
import { getArticlesClient } from '../../data/articles/getArticlesClient.api';

type ArticleClient = {
  articleId: number;
  title: string;
  message: string;
  link: string;
  gursMunicipalityId: number;
  municipalityName: string;
  clientArticleList: [
    {
      articleId: number;
      title: string;
      message: string;
      link: string;
      gursMunicipalityId: number;
      municipalityName: string;
      managerUserId: number;
      managerUserName: string;
      utilityId: number;
      utilityName: string;
      managerId: number;
      managerName: string;
      dateFrom: string;
      dateTo: string;
      isRead: string;
    }
  ];
};

interface TabsProps {
  id?: string;
  tabAction?: () => void;
  tabActionDelete?: () => void;
  tabActionNotify?: () => void;
  active?: number;
}

const FooterTabs: React.FC<TabsProps> = ({
  id,
  tabAction,
  tabActionDelete,
  tabActionNotify,
  active,
}) => {
  const [activeItem, setactiveItem] = useState<number>();
  const [theme, setTheme] = useState<string>('light');
  const [newItems, setNewItems] = useState<number>(0);

  Preferences.get({ key: 'theme' }).then((storage: any) => {
    if (storage.value === "dark") {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  });

  const data = {
    tabs: [
      {
        id: '0',
      },
      {
        id: '1',
      },
      {
        id: '2',
      },
    ],
  };

  const iconColor = theme == "dark" ? "#ffffff" : "#718096";

  useIonViewWillEnter(() => {
    let isMounted = true; // track whether component is mounted 

    //Get client articles
    getArticlesClient()
      .then((response) => {
        //console.log(response);
        var countNotRead = 0;
        response.map((item: any, index: any) => (
          item.clientArticleList.map((article: any, i: any) => (
            countNotRead += article.isRead ? 0 : 1
          ))
        ))
        setNewItems(countNotRead)
      })
      .catch((error) => console.log(error));

    return () => {
      //clean up
      isMounted = false;
    };
  }, []);

  return (
    <div className="flex items-center justify-between px-12 bg-white dark:bg-gray-800 pt-2 pb-8 rounded-t-3xl">
      {data.tabs.map((tab, index) => {
        return (
          <div className="relative" id={id} key={index} onClick={index === 1 ? tabActionDelete : index === 1 ? tabActionNotify : tabAction}>
            {index === 0 && (
              <IonRouterLink
                className=""
                routerLink="/dashboardmain"
              >
                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.15722 19.7714V16.7047C8.1572 15.9246 8.79312 15.2908 9.58101 15.2856H12.4671C13.2587 15.2856 13.9005 15.9209 13.9005 16.7047V16.7047V19.7809C13.9003 20.4432 14.4343 20.9845 15.103 21H17.0271C18.9451 21 20.5 19.4607 20.5 17.5618V17.5618V8.83784C20.4898 8.09083 20.1355 7.38935 19.538 6.93303L12.9577 1.6853C11.8049 0.771566 10.1662 0.771566 9.01342 1.6853L2.46203 6.94256C1.86226 7.39702 1.50739 8.09967 1.5 8.84736V17.5618C1.5 19.4607 3.05488 21 4.97291 21H6.89696C7.58235 21 8.13797 20.4499 8.13797 19.7714V19.7714" stroke={activeItem === index || active === index ? "#00A7E1" : iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </IonRouterLink>
            )
            }
            {index === 1 && (
              <IonRouterLink
                className=""
                routerLink="/auth/profile"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M11.9849 15.3462C8.11731 15.3462 4.81445 15.931 4.81445 18.2729C4.81445 20.6148 8.09636 21.2205 11.9849 21.2205C15.8525 21.2205 19.1545 20.6348 19.1545 18.2938C19.1545 15.9529 15.8735 15.3462 11.9849 15.3462Z" stroke={activeItem === index || active === index ? "#00A7E1" : iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M11.9849 12.0059C14.523 12.0059 16.5801 9.94779 16.5801 7.40969C16.5801 4.8716 14.523 2.81445 11.9849 2.81445C9.44679 2.81445 7.3887 4.8716 7.3887 7.40969C7.38013 9.93922 9.42394 11.9973 11.9525 12.0059H11.9849Z" stroke={activeItem === index || active === index ? "#00A7E1" : iconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </IonRouterLink>
            )
            }
            {index === 2 && (
              <IonRouterLink
                routerLink="/notifications"
              >
                {newItems > 0 && (<div className="absolute z-40 top-0 right-0 text-white bg-red-5 w-5 h-5 rounded-full flex items-center justify-center text-xs -mr-2 -mt-1">{newItems}</div>)}
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Icon/Notification">
                    <g id="Notification">
                      <path id="Stroke 1" fillRule="evenodd" clipRule="evenodd" d="M12 17.8476C17.6392 17.8476 20.2481 17.1242 20.5 14.2205C20.5 11.3188 18.6812 11.5054 18.6812 7.94511C18.6812 5.16414 16.0452 2 12 2C7.95477 2 5.31885 5.16414 5.31885 7.94511C5.31885 11.5054 3.5 11.3188 3.5 14.2205C3.75295 17.1352 6.36177 17.8476 12 17.8476Z" stroke={activeItem === index || active === index ? "#00A7E1" : iconColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path id="Stroke 3" d="M14.3887 20.8574C13.0246 22.3721 10.8966 22.3901 9.51941 20.8574" stroke={activeItem === index || active === index ? "#00A7E1" : iconColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </g>
                  </g>
                </svg>
              </IonRouterLink>
            )
            }
          </div>
        );
      })}
    </div>
  );
};

export default FooterTabs;
