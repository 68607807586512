import { IonIcon } from "@ionic/react";

interface ContainerProps {
    id?: string;
    image?: string;
    imageWidth?: string;
    imageHeight?: string;
    title?: string;
    className?: string;
    OutherClass?: string;
    onClickTab: () => void; 
  }

const DashTabs: React.FC<ContainerProps> = ({ 
    id,
    image,
    imageWidth,
    imageHeight,
    title,
    className,
    OutherClass,
    onClickTab, 
  }) => {
    
    return (
        <div id={id} className={OutherClass} onClick={onClickTab}>  
            <div className={`${className}`}>{image !== '' && (<IonIcon
              src={image} class="ios hydrated text-xl mr-2"></IonIcon>)}{title}</div> 
        </div>
    );
};
export default DashTabs;
