import { IonBackButton, IonButtons, IonIcon, IonRouterLink } from "@ionic/react";
import { chevronBackOutline } from 'ionicons/icons';
import { useState } from "react";
import { Preferences } from '@capacitor/preferences';

interface ContainerProps {
  title: string;
  backLink?: string;
  hideArrow?: boolean;

}

const StepsHeaderWithBackCustom: React.FC<ContainerProps> = ({
  title,
  backLink,
  hideArrow
}) => {
  const [theme, setTheme] = useState<string>('light');

  Preferences.get({ key: 'theme' }).then((storage: any) => {
    if (storage.value === "dark") {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  });

  const closeIcon = theme == "dark" ? "ic-close-gray.svg" : "ic-close-black.svg";
  return (
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center">
        {hideArrow != true && (
          <IonButtons slot="start">
            <IonBackButton
              className="h-4"
              mode="md"
              defaultHref={backLink}
              icon={chevronBackOutline}
              class="text-gray-60 -ml-3"
            />
          </IonButtons>
        )}
        <div className="text-gray-55 font-bold text-xl dark:text-white">{title}</div>
      </div>
      <IonButtons slot="end">
        <IonRouterLink
          routerLink={backLink}
          routerDirection="forward"
          className="text-gray-10 h-4">
          <IonIcon
            src={`/assets/icon/${closeIcon}`}
            class={`ios hydrated text-xl`}
          ></IonIcon>
        </IonRouterLink>
      </IonButtons>
    </div>
  );
};
export default StepsHeaderWithBackCustom;
