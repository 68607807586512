import {
  IonItem,
  IonLabel,
  IonHeader,
  IonButton,
  IonToolbar,
  IonTitle,
  IonRouterLink,
  IonList,
  IonSelect,
  IonSelectOption,
  IonModal,
  IonButtons,
  IonBackButton,
  IonIcon,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react';
import { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { addClientPoint } from '../../data/client-points/addClientPoint.api';
import { editClientPoint } from '../../data/client-points/editClientPoint.api';
import Input from './../../components/form/Input';
import { useTranslation } from 'react-i18next';
import { deleteClientPoint } from '../../data/client-points/deleteClientPoint.api';
import { getCurrentUser } from '../../data/user/current-user.api';
import { chevronBackOutline } from 'ionicons/icons';
import { createBrowserHistory } from 'history';

type SingleClientPoint = {
  id: number;
  clientPointId: number;
  creationTime: string;
  creatorId: string;
  lastModificationTime: string;
  lastModifierId: string;
  isDeleted: boolean;
  deleterId: string;
  deletionTime: string;
  code: string;
  customerCode: string;
  externalId: string;
  name: string;
  address: string;
  clientPointDescription: string;
  utilityId: number;
  clientPointCode: string;
  clientPointCustomerCode: string;
  userId: string;
  clientPointTypeId: number;
  utilityTypeName: string;
  readoutList: [
    {
      value: string;
      valueTime: string;
      vif: {
        name: string;
        unit: string;
      };
    },
    {
      value: string;
      valueTime: string;
      vif: {
        name: string;
        unit: string;
      };
    }
  ];
};

type CurrentUserlData = {
  isAuthenticated: boolean;
  id: string;
  userName: string;
  email: string;
};

interface ContainerProps {
  //onClick: () => void;
  onCancelEdit: () => void;
  onDidDismiss: () => void;
  onSuccessEdit: () => void;
  isOpen: boolean;
  id: string;
  cssClass?: string;
  inputkoda?: string;
  inputkodaUser?: string;
  inputPlace?: string;
  selectProvider?: string;
  ProvidersList?: any;
  UserIdGet?: string;
  page?: string;
  clientBuildingId?: number;
  clientBuildingPartId?: number;
}

const EditPlaceModal: React.FC<ContainerProps> = ({
  //onClick,
  onCancelEdit,
  onDidDismiss,
  onSuccessEdit,
  isOpen,
  id,
  cssClass,
  inputkoda,
  inputkodaUser,
  inputPlace,
  selectProvider,
  ProvidersList,
  UserIdGet,
  page,
  clientBuildingId,
  clientBuildingPartId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errors, setErrors] = useState({ koda: [], uporabnik: [], place: [] });
  const [koda, setKoda] = useState(inputkoda);
  const [uporabnik, setUporabnik] = useState(inputkodaUser);
  const [place, setPlace] = useState(inputPlace);
  const [provider, setProvider] = useState<string>(selectProvider ?? '');
  const [currentUserId, setcurrentUserId] = useState<CurrentUserlData>();
  const [SingleClientPoint, setSingleClientPoint] =
    useState<SingleClientPoint>();
  const [changeInput, setChangeInput] = useState(false);
  const [changeProvider, setChangeProvider] = useState(false);

  const idAdd = 0;
  const creationTime = new Date().toISOString().toString();
  const creatorId = currentUserId?.id;
  const lastModificationTime = new Date().toISOString().toString();
  const lastModifierId = currentUserId?.id;
  const isDeleted = true;
  const deleterId = currentUserId?.id;
  const deletionTime = new Date().toISOString();
  const code = koda;
  const customerCode = uporabnik;
  const externalId = '';
  const name = '';
  const address = '';
  const description = place;
  const utilityId = Number(provider);
  const userId = currentUserId?.id;
  const clientPointTypeId = 0;

  //Edit Client point:
  const handleEditPoint = async (id: string) => {
    /*console.log(provider);
  console.log(koda);
  console.log(uporabnik);
  console.log(place);
  console.log(utilityId);
  console.log(changeProvider);
  console.log("Edit client point: ", id); */

    //If provider is changed delete old and add new client point
    /*if (changeProvider === true) {
      //Delete client point from api:
      deleteClientPoint({ id: id })
        .then((response) => {
          // success
          console.log('Deleted', id);
          console.log(response);

          //Add new client point
          addClientPoint(
            Number(id + 1),
            creationTime,
            creatorId,
            lastModificationTime,
            lastModifierId,
            isDeleted,
            deleterId,
            deletionTime,
            code,
            customerCode,
            externalId,
            name,
            address,
            description,
            utilityId,
            userId,
            clientPointTypeId
          )
            .then((response: any) => {
              // success
              //Close modal
              onDidDismiss();
              if (page == "single") {
                //Redirect to new client point
                history.push(`/dashboard/single/${response.id}`);
                //Refresh client points
                onSuccessEdit();
              } else {
                //Redirect to dashboard
                history.push(`/dashboardmain/client-building/${clientBuildingId}/part/${clientBuildingPartId}`);
                //Refresh client points
                onSuccessEdit();
              }
              console.log('Client point added!');
              //console.log(response);
            })
            .catch((error) => {
              // failure
              console.log(error);
            });
          //End Add new client point
        })
        .catch((error) => {
          // failure
          console.log(error);
        });
      //If only input is changed edit client point
    } else {*/
    //Return if input value not changed
    if (changeInput !== true) {
      return;
    }
    //Edit client point
    editClientPoint(
      Number(id),
      creationTime,
      creatorId,
      lastModificationTime,
      lastModifierId,
      isDeleted,
      deleterId,
      deletionTime,
      code,
      customerCode,
      externalId,
      name,
      address,
      description,
      utilityId,
      userId,
      clientPointTypeId
    )
      .then((response: any) => {
        // success
        //Close modal
        onDidDismiss();
        if (page == 'single') {
          //Refresh client points
          onSuccessEdit();
        } else {
          //Redirect to dashboard
          //history.push(`/dashboardmain/client-building/${clientBuildingId}/part/${clientBuildingPartId}`);
          //Refresh client points
          onSuccessEdit();
        }
        //console.log('Client point edited!');
        //console.log(response);
      })
      .catch((error) => {
        // failure
        console.log(error);
      });
    //End Add new client point*/
    /*}*/
  };

  useIonViewDidEnter(() => {
    let isMounted = true; // track whether component is mounted
    //Get current user
    getCurrentUser()
      .then((response) => {
        //console.log(response);
        if (isMounted) {
          setcurrentUserId(response);
        }
      })
      .catch((error) => console.log(error));
    return () => {
      // clean up
      isMounted = false;
    };
  });

  //Handle emailinput
  const handleInputKodaChange = (e: any) => {
    if (e.target.value === '') {
      setErrors({ koda: [], uporabnik: [], place: [] });
    } else {
      setErrors({ koda: [], uporabnik: [], place: [] });
    }
    setKoda(e.target.value);
    setChangeInput(true);
  };

  //Handle password input
  const handleInputUporabnikChange = (e: any) => {
    if (e.target.value === '') {
      setErrors({ koda: [], uporabnik: [], place: [] });
    } else {
      setErrors({ koda: [], uporabnik: [], place: [] });
    }
    setUporabnik(e.target.value);
    setChangeInput(true);
  };

  //Handle place input
  const handleInputPlaceChange = (e: any) => {
    if (e.target.value === '') {
      setErrors({ koda: [], uporabnik: [], place: [] });
    } else {
      setErrors({ koda: [], uporabnik: [], place: [] });
    }
    setPlace(e.target.value);
    setChangeInput(true);
  };

  //Handle dropdownProviderSelect
  const handleDropProviderChange = (e: any) => {
    setProvider(e.detail.value)
    setChangeProvider(true);
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => onDidDismiss()}
      key="edit-modal"
    >
      <div className={`${cssClass} overflow-scroll px-4`} id={id}>
        <IonHeader mode="ios">
          <IonToolbar mode="ios">
            <IonButtons slot="start">
              <button
                className="flex items-center text-2xl text-gray-10"
                onClick={onCancelEdit}
              >
                <IonIcon class="ios hydrated" icon={chevronBackOutline} />
              </button>
            </IonButtons>
            <IonTitle className="dark:text-white">{t('dashboard.editPlace.title')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>
          {/*<h2 className="p-4 pt-6 mt-4 text-base font-bold text-black-5 dark:text-white">
            {t('dashboard.editPlace.select')}
          </h2>*/}
          {/*<form onSubmit={() => handleEditPoint(id.replace('-edit','').toString() ?? '')}>*/}
          {/*<IonList>
            <IonItem lines="none">
              <IonLabel className="">{provider}</IonLabel>
              <IonSelect
                value={provider}
                placeholder=""
                onIonChange={handleDropProviderChange}
              >
                {ProvidersList.map((prov: any) => (
                  <IonSelectOption
                    key={prov.id.toString()}
                    value={prov.id.toString()}
                  >
                    {prov.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          </IonList>
          <div className="p-4 mt-2 text-sm text-gray-5 dark:text-white">
            {t('dashboard.editPlace.infoText')}{' '}
            <IonRouterLink
              className="ml-1 font-bold underline"
              routerLink="/auth/register-provider"
            >
              {t('general.here')}
            </IonRouterLink>
            .
          </div>*/}
          {/*<h2 className="p-4 text-base font-bold text-black-5 dark:text-white">
            {t('dashboard.editPlace.info')}
          </h2>
          <div className="py-2">
            <Input
              type="text"
              label={t('auth.register.clientLocationCodePlaceholder')}
              error={errors.koda}
              value={koda}
              onChange={handleInputKodaChange}
            />
          </div>
          <div className="py-2">
            <Input
              type="text"
              label={t('auth.register.clientCodePlaceholder')}
              error={errors.uporabnik}
              value={uporabnik}
              onChange={handleInputUporabnikChange}
            />
          </div>*/}
          <h2 className="p-4 text-base font-bold text-black-5 dark:text-white">
            {t('dashboard.editPlace.rename')}
          </h2>
          <Input
            type="text"
            label={t('auth.register.placePlaceholder')}
            error={errors.place}
            value={place}
            onChange={handleInputPlaceChange}
          />
          <div className="p-4 mt-4">
            <IonButton
              onClick={() =>
                handleEditPoint(id.replace('-edit', '').toString() ?? '')
              }
              shape="round"
              color="orange"
              className="w-full h-12 text-base font-semibold normal-case"
            >
              {t('dashboard.ctaDone')}
            </IonButton>
            <IonRouterLink
              onClick={onCancelEdit}
              className="block w-full h-12 p-6 text-base text-center normal-case text-gray-5"
            >
              {t('dashboard.ctaCancel')}
            </IonRouterLink>
          </div>
          {/*</form>*/}
        </div>
      </div>
    </IonModal>
  );
};

export default EditPlaceModal;
