import { IonContent, IonIcon, IonPopover } from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  leakStatus?: boolean | null;
  leakName?: string | null;
  leakDescription?: string | null;
}

const EffusionSensorCard: React.FC<ContainerProps> = ({
  leakStatus,
  leakName,
  leakDescription,
}) => {
  const { t } = useTranslation();
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  return (
    <>
      {leakStatus === true ? (
        <div className="pr-10 relative">
          <div className="bg-red-100 rounded-xl text-red-800 text-xs font-semibold pl-2 pr-3 h-5 inline-flex items-center">
            <div className="ml-1 w-2 h-2 rounded-full bg-red-400 mr-2"></div>{t("dashboard.leakage")}
            <button
              style={{ outline: "none" }}
              className="absolute right-0 top-0"
              onClick={(e: any) => {
                e.persist();
                setShowPopover({ showPopover: true, event: e });
              }}>
              <IonIcon
                src="/assets/icon/icon-alarm.svg"
                class="ios hydrated text-3xl"
              ></IonIcon>
            </button>
            <IonPopover
              show-backdrop={false}
              event={popoverState.event}
              isOpen={popoverState.showPopover}
              onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
            >
              <IonContent>
                <div className='p-4 bg-gray-800 text-white text-2xs'>
                  <div className='font-bold mb-1 text-xs'>{leakName}</div>
                  <div>{leakDescription}</div>
                </div>
              </IonContent>
            </IonPopover>
          </div>
        </div>
      ) : (
        <div className="bg-green-100 rounded-xl text-green-800 text-xs font-semibold pl-2 pr-3 h-5 inline-flex items-center">
          <div className="ml-1 w-2 h-2 rounded-full bg-green-400 mr-2"></div> {t("dashboard.noLeak")}
        </div>
      )}
    </>
  );
};
export default EffusionSensorCard;
