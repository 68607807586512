import { useState, useRef, useEffect } from 'react';
import {
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonRouterLink,
  IonToolbar,
  useIonViewWillEnter,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import DashFilter from '../components/DashFilter';
import Demodata from '../data/demoData';

const ErrorPage: React.FC = () => {
  const { t } = useTranslation();
  const [tabSlider, setTabSlider] = useState<boolean>(true);
  const [tablist, setTablist] = useState<boolean>(false);

  //Add card
  const onClickTabAdd = () => {
    console.log("add clicked!");
  }

  //Edit/delete Card
  const onClickCard = () => {
    console.log("edit clicked!");
  };

  //Change cards view
  const onClickTabChangeView = () => {
    console.log("onClickTabChangeView clicked");
    if (tabSlider) {
      setTablist(true);
      setTabSlider(false);
    } else {
      setTablist(false);
      setTabSlider(true);
    }

  }

  useIonViewWillEnter(() => {

  });

  return (
    <IonPage id="dashboard">
      <IonHeader mode="ios" className="ion-no-border pt-2 bg-gray-45 dark:bg-gray-900">
        <IonToolbar color="graybg">
          <DashFilter
            onClickTabAdd={onClickTabAdd}
            onClickTabChangeView={onClickTabChangeView}
            list={tablist}
            slider={tabSlider}
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="graybg">
        <div className="text-gray-50 text-center flex items-center h-full justify-center">
          <div className="px-20">
            <div>
              <IonIcon
                src="/assets/icon/icon-cloud.svg"
                class="ios hydrated text-3xl w-24 h-16"
              ></IonIcon>
            </div>
            <div className="-mt-6">
              <IonIcon
                src="/assets/icon/icon-reload.svg"
                class="ios hydrated text-3xl w-9 h-9"
              ></IonIcon>
            </div>
            <h2 className="font-bold text-gray-55 mt-5">{t('error.title')}</h2>
            <p className="mt-4">{t('error.contentOne')}</p>
            <p className="mt-2">{t('error.contentTwo')}</p>
            {<IonRouterLink routerLink="/auth/login" routerDirection="forward">{t('auth.login.cta')}</IonRouterLink>}
          </div>
        </div>
      </IonContent>

    </IonPage>
  );
};

export default ErrorPage;
