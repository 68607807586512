import { useState } from 'react';
import {
  IonContent,
  IonFooter,
  IonPage,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import DashTabsNoIcons from '../components/DashTabsNoIcons';
import NotifiyCard from '../components/NotifiyCard';
import FooterTabs from '../components/dashboardSingle/FooterTabs';
import { getArticles } from '../data/articles/getArticles.api';
import { getCurrentUserManger } from '../data/user/current-user-manage.api';
import { getArticlesClient } from '../data/articles/getArticlesClient.api';
import { getGursMunicipalityIdForManager } from '../data/articles/getGursMunicipalityIdForManager.api';
import { setArticleStatus } from '../data/articles/setArticleStatus.api';
import { useHistory } from 'react-router';

type Article = {
  articleId: number;
  title: string;
  message: string;
  link: string;
  managerUserId: number;
  managerUserName: string;
  managerId: number;
  utilityId: number;
  utilityName: string;
  dateFrom: string;
  dateTo: string;
};

type ArticleClient = {
  articleId: number;
  title: string;
  message: string;
  link: string;
  gursMunicipalityId: number;
  municipalityName: string;
  clientArticleList: [
    {
      articleId: number;
      title: string;
      message: string;
      link: string;
      gursMunicipalityId: number;
      municipalityName: string;
      managerUserId: number;
      managerUserName: string;
      utilityId: number;
      utilityName: string;
      managerId: number;
      managerName: string;
      dateFrom: string;
      dateTo: string;
      isRead: string;
    }
  ];
};
type Gurs = {
  gursMunicipalityId: number;
  gursMunicipalityName: string;
};

const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const [filteredItem, setfilteredItem] = useState<string>('all');
  const [articles, setArticles] = useState<ArticleClient[]>([]);
  const [gursMunicipality, setGursMunicipality] = useState<Gurs[]>([]);
  const history = useHistory();

  const activeItemClass = 'bg-blue-40 text-white dark:text-gray-900 shadow-md';


  /*const tabs = [
    {
      id: 'all',
      title: t('dashboard.allMunicipalities'),
      className: 'rounded-2xl py-1 px-3 text-center inline-block',
      OutherClass: '',
      new: false,
      //hideit: props.match.params.part === "0" && buildingData?.hasApartments ? true : false
      hideit: false
    },


    {
      id: 'ljubljana',
      title: "Ljubljana",
      className: 'flex items-center rounded-2xl py-1 px-3 text-center',
      OutherClass: 'px-1',
      //new: nonPayedBills.length > 0 && true,
      //hideit: wasteAll.length != 0 ? false : true,
      hideit: false
    },
    {
      id: 'maribor',
      title: "Maribor",
      className: 'flex items-center rounded-2xl py-1 px-3 text-center',
      OutherClass: 'px-1',
      //new: nonPayedBills.length > 0 && true,
      //hideit: sensorsAll.length != 0 ? false : true,
      hideit: false
    },
    {
      id: 'sencur',
      title: "Šenčur",
      className: 'flex items-center rounded-2xl py-1 px-3 text-center',
      OutherClass: 'px-1',
      // new: nonPayedBills.length > 0 && true,
      //hideit: allBills.length ? false : true,
      hideit: false
    },
  ];*/


  //Create tabs from data
  const gursMunicipalityTabs = gursMunicipality.map((item: any) => {
    return {
      ...item,
      id: item.gursMunicipalityId.toString(),
      title: item.gursMunicipalityName,
      className: 'flex items-center rounded-2xl py-1 px-3 text-center',
      OutherClass: 'px-1',
      // new: nonPayedBills.length > 0 && true,
      //hideit: allBills.length ? false : true,
      hideit: false
    };
  });
  //Add first tab to created tabs
  const gursMunicipalityTabsAdd = [{
    id: 'all',
    title: t('dashboard.allMunicipalities'),
    className: 'rounded-2xl py-1 px-3 text-center inline-block',
    OutherClass: '',
    new: false,
    //hideit: props.match.params.part === "0" && buildingData?.hasApartments ? true : false
    hideit: false
  }, ...gursMunicipalityTabs]

  //Tabs filtering:
  const onClickTab = (index: number, id: string) => {
    //setactiveItem(index);
    setfilteredItem(id);
    //Load komunala items
    /*if (id === "komunala") {
       console.log(clientBuildId)
    }*/
  };

  useIonViewDidEnter(() => {
    let isMounted = true; // track whether component is mounted 
    //Set active tab services
    setfilteredItem('all');

    return () => {
      //clean up
      isMounted = false;
    };

  }, []);

  useIonViewWillEnter(() => {
    let isMounted = true; // track whether component is mounted 
    //Get articles from manager
    /*getCurrentUserManger()
      .then((response) => {
        console.log(response.managerId)
        getArticles({ managerId: response.managerId })
          .then((response) => {
            console.log(response);
            setArticles(response);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));*/

    //Get client articles
    getArticlesClient()
      .then((response) => {
        //console.log(response);
        setArticles(response);
      })
      .catch((error) => console.log(error));

    //Get articles getGursMunicipalityIdForManager
    getCurrentUserManger()
      .then((response) => {
        //console.log(response.managerId)
        getGursMunicipalityIdForManager({ managerId: response.managerId })
          .then((response) => {
            //console.log(response);
            setGursMunicipality(response)
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));

    return () => {
      //clean up
      isMounted = false;
    };
  }, []);

  /*const notifications = [
    {
      id: "ljubljana",
      newItem: true,
      date: "25. 8. 2022",
      title: "VOKA Snaga Ljubljana",
      subtitle: "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.",
      description: "Nam quis nulla. Integer malesuada. In in enim a arcu imperdiet malesuada. Sed vel lectus. Donec odio urna, tempus molestie, porttitor. Nam quis nulla. Integer malesuada. In in enim a arcu imperdiet malesuada. Sed vel lectus. Donec odio urna, tempus molestie, porttitor.",
      externalLink: "https://ljubljana.si"
    },
    {
      id: "ljubljana",
      newItem: false,
      date: "25. 8. 2022",
      title: "VOKA Snaga Ljubljana",
      subtitle: "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.",
      description: "Nam quis nulla. Integer malesuada. In in enim a arcu imperdiet malesuada. Sed vel lectus. Donec odio urna, tempus molestie, porttitor. Nam quis nulla. Integer malesuada. In in enim a arcu imperdiet malesuada. Sed vel lectus. Donec odio urna, tempus molestie, porttitor.",
      externalLink: "https://ljubljana.si"
    },
    {
      id: "maribor",
      newItem: false,
      date: "25. 8. 2022",
      title: "Mestna občina Maribor",
      subtitle: "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.",
      description: "Nam quis nulla. Integer malesuada. In in enim a arcu imperdiet malesuada. Sed vel lectus. Donec odio urna, tempus molestie, porttitor. Nam quis nulla. Integer malesuada. In in enim a arcu imperdiet malesuada. Sed vel lectus. Donec odio urna, tempus molestie, porttitor.",
      externalLink: "https://maribor.si"
    }
  ]*/

  const setStatus = (e: any) => {
    //Set article status
    setArticleStatus(e, true)
      .then((response) => {
        //console.log(response);
      })
      .catch((error) => console.log(error));
    //console.log(e)
  }

  //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/dashboardmain/")
    });
  });

  const allArticles = articles.map((item: any) => {
    return item.clientArticleList.map((values: any) => {
      return {
        ...values,
        orderDate: new Date(values.dateFrom),
      };
    });
  });
  //Merge all in one list
  const allArticlesList = allArticles.flat();


  return (
    <IonPage>
      <Header
        title={t('dashboard.notifications')}
        backLink={`/dashboardmain/`}
        titleColor="text-black-5 dark:text-white text-left pl-12"
        className="bg-gray-45 dark:bg-gray-900 mt-0"
        bgColor="graybg"
      />
      <IonContent fullscreen color="graybg">
        <div className="w-full overflow-x-auto">
          <div className="w-auto flex items-center justify-left text-sm p-4 text-gray-10">
            {gursMunicipalityTabsAdd.map((tab, index) => {
              return (
                <DashTabsNoIcons
                  id={tab.id}
                  key={tab.id}
                  title={tab.title}
                  className={`mr-4 whitespace-nowrap ${tab.className} ${tab.id === filteredItem ? activeItemClass : 'mr-4'
                    }`}
                  OutherClass={tab.OutherClass}
                  onClickTab={() => onClickTab(index, tab.id)}
                  hideit={tab.hideit}
                />
              );
            })}
          </div>
        </div>

        <div className="p-4">
          {/** Display all items */}
          {filteredItem === 'all' && (
            <>
                <div>
                  {allArticlesList.sort((a, b) => b.orderDate - a.orderDate).map((article, i) => (
                    <NotifiyCard
                      id={article.articleId.toString()}
                      key={i}
                      newItem={article.isRead ? false : true}
                      date={article.dateFrom}
                      title={article.utilityName}
                      subtitle={article.title}
                      description={article.message}
                      externalLink={article.link}
                      setStatus={setStatus}
                    />
                  ))}
                </div>
            </>
          )}
          {/** Display specific items */}
          {articles.map((item, index) => (
            <div key={index}>
              {filteredItem === item.gursMunicipalityId.toString() && (
                item.clientArticleList.sort((a, b) => new Date(b.dateFrom).setHours(0, 0, 0, 0) - new Date(a.dateFrom).setHours(0, 0, 0, 0)).map((article, i) => (
                  <NotifiyCard
                    id={article.articleId.toString()}
                    key={i}
                    newItem={article.isRead ? false : true}
                    date={article.dateFrom}
                    title={article.utilityName}
                    subtitle={article.title}
                    description={article.message}
                    externalLink={article.link}
                    setStatus={setStatus}
                  />
                ))
              )}
            </div>
          ))}

        </div>

      </IonContent>

      <IonFooter mode="ios" className="ion-no-border" color="graybg">
        <FooterTabs active={2} />
      </IonFooter>

    </IonPage>
  );
};

export default Notifications;
