import { Api } from '../api';

//Send contact message
export const sendProposal = async (
  message: string,
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('POST', 'app/account/send-user-proposal', true, {
      message
    });
    return response;
  } catch (error) {
    throw error;
  }
};
