import { Api } from '../api';


export type GetKeyAccountsResponse = {
  token: string;
  phone: string | null;
  email: string | null;
};

export const getBuildingSingle = async (
  buildingId: string,
  addressId: string,
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('POST', `app/client-building/search-with-id?buildingId=${buildingId}&addressId=${addressId}`, true, { 
    });
    return response;
  } catch (error) {
    throw error;
  }
};
