import { Api } from '../api'; 

export type ClientPointActions =
  | { type: 'FETCH' }
  | { type: 'REJECT' }
  | { type: 'CANCEL' }
  | { type: 'RESOLVE' };

  export const defaultClientPointState = { status: 'idle' };

export type ClientPointState = {
  status: string;
};


export const reportUserReadout = async (
  clientPointId?: number,
  value?: number,
  value2?: number,
): Promise<any> => { 
  const api = new Api();

  try {
    const response = await api.request('POST', 'app/end-user/report-user-readout', true, { 
      clientPointId,
      value,
      value2,
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};