import { Api } from '../api'; 

export type ClientPointActions =
  | { type: 'FETCH' }
  | { type: 'REJECT' }
  | { type: 'CANCEL' }
  | { type: 'RESOLVE' };

  export const defaultClientPointState = { status: 'idle' };

export type ClientPointState = {
  status: string;
};


export const addBuildingPart = async (
  id?: number,
  clientBuildingId?: number,
  gursBuildingPartId?: number,
  description?: string,
  creationTime?: string,
  creatorId?: string,
  lastModificationTime?: string,
  lastModifierId?: string,
  isDeleted?: boolean,
  deleterId?: string,
  deletionTime?: string,
  userId?: string,

): Promise<any> => { 
  const api = new Api();

  try {
    const response = await api.request('POST', `app/client-building/client-building-part?clientBuildingId=${clientBuildingId}&gursBuildingPartId=${gursBuildingPartId}&description=${description}`, true, { 
      id,
      creationTime,
      creatorId,
      lastModificationTime,
      lastModifierId,
      isDeleted,
      deleterId,
      deletionTime,
      userId
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};