import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonIcon,
  IonRouterLink,
} from '@ionic/react';

import { chevronBackOutline } from 'ionicons/icons';
import DemoHeaderBar from './DemoHeaderBar';

interface ContainerProps {
  title?: string;
  titleColor?: string;
  backLink?: string;
  bgColor?: string;
  type?: string;
  onClickCard: () => void;
}

const ClassWawtter = 'from-blue-5 to-blue-25';
const ClassGas = 'from-green-5 to-green-10';
const ClassElectricity = 'from-yellow-15 to-yellow-20';
const ClassHeat = 'from-red-15 to-red-20';
//const ClassWawtter = 'bg-white';
//const ClassGas = 'bg-white';
//const ClassElectricity = 'bg-white';

const HeaderWithIcon: React.FC<ContainerProps> = ({
  title,
  titleColor,
  backLink,
  bgColor,
  type,
  onClickCard,
}) => {
  return (
    <IonHeader mode="ios" className="ion-no-border pt-2 bg-white dark:bg-gray-900" color={bgColor}>
      <IonToolbar color={bgColor}>
        <DemoHeaderBar />
        <div className="flex items-center pl-4 justify-between">
          <div className="flex items-center justify-center">
            <div
              className={`shadow-md rounded-md flex items-center justify-center w-8 h-8 mr-2 bg-gradient-to-r ${type === 'WATER' && ClassWawtter} 
                ${type === 'ELECTRICITY' && ClassElectricity} 
                ${type === 'GAS' && ClassGas}
                ${type === 'HEAT' && ClassHeat}
                `}
            >
              {type === 'WATER' && (
                <IonIcon
                  src="/assets/icon/ic-watter-white.svg"
                  class="ios hydrated text-xl"
                ></IonIcon>
              )}
              {type === 'ELECTRICITY' && (
                <IonIcon
                  src="/assets/icon/ic-lightning-white.svg"
                  class="ios hydrated text-xl"
                ></IonIcon>
              )}
              {type === 'GAS' && (
                <IonIcon
                  src="/assets/icon/ic-gas-white.svg"
                  class="ios hydrated text-xl"
                ></IonIcon>
              )}
              {type === 'HEAT' && (
                <IonIcon
                  src="/assets/icon/ic-heat-white.svg"
                  class="ios hydrated text-xl"
                ></IonIcon>
              )}
            </div>
            <div className={`text-xl font-bold pr-6 ${titleColor}`}>
              {title}
            </div>
          </div>
          <IonButtons>
            <IonRouterLink
              className="h-4"
              class="text-gray-10 text-sm"
              routerLink={backLink}
              routerDirection="back"
            >
              <IonIcon
                src="/assets/icon/icon-close-gray-dark.svg"
                class="ios hydrated text-base mr-2"
              ></IonIcon>
            </IonRouterLink>
          </IonButtons>
        </div>

      </IonToolbar>
    </IonHeader>
  );
};
export default HeaderWithIcon;
