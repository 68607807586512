import { IonRouterLink } from '@ionic/react';
import { useState } from 'react';
import { constructOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import login from '../data/login';
import { Redirect, useHistory } from 'react-router';
import { useAuth } from '../context/Auth';
import { Preferences } from '@capacitor/preferences';

interface ContainerProps { }

const HeaderLogoNotLoggedIn: React.FC<ContainerProps> = ({ }) => {
  const [theme, setTheme] = useState<string>('light');
  const { t } = useTranslation();
  const history = useHistory();
  const { authToken, setAuthToken } = useAuth();

  Preferences.get({ key: 'theme' }).then((storage: any) => {
    if (storage.value === 'dark') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  });

  const logo =
    theme == 'dark' ? 'infohabita_logo-white.svg' : 'infohabita_logo-white.svg';

  const handleDemoLogin = async () => {
    await login(
      process.env.REACT_APP_DEMO_USERNAME
        ? process.env.REACT_APP_DEMO_USERNAME
        : '',
      process.env.REACT_APP_DEMO_PASSWORD
        ? process.env.REACT_APP_DEMO_PASSWORD
        : ''
    )
      .then((response: any) => {
        if (!document.body.parentElement?.classList.contains('demo')) {
          document.body.parentElement?.classList.add('demo');
        }
        if (!document.body.classList.contains('demo')) {
          document.body.classList.add('demo');
        }
        //console.log('demo log in');

        // Set local storage demo user
        Preferences.set({
          key: 'demo',
          value: 'true',
        });

        setAuthToken(response.access_token);

        // history.push('/dashboardmain');
        // console.log(response)
      })
      .catch((error: any) => {
        console.log(error.response);
      });
  };

  return (
    <div className="flex justify-between mb-6">
      <IonRouterLink
        className=""
        routerLink="/auth/login"
        routerDirection="back"
      >
        <div className="flex items-center w-32">
          <img width="103" height="25" src={`/assets/${logo}`} />
        </div>
      </IonRouterLink>
      <button
        onClick={handleDemoLogin}
        className="flex items-center px-2 py-1 text-xs font-semibold bg-white rounded-md cursor-pointer text-blue-40"
      >
        {t('auth.login.startDemoLabel')}
      </button>
    </div>
  );
};
export default HeaderLogoNotLoggedIn;
