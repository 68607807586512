import {
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonItem,
  IonCheckbox,
  IonLabel,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { chevronBackOutline } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import Input from '../form/Input';
import { reportUserReadout } from '../../data/client-points/reportUserReadout';

interface ContainerProps {
  onCancelSubmit: () => void;
  id: string;
  cssClass?: string;
  highRateShow?: boolean;
  meter?: string;
  lastLowRate: number;
  lastHighRate: number;
  NoDecimalNumbers?: boolean;
}

const SubmitStatusModal: React.FC<ContainerProps> = ({
  onCancelSubmit,
  id,
  cssClass,
  highRateShow,
  meter,
  lastLowRate,
  lastHighRate,
  NoDecimalNumbers
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({ lowRate: [], highRate: [] });
  const [lowRate, setLowRate] = useState<string>('');
  const [highRate, setHighRate] = useState<string>('');
  const [lowRateDecimal, setLowRateDecimal] = useState<string>('');
  const [highRateDecimal, setHighRateDecimal] = useState<string>('');
  const [lowRateBeforeDecimal, setLowRateBeforeDecimal] = useState<string>('');
  const [highRateBeforeDecimal, setHighRateBeforeDecimal] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(true);
  const [statusSuccess, setStatusSuccess] = useState<boolean>(false);


  //Handle inputs

  const handleLowRateChange = (e: any) => {
    const validated = e.target.value.match(/^(\d*\.{0,1}\d{0,3}$)/)
    if (e.target.value === '' && !validated) {
      setErrors({ lowRate: [t('dashboard.submitStatus.errorRequired')], highRate: [] });
    } else {
      setErrors({ lowRate: [], highRate: [] });
    }
    if (validated) {
      setLowRate(e.target.value);
    }
    if (!highRateShow) {
      setHighRate("0");
    }

  };
  const handleHighRateChange = (e: any) => {
    const validated = e.target.value.match(/^(\d*\.{0,1}\d{0,3}$)/)
    if (e.target.value === '' && !validated) {
      setErrors({ lowRate: [], highRate: [t('dashboard.submitStatus.errorRequired')] });
    } else {
      setErrors({ lowRate: [], highRate: [] });
    }
    if (validated) {
      setHighRate(e.target.value);
    }
  };

  //Handle lowratefields
  const handleLowRateChangeBeforeDecimal = (e: any) => {
    //console.log(e.target.value)
    const validated = e.target.value.match(/^(\d*\.{0,1}\d{0,3}$)/)
    if (validated) {
      setLowRateBeforeDecimal(e.target.value)
      setLowRate((e.target.value ? e.target.value : "0") + '.' + (lowRateDecimal ? lowRateDecimal : "000"))
    }
    if (!highRateShow) {
      setHighRate("0");
    }
  }
  const handleLowRateChangeDecimal = (e: any) => {
    const validated = e.target.value.match(/^(\d*\.{0,1}\d{0,3}$)/)
    if (validated) {
      setLowRateDecimal(e.target.value)
      setLowRate((lowRateBeforeDecimal ? lowRateBeforeDecimal : "0") + '.' + (e.target.value ? e.target.value : "000"))
    }
    if (!highRateShow) {
      setHighRate("0");
    }
  }


  //Handle highratefields
  const handleHighRateChangeBeforeDecimal = (e: any) => {
    //console.log(e.target.value)
    const validated = e.target.value.match(/^(\d*\.{0,1}\d{0,3}$)/)
    if (validated) {
      setHighRateBeforeDecimal(e.target.value)
      setHighRate((e.target.value ? e.target.value : "0") + '.' + (highRateDecimal ? highRateDecimal : "000"))
    }
  }
  const handleHighRateChangeDecimal = (e: any) => {
    const validated = e.target.value.match(/^(\d*\.{0,1}\d{0,3}$)/)
    if (validated) {
      setHighRateDecimal(e.target.value)
      setHighRate((highRateBeforeDecimal ? highRateBeforeDecimal : "0") + '.' + (e.target.value ? e.target.value : "000"))
    }
  }



  //Submit status
  const handleSubmitStatusClientPoint = async (id: string) => {
    //console.log(Number(lowRate))
    //console.log(Number(highRate))
    //console.log(lastLowRate)
    //console.log(lastHighRate)
    //console.log(lowRate)
    //console.log(highRate)
    //console.log(errors)

    if (lowRate !== '' && highRate !== '' && Number(lowRate) > lastLowRate && (Number(highRate) > lastHighRate || (Number(highRate) === 0 && !highRateShow))) {
      //console.log("success")
      setShowForm(false);
      setStatusSuccess(true);
      reportUserReadout(
        Number(id),
        Number(lowRate),
        Number(highRate),
      )
        .then((response: any) => {
          // success
          //console.log("Status Submitted " + id)
          //console.log(response);
          setShowForm(false);
          setStatusSuccess(true);
        })
        .catch((error) => {
          // failure
          console.log(error);
        });
    } else if (lowRate === '') {
      setErrors({ lowRate: [t('dashboard.submitStatus.errorRequired')], highRate: [] });
    } else if (highRate === '') {
      setErrors({ lowRate: [], highRate: [t('dashboard.submitStatus.errorRequired')] });
    } else if (Number(lowRate) <= lastLowRate) {
      console.log("error 3")
      setErrors({ lowRate: [t('dashboard.submitStatus.consumptionError')], highRate: [] });
    } else if (Number(highRate) <= lastHighRate || (Number(highRate) !== 0 && highRateShow)) {
      console.log("error 4")
      setErrors({ lowRate: [], highRate: [t('dashboard.submitStatus.consumptionError')] });
    }
  };

  //Split number and decimals
  const getWholeAndDecimal = (value: any) => {
    const [whole, decimal] = String(value).split('.');
    return [whole, decimal];
  }


  return (
    <div className={cssClass} id={id}>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <button
              className="flex items-center text-2xl text-gray-10"
              onClick={onCancelSubmit}
            >
              <IonIcon class="ios hydrated" icon={chevronBackOutline} />
            </button>
          </IonButtons>
          <IonTitle>{t('dashboard.submitStatus.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className={showForm ? "" : "hidden"}>

        <div className='px-4'>
          <div className='bg-gray-45 dark:bg-gray-900 rounded-lg p-4 mt-6 text-gray-600 dark:text-white'>
            <div className='text-sm mb-2'>{t('dashboard.submitStatus.lastMonthStatus')}</div>
            {highRateShow && (
              <div className='mr-2 text-sm'>{t('dashboard.lowRateLabel')}</div>
            )}
            <div className='flex'>
              {getWholeAndDecimal(lastLowRate)[0] && (
                <div className='bg-gray-900 text-white opacity-40 px-3 py-[6px] mr-2 rounded-md'>{getWholeAndDecimal(lastLowRate)[0]}</div>
              )}
              {getWholeAndDecimal(lastLowRate)[1] && (
                !NoDecimalNumbers && (
                  <div className='bg-red-5 text-white opacity-40 px-3 py-[6px] mr-2 rounded-md'>{getWholeAndDecimal(lastLowRate)[1]}</div>
                )
              )}
              <div className='text-base'>{meter}</div>
            </div>

            {highRateShow && (
              <>
                <div className='text-sm mt-2'>{t('dashboard.highRateLabel')}</div>
                <div className='flex items-center'>
                  {getWholeAndDecimal(lastHighRate)[0] && (
                    <div className='bg-gray-900 text-white opacity-40 px-3 py-[6px] mr-2 rounded-md'>{getWholeAndDecimal(lastHighRate)[0]}</div>
                  )}
                  {getWholeAndDecimal(lastHighRate)[1] && (
                    !NoDecimalNumbers && (
                      <div className='bg-red-5 text-white opacity-40 px-3 py-[6px] mr-2 rounded-md'>{getWholeAndDecimal(lastHighRate)[1]}</div>
                    )
                  )}
                  <div className='text-base'>{meter}</div>
                </div>
              </>
            )}

          </div>
        </div>

        <div className="px-4 mt-6 text-sm text-gray-5">
          {t('dashboard.submitStatus.description')}
        </div>
        <div className="px-4 mt-1 text-sm text-gray-5">
          <div className='flex items-center'>
            <input
              name="lowRate"
              onKeyDown={handleLowRateChangeBeforeDecimal}
              placeholder="000"
              className={'white-placeholder w-28 text-center h-14 block font-semibold text-xl appearance-none focus:outline-none bg-gray-800 dark:bg-gray-600 text-white dark:text-white px-3 py-1 mr-2 rounded-md'}
              value={lowRateBeforeDecimal}
              step="0.001"
              type="text"
              onChange={handleLowRateChangeBeforeDecimal}
              maxLength={6}
            />
            {!NoDecimalNumbers && (
              <input
                name="lowRate"
                onKeyDown={handleLowRateChangeDecimal}
                placeholder="0"
                className={'white-placeholder w-24 text-center h-14 block font-semibold text-xl appearance-none focus:outline-none bg-red-5 text-white dark:text-white px-3 py-1 mr-2 rounded-md'}
                value={lowRateDecimal}
                step="0.001"
                type="text"
                onChange={handleLowRateChangeDecimal}
                maxLength={3}
              />
            )}

            {highRateShow && (
              <div className='mr-2 text-sm'>{t('dashboard.lowRateLabel')}</div>
            )}
          </div>
          <div className="mt-1 mb-2 text-red-5">{errors.lowRate}</div>

          {highRateShow && (
            <>
              <div className='flex items-center mt-4'>
                <input
                  name="highRate"
                  onKeyDown={handleHighRateChangeBeforeDecimal}
                  placeholder="000"
                  className={'white-placeholder w-28 text-center h-14 block font-semibold text-xl appearance-none focus:outline-none bg-gray-800 dark:bg-gray-600 text-white dark:text-white px-3 py-1 mr-2 rounded-md'}
                  value={highRateBeforeDecimal}
                  step="0.001"
                  type="text"
                  onChange={handleHighRateChangeBeforeDecimal}
                  maxLength={6}
                />
                {!NoDecimalNumbers && (
                  <input
                    name="highRate"
                    onKeyDown={handleHighRateChangeDecimal}
                    placeholder="0"
                    className={'white-placeholder w-24 text-center h-14 block font-semibold text-xl appearance-none focus:outline-none bg-red-5 text-white dark:text-white px-3 py-1 mr-2 rounded-md'}
                    value={highRateDecimal}
                    step="0.001"
                    type="text"
                    onChange={handleHighRateChangeDecimal}
                    maxLength={3}
                  />
                )}
                <div className='text-sm'>{t('dashboard.highRateLabel')}</div>
              </div>
              <div className="mt-1 mb-2 text-red-5">{errors.highRate}</div>
            </>
          )}


          <div className='hidden'>
            <Input
              type="number"
              step="0.001"
              label={highRateShow ? t('dashboard.lowRateLabel') + " " + t('dashboard.in') + " " + meter : meter ? meter : "m3"}
              error={errors.lowRate}
              value={lowRate}
            />
            <div className="mt-1 mb-2 text-red-5">{errors.lowRate}</div>
          </div>

        </div>

        {highRateShow && (
          <div className="px-4 text-sm text-gray-5 hidden">
            <Input
              type="number"
              label={t('dashboard.highRateLabel') + " " + t('dashboard.in') + " " + meter}
              error={errors.highRate}
              value={highRate}
              onChange={handleHighRateChange}
            />
            <div className="mt-1 mb-2 text-red-5">{errors.highRate}</div>

          </div>
        )}

        <div className="p-4">
          <IonButton
            onClick={() => handleSubmitStatusClientPoint(id)}
            color="blue"
            className="w-full h-12 text-base font-semibold normal-case"
          >
            {t('dashboard.submitStatus.send')}
          </IonButton>
        </div>
      </div>

      <div className={showForm ? "hidden" : ""}>
        {statusSuccess ? (
          <div className="flex items-center justify-center px-12 mt-10">
            <IonIcon
              src="/assets/icon/ic-check-success.svg"
              class="ios hydrated w-14 h-14 mr-4"
            ></IonIcon>
            <div className="text-xl font-semibold text-gray-55 dark:text-white">{t('dashboard.submitStatus.success')}</div>
          </div>
        ) : (
          <div className="flex items-center justify-center px-12 mt-10">
            <IonIcon
              src="/assets/icon/ic-check-error.svg"
              class="ios hydrated w-14 h-14 mr-4"
            ></IonIcon>
            <div className="text-xl font-semibold text-gray-55 dark:text-white">{t('dashboard.submitStatus.error')}</div>
          </div>
        )}

        <div className="p-12">
          <IonButton
            onClick={onCancelSubmit}
            color="blue"
            className="w-full h-12 text-base font-semibold normal-case"
          >
            {t('dashboard.submitStatus.close')}
          </IonButton>
        </div>
      </div>

    </div >
  );
};

export default SubmitStatusModal;
