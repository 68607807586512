import {
  IonContent,
  IonPage,
  IonButton,
  IonToolbar,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useState} from 'react';
import { useTranslation } from 'react-i18next';
import { getStorage, setStorage } from '../../data/user/register.api';
import InputSearch from '../../components/form/InputSearch';
import { getCurrentUser } from '../../data/user/current-user.api';
import UnitCard from '../../components/UnitCard';
import { getBuildingSingle } from '../../data/client-points/getBuildingSingle.api';
import { searchBuildingPart } from '../../data/client-points/searchBuildingPart';
import { getClientBuildingSingle } from '../../data/client-points/clientBuildingSingle.api';
import { getClientBuildings } from '../../data/client-points/clientBuildings.api';
import Loader from '../../components/Loader';
import StepsHeaderWithBackCustom from '../../components/StepsHeaderWithBackCustom';
import { useIonRouter } from '@ionic/react';


type FormErrors = {
  search: string[] | undefined;
  buildingpart: string[] | undefined;
};

type PartData = {
  actualUse: string;
  actualUseId: number;
  buildingId: number;
  buildingPartId: number;
  clientBuildingId: number;
  floorNumber: number;
  levelNumber: number;
  partNumber: number;
  roomNumber: number;
  surface: number;
};

type BuildingData = {
  addressId: number;
  buildingId: number;
  buildingNumber: number;
  distance: number;
  gpsLat: number;
  gpsLon: number;
  hasApartments: boolean;
  hasBuildingParts: boolean;
  houseNumber: string;
  municipality: string;
  post: string;
  settelment: string;
  street: string;
  description: string;
  address: string;
};
type ProfileData = {
  id: string;
  email: string;
  userName: string;
};

const RegisterStep1BMultiple: React.FC = (props: any) => {
  const ionRouter = useIonRouter();
  const { t } = useTranslation();
  const history = useHistory();
  const [provider, setProvider] = useState();
  const [errors, setErrors] = useState<FormErrors>({
    search: undefined,
    buildingpart: undefined,
  });
  const [Profile, setProfile] = useState<ProfileData>();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buildingId, setBuildingId] = useState('');
  const [clientBuildingId, setClientBuildingId] = useState('');
  const [buildingAddressId, setBuildingAddressId] = useState('');
  const [hasApartments, setHasApartments,] = useState(false);
  const [hasBuildingParts, setHasBuildingParts] = useState(false);
  const [buildingPartId, setBuildingPartId] = useState();
  const [buildingClientPartId, setBuildingClientPartId] = useState('');
  const [buildingPartName, setBuildingPartName] = useState('');
  const [buildingPartType, setBuildingPartType] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [buildingType, setBuildingType] = useState('');
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [place, setPlace] = useState('');
  const [isLoggedIn, setLoggedIn] = useState(false);


  const [selected, setSelected] = useState();

  const [unit, setUnit] = useState('');

  const [buldingResults, setBuldingResults] = useState<PartData[]>([]);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [buildingData, setBuildingData] = useState<BuildingData>();
  const [clientBuildingData, setClientBuildingData] = useState<BuildingData>();
  const [name, setName] = useState('');
  const [FoundBuildingParts, setFoundBuildingParts] = useState(buldingResults);
  const [countBuildingParts, setCountBuildingParts] = useState();
  const [checked, setChecked] = useState(false);
  const [dataLoad, setDataLoad] = useState<boolean>(false);

  const user = [
    username,
    email,
    password,
    buildingId,
    clientBuildingId,
    buildingAddressId,
    buildingName,
    buildingType,
    hasApartments,
    hasBuildingParts,
    buildingPartId,
    countBuildingParts,
    buildingPartName,
    buildingPartType,
    provider,
    locationCode,
    clientCode,
    place,
  ];



  useIonViewWillEnter(() => {
    setDataLoad(false);
  }, [props]);

  useIonViewDidEnter(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    //Get local stored user data
    getStorage('user').then((value) => {
      setUsername(value[0]);
      setEmail(value[1]);
      setPassword(value[2]);
      setBuildingId(value[3]);
      setClientBuildingId(value[4]);
      setBuildingAddressId(value[5]);
      setBuildingName(value[6]);
      setBuildingType(value[7]);
      setHasApartments(value[8]);
      setHasBuildingParts(value[9]);
      setBuildingPartId(value[10]);
      setBuildingClientPartId(value[11]);
      setBuildingPartName(value[12]);
      setBuildingPartType(value[13]);
      setProvider(value[14]);
      setLocationCode(value[15]);
      setclientCode(value[16]);
      setPlace(value[17]);
      //console.log(value); 
    });

    //Get units
    /*getProviders()
      .then((response) => {
        setUnits(response);
        console.log(Providers);
      })
      .catch((error) => console.log(error));*/

    getCurrentUser()
      .then((response) => {
        //console.log(response);
        setProfile(response);
        setLoggedIn(true)
      })
      .catch((error) => console.log(error));


    //Get single building
    if (props.match.params.buildingid) {
      onLoad(props.match.params.buildingid, props.match.params.addressid, props.match.params.hasapartments, props.match.params.hasbuildingparts, props.match.params.clientbuildingid);
    }

    //Remove selected
    setSelected(undefined);

  }, [props]);



  //On Load
  const onLoad = (buildingid: any, addressid: any, hasBuildingParts: any, hasApartments: any, id: string) => {
    let isMounted = true; // track whether component is mounted 
    //console.log(buildingid)


    //Get single building
    getBuildingSingle(buildingid, addressid)
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setBuildingData(response);

          //Data loaded
          setDataLoad(true)

        }
      })
      .catch((error) => console.log(error));


    //Get single building Parts
    searchBuildingPart(
      buildingid,
      buildingid,
      addressid,
    )
      .then((response: any) => {
        // success
        //console.log("Status Submitted " + e.target.value)
        //console.log(response);
        setBuldingResults(response);
        setFoundBuildingParts(response);
      })
      .catch((error) => {
        // failure
        console.log(error);
      });

    //Get client building
    if (id != "0") {
      getClientBuildingSingle({ id })
        .then((response) => {
          //console.log(response.pop().clientBuildingId);
          //Set the last client building id from all buildings
          setClientBuildingData(response)
        })
        .catch((error) => console.log(error));
    }

    return () => {
      // clean up
      isMounted = false;
    };

  };



  const handleNextStep = (buildingId: any, addressId: any, hasBuildingParts: any, hasApartments: any, clientBuildingId: any, partid: any) => {
    if (selected) {
      //console.log(buildingPartId)
      //Set building id
      setBuildingId(buildingId);
      setBuildingAddressId(addressId);
      setHasApartments(hasApartments);
      setHasBuildingParts(hasBuildingParts);
      //setClientBuildingId(clientBuildingId);
      //console.log(building);
      //Set user data to local storage
      setStorage("user", user);
      history.push(`/auth/register-step-1-c-multiple/buildingid/${buildingId}/addressid/${addressId}/hasbuildingparts/${hasBuildingParts}/hasapartments/${hasApartments}/clientbuildingid/${clientBuildingId}/clientbuildingpartid/${partid}`);
    }

  };

  //Handle Search buildings
  const doSearch = () => {

  };


  //Check if buillding part
  const handleCheckbox = (e: any, buildingId: any, addressId: any, hasBuildingParts: any, hasApartments: any, clientBuildingId: any, partid: any) => {
    setDataLoad(false);
    setBuildingPartId(e)
    setSelected(e)

    //Get all client buildings
    if (props.match.params.clientbuildingid == null || props.match.params.clientbuildingid == 0) {
      getClientBuildings()
        .then((response) => {
          //console.log(response);
          //Set the last client building id from all buildings
          setClientBuildingId(response.pop().clientBuildingId)
          setDataLoad(true);

          //Selected part scroll into view
          const selectedPart = document.querySelector<HTMLInputElement>(
            `.selected-part`
          );
          selectedPart?.scrollIntoView();

        })
        .catch((error) => console.log(error));
    } else {
      setClientBuildingId(props.match.params.clientbuildingid)
      setDataLoad(true);
    }



  }


  //Filter building parts
  const filter = (e: any) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = buldingResults.filter((building) => {

        const searchWord = building.floorNumber + " " + t('auth.register.floor') + " " + t('auth.register.groundfloor') + " " + building.actualUse + " " + building.roomNumber + " " + t('auth.register.apartmentno');
        console.log(searchWord.toLowerCase());
        return searchWord.toLowerCase().includes(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive 
      });
      setFoundBuildingParts(results);
    } else {
      setFoundBuildingParts(buldingResults);
      // If the text field is empty, show all users
    }
    setName(keyword);
  };

  //Clear input
  const clearInput = (e: any) => {
    setName('');
  };


  useIonViewWillEnter(() => { //useEffect

    //Remove selected
    setSelected(undefined);

  }, [props]);

  const buildType = buildingData?.hasBuildingParts || buildingData?.hasApartments ? t('auth.register.union') : t('auth.register.house');

  //console.log(clientBuildingData)

   //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/dashboardmain")
    });
  });

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="h-full p-4 flex pt-8 justify-center rounded-t-2xl bg-white dark:bg-gray-900">
          <div className="w-full mb-5">
            <StepsHeaderWithBackCustom
              title={t('auth.register.step1ATitleUnitSelect')}
              backLink="/dashboardmain"
              hideArrow={true}
            />

            {dataLoad ? (
              <>
                <UnitCard
                  title={buildingName ? buildingName : clientBuildingData?.description}
                  address={`${clientBuildingData ? clientBuildingData?.address : buildingData?.street + ' ' + buildingData?.houseNumber + ', ' + buildingData?.post}`}
                  type={buildingData?.hasBuildingParts && buildingData?.hasApartments ? "SKYSCRAPER" : buildingData?.hasBuildingParts && !buildingData?.hasApartments ? "HOMEMULTIPLE" : "HOME"}
                  mapLat={buildingData?.gpsLat.toString()}
                  mapLong={buildingData?.gpsLon.toString()}
                />

                <div className="mt-10 mb-2 text-gray-55 dark:text-white text-sm font-bold">{t('auth.register.searchAmongUnits')}</div>

                <div className="py-4">
                  <InputSearch
                    type="text"
                    label={t('auth.register.searchAmongUnits')}
                    value={name}
                    onChange={filter}
                    noRightIcon={true}
                    onClear={clearInput}
                  />
                </div>

                {FoundBuildingParts[0] && (
                  <div className="calc-height-25 overflow-auto scrollbar scrollbar-thumb-blue-45 scrollbar-track-blue-50 scrollbar-thumb-rounded-md scrollbar-track-rounded-md scrollbar-thin">
                    {FoundBuildingParts.map((building, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleCheckbox(building.buildingPartId, props.match.params.buildingid, props.match.params.addressid, props.match.params.hasbuildingparts, props.match.params.hasapartments, props.match.params.clientbuildingid, 0)}
                          className={`flex items-center py-4 border-b border-gray-45 mt-4 ${selected === building.buildingPartId && "opacity-50 selected-part"}`}>
                          <div className="text-sm text-gray-55 dark:text-white">{building.floorNumber ? building.floorNumber + " " + t('auth.register.floor') + ";" : t('auth.register.groundfloor') + ";"} {building.actualUse} {building.roomNumber && t('auth.register.apartmentno') + " " + building.roomNumber}</div>
                        </div>
                      );
                    })}
                  </div>
                )}



              </>
            ) : (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <Loader />
              </div>
            )}

          </div>
        </div>
      </IonContent>
      <IonFooter mode="ios" className="ion-no-border p-4 bg-white dark:bg-gray-900">
        <IonToolbar>
          <IonButton onClick={() => handleNextStep(props.match.params.buildingid, props.match.params.addressid, props.match.params.hasbuildingparts, props.match.params.hasapartments, clientBuildingId, 0)} color={selected ? "blue" : "graybgbut"} className="w-full normal-case font-semibold h-12 text-base"><span className={`${selected ? "text-white" : "dark:text-gray-900 text-gray-400"} `}>{t('auth.register.add')}</span></IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
}

export default RegisterStep1BMultiple;
