import { IonIcon, IonRouterLink } from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MeterInfo from './MeterInfo';
interface ContainerProps {
  id?: string;
  title?: string;
  address?: string;
  singleLink?: string;
  healthIndex?: number;
  ClientBuildingId: string;
  ClientBuildingPartId: string;
}

const DashCardMainChildren: React.FC<ContainerProps> = ({
  id,
  title,
  address,
  singleLink,
  healthIndex,
  ClientBuildingId,
  ClientBuildingPartId
}) => {

  const { t } = useTranslation();
  /**const [showMeter, setShowmeter] = useState(false)
  const handleShowMeterInfo = () => {
    setShowmeter(true)
  }*/

  return (
    <div id={id} className=" text-white rounded-md block p-4 w-full bg-gray-45 dark:bg-black-5 relative mb-3">
      <IonRouterLink routerLink={singleLink} routerDirection="forward"  className={`relative z-50 bold min-h-20 inline-block text-gray-800 font-bold text-base dark:text-white`}>{address}</IonRouterLink>
        {/*<MeterInfo
          ClientBuildingId={ClientBuildingId}
          ClientBuildingPartId={ClientBuildingPartId}
          className="mt-4"
          showMeterInfo={handleShowMeterInfo}
  />*/}

      
      <IonRouterLink routerLink={singleLink} routerDirection="forward" className="text-sm pr-16 text-gray-600 dark:text-white w-full block">{title}</IonRouterLink>
      {healthIndex !== 0 && (
        <IonRouterLink routerLink={singleLink} routerDirection="forward"className="text-gray-800 uppercase text-3xs mt-4 absolute top-0 right-0 w-16 text-right mr-4">
          {t('dashboard.airQuality')}
          {healthIndex === 1 && (
            <img className='ml-auto mt-1' width="16" height="16" src="/assets/icon/air-bad-red.svg" />
          )}
          {(healthIndex === 2) && (
            <img className='ml-auto mt-1' width="16" height="16" src="/assets/icon/air-neutral-yellow.svg" />
          )}
          {healthIndex === 3 && (
            <img className='ml-auto mt-1' width="16" height="16" src="/assets/icon/air-good-green-light.svg" />
          )}
        </IonRouterLink>
      )}
    </div>
  );
};
export default DashCardMainChildren;
