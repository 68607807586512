const API_URL = process.env.REACT_APP_API_URL;

export class ApiException {
  name: string;
  message: string;
  response: any;

  constructor(message: string, response: any) {
    this.name = 'ApiException';
    this.message = message;
    this.response = response;
  }
}

export default function login(
  username: string,
  password: string
): Promise<any> {
  // Fetch it from storage, if route requires auth, if no token present, fail

  const headers = new Headers();
  headers.append('Content-type', 'application/x-www-form-urlencoded');
  headers.append('Accept', '*/*');

  const body: any = {
    grant_type: 'password',
    client_id: 'eMRDashboard_App',
    scope: 'eMRDashboard',
    username: username,
    password: password,
  };

  let formBody = [];
  for (var property in body) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(body[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  const queryString = formBody.join('&');

  return fetch(`${API_URL}/connect/token`, {
    method: 'POST',
    body: queryString,
    mode: 'cors',
    redirect: 'follow',
    headers,
  }).then(async (response: any) => {
    if (response.status !== 200) {
      throw new ApiException('error', await response.json());
    }

    return response.json();
  });
}
