import { useState, useRef, useEffect } from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonSlides,
  IonSlide,
  IonRouterLink,
  IonToolbar,
  IonModal,
  useIonActionSheet,
  IonIcon,
  IonThumbnail,
  IonImg,
  IonFooter,
  useIonViewDidEnter,
  useIonViewWillEnter,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  isPlatform,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import DashFilter from '../components/DashFilter';
import DashCardMain from '../components/DashCardMain';
import { getClientBuildings } from '../data/client-points/clientBuildings.api';
import FooterTabs from '../components/dashboardSingle/FooterTabs';
import DeletePlaceModal from '../components/modals/deletePlace';
import { deleteBuilding } from '../data/client-points/deleteBuilding.api';
import { useHistory } from 'react-router-dom';
import Loader from '../components/Loader';
import EditBuildingModal from '../components/modals/editBuilding';
import { setStorage, getStorage } from '../data/user/register.api';
import ReportBuildingModal from '../components/modals/reportBuilding';
import DemoHeaderBar from '../components/DemoHeaderBar';
import { checkOldApplicationData } from '../data/client-points/checkOldApplicationData';
import { getProfile } from '../data/user/profile.api';
import { transferOldApplicationData } from '../data/client-points/transferOldApplicationData';
//Push notifications
import { FCM } from '@capacitor-community/fcm';
import { PushNotificationSchema, PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { getArticlesClient } from '../data/articles/getArticlesClient.api';
import { App } from '@capacitor/app';

type ClientBuilding = {
  clientBuildingId: number;
  gursAddressId: number;
  gursBuildingId: number;
  gursMunicipalityId: number;
  address: string;
  description: string;
  hasBuildingParts: boolean;
  hasApartments: boolean;
  gpsLat: any;
  gpsLon: any;
  managed: boolean;
  healthIndex: number;
  partsList: [
    {
      clientBuildingId: number;
      clientBuildingPartId: number;
      description: string;
      actualUse: string;
      healthIndex: number;
    }
  ];
};

type ArticleClient = {
  articleId: number;
  title: string;
  message: string;
  link: string;
  gursMunicipalityId: number;
  municipalityName: string;
  clientArticleList: [
    {
      articleId: number;
      title: string;
      message: string;
      link: string;
      gursMunicipalityId: number;
      municipalityName: string;
      managerUserId: number;
      managerUserName: string;
      utilityId: number;
      utilityName: string;
      managerId: number;
      managerName: string;
      dateFrom: string;
      dateTo: string;
      isRead: string;
    }
  ];
};

const DashboardMain: React.FC = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [tabSlider, setTabSlider] = useState<boolean>(true);
  const [tablist, setTablist] = useState<boolean>(false);
  const [sliderDataLoad, setsliderDataLoad] = useState<boolean>(false);
  const [clientBuildings, setClientBuildings] = useState<ClientBuilding[]>([]);
  const cardSlider = useRef<HTMLIonSlidesElement>(null);
  const [presentSecond, dismissSecond] = useIonActionSheet();
  const [showModalDeleteBuilding, setShowModalDeleteBuilding] = useState(false);
  const [showDeleteBuilding, setShowDeleteBuilding] = useState<number>();
  const navigate = useHistory();
  //Modals
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showEditBuilding, setShowEditBuilding] = useState<number>();
  const [showModalReport, setShowModalReport] = useState(false);
  const [showModalTransferData, setShowModalTransferData] = useState(false);
  //Data transfer from old app
  const [dataLoad, setDataLoad] = useState<boolean>(false);
  const [datasuccess, setDataSuccess] = useState<boolean>(false);

  const nullEntry: any[] = []
  const [notifications, setnotifications] = useState(nullEntry);
  const [newItems, setNewItems] = useState<number>(0);

  const [articles, setArticles] = useState<ArticleClient[]>([]);
  const [redirectToLogin, setRedirectToLogin] = useState(false)

  //Slider options:
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    mode: 'ios',
    pager: true,
  };

  if (Capacitor.isPluginAvailable('PushNotifications')) {
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (response) => {
        const data = response.notification.data;
        //console.log('Action performed: ' + JSON.stringify(response.notification));
        // console.log('Action performed data: ' + JSON.stringify(response.notification.data));
        // console.log('Action performed data clientBuilding : ' + JSON.stringify(response.notification.data.clientBuilding));
        //console.log('Action performed data part: ' + JSON.stringify(response.notification.data.part));
        if (data.clientBuilding && !data.part) {
          history.push(`/dashboardmain/client-building/${data.clientBuilding}`);
        }
        if (data.clientBuilding && data.part) {
          history.push(`/dashboardmain/client-building/${data.clientBuilding}/part/${data.part}`);
        }
      }
    );
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
      }
    );
  }


  //Add card
  const onClickTabAdd = () => {
    //console.log("add clicked!");
  };

  //Change cards view
  const onClickTabChangeView = () => {
    //console.log("onClickTabChangeView clicked");
    if (tabSlider) {
      setTablist(true);
      setTabSlider(false);
      setStorage('tabslider', 'no');
    } else {
      setTablist(false);
      setTabSlider(true);
      setStorage('tabslider', 'yes');
    }
  };

  useIonViewWillEnter(() => {
    //Clear storage
    setStorage('user', [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ]);

    setsliderDataLoad(false);

    /* Display tab view based on storage */
    getStorage('tabslider').then((value) => {
      if (value === 'no') {
        setTablist(true);
        setTabSlider(false);
      } else {
        setTablist(false);
        setTabSlider(true);
      }
    });

    //Get client articles - count new articles
    getArticlesClient()
      .then((response) => {
        //console.log(response);
        setArticles(response)
        var countNotRead = 0;
        response.map((item: any, index: any) => (
          item.clientArticleList.map((article: any, i: any) => (
            countNotRead += article.isRead ? 0 : 1
          ))
        ))
        setNewItems(countNotRead)
      })
      .catch((error) => console.log(error));


  });

  useIonViewDidEnter(() => {
    fetchClientBuildings(props.match.params.slide);
  }, [props]);

  //Delete card
  const tabAction = (index: number) => {
    //console.log(index);
  };
  const tabActionDelete = (index: number) => {
    //console.log(index);
  };

  const tabActionNotify = (index: number) => {
    //console.log(index);
  };

  //Edit/delete Card
  const onClickCard = (
    index: any,
    buildingid: any,
    addressid: any,
    hasbuildingparts: any,
    hasapartments: any,
    partid: any,
    managed: boolean
  ) => {
    presentSecond({
      mode: 'ios',
      buttons: [
        {
          text:
            hasbuildingparts || hasapartments
              ? t('dashboard.addBuildingPart')
              : '',
          cssClass: `important-text-gray-20 h-0 ${hasbuildingparts || hasapartments ? '' : 'hideit'
            }`,
          handler: () => {
            if (hasbuildingparts || hasapartments) {
              history.push(
                `/auth/register-step-1-b-multiple/buildingid/${buildingid}/addressid/${addressid}/hasbuildingparts/${hasbuildingparts}/hasapartments/${hasapartments}/clientbuildingid/${index}/clientbuildingpartid/${partid}`
              );
            }
          },
        },
        {
          text: t('dashboard.editBuilding'),
          cssClass: 'important-text-gray-20',
          handler: () => {
            setShowEditBuilding(index);
            if (index) {
              setShowModalEdit(true);
            }
          },
        },
        {
          text: t('dashboard.reportAnIssue'),
          handler: () => {
            setShowModalReport(true);
            setShowEditBuilding(index);
          },
          cssClass: managed
            ? 'important-text-gray-20'
            : 'hideit important-text-gray-20',
        },
        {
          text: t('dashboard.ctaDelete'),
          role: 'destructive',
          handler: () => {
            setShowModalDeleteBuilding(true);
            setShowDeleteBuilding(index);
          },
          cssClass: managed ? 'hideit' : '',
        },
        {
          text: t('dashboard.ctaCancel'),
          cssClass: 'important-text-gray-20',
          role: 'cancel',
        },
      ],
    });
  };

  //Delete Building
  const handleDeleteBuilding = async (id: string) => {
    setTablist(true);
    setTabSlider(false);

    deleteBuilding({ id: id })
      .then((response) => {
        //Refresh buildings
        fetchClientBuildings(0);
        setShowModalDeleteBuilding(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchClientBuildings = (slide: any) => {

    getClientBuildings()
      .then((response) => {
        //console.log(response);

        const buildingsWithSensors = response.map((items: ClientBuilding) => {
          //Get sensors
          /*getClientSensorsForBuildings({ ClientBuildingId: 314, ClientBuildingPartId: 195 })
            .then((response) => {
              console.log(response)
            })
            .catch((error) => {
              // failure
              console.log(error);
            });*/

          return {
            sensors: null,
            ...items,
          }
        });
        setClientBuildings(buildingsWithSensors);
        setsliderDataLoad(true);
        //go to specific slide on back button
        goToSlide(slide);

        //console.log(buildingsWithSensors.length)

        //Check if user has old data
        if (buildingsWithSensors.length < 1) {
          handleCheckUser();
        }

      })
      .catch((error) => {
        // failure
        //Exit app if failure
        if (isPlatform('cordova')) {
          App.exitApp();
        } else {
          window.location.reload();
        }
        console.log(error);
        //Remove token if error
        Preferences.remove({ key: 'token' });
        Preferences.clear();
      });
  };


  const goToSlide = async (id: any) => {
    cardSlider.current?.slideTo(id);
  };

  //Check if user data exist in old app
  const handleCheckUser = () => {
    getProfile()
      .then((response) => {
        //console.log(response);
        //Check for user data in old app
        checkOldApplicationData(response.email)
          .then((response) => {
            //console.log(response);
            if (response === true) {
              setShowModalTransferData(true)
            }
          })
          .catch((error) => {
            // failure
            console.log(error);
          });

      })
      .catch((error) => console.log(error));
  }

  //Transfer user data from old app
  const handleTransfer = () => {
    setDataLoad(true);
    //Transfer data
    getProfile()
      .then((response) => {
        //console.log(response);
        //Check for user data in old app
        transferOldApplicationData(response.email)
          .then((response) => {
            //console.log(response);
            setDataSuccess(true);
          })
          .catch((error) => {
            // failure
            console.log(error);
          });

      })
      .catch((error) => console.log(error));
  }

  const notifyTooltip = {
    date: "25. 8. 2022",
    title: "VOKA Snaga Ljubljana",
    description: "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.",
  }


  return (
    <IonPage id="dashboard">
      <IonHeader
        mode="ios"
        className="pt-2 ion-no-border bg-gray-45 dark:bg-gray-900"
      >
        <IonToolbar color="graybg">
          <DemoHeaderBar />
          <DashFilter
            onClickTabAdd={onClickTabAdd}
            onClickTabChangeView={onClickTabChangeView}
            list={tablist}
            slider={tabSlider}
          />
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen color="graybg">
        {sliderDataLoad ? (
          clientBuildings.length > 0 ? (
            tabSlider ? (
              <div className="flex flex-col flex-wrap w-full h-full px-3">
                <>
                  <IonSlides
                    className="flex-grow w-full h-full-minus"
                    pager={true}
                    options={slideOpts}
                    ref={cardSlider}
                  >
                    {clientBuildings.map((card, index) => (
                      <IonSlide
                        className="h-full"
                        key={`${index.toString()}-slide`}
                      >
                        <DashCardMain
                          notifyTooltipGursId={card.gursMunicipalityId}
                          articles={articles}
                          newItems={newItems}
                          showWeather={true}
                          key={`main-${index}`}
                          id={`main-${index}`}
                          slideid={index}
                          type={
                            card.hasBuildingParts && !card.hasApartments
                              ? 'office'
                              : card.hasBuildingParts && card.hasApartments
                                ? 'block'
                                : 'house'
                          }
                          title={card.description}
                          address={card.address}
                          ShowHide=""
                          singleLink={`/dashboardmain/client-building/${card.clientBuildingId}/part/0/slide/${index}`}
                          onClickCard={() =>
                            onClickCard(
                              card?.clientBuildingId,
                              card?.gursBuildingId,
                              card?.gursAddressId,
                              card?.hasBuildingParts,
                              card?.hasApartments,
                              0,
                              card?.managed
                            )
                          }
                          data={card.partsList}
                          view="slider"
                          gpsLat={card.gpsLat}
                          gpsLon={card.gpsLon}
                          healthIndex={card.healthIndex}
                          ClientBuildingId={card.clientBuildingId.toString()}
                          ClientBuildingPartId={"0"}
                        />
                      </IonSlide>
                    ))}
                  </IonSlides>
                  {
                    <div className="flex items-center justify-center flex-grow-0 w-full h-12 slider-bullets text-gray-5"></div>
                  }
                </>
              </div>
            ) : (
              <div className="flex flex-col flex-wrap w-full px-3">
                <div className="flex-grow w-full">
                  {clientBuildings.map((card, index) => (
                    <div className="mb-2" key={`${index.toString()}-list`}>
                      <DashCardMain
                        notifyTooltipGursId={card.gursMunicipalityId}
                        articles={articles}
                        key={`maincard-${index}`}
                        id={`main-${index}`}
                        type={
                          card.hasBuildingParts && !card.hasApartments
                            ? 'office'
                            : card.hasBuildingParts && card.hasApartments
                              ? 'block'
                              : 'house'
                        }
                        title={card.description}
                        address={card.address}
                        ShowHide=""
                        singleLink={`/dashboardmain/client-building/${card.clientBuildingId}/part/0`}
                        onClickCard={() =>
                          onClickCard(
                            card?.clientBuildingId,
                            card?.gursBuildingId,
                            card?.gursAddressId,
                            card?.hasBuildingParts,
                            card?.hasApartments,
                            0,
                            card?.managed
                          )
                        }
                        data={card.partsList}
                        gpsLat={card.gpsLat}
                        gpsLon={card.gpsLon}
                        healthIndex={card.healthIndex}
                        ClientBuildingId={card.clientBuildingId.toString()}
                        ClientBuildingPartId={"0"}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )
          ) : (
            <div className="flex items-center justify-center h-full">
              <div>
                <div className="w-24 h-24 mx-auto text-center">
                  <IonThumbnail>
                    <IonImg
                      className="w-24 h-auto"
                      src="/assets/icon/icon-house-empty.svg"
                    />
                  </IonThumbnail>
                </div>
                <div className="font-bold text-center text-gray-55">
                  {t('dashboard.noBuildings')}
                </div>
                <div className="flex items-center justify-center text-sm text-center">
                  <IonRouterLink
                    className="font-bold text-gray-50"
                    routerLink="/auth/register-step-1-a"
                  >
                    <div className="flex items-center mt-7">
                      <IonIcon
                        src="/assets/icon/ic-plus-add.svg"
                        class="ios hydrated text-xl w-4 h-4 mr-3"
                      ></IonIcon>
                      {t('dashboard.addBuilding')}
                    </div>
                  </IonRouterLink>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <Loader />
          </div>
        )}

        {clientBuildings
          .filter(
            (clientBuilding) =>
              clientBuilding.clientBuildingId === showEditBuilding
          )
          .map((clientBuilding) => (
            <EditBuildingModal
              onDidDismiss={() => setShowModalEdit(false)}
              isOpen={showModalEdit}
              key={`${clientBuilding.clientBuildingId.toString()}-edit`}
              cssClass={
                showEditBuilding === clientBuilding.clientBuildingId
                  ? 'opacity-100'
                  : 'hidden opacity-0'
              }
              id={`${clientBuilding.clientBuildingId.toString()}-edit`}
              inputtitle={clientBuilding.description.toString()}
              //onClick={() => handleEditPoint(clientPoint.clientPointId.toString())}
              onCancelEdit={() => setShowModalEdit(false)}
              onSuccessEdit={() => fetchClientBuildings(0)}
            />
          ))}

        <IonModal
          key={`delete-modal-building`}
          isOpen={showModalDeleteBuilding}
        >
          {clientBuildings.map((card, index) => (
            <DeletePlaceModal
              key={card?.clientBuildingId}
              cssClass={
                showDeleteBuilding === card?.clientBuildingId
                  ? 'opacity-100'
                  : 'hidden opacity-0'
              }
              id={`${card?.clientBuildingId.toString()}-delete-building-slider`}
              onClick={() =>
                handleDeleteBuilding(card?.clientBuildingId.toString())
              }
              onCancelDelete={() => setShowModalDeleteBuilding(false)}
            />
          ))}
        </IonModal>

        <IonModal key="report-modal" isOpen={showModalReport}>
          {clientBuildings.map((card, index) => (
            <ReportBuildingModal
              onDidDismiss={() => setShowModalReport(false)}
              key={`${card?.clientBuildingId.toString()}-report`}
              cssClass={
                showEditBuilding === card?.clientBuildingId
                  ? 'opacity-100'
                  : 'hidden opacity-0'
              }
              id={`${card?.clientBuildingId.toString()}`}
              clientBuildingId={`${card?.clientBuildingId.toString()}`}
              clientBuildingPartId="0"
              gursAddressId={`${card?.gursAddressId.toString()}`}
              gursBuildingId={`${card?.gursBuildingId.toString()}`}
              /*onClick={() =>
                handleReportClientPoint(clientPoint.clientPointId.toString())
              }*/
              onCancelReport={() => setShowModalReport(false)}
            />
          ))}
        </IonModal>
      </IonContent>

      <IonModal
        isOpen={showModalTransferData}
        key="data-modal"
      >
        <div className="w-full h-full bg-white overflow-scroll">
          <IonIcon
            onClick={() => setShowModalTransferData(false)}
            src={`/assets/icon/icon-close-gray-dark.svg`}
            class={`ios hydrated text-3xl absolute right-0 top-0 p-4 w-6`}
          ></IonIcon>

          <div className='flex items-center justify-center w-full h-full p-5 text-center'>
            {dataLoad ? (
              <div className="flex items-center justify-center w-full h-full">
                {datasuccess ? (
                  <div className='w-full h-12 text-base normal-case'>{t('auth.register.transferSuccess')}
                    <IonButton
                      onClick={() => setShowModalTransferData(false)}
                      color="blue"
                      className="w-full h-12 mt-10 text-base font-semibold normal-case"
                    >
                      {t('auth.register.close')}
                    </IonButton>
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            ) : (
              <div className=''>
                {t('auth.register.transferDataDesc')}
                <IonButton
                  onClick={handleTransfer}
                  color="blue"
                  className="w-full h-12 mt-10 text-base font-semibold normal-case"
                >
                  {t('auth.register.transfer')}
                </IonButton>
                <IonRouterLink
                  onClick={() => setShowModalTransferData(false)}
                  className="block w-full h-12 p-6 text-base text-center normal-case text-gray-5"
                >
                  {t('dashboard.ctaCancel')}
                </IonRouterLink>
              </div>
            )}

          </div>

        </div>
      </IonModal>

      <IonFooter mode="ios" className="ion-no-border" color="graybg">
        <FooterTabs
          tabAction={() => tabAction(0)}
          tabActionDelete={() => tabActionDelete(1)}
          tabActionNotify={() => tabActionNotify(2)}
          active={0}
        />
      </IonFooter>
    </IonPage >
  );
};

export default DashboardMain;
