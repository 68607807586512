import { IonIcon, IonRouterLink } from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDocumentsDownloadLink } from '../data/client-points/documentsDownloadLink.api';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { isPlatform } from '@ionic/react';
interface ContainerProps {
  id?: string;
  title?: string;
  sendEmail: Function;
}

const InvoicePdfCard: React.FC<ContainerProps> = ({
  id,
  title,
  sendEmail
}) => {

  const { t } = useTranslation();
  const [url, setUrl] = useState('');


    //File download link handle 
    const handleDocumentDownload = (fileId: any, title: any) => {
      //Genereate document download link
      getDocumentsDownloadLink({ fileId })
        .then((response) => {
          //console.log(response.url);
          setUrl(response.url)
  
          //Detetect if ios
          if (isPlatform('ios')) {
            InAppBrowser.create(response.url)
          } else {
            window.open(response.url, "_blank")
          }
  
  
        })
        .catch((error) => console.log(error));
    }

  return (
    <div id={id} className={`mb-2`}>
      <div id={id} className={`py-3 px-4 text-gray-700 dark:text-white text-sm flex items-center justify-between bg-white dark:bg-gray-800 rounded-lg`}>
        <div className="flex items-center pr-4">
          {title}
        </div>
        <div id={id} className={`flex items-center`}>
          <button onClick={() => handleDocumentDownload(id, title)}>
            <IonIcon
              src={"/assets/icon/ic-downl.svg"}
              class="ios hydrated text-3xl w-6 h-6 mr-4"
            ></IonIcon>
            </button>
            <button onClick={() => sendEmail(id)}>
            <IonIcon
              src={"/assets/icon/ic-send-email.svg"}
              class="ios hydrated text-3xl w-6 h-6"
            ></IonIcon>
          </button>
        </div>

      </div>

    </div>
  );
};
export default InvoicePdfCard;
