import { Api } from '../api';

export type GetKeyAccountsResponse = {
  token: string;
  phone: string | null;
  email: string | null;
};

export const getClientBuildings = async (): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('GET', 'app/client-building/client-buildings', true);
    return response;
  } catch (error) {
    throw error;
  }
};
