import { Preferences } from '@capacitor/preferences';

import { Api } from '../api';

//Local storage
export async function setStorage(key: string, value: any): Promise<void> {
  await Preferences.set({
    key: key,
    value: JSON.stringify(value),
  });
}

export async function getStorage(key: string) {
  const item = await Preferences.get({ key: key });
  return JSON.parse(item.value || '{}');
}

export async function removeStorage(key: string): Promise<void> {
  await Preferences.remove({
    key: key,
  });
}

//Register
export const registerOnlyUser = async (
  userName: string,
  emailAddress: string,
  password: string,
  appName: string,
  pushNotifications: boolean
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('POST', 'app/account/register', true, {
      userName,
      emailAddress,
      password,
      appName,
      pushNotifications
    });
    return response;
  } catch (error) {
    throw error;
  }
};
