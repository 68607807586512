import { IonIcon, IonRouterLink } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import DateCard from './DateCard';
interface ContainerProps {
  id?: string;
  title?: string;
  cost?: string;
  paid?: boolean;
  ShowHide?: string;
  reference?: string;
  billDate?: string;
  dueDate?: string;
}

const InvoiceCard: React.FC<ContainerProps> = ({
  id,
  title,
  cost,
  paid,
  ShowHide,
  reference,
  billDate,
  dueDate
}) => {

  const { t } = useTranslation();

  return (
    <div id={id} className={`px-4 mb-2 ${ShowHide}`}>
      <div id={id} className={`pt-3 px-4 text-gray-700 dark:text-white text-sm flex items-center justify-between bg-white dark:bg-gray-800 rounded-t-lg`}>
        <div className="flex flex-wrap">
          {t('dashboard.date')}: <DateCard getDay={true} getMonth={true} getYear={true} date={billDate ? billDate : ""} className="ml-1" />
        </div>
        <div className="flex items-center">
          {reference}
        </div>
      </div>
      <div id={id} className={`pb-3 pt-1 px-4 text-gray-700 dark:text-white text-sm flex items-center justify-between bg-white dark:bg-gray-800 rounded-b-lg`}>
        <div className="flex flex-wrap">
          {t('dashboard.due_date')}: <DateCard getDay={true} getMonth={true} getYear={true} date={dueDate ? dueDate : ""} className="ml-1" />
        </div>
        <div className="flex items-center">
          <strong className="mr-3">{cost?.replace(".", ",")} EUR</strong>
          <IonIcon
            src={paid ? "/assets/icon/icon-check.svg" : "/assets/icon/icon-close.svg"}
            class="ios hydrated text-3xl w-3 h-3"
          ></IonIcon>
        </div>
      </div>
    </div>
  );
};
export default InvoiceCard;
