import {
  IonContent,
  IonPage,
  IonButton,
  IonToolbar,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStorage, setStorage} from '../../data/user/register.api';
import Input from '../../components/form/Input';
import StepsHeaderWithBack from '../../components/StepsHeaderWithBack';
import UnitCard from '../../components/UnitCard';
import { getBuildingSingle } from '../../data/client-points/getBuildingSingle.api';
import Loader from '../../components/Loader';
import { addBuildings } from '../../data/client-points/addBuildings';
import { getClientBuildings } from '../../data/client-points/clientBuildings.api';
import { getCurrentUser } from '../../data/user/current-user.api';
import { useIonRouter } from '@ionic/react';
import ServiceNotAvailable from '../../components/ServiceNotAvailable';
import { getProfile } from '../../data/user/profile.api';

type FormErrors = {
  building: string[] | undefined;
};

type BuildingData = {
  addressId: number;
  buildingId: number;
  buildingNumber: number;
  distance: number;
  gpsLat: number;
  gpsLon: number;
  hasApartments: boolean;
  hasBuildingParts: boolean;
  houseNumber: string;
  municipality: string;
  post: string;
  settelment: string;
  street: string;
  isAvailable: boolean
};

type CurrentUserlData = {
  isAuthenticated: boolean;
  id: string;
  userName: string;
  email: string;
};


const RegisterStep1B: React.FC = (props: any) => {
  const ionRouter = useIonRouter();
  const { t } = useTranslation();
  const history = useHistory();
  const [provider, setProvider] = useState();
  const [errors, setErrors] = useState<FormErrors>({
    building: undefined,
  });
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [predefinedEmail, setPredefinedEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buildingId, setBuildingId] = useState('');
  const [buildingAddressId, setBuildingAddressId] = useState('');
  const [clientBuildingId, setClientBuildingId] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [buildingType, setBuildingType] = useState('');
  const [hasApartments, setHasApartments,] = useState(false);
  const [hasBuildingParts, setHasBuildingParts] = useState(false);
  const [buildingPartId, setBuildingPartId] = useState('');
  const [buildingClientPartId, setBuildingClientPartId] = useState('');
  const [buildingPartName, setBuildingPartName] = useState('');
  const [buildingPartType, setBuildingPartType] = useState('');
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [place, setPlace] = useState('');
  const [unit, setUnit] = useState('');
  const [dataLoad, setDataLoad] = useState<boolean>(false);
  const [countBuildings, setCountBuildings] = useState<[]>([]);
  const [currentUserId, setcurrentUserId] = useState<CurrentUserlData>();

  const [buildingSearch, setBuildingSearch] = useState<string>("");
  const [active, setActive] = useState<number>();
  const [buildingData, setBuildingData] = useState<BuildingData>();
  const [notAvailable, setNotAvailable] = useState(true)

  const user = [
    username,
    email,
    password,
    buildingId,
    clientBuildingId,
    buildingAddressId,
    buildingName,
    buildingType,
    hasApartments,
    hasBuildingParts,
    buildingPartId,
    buildingClientPartId,
    buildingPartName,
    buildingPartType,
    provider,
    locationCode,
    clientCode,
    place,
  ];

  const idAdd = countBuildings.length + 1;
  const gursBuildingId = 123;
  const gursAddressId = 123;
  const description = buildingName;
  const creationTime = new Date().toISOString().toString();
  const creatorId = currentUserId?.id;
  const lastModificationTime = new Date().toISOString();
  const lastModifierId = currentUserId?.id;
  const isDeleted = true;
  const deleterId = currentUserId?.id;
  const deletionTime = new Date().toISOString();
  const userId = currentUserId?.id;


  const data = {
    tabs: [
      {
        id: 'bivalni',
        title: t('auth.register.livingBuilding'),
        icon: '/assets/icon/ic-house.svg',
        iconActive: '/assets/icon/ic-house-white.svg',
        active: { active }
      },
      {
        id: 'poslovni',
        title: t('auth.register.officeBuilding'),
        icon: '/assets/icon/ic-business.svg',
        iconActive: '/assets/icon/ic-business-white.svg',
        active: { active }
      }
    ]
  };

  useIonViewWillEnter(() => { //useEffect
    getProfile()
      .then((response) => {
        setPredefinedEmail(response.email);
      })
      .catch((error) => console.log(error));
  }, []);


  useIonViewWillEnter(() => {
    setDataLoad(false);

  }, [props]);

  useIonViewDidEnter(() => {

    const abortController = new AbortController();
    const signal = abortController.signal;
    //Get local stored user data
    getStorage('user').then((value) => {
      setUsername(value[0]);
      setEmail(value[1]);
      setPassword(value[2]);
      setBuildingId(value[3]);
      setClientBuildingId(value[4]);
      setBuildingAddressId(value[5]);
      setBuildingName(t('auth.register.buildingPredefinedValue'));
      setBuildingType(value[7]);
      setHasApartments(value[8]);
      setHasBuildingParts(value[9]);
      setBuildingPartId(value[10]);
      setBuildingClientPartId(value[11]);
      setBuildingPartName(value[12]);
      setBuildingPartType(value[13]);
      setProvider(value[14]);
      setLocationCode(value[15]);
      setclientCode(value[16]);
      setPlace(value[17]);
      //console.log(value); 
    });

    //console.log(buildingType);

    //Get current user
    getCurrentUser()
      .then((response) => {
        //console.log(response);
        setcurrentUserId(response);
      })
      .catch((error) => console.log(error));

    //Set active tab from storage
    /*Storage.get({ key: 'user' }).then((storage: any) => {
      const userData = JSON.parse(storage.value || '{}');
      if (userData[6] === 'bivalni') {
        setActive(0)
      } else if (userData[6] === 'poslovni') {
        setActive(1)
      }
    });*/

    //Get single building
    if (props.match.params.buildingid && props.match.params.addressid) {
      onLoad(props.match.params.buildingid, props.match.params.addressid);
    }


  }, [props]);

  //On Load
  const onLoad = (buildingid: any, addressid: any) => {
    let isMounted = true; // track whether component is mounted 
    //console.log(buildingid)
    //Get single building
    getBuildingSingle(buildingid, addressid)
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setBuildingData(response);

          //Set active based on bulding type
          if (response.hasBuildingParts && !response.hasApartments) {
            setActive(1)
            setBuildingType('poslovni')
          } else {
            setActive(0)
            setBuildingType('bivalni')
          }

          //Data loaded
          setDataLoad(true)

        }
      })
      .catch((error) => console.log(error));

    //Get all client buildings
    getClientBuildings()
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setCountBuildings(response);

        }
      })
      .catch((error) => console.log(error));

    //predefined word set in input
    setBuildingSearch(t('auth.register.buildingPredefinedValue'))
    setBuildingName(t('auth.register.buildingPredefinedValue'))

    return () => {
      // clean up
      isMounted = false;
    };

  };


  const handleAddBuilding = (gursBuildingId: any, gursAddressId: any, description: any) => {
    //console.log(clientBuildingId);
    //console.log(buildingName);


    //Add new building
    addBuildings(
      gursBuildingId,
      gursAddressId,
      description,
      creationTime,
      creatorId,
      lastModificationTime,
      lastModifierId,
      isDeleted,
      deleterId,
      deletionTime,
      userId,
    )
      .then((response: any) => {
        // success
        //console.log('Building added!');
        //console.log(response);
        setStorage('user', ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
        history.push(`/dashboardmain`);

      })
      .catch((error: any) => {
        // failure
        console.log(error);
      });
    //End Add new building



  };

  const handleNextStep = (gursBuildingId: any, gursAddressId: any, description: any) => {
    // Chek if selected fields are correct
    if (buildingSearch !== '' && buildingType != '') {
      //Set user data to local storage
      setStorage("user", user);

      //Add new building
      addBuildings(
        gursBuildingId,
        gursAddressId,
        description,
        creationTime,
        creatorId,
        lastModificationTime,
        lastModifierId,
        isDeleted,
        deleterId,
        deletionTime,
        userId,
      )
        .then((response: any) => {
          // success
          //console.log('Building added!');
          //console.log(response);
          history.push(`/auth/register-step-1-c/buildingid/${gursBuildingId}/addressid/${gursAddressId}/hasbuildingparts/false/hasapartments/false/clientbuildingid/${response.id}/clientbuildingpartid/0`);

        })
        .catch((error: any) => {
          // failure
          console.log(error);
        });
      //End Add new building

    } else {
      setErrors({
        ...errors,
        building: ['Building is required'],
      });
      return;
    }

  };

  //Handle Building
  const handleInputBuildingChange = (e: any) => {
    if (e.target.value !== '' && e.target.value.length > 1) {
      setErrors({
        ...errors,
        building: undefined,
      });
      setBuildingName(e.target.value);

    } else {
      setErrors({
        ...errors,
        building: ['Building is required'],
      });
    }

    setBuildingSearch(e.target.value)
    setBuildingId(props.match.params.buildingid);
    setBuildingAddressId(props.match.params.addressid);
  };
  //Handle Building Click clear input if "Doma"
  const handleInputBuildingClick = (e: any) => {
    if (buildingSearch === t('auth.register.buildingPredefinedValue')) {
      setBuildingSearch("")
    }
  };
  //Handle ActiveTab
  const handleActiveTab = (index: number, id: string) => {
    setActive(index);
    setBuildingType(id);
  };

  //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/auth/register-step-1-a")
    });
  });

  //console.log(buildingData);

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="p-4 flex pt-8 justify-center rounded-t-2xl bg-white dark:bg-gray-900">
          {dataLoad ? (

            <div className="w-full mb-5">
              <StepsHeaderWithBack title={t('auth.register.step1ATitle')} backLink="/auth/register-step-1-a" />
              <UnitCard
                title={buildingData?.hasBuildingParts && buildingData?.hasApartments ? t('auth.register.union') : buildingData?.hasBuildingParts && !buildingData?.hasApartments ? t('auth.register.union') : t('auth.register.house')}
                address={`${buildingData?.street} ${buildingData?.houseNumber}, ${buildingData?.post}`}
                type={buildingData?.hasBuildingParts && buildingData?.hasApartments ? "SKYSCRAPER" : buildingData?.hasBuildingParts && !buildingData?.hasApartments ? "HOMEMULTIPLE" : "HOME"}
                mapLat={buildingData?.gpsLat.toString()}
                mapLong={buildingData?.gpsLon.toString()}
              />

              {!buildingData?.isAvailable ? (
                <ServiceNotAvailable
                gursAddressId={buildingData?.addressId}
                predefinedemail = {predefinedEmail}
                />
              ) : (
                <>
                  <div className="mt-10 text-gray-55 dark:text-white text-sm font-bold">{t('auth.register.buildingName')}</div>

                  <div className="py-4">
                    <Input
                      type="text"
                      label={t('auth.register.buildingPlaceholder')}
                      error={errors.building}
                      value={buildingSearch}
                      onChange={handleInputBuildingChange}
                      onClick={handleInputBuildingClick}
                    />
                  </div>
                </>
              )}

              {/*<div className="mt-10 mb-4 text-gray-55 dark:text-white text-sm font-bold">{t('auth.register.buildingType')}</div>*/}

              {/* <div className="flex items-center justify-between">
                {data.tabs.map((tab: any, index: any) => {
                  return (
                    <BuildingType
                      key={index}
                      index={index}
                      icon={tab.icon}
                      iconActive={tab.iconActive}
                      //onClick={() => handleActiveTab(index, tab.id)}
                      title={tab.title}
                      active={active}
                    />

                  );
                })}
              </div>*/}

            </div>
          ) : (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <Loader />
            </div>
          )}


        </div>
      </IonContent>
      {buildingData?.isAvailable && (
        <IonFooter mode="ios" className="ion-no-border p-4 bg-white dark:bg-gray-900">
          <IonToolbar>
            <IonButton onClick={() => handleNextStep(props.match.params.buildingid, props.match.params.addressid, buildingName)} color={errors.building ? "graybgbut" : "blue"} className="w-full normal-case font-semibold h-12 text-sm"><span className={`${errors.building ? "dark:text-gray-900 text-gray-400" : "text-white"} `}>{t('auth.register.addLocationSave')}</span></IonButton>
            <IonButton onClick={() => handleAddBuilding(props.match.params.buildingid, props.match.params.addressid, buildingName)} color="graybgbut" className="w-full normal-case font-semibold h-12 text-sm"><span className='text-gray-900 dark:text-white'>{t('auth.register.skip')}</span></IonButton>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
}

export default RegisterStep1B;
