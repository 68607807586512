interface ContainerProps {
  id?: string;
  title?: string;
  newItem?: boolean;
  className?: string;
  OutherClass?: string;
  onClickTab: () => void;
  hideit?: boolean;
}

const DashTabsNoIcons: React.FC<ContainerProps> = ({
  id,
  title,
  newItem,
  className,
  OutherClass,
  onClickTab,
  hideit,
}) => {

  return (
    <>
      {!hideit && (
        <div id={id} className={OutherClass} onClick={onClickTab}>
          <div className={`relative ${className}`}>{title}{newItem && (<span className="w-2 h-2 bg-red-5 rounded -mt-2 ml-1"></span>)}</div>
        </div>
      )}
    </>
  );
};
export default DashTabsNoIcons;
