import { Api } from '../api';

type inputParams = {
  managerId: string;
};

export const getClientLevels = async (params: inputParams): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('GET', `app/client-manager/client-levels/${params.managerId}`, true);
    return response;
  } catch (error) {
    throw error;
  }
};
