interface ContainerProps {
  title: string;
  totalStep: string;
  currentStep: string;
  divider: string;
}

const Steps: React.FC<ContainerProps> = ({
  title,
  totalStep,
  currentStep,
  divider
}) => {
  return (
    <div className="flex items-center justify-between mb-4">
      <div className="text-gray-55 dark:text-white font-bold text-xl">{title}</div>
      <div className="text-gray-10">{currentStep} / {totalStep}</div>
    </div>
  );
};
export default Steps;
