import {
  IonContent,
  IonPage,
  IonButton,
  IonToolbar,
  IonFooter,
  useIonViewWillEnter,
  IonModal,
  IonIcon,
  IonThumbnail,
  IonImg,
} from '@ionic/react';
import { useState} from 'react';
import {useHistory } from 'react-router';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';
import { setStorage} from '../../data/user/register.api';
import { getBill } from '../../data/client-points/bill.api';
import BillingCodesModal from '../../components/modals/billingCodes';
import StepsHeaderWithBack from '../../components/StepsHeaderWithBack';
import UnitCard from '../../components/UnitCard';
import { getClientBuildingSingle } from '../../data/client-points/clientBuildingSingle.api';
import { addClientPointForBuilding } from '../../data/client-points/addClientPointForBuilding.api';
import { getProviders } from '../../data/client-points/providers.api';
import Loader from '../../components/Loader';
import { Preferences } from '@capacitor/preferences';

type FormErrors = {
  locationCode: string[] | undefined;
  clientCode: string[] | undefined;
  place: string[] | undefined;
};

type ProvidersData = {
  id: number;
  name: string;
  utilityTypeId: number;
  logo: string;
};

type ClientBuildingData = {
  address: string;
  clientBuildingId: number;
  description: string;
  gursAddressId: number;
  gursBuildingId: number;
  hasApartments: boolean;
  hasBills: boolean;
  hasBuildingParts: boolean;
  hasDocuments: boolean;
  gpsLat: string;
  gpsLon: string;
};

type BuildingPartData = {
  actualUse: string;
  clientBuildingId: number;
  clientBuildingPartId: number;
  description: string;
  gursBuildingPartId: number;
  partNumber: number;
  roomNumber: number;
  userId: string;
};

type CurrentUserlData = {
  isAuthenticated: boolean,
  id: string,
  userName: string,
  email: string,
};

type BillData = {
  data: string,
  message: string
};

const AddPlacePageStep2: React.FC = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errors, setErrors] = useState<FormErrors>({
    locationCode: undefined,
    clientCode: undefined,
    place: undefined,
  });
  const [currentUserId, setcurrentUserId] = useState<CurrentUserlData>();
  const [Bill, setBill] = useState<BillData>();
  const [showModal, setShowModal] = useState(false);
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [provider, setProvider] = useState();
  const [place, setPlace] = useState('');
  const [dataLoad, setDataLoad] = useState<boolean>(false);

  const [Providers, setProviders] = useState<ProvidersData[]>([]);
  const [buildingData, setBuildingData] = useState<ClientBuildingData>();
  const [buildingPartData, setBuildingPartData] = useState<BuildingPartData>();
  const [buildingName, setBuildingName] = useState('');

  const [errorMeter, setErrorMeter] = useState(false);


  //Current provider
  const currentpPovider = Providers.filter((items: any) => items.id === provider).map((items: ProvidersData) => {
    return {
      ...items,
    }
  });

  const id = 0;
  const creationTime = new Date().toISOString();
  const creatorId = currentUserId?.id;
  const lastModificationTime = new Date().toISOString();
  const lastModifierId = currentUserId?.id;
  const isDeleted = true;
  const deleterId = currentUserId?.id;
  const deletionTime = new Date().toISOString();
  const code = locationCode;
  const customerCode = clientCode;
  const externalId = '';
  const name = '';
  const address = '';
  const description = place;
  const utilityId = provider;
  const userId = currentUserId?.id;
  const clientPointTypeId = 0;
  const clientBuildingId = props.match.params.buildingid;
  const clientBuildingPartId = props.match.params.partid


  const placeData = [provider, locationCode, clientCode, currentpPovider[0]?.name];


  useIonViewWillEnter(() => {
    //Clear data
    setclientCode("");
    setLocationCode("");
    setPlace("");
    //Get local stored user data
    Preferences.get({ key: 'place' }).then((storage: any) => {
      const getPlaceData = JSON.parse(storage.value || '{}');
      setProvider(getPlaceData[0]);
      if (getPlaceData[1] == null) {
        setLocationCode('');
      } else {
        setLocationCode(getPlaceData[1]);
      }
      if (getPlaceData[2] == null) {
        setclientCode('');
      } else {
        setclientCode(getPlaceData[2]);
      }
      //setPlace(getPlaceData[3]);
      //console.log(getPlaceData);

      //Get bill image from api
      getBill({ id: getPlaceData[0].toString() })
        .then((response) => {
          //console.log(getPlaceData[0]);
          setBill(response.data);
        })
        .catch((error) => console.log(error));


      //Get providers
      getProviders()
        .then((response) => {
          //console.log(response)
          setProviders(response);

          //Set place title
          response.filter((items: any) => items.id === (Number(getPlaceData[0]))).map((item: any, index: any) => {
            if (item.utilityTypeId.toString() === "1") {
              setPlace(t('dashboard.tabs.water'));
            } else if (item.utilityTypeId.toString() === "2") {
              setPlace(t('dashboard.tabs.gas'));
            } else if (item.utilityTypeId.toString() === "3") {
              setPlace(t('dashboard.tabs.heat'));
            } else if (item.utilityTypeId.toString() === "4") {
              setPlace(t('dashboard.tabs.electricity'));
            } else {
              setPlace("");
            }
          })
        })
        .catch((error) => console.log(error));

    });

  });


  useIonViewWillEnter(() => { //useEffect
    //Get single building
    if (props.match.params.buildingid) {
      onLoad(props.match.params.buildingid);
    }
    //Get providers
    /* getProviders()
       .then((response) => {
         //console.log(response)
         setProviders(response);
       })
       .catch((error) => console.log(error));*/

  }, [props.match.params.buildingid]);


  const handleNextStep = () => {
    // Chek if selected fields are correct
    if (locationCode !== '' && clientCode !== '' && place !== '' && locationCode.length > 0 && clientCode.length > 0) {
      //Set user data to local storage
      setStorage('place', placeData);

      //console.log(clientBuildingId)

      /*setData({
          ...data,
          provider: provider,
          locationCode: locationCode,
          clientCode: clientCode,
          place: place,
        });*/

      addClientPointForBuilding(
        id,
        creationTime,
        creatorId,
        lastModificationTime,
        lastModifierId,
        isDeleted,
        deleterId,
        deletionTime,
        code,
        customerCode,
        externalId,
        name,
        address,
        description,
        utilityId,
        userId,
        clientBuildingId,
        clientBuildingPartId
      )
        .then((response: any) => {
          // success
          //Set empty strings to storage
          setStorage('place', ['', '', '', '']);
          if (response?.error) {
            setErrorMeter(true);
          } else {
            //console.log('Client point added!');
            setErrorMeter(false);
            history.push(`/dashboardmain/client-building/${props.match.params.buildingid}/part/${props.match.params.partid}`);
          }

        })
        .catch((error) => {
          // failure
          console.log(error);
          //history.push('/dashboard');
          setErrorMeter(true);
          setErrors({
            ...errors,
            locationCode: ['Location code is required'],
            clientCode: ['Client code is required'],
          });
        });
    } else {
      setErrorMeter(true);
      setErrors({
        ...errors,
        locationCode: ['Location code is required'],
        clientCode: ['Client code is required'],
      });
      return;
    }
  };


  /*const handleNextStep = () => {
    // Chek if selected fields are correct
    if (locationCode !== '' && clientCode !== '' && locationCode.length > 3 && clientCode.length > 1) {
      //Set user data to local storage
      setStorage("place", placeData);
      history.push(`/dashboard/add-place-step-3/clientbuildingid/${props.match.params.buildingid}/clientbuildingpartid/${props.match.params.partid}`);
    } else {
      setErrors({
        ...errors,
        locationCode: ['Location code is required'],
        clientCode: ['Client code is required'],
      });
      return;
    }

  };*/


  //Handle Location code
  const handleInputLocationCodeChange = (e: any) => {
    setErrorMeter(false);
    if (e.target.value !== '' && e.target.value.length > 0) {
      setErrors({
        ...errors,
        locationCode: undefined,
      });
    } else {
      setErrors({
        ...errors,
        locationCode: ['Location code is required'],
      });
    }
    setLocationCode(e.target.value)
  };

  //Handle Client code
  const handleInputClientCodeChange = (e: any) => {
    setErrorMeter(false);
    if (e.target.value !== '' && e.target.value.length > 0) {
      setErrors({
        ...errors,
        clientCode: undefined,
      });
    } else {
      setErrors({
        ...errors,
        clientCode: ['Client code is required'],
      });
    }
    setclientCode(e.target.value)
  };

  //Handle place input
  const handleInputPlaceChange = (e: any) => {
    if (e.target.value !== '') {
      setErrors({
        ...errors,
        place: undefined,
      });
    } else {
      setErrors({
        ...errors,
        place: ['Place is required'],
      });
    }
    setPlace(e.target.value);
  };

  //On Load
  const onLoad = (id: any) => {
    let isMounted = true; // track whether component is mounted 
    //console.log(id)
    //Get single building
    getClientBuildingSingle({ id })
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setBuildingData(response);
          const buildingParts = response.partsList.filter((item: any) => item.clientBuildingPartId === (Number(props.match.params.partid))).map((items: BuildingPartData) => {
            return {
              ...items,
            }
          });
          setBuildingPartData(buildingParts[0]);
          //Data loaded
          setDataLoad(true)
        }
      })
      .catch((error) => console.log(error));


    return () => {
      // clean up
      isMounted = false;
    };

  };

  //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/dashboardmain/")
    });
  });

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="p-4 flex pt-8 justify-center rounded-t-2xl bg-white dark:bg-gray-900">
          {dataLoad ? (
            <div className="w-full mb-5">
              <StepsHeaderWithBack title={t('auth.register.step4TitleProvider')} backLink={`/dashboard/add-place-step-1/clientbuildingid/${buildingData?.clientBuildingId}/clientbuildingpartid/${buildingPartData?.clientBuildingPartId}`} />
              <UnitCard
                title={props.match.params.partid === "0" ? buildingData?.description : buildingPartData?.actualUse}
                address={`${buildingData?.address}`}
                type={buildingData?.hasApartments ? "SKYSCRAPER" : buildingData?.hasBuildingParts ? "HOMEMULTIPLE" : "HOME"}
                mapLat={buildingData?.gpsLat.toString()}
                mapLong={buildingData?.gpsLon.toString()}
              />

              {/*<div className="mt-8 text-lg font-bold text-black-5">{t('auth.register.consumptionPoint')} {buildingData?.description}</div>*/}

              <div className="mt-4 mb-2 text-black-5 font-bold">{t('auth.register.provider')}</div>

              {provider && (
                Providers.filter((items) => items.id === (Number(provider))).map((item, index) => {
                  return (
                    <div key={index} className="flex items-center">
                      <IonThumbnail slot="start">
                        <IonImg src={item.logo ? `data:image/jpeg;base64, ${item.logo}` : '/assets/no-image.jpg'} className="w-12 h-12" />
                      </IonThumbnail>
                      <div className="text-gray-50 ml-6">{item.name}</div>
                    </div>
                  );
                })
              )}

              <div className="my-4 text-black-5 dark:text-white font-bold flex items-center">
                <div>{t('auth.register.meter_details')}</div>
                <IonIcon
                  src={"/assets/icon/ic-help.svg"}
                  class="ios hydrated text-3xl w-4 h-4 ml-3"
                  onClick={() => setShowModal(true)}
                ></IonIcon>
              </div>

              {/* <div className="py-2 mt-6">
                <Input
                  type="text"
                  label={t('auth.register.placePlaceholder')}
                  error={errors.place}
                  value={place}
                  onChange={handleInputPlaceChange}
                />
              </div>*/}

              <div className="py-2">
                <Input
                  type="text"
                  label={t('auth.register.clientLocationCodePlaceholder')}
                  error={errors.locationCode}
                  value={locationCode}
                  onChange={handleInputLocationCodeChange}
                />
              </div>
              <div className="py-2">
                <Input
                  type="text"
                  label={t('auth.register.clientCodePlaceholder')}
                  error={errors.clientCode}
                  value={clientCode}
                  onChange={handleInputClientCodeChange}
                />
              </div>

              {errorMeter && (
                <div className="mt-8 text-red-500 text-center">{t('auth.register.error_adding_meter')}</div>
              )}

            </div>

          ) : (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <Loader />
            </div>
          )}

        </div>
        <IonModal isOpen={showModal}>
          <BillingCodesModal
            onDidDismiss={() => setShowModal(false)}
            id="1"
            /*onClick={() =>
              handleReportClientPoint(clientPoint.clientPointId.toString())
            }*/
            onCancelModal={() => setShowModal(false)}
            data={Bill}
          />
        </IonModal>
      </IonContent>

      <IonFooter mode="ios" className="ion-no-border p-4">
        <IonToolbar>
          <IonButton onClick={handleNextStep} color={(locationCode !== '' && clientCode !== '' && place !== '' && locationCode.length > 0 && clientCode.length > 0) ? "blue" : "graybgbut"} className="w-full normal-case font-semibold h-12 text-base"><span className={`${(locationCode !== '' && clientCode !== '' && place !== '' && locationCode.length > 0 && clientCode.length > 0) ? "text-white" : "dark:text-gray-900 text-gray-400"} `}>{t('auth.register.add')}</span></IonButton>
        </IonToolbar>
      </IonFooter>

    </IonPage>
  );
}

export default AddPlacePageStep2;
