import { IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useAuth } from '../context/Auth';
import { Preferences } from '@capacitor/preferences';

interface ContainerProps { }

const DemoHeaderBar: React.FC<ContainerProps> = ({ }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { setAuthToken } = useAuth();

  const handleLogout = () => {
    Preferences.remove({ key: 'token' });
    Preferences.remove({ key: 'demo' });
    setAuthToken('');
    if (document.body.parentElement?.classList.contains('demo')) {
      document.body.parentElement?.classList.remove('demo');
    }
    if (document.body.classList.contains('demo')) {
      document.body.classList.remove('demo');
    }
    //localStorage.clear();
    history.push('/auth/login');

    //Exit app on logout or reload on web - temporary solution
    /*if (isPlatform('cordova')) {
      history.push('/auth/login');
      Plugins.App.exitApp();
    } else {
      history.push('/auth/login');
      window.location.reload();
    }*/
  };

  return (
    <div className="flex items-center justify-between px-4 py-3 mb-4 -mt-2 text-sm text-white bg-gradient-to-r from-blue-5 to-blue-25 dark:from-gray-900 dark:to-gray-700 show-demo-bar">
      <div className="pr-2 grow">
        {t('dashboard.demoText')} <strong>Demo MojHab</strong>
      </div>
      <button
        onClick={handleLogout}
        className="flex items-center justify-center w-32 py-1 pl-3 pr-1 font-bold bg-white rounded-md text-blue-40 whitespace-nowrap"
      >
        {t('dashboard.demoCta')}{' '}
        <IonIcon
          src="/assets/icon/ic-close-blue.svg"
          class="ios hydrated ml-1 w-4 h-4"
        ></IonIcon>
      </button>
    </div>
  );
};
export default DemoHeaderBar;
