import {
  IonContent,
  IonPage,
  IonButton,
  IonToolbar,
  IonFooter,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getStorage, setStorage } from '../../data/user/register.api';
import Input from '../../components/form/Input';
import StepsHeaderWithBack from '../../components/StepsHeaderWithBack';
import UnitCard from '../../components/UnitCard';
import { getBuildingSingle } from '../../data/client-points/getBuildingSingle.api';
import { getClientBuildingSingle } from '../../data/client-points/clientBuildingSingle.api';
import { addBuildingPart } from '../../data/client-points/addBuildingPart';
import { getClientBuildings } from '../../data/client-points/clientBuildings.api';
import Loader from '../../components/Loader';
import { useIonRouter } from '@ionic/react';

type FormErrors = {
  building: string[] | undefined;
};

type BuildingData = {
  addressId: number;
  buildingId: number;
  buildingNumber: number;
  distance: number;
  gpsLat: number;
  gpsLon: number;
  hasApartments: boolean;
  hasBuildingParts: boolean;
  houseNumber: string;
  municipality: string;
  post: string;
  settelment: string;
  street: string;
  description: string;
  address: string;
};

type CurrentUserlData = {
  isAuthenticated: boolean;
  id: string;
  userName: string;
  email: string;
};



const RegisterStep1CMultiple: React.FC = (props: any) => {
  const ionRouter = useIonRouter();
  const { t } = useTranslation();
  const history = useHistory();
  const [provider, setProvider] = useState();
  const [errors, setErrors] = useState<FormErrors>({
    building: undefined,
  });
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buildingId, setBuildingId] = useState('');
  const [clientBuildingId, setClientBuildingId] = useState('');
  const [buildingAddressId, setBuildingAddressId] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [buildingType, setBuildingType] = useState('');
  const [hasApartments, setHasApartments,] = useState(false);
  const [hasBuildingParts, setHasBuildingParts] = useState(false);
  const [buildingPartId, setBuildingPartId] = useState('');
  const [buildingClientPartId, setBuildingClientPartId] = useState('');
  const [buildingPartName, setBuildingPartName] = useState(t('auth.register.buildingPredefinedValue').toString());
  const [buildingPartType, setBuildingPartType] = useState('');
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [place, setPlace] = useState('');
  const [unit, setUnit] = useState('');

  const [buildingSearch, setBuildingSearch] = useState<string>('');
  const [active, setActive] = useState<number>();
  const [countBuildings, setCountBuildings] = useState<[]>([]);
  const [countBuildingParts, setCountBuildingParts] = useState<[]>([]);
  const [buildingData, setBuildingData] = useState<BuildingData>();
  const [clientBuildingData, setClientBuildingData] = useState<BuildingData>();
  const [currentUserId, setcurrentUserId] = useState<CurrentUserlData>();
  const [dataLoad, setDataLoad] = useState<boolean>(false);

  const user = [
    username,
    email,
    password,
    buildingId,
    clientBuildingId,
    buildingAddressId,
    buildingName,
    buildingType,
    hasApartments,
    hasBuildingParts,
    buildingPartId,
    buildingClientPartId,
    buildingPartName,
    buildingPartType,
    provider,
    locationCode,
    clientCode,
    place,
  ];

  const idAdd = countBuildings.length + 1;
  const idAddParts = countBuildingParts.length + 1;
  const gursBuildingId = 123;
  const gursAddressId = 123;
  const description = buildingName;
  const creationTime = new Date().toISOString().toString();
  const creatorId = currentUserId?.id;
  const lastModificationTime = new Date().toISOString();
  const lastModifierId = currentUserId?.id;
  const isDeleted = true;
  const deleterId = currentUserId?.id;
  const deletionTime = new Date().toISOString();
  const userId = currentUserId?.id;


  const data = {
    tabs: [
      {
        id: 'bivalni',
        title: t('auth.register.livingBuilding'),
        icon: '/assets/icon/ic-house.svg',
        iconActive: '/assets/icon/ic-house-white.svg',
        active: { active }
      },
      {
        id: 'poslovni',
        title: t('auth.register.officeBuilding'),
        icon: '/assets/icon/ic-business.svg',
        iconActive: '/assets/icon/ic-business-white.svg',
        active: { active }
      }
    ]
  };



  useIonViewWillEnter(() => {
    setDataLoad(false);
    //Clear input
    setBuildingPartName("")
  }, [props]);

  useIonViewDidEnter(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    //Get local stored user data
    getStorage('user').then((value) => {
      setUsername(value[0]);
      setEmail(value[1]);
      setPassword(value[2]);
      setBuildingId(value[3]);
      setClientBuildingId(value[4]);
      setBuildingAddressId(value[5]);
      setBuildingName(value[6]);
      setBuildingType(value[7]);
      setHasApartments(value[8]);
      setHasBuildingParts(value[9]);
      setBuildingPartId(value[10]);
      setBuildingClientPartId(value[11]);
      if (buildingPartName) {
        setBuildingPartName(t('auth.register.buildingPredefinedValue'));
      } else {
        setBuildingPartName(t('auth.register.buildingPredefinedValue'));
      }
      setBuildingPartType(value[13]);
      setProvider(value[14]);
      setLocationCode(value[15]);
      setclientCode(value[16]);
      setPlace(value[17]);
      //console.log(value); 
    });

    //console.log(buildingType);

    //Set active tab from storage
    /*Storage.get({ key: 'user' }).then((storage: any) => {
      const userData = JSON.parse(storage.value || '{}');
      if (userData[6] === 'bivalni') {
        setActive(0)
      } else if (userData[6] === 'poslovni') {
        setActive(1)
      }
    });*/



    //Get single building
    if (props.match.params.buildingid & props.match.params.addressid) {
      onLoad(props.match.params.buildingid, props.match.params.addressid, props.match.params.clientbuildingid);
    }

  }, [props]);

  //On Load
  const onLoad = (buildingid: any, addressid: any, id: string) => {
    let isMounted = true; // track whether component is mounted 
    //console.log(buildingid)
    //Get single building
    getBuildingSingle(buildingid, addressid)
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setBuildingData(response);

          //Data loaded
          setDataLoad(true)
        }
      })
      .catch((error) => console.log(error));

    //Get all client buildings
    getClientBuildings()
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setCountBuildings(response);
          setClientBuildingId(response.pop().clientBuildingId)
        }
      })
      .catch((error) => console.log(error));


    //Get client  single building
    getClientBuildingSingle({ id })
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setClientBuildingData(response);

          //Set active based on bulding type
          if (response.hasBuildingParts) {
            setActive(1)
            setBuildingType('poslovni')
          } else {
            setActive(0)
            setBuildingType('bivalni')
          }

        }
      })
      .catch((error) => console.log(error));

    return () => {
      // clean up
      isMounted = false;
    };

  };

  const handleNextStepPart = (id: any, clientBuildingId: any, gursBuildingPartId: any, description: any, buildingId: any, addressId: any, hasBuildingParts: any, hasApartments: any, partid: any) => {
    //console.log(userId);
    if (buildingPartName) {
      setStorage("user", user);

      //Add new building
      addBuildingPart(
        idAddParts,
        clientBuildingId,
        gursBuildingPartId,
        description,
        creationTime,
        creatorId,
        lastModificationTime,
        lastModifierId,
        isDeleted,
        deleterId,
        deletionTime,
        userId
      )
        .then((response: any) => {
          // success
          setStorage("user", user);
          getClientBuildingSingle({ id: response.clientBuildingId })
            .then((response) => {
              setBuildingClientPartId(response.partsList[0].clientBuildingPartId)
              //console.log(response.partsList[0].clientBuildingPartId)
              history.push(`/auth/register-step-1-c/buildingid/${buildingId}/addressid/${addressId}/hasbuildingparts/${hasBuildingParts}/hasapartments/${hasApartments}/clientbuildingid/${clientBuildingId}/clientbuildingpartid/${response.partsList[0].clientBuildingPartId}`);
            })
            .catch((error) => console.log(error));

        })
        .catch((error: any) => {
          // failure
          console.log(error);
        });
      //End Add new building
    } else {
      setErrors({
        ...errors,
        building: ['Building is required'],
      });
    }

  };

  const handleAddBuildingPart = (id: any, clientBuildingId: any, gursBuildingPartId: any, description: any) => {
    //console.log(userId);

    if (buildingPartName) {
      //Add new building
      addBuildingPart(
        idAddParts,
        clientBuildingId,
        gursBuildingPartId,
        description,
        creationTime,
        creatorId,
        lastModificationTime,
        lastModifierId,
        isDeleted,
        deleterId,
        deletionTime,
        userId
      )
        .then((response: any) => {
          // successss
          //console.log('Building part added!');
          //console.log(response);
          setStorage('user', ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '']);
          history.push(`/dashboardmain`);
        })
        .catch((error: any) => {
          // failure
          console.log(error);
        });
      //End Add new building
    } else {
      setErrors({
        ...errors,
        building: ['Building is required'],
      });
    }

  };

  //Handle Building
  const handleInputBuildingChange = (e: any) => {
    if (e.target.value !== '' && e.target.value.length > 1) {
      setErrors({
        ...errors,
        building: undefined,
      });

    } else {
      setErrors({
        ...errors,
        building: ['Building is required'],
      });
    }
    setBuildingPartName(e.target.value);
    setBuildingSearch(e.target.value)
    setBuildingId(props.match.params.buildingid);
    //setClientBuildingId(props.match.params.clientbuildingid);
    setBuildingAddressId(props.match.params.addressid);
    setHasBuildingParts(props.match.params.hasbuildingparts);
    setHasApartments(props.match.params.hasapartments);
  };

  //Handle Building Click clear input if "Doma"
  const handleInputBuildingClick = (e: any) => {
    if (buildingPartName === t('auth.register.buildingPredefinedValue')) {
      setBuildingPartName("")
    }
  };

  //Handle ActiveTab
  const handleActiveTab = (index: number, id: string) => {
    setActive(index);
    setBuildingType(id);
  };

  const buildType = buildingData?.hasBuildingParts || buildingData?.hasApartments ? t('auth.register.union') : t('auth.register.house');

  //console.log(clientBuildingId);

  //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/dashboardmain")
    });
  });

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="p-4 flex pt-8 justify-center rounded-t-2xl bg-white dark:bg-gray-900">
          <div className="w-full mb-5">
            <StepsHeaderWithBack title={t('auth.register.step1ATitleUnit')} backLink={`/auth/register-step-1-b-multiple/buildingid/${buildingData?.buildingId}/addressid/${buildingData?.addressId}/hasbuildingparts/${buildingData?.hasBuildingParts}/hasapartments/${buildingData?.hasApartments}/clientbuildingid/${clientBuildingId}/clientbuildingpartid/0`} />
            {dataLoad ? (
              <>
                <UnitCard
                  title={buildingName ? buildingName : clientBuildingData?.description}
                  address={`${clientBuildingData ? clientBuildingData?.address : buildingData?.street + ' ' + buildingData?.houseNumber + ', ' + buildingData?.post}`}
                  type={buildingData?.hasBuildingParts && buildingData?.hasApartments ? "SKYSCRAPER" : buildingData?.hasBuildingParts && !buildingData?.hasApartments ? "HOMEMULTIPLE" : "HOME"}
                  mapLat={buildingData?.gpsLat.toString()}
                  mapLong={buildingData?.gpsLon.toString()}
                />

                <div className="mt-10 text-gray-55 dark:text-white text-sm font-bold">{t('auth.register.unitName')}</div>

                <div className="py-4">
                  <Input
                    type="text"
                    label={t('auth.register.unitPlaceholder')}
                    error={errors.building}
                    value={buildingPartName}
                    onChange={handleInputBuildingChange}
                    onClick={handleInputBuildingClick}
                  />
                </div>
                {errors.building && (
                  <div className='text-red-500 text-center'>
                    {t('dashboard.submitStatus.errorRequired')}
                  </div>
                )}

                {/*<div className="mt-10 mb-4 text-gray-55 dark:text-white text-sm font-bold">{t('auth.register.unitType')}</div>
                <div className="flex items-center justify-between">
                  {data.tabs.map((tab: any, index: any) => {
                    return (
                      <BuildingType
                        key={index}
                        index={index}
                        icon={tab.icon}
                        iconActive={tab.iconActive}
                        //onClick={() => handleActiveTab(index, tab.id)}
                        title={tab.title}
                        active={active}
                      />

                    );
                  })}
                </div>*/}
              </>
            ) : (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <Loader />
              </div>
            )}

          </div>
        </div>
      </IonContent>
      <IonFooter mode="ios" className="ion-no-border p-4 bg-white dark:bg-gray-900">
        <IonToolbar>
          <IonButton onClick={() => handleNextStepPart(countBuildings.length, props.match.params.clientbuildingid, buildingPartId, buildingPartName, props.match.params.buildingid, props.match.params.addressid, props.match.params.hasbuildingparts, props.match.params.hasapartments, buildingClientPartId)} color={errors.building ? "graybgbut" : "blue"} className="w-full normal-case font-semibold h-12 text-base"><span className={`${errors.building ? "dark:text-gray-900 text-gray-400" : "text-white"} `}>{t('auth.register.addLocationSave')}</span></IonButton>
          <IonButton onClick={() => handleAddBuildingPart(countBuildings.length, props.match.params.clientbuildingid, buildingPartId, buildingPartName)} color="graybgbut" className="w-full normal-case font-semibold h-12 text-base"><span className='text-gray-900 dark:text-white'>{t('auth.register.skip')}</span></IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
}

export default RegisterStep1CMultiple;
