import {
    IonContent,
    IonDatetime,
    IonIcon,
    IonPopover,
    IonRouterLink,
} from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateCard from '../DateCard';
import ConsumptionCard from './ConsumptionCard';
interface ContainerProps {
    id?: string;
    date: string;
    title?: string;
    type?: string; //WATER, GAS, ELECTRICITY, HEAT
    measure?: string;
    lowNum?: string;
    highNum?: string;
    low?: boolean;
    high?: boolean;
    lowLabel?: string;
    highLabel?: string;
    ShowHide?: string;
    lastMonth?: string;
    lowRate?: string;
    highRate?: string;
    singleLink?: string;
    className?: string;
    onClickSubmitStatus?: () => void;
    hasReadout?: boolean;
    consumptionStatus?: number;
    hasAlarms?: boolean;
    alarmsTitle?: string;
    alarmsDescription?: string;
    sendReporthandle: Function;
    NoDecimalNumbers?: boolean;
}

const DashCardSingle: React.FC<ContainerProps> = ({
    id,
    title,
    date,
    type, //WATER, GAS, ELECTRICITY, HEAT
    measure,
    lowNum,
    highNum,
    low,
    high,
    lowLabel,
    highLabel,
    ShowHide,
    lastMonth,
    lowRate,
    highRate,
    singleLink,
    className,
    onClickSubmitStatus,
    hasReadout,
    consumptionStatus,
    hasAlarms,
    alarmsTitle,
    alarmsDescription,
    sendReporthandle,
    NoDecimalNumbers
}) => {

    const ClassWawtter = "from-blue-15 to-blue-20";
    const ClassGas = "from-gray-35 to-gray-40";
    const ClassElectricity = "from-yellow-5 to-yellow-10";

    const { t } = useTranslation();

    const [popoverState, setShowPopover] = useState({
        showPopover: false,
        event: undefined,
    });

    //console.log(consumptionStatus);

    return (
        <div id={id} className={`p-4 mt-4 ${ShowHide}`} datatype={type}>
            <div className={`p-5 text-gray-55 rounded-2xl bg-gradient-to-r bg-white dark:bg-gray-800 ${hasAlarms && "border-2 border-red-5"} ${className}`}>
                <div className="flex items-center justify-between mb-10">
                    <div className="text-base font-bold text-gray-55 dark:text-white">{t('dashboard.lastReading')}</div>
                    <div className="flex items-center text-xs text-gray-50">
                        <svg className="" width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.96101 0.379565C4.55122 0.134827 5.198 0 5.875 0C7.5106 0 8.96277 0.785343 9.875 1.99951V1C9.875 0.723858 10.0989 0.5 10.375 0.5C10.6511 0.5 10.875 0.723858 10.875 1V5C10.875 5.27614 10.6511 5.5 10.375 5.5C10.0989 5.5 9.875 5.27614 9.875 5C9.875 2.79086 8.08414 1 5.875 1C5.33174 1 4.81497 1.10803 4.34405 1.3033C4.10075 1.40418 3.86945 1.52846 3.65297 1.67339C3.43477 1.81946 3.23167 1.98647 3.04657 2.17157C2.86147 2.35667 2.69446 2.55977 2.54839 2.77797C2.39477 3.00744 2.08422 3.06893 1.85475 2.91531C1.62528 2.76169 1.56379 2.45114 1.71741 2.22167C1.8999 1.94906 2.10845 1.69549 2.33947 1.46447C2.57049 1.23345 2.82406 1.0249 3.09667 0.842405C3.36716 0.661324 3.65643 0.505861 3.96101 0.379565Z" fill="#718096" />
                            <path d="M1.375 4.5C1.65114 4.5 1.875 4.72386 1.875 5C1.875 7.20914 3.66586 9 5.875 9C6.41826 9 6.93503 8.89197 7.40595 8.6967C7.64925 8.59582 7.88054 8.47154 8.09703 8.32661C8.31523 8.18054 8.51833 8.01353 8.70343 7.82843C8.88853 7.64333 9.05554 7.44023 9.20161 7.22203C9.35523 6.99256 9.66578 6.93107 9.89525 7.08469C10.1247 7.23831 10.1862 7.54886 10.0326 7.77833C9.8501 8.05094 9.64155 8.30451 9.41053 8.53553C9.17951 8.76655 8.92594 8.9751 8.65333 9.15759C8.38284 9.33868 8.09357 9.49414 7.78899 9.62043C7.19878 9.86517 6.552 10 5.875 10C4.2394 10 2.78723 9.21466 1.875 8.00049V9C1.875 9.27614 1.65114 9.5 1.375 9.5C1.09886 9.5 0.875 9.27614 0.875 9V5C0.875 4.72386 1.09886 4.5 1.375 4.5Z" fill="#718096" />
                        </svg>
                        {date !== '' && (
                            <DateCard getDay={true} getMonth={true} getYear={true} date={date} className="ml-2" />
                        )}
                        {hasAlarms && (
                            <>
                                <button
                                    style={{ outline: "none" }}
                                    className="ml-4"
                                    onClick={(e: any) => {
                                        e.persist();
                                        setShowPopover({ showPopover: true, event: e });
                                    }}>
                                    <IonIcon
                                        src="/assets/icon/icon-alarm.svg"
                                        class="ios hydrated text-3xl"
                                    ></IonIcon>
                                </button>
                                <IonPopover
                                    show-backdrop={false}
                                    event={popoverState.event}
                                    isOpen={popoverState.showPopover}
                                    onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                                    className="popover-custom"
                                    arrow={false}
                                >
                                    <IonContent>
                                        <div className='p-4 w-full bg-gray-800 text-white text-2xs'>
                                            <div className='font-bold mb-1 text-xs'>{alarmsTitle}</div>
                                            <div>{alarmsDescription}</div>
                                        </div>
                                    </IonContent>
                                </IonPopover>
                            </>
                        )}
                    </div>
                </div>
                <IonRouterLink routerLink={singleLink}>
                    <div className="text-sm mb-4 text-gray-50">{lastMonth}</div>
                    <div className="flex items-center justify-between">
                        {lowNum !== "hidden" && (
                            <div>
                                <span className="font-bold text-3xl text-gray-55 dark:text-white">{NoDecimalNumbers ? Number(lowNum).toFixed(0) : lowNum?.replace(".", ",")}</span><span className="text-sm ml-2 text-gray-50 opacity-80">{measure}</span>
                                {lowLabel == "ELECTRICITY" && lowNum !== "hidden" && (
                                    <div className="text-xs mt-1 text-gray-50 opacity-70">{NoDecimalNumbers ? Number(lowRate).toFixed(0) : lowRate?.replace(".", ",")}</div>
                                )}
                            </div>
                        )}
                        {highLabel !== "WATER" && highNum !== "hidden" && (
                            <div className="text-right">
                                <span className="font-bold text-3xl text-gray-55 dark:text-white">{NoDecimalNumbers ? Number(highNum).toFixed(0) : highNum?.replace(".", ",")}</span><span className="text-sm ml-2 text-gray-50 opacity-80">{measure}</span>
                                {highLabel && (
                                    <div className="text-xs mt-1 text-left text-gray-50 opacity-70">{NoDecimalNumbers ? Number(highRate).toFixed(0) : highRate?.replace(".", ",")}</div>
                                )}
                            </div>
                        )}

                    </div>
                </IonRouterLink>
                <div className='flex items-center mt-4 justify-between'>
                    <div>
                        {hasReadout && (
                            <button
                                className="flex items-center bg-blue-40 rounded text-white px-3 py-1"
                                onClick={onClickSubmitStatus}
                            >
                                <div className='w-4 h-6 flex items-center justify-center'>
                                    <IonIcon
                                        src="/assets/icon/ic-plus-add-white.svg"
                                        class="ios hydrated text-3xl w-3 h-3"
                                    ></IonIcon>
                                </div>
                                <span className="ml-1 text-white font-bold text-sm">{t('dashboard.submit_status')}</span>
                            </button>
                        )}
                    </div>

                    {highLabel === "WATER" && (
                        <div className="text-right">
                            {consumptionStatus === 1 && (
                                <ConsumptionCard
                                    consumptionStatus={1}
                                />
                            )}
                            {consumptionStatus === 2 && (
                                <ConsumptionCard
                                    consumptionStatus={2}
                                />
                            )}
                            {consumptionStatus === 3 && (
                                <ConsumptionCard
                                    consumptionStatus={3}
                                />
                            )}
                        </div>
                    )}


                </div>

            </div>
        </div>
    );
};
export default DashCardSingle;




