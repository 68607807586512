import { createContext, useContext } from 'react';

interface Props {
  setData: Function;
  data: RegistrationForm;
}

export type RegistrationForm = {
  username?: string;
  email?: string;
  password?: string;
  provider?: string;
  locationCode?: string;
  clientCode?: string;
  description?: string;
};

export const RegistrationContext = createContext<Props>({
  data: {},
  setData: () => {},
});

export function useRegistration() {
  return useContext(RegistrationContext);
}
