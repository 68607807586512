import { IonIcon } from "@ionic/react";
import { useEffect, useState } from "react";

interface ContainerProps {
  gpsLat?: any;
  gpsLon?: any;
}

type WeatherData = {
  cod: number;
  main: {
    temp: string,
  },
  sys: {
    sunrise: any,
    sunset: any,
  },
  weather: [
    {
      main: string,
      description: string,
    }
  ]
}

const Weather: React.FC<ContainerProps> = ({
  gpsLat,
  gpsLon,
}) => {

  // State
  const [apiData, setApiData] = useState<WeatherData>();

  const weatherApiKey = "0f32e4c0ba63aca7a52d7659390de64e";
  const weatherApiUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${gpsLat}&lon=${gpsLon}&appid=${weatherApiKey}`;

  // Side effect
  useEffect(() => {
    fetch(weatherApiUrl)
      .then((res) => res.json())
      .then((data) => setApiData(data));
  }, [weatherApiUrl]);

  const currentTime = new Date().getTime()
  const dayNight = Number(currentTime.toString().substring(0, 10)) >= apiData?.sys.sunrise && Number(currentTime.toString().substring(0, 10)) < apiData?.sys.sunset ? "sunny" : "nighticon";

  //console.log(apiData)
  //console.log(gpsLat)
  //console.log(gpsLon)

  const icon =
    apiData?.weather[0].description === "clear sky" && dayNight === "sunny" ? "sunny.svg"
      : apiData?.weather[0].description === "few clouds" && dayNight === "sunny" ? "partly_cloudy.svg"
        : apiData?.weather[0].description === "scattered clouds" && dayNight === "sunny" ? "cloud.svg"
          : apiData?.weather[0].description === "broken clouds" && dayNight === "sunny" ? "clouds.svg"
            : apiData?.weather[0].description === "overcast clouds" && dayNight === "sunny" ? "clouds.svg"
              : apiData?.weather[0].description === "shower rain" && dayNight === "sunny" ? "light_rain.svg"
                : apiData?.weather[0].description === "rain" && dayNight === "sunny" ? "rain.svg"
                  : apiData?.weather[0].description === "very heavy rain" && dayNight === "sunny" ? "rain.svg"
                    : apiData?.weather[0].description === "extreme rain" && dayNight === "sunny" ? "rain.svg"
                      : apiData?.weather[0].description === "freezing rain" && dayNight === "sunny" ? "rain.svg"
                        : apiData?.weather[0].description === "light intensity shower rain" && dayNight === "sunny" ? "rain.svg"
                          : apiData?.weather[0].description === "heavy intensity shower rain" && dayNight === "sunny" ? "rain.svg"
                            : apiData?.weather[0].description === "ragged shower rain" && dayNight === "sunny" ? "rain.svg"
                              : apiData?.weather[0].description === "moderate rain" && dayNight === "sunny" ? "light_rain.svg"
                                : apiData?.weather[0].description === "light rain" && dayNight === "sunny" ? "light_rain.svg"
                                  : apiData?.weather[0].description === "thunderstorm" && dayNight === "sunny" ? "cloudlightning.svg"
                                    : apiData?.weather[0].description === "snow" && dayNight === "sunny" ? "snow-1.svg"
                                      : apiData?.weather[0].description === "mist" && dayNight === "sunny" ? "fog.svg"
                                        : apiData?.weather[0].description === "fog" && dayNight === "sunny" ? "fog.svg"
                                          : apiData?.weather[0].main === "Rain" && dayNight === "sunny" ? "rain.svg"
                                            : apiData?.weather[0].main === "Thunderstorm" && dayNight === "sunny" ? "cloudlightning.svg"
                                              : apiData?.weather[0].main === "Drizzle" && dayNight === "sunny" ? "light_rain.svg"
                                                : apiData?.weather[0].main === "Snow" && dayNight === "sunny" ? "snow-1.svg"
                                                  : apiData?.weather[0].main === "Mist" && dayNight === "sunny" ? "fog.svg"
                                                    : apiData?.weather[0].main === "Fog" && dayNight === "sunny" ? "fog.svg"
                                                      : apiData?.weather[0].description === "clear sky" && dayNight === "nighticon" ? "moon.svg"
                                                        : apiData?.weather[0].description === "few clouds" && dayNight === "nighticon" ? "night_cloudy.svg"
                                                          : apiData?.weather[0].description === "scattered clouds" && dayNight === "nighticon" ? "cloud.svg"
                                                            : apiData?.weather[0].description === "broken clouds" && dayNight === "nighticon" ? "clouds.svg"
                                                              : apiData?.weather[0].description === "overcast clouds" && dayNight === "nighticon" ? "clouds.svg"
                                                                : apiData?.weather[0].description === "shower rain" && dayNight === "nighticon" ? "night_rain.svg"
                                                                  : apiData?.weather[0].description === "rain" && dayNight === "nighticon" ? "rain.svg"
                                                                    : apiData?.weather[0].description === "very heavy rain" && dayNight === "nighticon" ? "night_rain.svg"
                                                                      : apiData?.weather[0].description === "extreme rain" && dayNight === "nighticon" ? "night_rain.svg"
                                                                        : apiData?.weather[0].description === "freezing rain" && dayNight === "nighticon" ? "night_rain.svg"
                                                                          : apiData?.weather[0].description === "light intensity shower rain" && dayNight === "nighticon" ? "night_rain.svg"
                                                                            : apiData?.weather[0].description === "heavy intensity shower rain" && dayNight === "nighticon" ? "night_rain.svg"
                                                                              : apiData?.weather[0].description === "ragged shower rain" && dayNight === "nighticon" ? "night_rain.svg"
                                                                                : apiData?.weather[0].description === "moderate rain" && dayNight === "nighticon" ? "night_rain.svg"
                                                                                  : apiData?.weather[0].description === "light rain" && dayNight === "nighticon" ? "night_rain.svg"
                                                                                    : apiData?.weather[0].description === "thunderstorm" && dayNight === "nighticon" ? "cloudlightning.svg"
                                                                                      : apiData?.weather[0].description === "snow" && dayNight === "nighticon" ? "night_snow.svg"
                                                                                        : apiData?.weather[0].description === "mist" && dayNight === "nighticon" ? "night_fog.svg"
                                                                                          : apiData?.weather[0].description === "fog" && dayNight === "nighticon" ? "night_fog.svg"
                                                                                            : apiData?.weather[0].main === "Rain" && dayNight === "nighticon" ? "rain.svg"
                                                                                              : apiData?.weather[0].main === "Thunderstorm" && dayNight === "nighticon" ? "cloudlightning.svg"
                                                                                                : apiData?.weather[0].main === "Drizzle" && dayNight === "nighticon" ? "night_rain.svg"
                                                                                                  : apiData?.weather[0].main === "Snow" && dayNight === "nighticon" ? "night_snow.svg"
                                                                                                    : apiData?.weather[0].main === "Mist" && dayNight === "nighticon" ? "night_fog.svg"
                                                                                                      : apiData?.weather[0].main === "Fog" && dayNight === "nighticon" ? "night_fog.svg"
                                                                                                        : "sunny.svg"
  const temp = apiData?.main.temp ? (Number(apiData?.main.temp) - 273.15).toFixed(0) : "0.0";

  //console.log(apiData);

  return (
    <>
      {apiData?.cod === 200 && (
        <div className="flex items-center absolute top-0 left-0">
          <IonIcon
            src={`/assets/icon/weather/${icon}`}
            class="ios hydrated text-4xl"
          ></IonIcon>
          <div className="ml-3 font-medium dark:text-white mt-1">{temp}°C</div>
        </div>
      )}
    </>
  );
};
export default Weather;
