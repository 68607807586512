import { IonBackButton, IonButtons, IonIcon } from "@ionic/react";
import { useState } from "react";
import { Preferences } from '@capacitor/preferences';

interface ContainerProps {
  title: string;
}

const StepsHeader: React.FC<ContainerProps> = ({
  title
}) => {
  const [theme, setTheme] = useState<string>('light');

  Preferences.get({ key: 'theme' }).then((storage: any) => {
    if (storage.value === "dark") {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  });

  const closeIcon = theme == "dark" ? "ic-close-gray.svg" : "ic-close-black.svg";

  return (
    <div className="flex items-center justify-between mb-4">
      <div className="text-gray-55 dark:text-white font-bold text-xl">{title}</div>
      <IonButtons slot="start">
        <IonBackButton
          className="h-4"
          mode="md"
          defaultHref="/dashboardmain"
          icon={`/assets/icon/${closeIcon}`}
          class="text-gray-10"
        />
      </IonButtons>
    </div>
  );
};
export default StepsHeader;
