interface ContainerProps {
  className?: string;
  onClick: () => void;
}

const ArrowLeftSlider: React.FC<ContainerProps> = ({
    className,
    onClick,
}) => {

  return (
    <button className={`cursor-pointer w-8 h-8 z-10 flex items-center justify-center ${className}`} onClick={() => onClick()}> 
        <svg width="12" height="16" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.13823 9.47124C4.87788 9.73159 4.45577 9.73159 4.19542 9.47124L0.195425 5.47124C-0.0649251 5.21089 -0.0649251 4.78878 0.195425 4.52843L4.19542 0.528433C4.45577 0.268083 4.87788 0.268083 5.13823 0.528433C5.39858 0.788782 5.39858 1.21089 5.13823 1.47124L1.60964 4.99984L5.13823 8.52843C5.39858 8.78878 5.39858 9.21089 5.13823 9.47124Z" fill="#718096"/>
        </svg>
     </button>
  );
};
export default ArrowLeftSlider;
