import { Api } from '../api';



export type GetKeyAccountsResponse = {
  token: string;
  phone: string | null;
  email: string | null;
};

export const getProvidersByAddress = async (
  gursAddressId: number
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('GET', `app/end-user/utilities-for-address/${gursAddressId}`, false); 
    return response;
  } catch (error) {
    throw error;
  }
};
