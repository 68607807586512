import { IonIcon, IonRouterLink } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import DashCardMainChildren from './DashCardMainChildren';
import MeterInfo from './MeterInfo';
import NotifiyTooltip from './NotifiyTooltip';
import Weather from './Weather';


interface ContainerProps {
  id?: string;
  slideid?: number;
  title?: any;
  type?: string;
  address?: string;
  ShowHide?: string;
  singleLink?: string;
  onClickCard?: () => void;
  data: any;
  view?: string;
  gpsLat?: any;
  gpsLon?: any;
  showWeather?: boolean;
  healthIndex?: number;
  newItems?: number;
  notifyTooltipGursId: number;
  articles: any;
  ClientBuildingId: string;
  ClientBuildingPartId: string;
}

const DashCardMain: React.FC<ContainerProps> = ({
  id,
  title,
  type, //house, office, block
  address,
  ShowHide,
  singleLink,
  data,
  view,
  gpsLat,
  gpsLon,
  onClickCard,
  showWeather,
  slideid,
  healthIndex,
  newItems,
  notifyTooltipGursId,
  articles,
  ClientBuildingId,
  ClientBuildingPartId
}) => {
  const classHouse = 'from-blue-30 to-blue-35';
  const classOffice = 'from-green-15 to-green-20';
  const classBlock = 'from-orange-5 to-orange-10';

  const bgHouse = "/assets/icon/bg-house.svg";
  const bgOffice = "/assets/icon/bg-apartment.svg";
  const cardBg = view == "slider" && type === 'house' ? bgHouse : view == "slider" && type === 'office' ? bgOffice : view == "slider" && type === 'block' ? bgOffice : "";
  const { t } = useTranslation();

  //console.log(healthIndex);

  //console.log(data.length > 0 ? data[0].clientBuildingPartId : 0)

  return (
    <div
      id={id}
      className={`relative shadow-md rounded-md flex h-full justify-center bg-gradient-to-tr p-6 w-full text-left
      ${ShowHide} ${type === 'house' && classHouse} 
      ${type === 'office' && classOffice}
      ${type === 'block' && classBlock}`}
      datatype={type}
    >
      <div className="absolute top-0 left-0 w-full h-full dark:bg-black-5 dark:bg-opacity-50 rounded-md"></div>
      <div
        className="absolute top-0 left-0 w-full h-full z-0 bg-no-repeat bg-right"
        style={{
          backgroundImage: `url(${cardBg} )`
        }}
      >

      </div>
      <div className={`w-full relative h-full overflow-x-hidden overflow-y-auto`}>
        {showWeather && (
          <Weather
            gpsLat={gpsLat}
            gpsLon={gpsLon}
          />
        )}

        {/** Check if bulding have meters added */}
        {(ClientBuildingId && ClientBuildingPartId === "0") && (
          <MeterInfo
            ClientBuildingId={ClientBuildingId}
            ClientBuildingPartId={ClientBuildingPartId}
            className="mt-2"
          />
        )}
        {/** Check if particular bulding part have meters added */}
        {(ClientBuildingId && data.length > 0) && (
          data.map((item: any, index: any) => (
            <MeterInfo
              key={index}
              ClientBuildingId={ClientBuildingId}
              ClientBuildingPartId={item.clientBuildingPartId}
              className="mt-2"
            />
          ))
        )}


        {articles.filter((gursid: any) => gursid.gursMunicipalityId === notifyTooltipGursId).map((item: any, index: any) => (
          item.clientArticleList.sort((a: any, b: any) => a.dateFrom + b.dateFrom).slice(0, 1).map((article: any, i: any) => (
            <NotifiyTooltip
              key={i}
              newItems={article.isRead ? 0 : 1}
              date={article.dateFrom}
              title={article.title}
              description={article.message
              }
            />
          ))

        ))}

        <button onClick={onClickCard} className="absolute right-0 top-0 z-40 mt-1 -mr-2">
          <IonIcon
            src="/assets/icon/icon-more-one-white.svg"
            class="ios hydrated text-lg"
          ></IonIcon>
        </button>

        <div className="flex flex-wrap h-full items-end w-full relative pt-12">
          <div className="w-full">
            <IonRouterLink className="block w-full h-full absolute bottom-0 left-0" routerLink={singleLink} routerDirection="forward" />
            {/*data[0] ? (
              <div className="block relative">
                <div className="">
                  <div className="text-2xl font-bold text-white">
                    {title}
                  </div>
                  <div className="text-sm text-white">
                    {address}
                  </div>
                </div>
              </div>
            ) : (*/
              <IonRouterLink routerLink={singleLink} routerDirection="forward" className="block relative">
                <div className="mt-28">
                  <div className="text-2xl font-bold text-white">
                    {title}
                  </div>
                  <div className="text-sm text-white">
                    {address}
                  </div>
                  {healthIndex !== 0 && (
                    <div className="text-white uppercase text-3xs mt-4 flex items-center">
                      {t('dashboard.airQuality')}
                      {healthIndex === 1 && (
                        <img className='ml-2' width="16" height="16" src="/assets/icon/air-bad-white.svg" />
                      )}
                      {(healthIndex === 2) && (
                        <img className='ml-2' width="16" height="16" src="/assets/icon/air-neutral-white.svg" />
                      )}
                      {healthIndex === 3 && (
                        <img className='ml-2' width="16" height="16" src="/assets/icon/air-good-white.svg" />
                      )}
                    </div>
                  )}
                </div>
              </IonRouterLink>
            /*)*/}

            {data.length > 0 && (
              <div className="w-full mt-8">
                {data.map((card: any, index: any) => (
                  <DashCardMainChildren
                    key={`child-${index}`}
                    id={`child-${index}`}
                    title={card.actualUse + " " + card.roomNumber}
                    address={card.description}
                    singleLink={`/dashboardmain/client-building/${card.clientBuildingId}/part/${card.clientBuildingPartId}/slide/${slideid}`}
                    healthIndex={card.healthIndex}
                    ClientBuildingId={card.clientBuildingId}
                    ClientBuildingPartId={card.clientBuildingPartId}
                  />
                ))}
              </div>
            )}

          </div>

        </div>

      </div>
    </div >
  );
};
export default DashCardMain;
