import { useState } from 'react';
import { Message, getMessages } from '../data/messages';
import {
  IonButton,
  IonContent,
  IonPage,
  useIonViewWillEnter,
} from '@ionic/react';
import Header from '../components/Header';
import Input from '../components/form/Input';
import { useTranslation } from 'react-i18next';
import { utilitySignUp } from '../data/client-points/utilitySignUp';
import { Redirect, useHistory } from 'react-router';
import EULogos from '../components/EULogos';
import { useIonRouter } from '@ionic/react';

type FormErrors = {
  email: string[] | undefined;
  phone: string[] | undefined;
  company: string[] | undefined;
  address: string[] | undefined;
  country: string[] | undefined;
};

const Provider: React.FC = () => {
  const ionRouter = useIonRouter();
  const { t } = useTranslation();
  const history = useHistory();
  const [messages, setMessages] = useState<Message[]>([]);
  const [errors, setErrors] = useState<FormErrors>({
    email: undefined,
    phone: undefined,
    company: undefined,
    address: undefined,
    country: undefined,
  });
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');

  // Utullity sign up form
  const postProviderRequest = async () => {

    if (
      email !== '' &&
      emailRegex.test(email) &&
      phone !== '' &&
      company !== '' &&
      address !== '' &&
      country !== ''
    ) {
      utilitySignUp(
        email,
        phone,
        company,
        address,
        country
      )
        .then((response: any) => {
          // success
          //Set empty strings to storage
          console.log('Send provider request');
          //console.log(response);
          history.push('/auth/login');
        })
        .catch((error) => {
          // failure
          console.log(error);
        });
    } else {
      setErrors({
        email: ['Email is required'],
        phone: ['Phone is required'],
        company: ['Company is required'],
        address: ['Address is required'],
        country: ['Country is required'],
      });
    }

  };



  // The regular exprssion to validate the email pattern
  // It may not be 100% perfect but can catch most email pattern errors and assures that the form is mostly right
  const emailRegex = /\S+@\S+\.\S+/;

  useIonViewWillEnter(() => {
    const msgs = getMessages();
    setMessages(msgs);
  });

  const refresh = (e: CustomEvent) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };

  //Handle email input:
  const handleInputEmailChange = (e: any) => {
    if (e.target.value !== '' && emailRegex.test(email)) {
      setErrors({
        ...errors,
        email: undefined,
      });
    } else {
      setErrors({
        ...errors,
        email: ['Email is required'],
      });
    }
    setEmail(e.target.value);
  };

  //Handle phone input:
  const handleInputPhoneChange = (e: any) => {
    if (e.target.value === '') {
      setErrors({
        ...errors,
        phone: ['Phone is required'],
      });
    } else {
      setErrors({
        ...errors,
        phone: undefined,
      });
    }
    setPhone(e.target.value);
  };

  //Handle company input:
  const handleInputCompanyChange = (e: any) => {
    if (e.target.value === '') {
      setErrors({
        ...errors,
        company: ['Company is required'],
      });
    } else {
      setErrors({
        ...errors,
        company: undefined,
      });
    }
    setCompany(e.target.value);
  };


  //Handle address input:
  const handleInputAddressChange = (e: any) => {
    if (e.target.value === '') {
      setErrors({
        ...errors,
        address: ['Address is required'],
      });
    } else {
      setErrors({
        ...errors,
        address: undefined,
      });
    }
    setAddress(e.target.value);
  };

  //Handle country input:
  const handleInputCountryChange = (e: any) => {
    if (e.target.value === '') {
      setErrors({
        ...errors,
        country: ['Country is required'],
      });
    } else {
      setErrors({
        ...errors,
        country: undefined,
      });
    }
    setCountry(e.target.value);
  };

  //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/auth/login")
    });
  });

  return (
    <IonPage>
      <Header
        titleColor="text-gray-55 dark:text-white text-center"
        backLink="/auth/login"
        title={t('auth.accountSettings.registerprovider')}
        rightIcon={false}
      />
      <IonContent fullscreen>
        <div className="">
          <div className="flex justify-center px-4 pt-10">
            <div className="w-full">
              <div className="py-2">
                <Input
                  type="text"
                  label={t('auth.accountSettings.emailPlaceholder')}
                  error={errors.email}
                  value={email}
                  onChange={handleInputEmailChange}
                />
              </div>
              <div className="py-2">
                <Input
                  type="text"
                  label={t('auth.accountSettings.phonePlaceholder')}
                  error={errors.phone}
                  value={phone}
                  onChange={handleInputPhoneChange}
                />
              </div>
              <div className="py-2">
                <Input
                  type="text"
                  label={t('auth.accountSettings.companyPlaceholder')}
                  error={errors.company}
                  value={company}
                  onChange={handleInputCompanyChange}
                />
              </div>
              <div className="py-2">
                <Input
                  type="text"
                  label={t('auth.accountSettings.addressPlaceholder')}
                  error={errors.address}
                  value={address}
                  onChange={handleInputAddressChange}
                />
              </div>
              <div className="py-2">
                <Input
                  type="text"
                  label={t('auth.accountSettings.countryPlaceholder')}
                  error={errors.country}
                  value={country}
                  onChange={handleInputCountryChange}
                />
              </div>

              <IonButton onClick={postProviderRequest} color="blue" className="mt-6 w-full normal-case font-semibold h-12 text-base">{t('auth.accountSettings.ctaSend')}</IonButton>

            </div>
          </div>
        </div>
      </IonContent>



    </IonPage>
  );
};

export default Provider;
