import {
  IonContent,
  IonPage,
  IonButton,
  IonToolbar,
  IonFooter,
  IonRouterLink,
} from '@ionic/react';
import { useState } from 'react';
import Header from './../../components/Header';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';
import HeaderNoBack from '../../components/HeaderWithoutBackBut';
import { utilityRequest } from '../../data/client-points/utilityRequest';
import { Redirect, useHistory } from 'react-router';

type FormErrors = {
  koda: string[] | undefined;
};

function RegisterProviderThankyou() {
  const { t } = useTranslation();
  const history = useHistory();
  const [errors, setErrors] = useState<FormErrors>({
    koda: undefined,
  });

  return (
    <IonPage>
      <Header
        title={t('auth.register.provider_sent')}
        backLink="/auth/register-step-1-c"
        titleColor="text-black-5 dark:text-white"
        rightIcon={false}
      />
      <IonContent fullscreen>
        <div className="flex justify-center p-4 pt-4">
          <div className="w-full">
            <div className="mt-2 mb-6 text-sm text-gray-5">
              {t('auth.register.provider_sent_text')}
            </div>

          </div>
        </div>
      </IonContent>

      <IonFooter mode="ios" className="p-4 ion-no-border">
        <IonToolbar>
          <IonButton
            shape="round"
            color="orange"
            className="w-full h-12 text-base font-semibold normal-case"
            href="/auth/register-step-1-c"
          >
            {t('auth.register.addAnotherLocation')}
          </IonButton>
          <div className="mt-4 text-sm text-center">
            <div>
              <IonRouterLink className="font-bold text-gray-50" routerLink="/auth/register-step-1-c">
                {t('auth.register.saveAndClose')}
              </IonRouterLink>
            </div>
          </div>
        </IonToolbar>
      </IonFooter>
    </IonPage >
  );
}

export default RegisterProviderThankyou;
