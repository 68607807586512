import { IonContent, IonDatetime, IonIcon, IonPopover, useIonViewDidEnter, useIonViewWillEnter } from "@ionic/react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DateCard from "./DateCard";
import WasteCard from "./WasteCard";
import Calendar from 'react-calendar';
import moment from 'moment';

interface ContainerProps {
  id?: string | number;
  data?: any;
  utilityId?: string;
  slidePrev: Function;
  slideNext: Function;
  locale?: string;
  startTime: string;
  endTime: string;
  setSlide: Function;
  startCalendar: string;
  endCalendar: string;
  wasteAll?: any;
}


const CommunalTab: React.FC<ContainerProps> = ({
  id,
  data,
  utilityId,
  slidePrev,
  slideNext,
  locale,
  startTime,
  endTime,
  setSlide,
  startCalendar,
  endCalendar,
  wasteAll
}) => {

  const { t } = useTranslation();
  const slideRef = useRef<HTMLIonInputElement>(null);
  const [value, onChange] = useState(new Date());

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const handleDayClick = (e: any) => {
    const clickedDate = moment(e).date() + "-" + moment(e).month() + "-" + moment(e).year()
    wasteAll.map((date: any, index: any) => {
      const sliderDate = moment(date.startTime).date() + "-" + moment(date.startTime).month() + "-" + moment(date.startTime).year()
      if (clickedDate == sliderDate) {
        setSlide(index)
      }
    })
  }

  return (
    <div className='px-4 w-full'>
      <IonPopover
        className="left-0 pop-calendar popover-custom"
        show-backdrop={false}
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
        arrow={false}
      >
        <IonContent className="pop-calendar-content">
          <div className="p-4 w-full">
            <Calendar
              minDate={new Date(startCalendar)}
              maxDate={new Date(endCalendar)}
              onChange={onChange}
              value={value}
              locale={locale ? locale : ""}
              onClickDay={handleDayClick}
              tileDisabled={({ date }) => date.getDay() === 0 || date.getDay() === 2 || date.getDay() === 3 || date.getDay() === 4 || date.getDay() === 5 || date.getDay() === 6}
            />
          </div>
        </IonContent>
      </IonPopover>
      <div className='my-4 flex justify-between'>
        <button onClick={() => slidePrev()} className='w-10 h-6 flex items-center justify-center focus:outline-none'><IonIcon
          src={`/assets/icon/ic-left-grey.svg`}
          class=""
        ></IonIcon></button>
        <div className='text-gray-900 font-medium text-sm flex items-center'><IonIcon
          src={`/assets/icon/icon-calendar.svg`}
          class="mr-1"
        ></IonIcon>
          <div
            onClick={(e: any) => {
              e.persist();
              setShowPopover({ showPopover: true, event: e });
            }}
          >
            <DateCard
              getDay={true}
              getDayFull={true}
              getMonthFull={true}
              getYear={false}
              date={startTime}
              className="text-sm text-gray-55 dark:text-gray-15"
            />
          </div>
        </div>
        <button onClick={() => slideNext()} className='w-10 h-6 flex items-center justify-center focus:outline-none'><IonIcon
          src={`/assets/icon/ic-right-grey.svg`}
          class=""
        ></IonIcon></button>
      </div>
      <div className='bg-white rounded-2xl text-gray-800 p-6 mb-6'>
        <div className='text-base font-bold'>{t('dashboard.waste.title')}</div>
        {/*<div className='text-gray-600 text-xs mb-6'>Komunala Ljubljana</div>*/}
        {data.map((item: any, index: any) => (
          <WasteCard
            key={index}
            date={moment(item.collectionDate).date().toString()}
            day={item.collectionDateWeekName}
            active={item.collectionDate === (moment(new Date()).format("YYYY-MM-DD") + "T00:00:00") ? true : false}
            weekend={(item.collectionDateWeekName === "Saturday") || (item.collectionDateWeekName === "Sunday") ? true : false}
            waste={item.typeList}
          />
        ))}

      </div>
    </div>
  );
};
export default CommunalTab;
