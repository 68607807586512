import { Api } from '../api'; 

export type ClientPointActions =
  | { type: 'FETCH' }
  | { type: 'REJECT' }
  | { type: 'CANCEL' }
  | { type: 'RESOLVE' };

  export const defaultClientPointState = { status: 'idle' };

export type ClientPointState = {
  status: string;
};


export const deviceToken = async (
  //id?: number,
  token?: string,

): Promise<any> => { 
  const api = new Api();

  try {
    const response = await api.request('POST', `app/account/set-device-token?deviceToken=${token}`, true, { 
      //id,
      token,
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};