import {
  IonContent,
  IonPage,
  IonButton,
  IonRouterLink,
  IonToolbar,
  IonFooter,
  IonItem,
  IonLabel,
  IonCheckbox,
  useIonViewWillEnter,
  useIonViewDidEnter,
  IonIcon,
  IonModal,
} from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import { Redirect, useHistory } from 'react-router';
import { useState} from 'react';
import Steps from './../../components/Steps';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';
import { checkEmail } from '../../data/user/check-email.api';
import {
  getStorage,
  setStorage,
  removeStorage,
} from '../../data/user/register.api';
import { registerOnlyUser } from '../../data/user/registeronlyuser.api';
import HeaderLogoNotLoggedIn from '../../components/HeaderLogoNotLoggedIn';
import { useIonRouter } from '@ionic/react';
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';
import { deviceToken } from '../../data/user/device-token'
import { setPushNotifications } from '../../data/user/set-push-notifications';

type FormErrors = {
  email: string[] | undefined;
  password: string[] | undefined;
  confirmpassword: string[] | undefined;
};

const Register: React.FC = () => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<FormErrors>({
    email: undefined,
    password: undefined,
    confirmpassword: undefined,
  });
  const [errorNumber, setErrorNumber] = useState('');
  const [errorLower, setErrorLower] = useState('');
  const [errorUpper, setErrorUpper] = useState('');
  const [errorAlpgabetic, setErrorAlpgabetic] = useState('');
  const [errorSixChar, setErrorSixChar] = useState('');
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pushChecked, setPushChecked] = useState(false);
  const [buildingId, setBuildingId] = useState('');
  const [buildingAddressId, setBuildingAddressId] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [buildingType, setBuildingType] = useState('');
  const [provider, setProvider] = useState('');
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [place, setPlace] = useState('');
  const [checked, setChecked] = useState(false);
  const [classDanger, setClassDanger] = useState<string>('');
  const username = email;
  const [errorRegistrationPassword, seterrorRegistrationPassword] =
    useState<boolean>(false);
  const [errorRegistrationEmail, seterrorRegistrationEmail] =
    useState<boolean>(false);
  const [errorEmailNotValid, seterrorEmailNotValid] =
    useState<boolean>(false);
  const [successRegistration, setsuccessRegistration] =
    useState<boolean>(false);
  const [errorRegistration, seterrorRegistration] = useState<boolean>(false);
  const [errorMessageFromApi, seterrorMessageFromApi] = useState('');
  const ionRouter = useIonRouter();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  // The regular exprssion to validate the email pattern
  // It may not be 100% perfect but can catch most email pattern errors and assures that the form is mostly right
  const emailRegex = /\S+@\S+\.\S+/;
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,25}$/;
  const passwordOneNumber = /^(?=.*[0-9])/;
  const passwordOneLowerCase = /^(?=.*[a-z])/;
  const passwordOneUpperCase = /^(?=.*[A-Z])/;
  const passwordAlphabetic = /^(?=.*[^a-zA-Z0-9])(?!.*\s)/;
  const passwordSixChar = /.{6,25}$/;

  const appName = 'eMRDashboard_App';

  const user = [
    username,
    email,
    password,
    buildingId,
    buildingAddressId,
    buildingName,
    buildingType,
    provider,
    locationCode,
    clientCode,
    place,
  ];

  useIonViewWillEnter(() => { //useEffect
    getStorage('user').then((value) => {
      setEmail(value[0] ?? '');
      setPassword(value[2] ?? '');
      setConfirmpassword(value[2] ?? '');
      setBuildingId(value[3]);
      setBuildingAddressId(value[4]);
      setBuildingName(value[5]);
      setBuildingType(value[6]);
      setProvider(value[7]);
      setLocationCode(value[8]);
      setclientCode(value[9]);
      setPlace(value[10]);
      seterrorRegistrationEmail(false);
      seterrorEmailNotValid(false);
      seterrorRegistrationPassword(false);
      setChecked(false)
      setClassDanger('')
    });
    setStorage('user', user);
    setsuccessRegistration(false);
    seterrorRegistration(true);
  }, []);

  const handleNextStep = () => {
    //if (email != '') {
    checkEmail(email)
      .then((response: any) => {
        // success
        //check if email exist in database
        if (response == true) {
          seterrorRegistrationEmail(true);
          setErrors({
            ...errors,
            email: ['Email is required'],
          });
        } else {
          seterrorRegistrationEmail(false);
          setErrors({
            ...errors,
            email: undefined,
          });
          // Check if input fields are correct
          if (!emailRegex.test(email) || email === '') {
            seterrorEmailNotValid(true);
            setErrors({
              ...errors,
              email: ['Email is required'],
            });
            return;
          } else if (
            password === '' ||
            !password.match(passwordRegex) ||
            confirmpassword === '' ||
            password !== confirmpassword
          ) {
            setErrors({
              ...errors,
              password: ['Password is required'],
              confirmpassword: ['Confirm password is required'],
            });
            seterrorRegistrationPassword(true);
            return;
          } else if (!checked) {
            setClassDanger("text-red-5")
            return;
          } else {
            seterrorRegistrationPassword(false);
            //Set user data to local storage
            setStorage('user', user);
            //history.push('/auth/register-step-1-a');

            registerOnlyUser(
              username,
              email,
              password,
              appName,
              pushChecked
            )
              .then(async (response: any) => {
                // on successful user response, if he has selected that he wants to receive notifications
                // we show the notification dialog
                // if he accpets we register the device

                // Store notification preferences online, so we can retrieve them
                // on every login we can try and register (for new devices, new installs etc)
                if (pushChecked && Capacitor.isNativePlatform()) {
                  await PushNotifications.requestPermissions();
                  await PushNotifications.register();
                  FCM.subscribeTo({ topic: 'general' }).catch((err) =>
                    console.log(err)
                  );
                  PushNotifications.addListener('registration', (token) => {
                    console.log('Push registration success, token: ' + token, token.value);

                    //Add user token:
                    deviceToken(
                      token.value
                    )
                      .then((response) => {
                        // success
                        console.log(response);
                        console.log("Token updated");
                      })
                      .catch((error) => {
                        // failure
                        console.log(error);
                        console.log("Token not updated");
                      });
                    //Set push in database user
                    setPushNotifications(
                      true
                    )
                      .then((response) => {
                        // success
                        console.log(response);
                      })
                      .catch((error) => {
                        // failure
                        console.log(error);
                      });

                  });
                }

                // success
                setsuccessRegistration(true);
                seterrorRegistration(false);
                //Remove user data from storage
                await removeStorage('user');
                //console.log(response);
              })
              .catch((error) => {
                // failure
                setsuccessRegistration(false);
                seterrorRegistration(true);
                seterrorMessageFromApi(error.response.error.message);
                console.log(error);
              });
          }
        }
      })
      .catch((error) => {
        // failure
        console.log(error);
      });
    //}

  };

  //Handle email input:
  const handleInputEmailChange = (e: any) => {
    /* if (e.target.value !== '' && emailRegex.test(email)) {
       setErrors({
         ...errors,
         email: undefined,
       });
       seterrorRegistrationEmail(false);
     } else {
       setErrors({
         ...errors,
         email: ['Email is required'],
       });
     }*/
    setErrors({
      ...errors,
      email: undefined,
    });
    seterrorRegistrationEmail(false);
    seterrorEmailNotValid(false);
    setEmail(e.target.value);
  };

  //Handle password input:
  const handleInputPasswordChange = (e: any) => {
    //Validate if password have six characters
    if (!e.target.value.match(passwordSixChar)) {
      setErrorSixChar(t('auth.register.errorMessagePasswordSixChar'));
      seterrorRegistrationPassword(true);
    } else {
      setErrorSixChar('');
    }
    //Validate if password have one lowercase
    if (!e.target.value.match(passwordOneLowerCase)) {
      setErrorLower(t('auth.register.errorMessagePasswordLowercase'));
      seterrorRegistrationPassword(true);
    } else {
      setErrorLower('');
    }
    //Validate if password have one uppercase
    if (!e.target.value.match(passwordOneUpperCase)) {
      setErrorUpper(t('auth.register.errorMessagePasswordUppercase'));
      seterrorRegistrationPassword(true);
    } else {
      setErrorUpper('');
    }
    //Validate if password have one number
    if (!e.target.value.match(passwordOneNumber)) {
      setErrorNumber(t('auth.register.errorMessagePasswordNumber'));
      seterrorRegistrationPassword(true);
    } else {
      setErrorNumber('');
    }
    //Validate if password have one alphabetic
    if (!e.target.value.match(passwordAlphabetic)) {
      setErrorAlpgabetic(t('auth.register.errorMessagePasswordAlpgabetic'));
      seterrorRegistrationPassword(true);
    } else {
      setErrorAlpgabetic('');
    }
    //Validate password with all characters
    if (e.target.value !== '' && e.target.value.match(passwordRegex)) {
      setErrors({
        ...errors,
        password: undefined,
      });
      seterrorRegistrationPassword(false);
    } else {
      setErrors({
        ...errors,
        password: ['Password is required'],
      });
      seterrorRegistrationPassword(true);
    }

    setPassword(e.target.value);
  };

  //Handle confirm password input:
  const handleInputConfirmPasswordChange = (e: any) => {
    if (e.target.value !== '' && password === e.target.value) {
      setErrors({
        ...errors,
        password: undefined,
        confirmpassword: undefined,
      });
    } else {
      setErrors({
        ...errors,
        confirmpassword: ['Confirm password is required'],
      });
    }
    setConfirmpassword(e.target.value);
  };

  //Handle terms checkbox:
  const handleTermsAgree = (e: any) => {
    if (checked == true) {
      setClassDanger("text-red-5")
    } else {
      setClassDanger("")
    }
    setChecked(e.detail.checked)
  };

  //Handle terms checkbox:
  const handleNotificationsCheckbox = (e: any) => {
    setPushChecked(e.detail.checked)
  };



   //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/auth/login")
    });
  });

  /** Show hide password */
  const handleShowPassword = (e: any) => {
    setShowPassword(!showPassword)
  }
  /** Show hide confirm password */
  const handleShowConfirmPassword = (e: any) => {
    setShowConfirmPassword(!showConfirmPassword)
  }


  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="px-4 pt-16 pb-10 bg-gradient-to-r from-blue-5 to-blue-25 dark:from-gray-900 dark:to-gray-700 -mb-4">
          <HeaderLogoNotLoggedIn />
          <div className="text-3xl font-bold text-white border-none bg-transparent">
            {t('auth.register.title')}
          </div>
        </div>
        <div className="flex justify-center p-4 pt-8 bg-white dark:bg-gray-900 rounded-t-2xl">
          <div className="w-full">
            {successRegistration !== true ? (
              <>
                <Steps
                  title={t('auth.register.stepTitle')}
                  currentStep="1"
                  divider={t('general.of')}
                  totalStep="1"
                />
                <div className="py-2">
                  <Input
                    type="email"
                    label={t('auth.register.emailPlaceholder')}
                    error={errors.email}
                    value={email}
                    onChange={handleInputEmailChange}
                  />
                </div>
                <div
                  className={`text-red-5 text-center ${errorRegistrationEmail !== true && 'hidden'
                    }`}
                >
                  {t('auth.register.errorMessageEmail')}
                </div>
                <div
                  className={`text-red-5 text-center ${errorEmailNotValid !== true && 'hidden'
                    }`}
                >
                  {t('auth.register.errorMessageEmailNotValid')}
                </div>
                <div className="py-2 relative">
                  <Input
                    type={showPassword ? "text" : "password"}
                    label={t('auth.register.passwordPlaceholder')}
                    error={errors.password}
                    value={password}
                    onChange={handleInputPasswordChange}
                  />
                  <IonIcon onClick={handleShowPassword} class="ios hydrated absolute right-0 top-0 z-10 mt-6 mr-2 text-gray-20 dark:text-white" icon={showPassword ? eyeOutline : eyeOffOutline} />
                </div>
                <div className="py-2 relative">
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    label={t('auth.register.confirmPasswordPlaceholder')}
                    error={errors.confirmpassword}
                    value={confirmpassword}
                    onChange={handleInputConfirmPasswordChange}
                  />
                  <IonIcon onClick={handleShowConfirmPassword} class="ios hydrated absolute right-0 top-0 z-10 mt-6 mr-2 text-gray-20 dark:text-white" icon={showConfirmPassword ? eyeOutline : eyeOffOutline} />
                </div>
                {(errors.password || errors.confirmpassword) && (
                  <div className='text-red-5 text-center'>{t('auth.register.errorPassword')}</div>
                )}
                <div className="pt-2">
                  {<IonItem lines="none" key="checkbox-notifications" className={`text-sm text-gray-55 dark:text-white -ml-3 flex items-center`}>
                    <IonCheckbox className="" border-color="danger" mode="md" checked={pushChecked} onIonChange={handleNotificationsCheckbox} />
                    <IonLabel style={{ whiteSpace: "normal" }} className="ml-2 ">{t('auth.register.notifications')}</IonLabel>
                  </IonItem>}
                </div>
                <div className="pb-2">
                  {<IonItem lines="none" key="checkbox-agree" className={`${classDanger} text-sm text-gray-55 dark:text-white -ml-3 flex items-center`}>
                    <IonCheckbox className="" border-color="danger" mode="md" checked={checked} onIonChange={handleTermsAgree} />
                    <IonLabel style={{ whiteSpace: "normal" }} className="ml-2 ">{t('auth.register.termsAgree')} <a className="underline text-blue-40 font-bold" target="_blank" href={`${t('auth.register.termsAgreeLinkUrl')}`}>{t('auth.register.termsAgreeLinkTitle')}</a>, <a className="underline text-blue-40 font-bold" target="_blank" href={`${process.env.REACT_APP_API_URL}${t('auth.register.privacyLinkUrl')}`}>{t('auth.register.privacyLinkTitle')}</a> {t('auth.register.termsAgreeAnd')} <a className="underline text-blue-40 font-bold" target="_blank" href={`${process.env.REACT_APP_API_URL}${t('auth.register.eulaAgreeLinkUrl')}`}>{t('auth.register.eulaLinkTitle')}</a>.</IonLabel>
                  </IonItem>}
                </div>
                {(classDanger) && (
                  <div className='text-red-5 text-center'>{t('auth.register.errorTerms')}</div>
                )}
                <div
                  className={`text-red-5 mt-6 text-center ${errorRegistrationPassword !== true && 'hidden'
                    }`}
                >
                  {errorNumber} {errorLower} {errorUpper} {errorAlpgabetic} {errorSixChar}

                </div>
                <IonButton
                  onClick={handleNextStep}
                  color="blue"
                  className="w-full h-12 mt-10 text-base font-semibold normal-case"
                >
                  {t('auth.login.register')}
                </IonButton>
                <div className="mt-4 text-sm text-center text-gray-55 dark:text-white">
                  <div>
                    {t('auth.register.alreadyhaveAccount')}{' '}
                    <IonRouterLink className="font-bold text-blue-40" routerLink="/auth/login">
                      {t('auth.register.signIn')}
                    </IonRouterLink>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className={`text-gray-700 dark:text-white mt-6 text-sm`}
                >
                  <div className='block text-gray-800 dark:text-white font-semibold mb-4 text-xl w-56 text-left'>{t('auth.register.thankYouTitle')}</div>
                  {t('auth.register.thankYouMessage')}{' '}
                  <IonRouterLink
                    className="underline text-blue-40"
                    routerLink="/auth/login"
                  >
                    {t('auth.register.thankYouMessageLinkText')}.
                  </IonRouterLink>
                </div>
                <IonRouterLink
                  className="block text-white bg-blue-40 text-base font-bold no-underline w-full mt-10 text-center py-3 rounded-md"
                  routerLink="/auth/login"
                >
                  {t('auth.register.close')}
                </IonRouterLink>
              </>
            )}

            <div
              className={`text-red-5 mt-6 text-center ${errorRegistration !== true && 'hidden'
                }`}
            >
            </div>

          </div>
        </div>
      </IonContent>



      {/*<IonFooter mode="ios" className="p-4 bg-white dark:bg-gray-900 ion-no-border">
        <IonToolbar>
          <div className="text-sm text-center text-gray-55">
            <IonRouterLink
              className="font-bold text-gray-50"
              routerLink="/provider"
            >
              {t('auth.login.areYouProvider')}
            </IonRouterLink>
          </div>
        </IonToolbar>
              </IonFooter>*/}
    </IonPage>
  );
};

export default Register;
