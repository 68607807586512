import { Api } from '../api';

export const checkEmail = async (
  email: string,
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('POST', `app/account/email-exists?email=${email}`, false, {
    });
    return response;
  } catch (error) {
    throw error;
  }
};