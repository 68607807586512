import { IonIcon } from "@ionic/react";

interface ContainerProps {
  title?: string;
  address?: string;
  mapLat?: string;
  mapLong?: string;
  type?: string; //HOME, SKYSCRAPER, HOMEMULTIPLE
}

const UnitCard: React.FC<ContainerProps> = ({
  title,
  address,
  type,
  mapLat,
  mapLong
}) => {

  const ClassHome = 'from-blue-5 to-blue-25';
  const ClassHomeMultiple = 'from-green-15 to-green-20';
  const ClassSkyscraper = 'from-orange-5 to-orange-10';
  return (
    <div className="flex items-center justify-between mb-4">
      {/*<div className="mr-4 rounded-md">
        mapLat ? (
          <iframe className="rounded-md" src={`https://maps.google.com/maps?q=${mapLat}, ${mapLong}&z=15&output=embed`} width="79" height="79" frameBorder="0" allowFullScreen></iframe>
        ) : (
          <img src="/assets/google-map-sample.png" />
        )
      </div>*/}
      <div className="w-full">
        <div className="text-gray-55 dark:text-white font-bold text-base flex items-center">
          <div
            className={`shadow-md rounded-md flex items-center justify-center w-12 h-12 mr-2 bg-gradient-to-tr ${type === 'HOME' && ClassHome} 
                  ${type === 'SKYSCRAPER' && ClassSkyscraper} ${type === 'HOMEMULTIPLE' && ClassHomeMultiple} `}
          >
            {type === 'HOME' && (
              <IonIcon
                src="/assets/icon/ic-house-white.svg"
                class="ios hydrated text-2xl"
              ></IonIcon>
            )}
            {type === 'SKYSCRAPER' && (
              <IonIcon
                src="/assets/icon/ic-union-white.svg"
                class="ios hydrated text-2xl"
              ></IonIcon>
            )}
            {type === 'HOMEMULTIPLE' && (
              <IonIcon
                src="/assets/icon/ic-business-white.svg"
                class="ios hydrated text-2xl"
              ></IonIcon>
            )}
          </div>
          <div className="w-10/12">
            <div>{title}</div>
            <div className="mt-1 text-gray-50 text-sm">{address}</div>
          </div>

        </div>

      </div>

    </div>
  );
};
export default UnitCard;
