import { IonRouterLink } from "@ionic/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  newItems: number;
  date?: string;
  title?: string;
  description?: string;
}



const NotifiyTooltip: React.FC<ContainerProps> = ({
  newItems,
  date,
  title,
  description
}) => {
  const { t } = useTranslation();
  const [active, setaActive] = useState(true);
  const [newItem, setNewItem] = useState(true);


  return (
    <>
      <button
        onClick={() => setaActive(true)}
        className="absolute right-0 top-0 mr-9 mt-1 z-40"
      >
        {newItems > 0 && (<div className="absolute top-0 right-0 text-white bg-red-5 w-5 h-5 rounded-full flex items-center justify-center text-xs -mr-2 -mt-1">{newItems}</div>)}
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M12 17.8476C17.6392 17.8476 20.2481 17.1242 20.5 14.2205C20.5 11.3188 18.6812 11.5054 18.6812 7.94511C18.6812 5.16414 16.0452 2 12 2C7.95477 2 5.31885 5.16414 5.31885 7.94511C5.31885 11.5054 3.5 11.3188 3.5 14.2205C3.75295 17.1352 6.36177 17.8476 12 17.8476Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M14.3889 20.8572C13.0247 22.3719 10.8967 22.3899 9.51953 20.8572" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </button>
      {active && (
        <div className='w-full absolute z-50 p-4 right-0 top-0 mt-12 bg-whiteTransparent dark:bg-blackTransparent rounded-lg'>
          <svg className='hide-dark absolute top-0 right-0 tooltip-popup mr-8' width="28" height="14" viewBox="0 0 28 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7544 0L0 14H28L13.7544 0Z" fill="white" fillOpacity="0.5" />
          </svg>
          <svg className='show-dark absolute top-0 right-0 tooltip-popup mr-8' width="28" height="14" viewBox="0 0 28 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7544 0L0 14H28L13.7544 0Z" fill="black" fillOpacity="0.75" />
          </svg>
          <button className='absolute right-0 top-0 mt-3 mr-3 w-5 h-5'
            onClick={() => setaActive(false)}
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.9968 4.99487L5.00342 14.9883" stroke="#718096" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15 14.9947L4.99829 4.99084" stroke="#718096" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </button>
          <IonRouterLink
            routerLink="/notifications">
            <div className='uppercase text-3xs text-gray-700 dark:text-white font-bold mb-2'>{t('dashboard.lastNotice')}</div>
            <div className='text-xs text-gray-700 dark:text-white mb-1 flex items-center'>{newItems > 0 && (<span className='inline-block w-3 h-3 bg-red-5 rounded-full mr-2'></span>)}{moment(date).date() + "-" + (moment(date).month() + 1) + "-" + moment(date).year()}<span className="ml-2">{title}</span></div>
            <div className='text-sm text-gray-800 dark:text-white font-bold' dangerouslySetInnerHTML={{ __html: description ? description?.slice(0, 180) : "" }}></div>
          </IonRouterLink>
        </div>
      )}
    </>
  );
};
export default NotifiyTooltip;
