import {
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonButton,
  IonToolbar,
  IonFooter,
  IonList,
  IonRadioGroup,
  IonRadio,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { setStorage } from '../../data/user/register.api';
import UnitCard from '../../components/UnitCard';
import StepsHeaderWithBack from '../../components/StepsHeaderWithBack';
import { getClientBuildingSingle } from '../../data/client-points/clientBuildingSingle.api';
import Loader from '../../components/Loader';
import { getProvidersByAddress } from '../../data/client-points/providersbyaddress.api';
import { Preferences } from '@capacitor/preferences';

type FormErrors = {
  provider: string[] | undefined;
};

type ProvidersData = {
  id: number;
  name: string;
  utilityTypeId: number;
  logo: string;
};

type ClientBuildingData = {
  address: string;
  clientBuildingId: number;
  description: string;
  gursAddressId: number;
  gursBuildingId: number;
  hasApartments: boolean;
  hasBills: boolean;
  hasBuildingParts: boolean;
  hasDocuments: boolean;
  gpsLat: string;
  gpsLon: string;
};

type BuildingPartData = {
  actualUse: string;
  clientBuildingId: number;
  clientBuildingPartId: number;
  description: string;
  gursBuildingPartId: number;
  partNumber: number;
  roomNumber: number;
  userId: string;
};

const AddPlacePageStep1: React.FC = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [provider, setProvider] = useState("");
  const [providerClass, setProviderClass] = useState("");
  const [errors, setErrors] = useState<FormErrors>({
    provider: undefined,
  });
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [place, setPlace] = useState('');
  const [buildingData, setBuildingData] = useState<ClientBuildingData>();
  const [buildingPartData, setBuildingPartData] = useState<BuildingPartData>();
  const [Providers, setProviders] = useState<ProvidersData[]>([]);
  const [foundProviders, setFoundProviders] = useState(Providers);
  const [name, setName] = useState('');
  const [dataLoad, setDataLoad] = useState<boolean>(false);

  const placeData = [provider, locationCode, clientCode, place];

  const providerSelected = provider ? provider : "";

  useIonViewWillEnter(() => {
    //Get local stored user data
    Preferences.get({ key: 'place' }).then((storage: any) => {
      const getPlaceData = JSON.parse(storage.value || '{}');
      //setProvider(getPlaceData[0]);
      setLocationCode(getPlaceData[1]);
      setclientCode(getPlaceData[2]);
      setPlace(getPlaceData[3]);
      //console.log(getPlaceData);
      //Clear data
      setProvider("");
      setProviderClass("");
    });


    //console.log(props.match.params.buildingid);



  });

  //console.log(buildingData?.gursAddressId);

  useIonViewWillEnter(() => {
    setDataLoad(false);
  }, [props]);

  useIonViewDidEnter(() => {
    //Get single building
    if (props.match.params.buildingid) {
      onLoad(props.match.params.buildingid);
    }

  }, [props.match.params.buildingid, props.match.params.partid]);

  const handleNextStep = () => {
    // Chek if selected fields are correct
    if (provider && provider !== null) {
      //Set place data to local storage
      setStorage("place", placeData);
      history.push(`/dashboard/add-place-step-2/clientbuildingid/${props.match.params.buildingid}/clientbuildingpartid/${props.match.params.partid}`);
    } else {
      setErrors({
        ...errors,
        provider: [t('dashboard.reportIssue.errorTextArea')],
      });
      return;
    }

  };

  //On Load
  const onLoad = (id: any) => {
    let isMounted = true; // track whether component is mounted 
    //console.log(id)
    //Get single building
    getClientBuildingSingle({ id })
      .then((response) => {
        if (isMounted) {
          //console.log(response);

          //Get providers by address
          getProvidersByAddress(
            response.gursAddressId
          )
            .then((response) => {
              setProviders(response);
              setFoundProviders(response);
              //console.log(response);
            })
            .catch((error) => console.log(error));

          setBuildingData(response);
          const buildingParts = response.partsList.filter((item: any) => item.clientBuildingPartId === (Number(props.match.params.partid))).map((items: BuildingPartData) => {
            return {
              ...items,
            }
          });
          setBuildingPartData(buildingParts[0]);
          //Data loaded
          setDataLoad(true)
        }
      })
      .catch((error) => console.log(error));

    return () => {
      // clean up
      isMounted = false;
    };

  };




  //Check if provider is selected 
  const onIonChange = (e: any) => {
    if (e.target.value !== null) {
      setErrors({
        ...errors,
        provider: undefined,
      });


    } else {
      setErrors({
        ...errors,
        provider: [t('dashboard.reportIssue.errorTextArea')],
      });
    }
    setProvider(e.detail.value);
    setProviderClass(e.detail.value);

    setStorage("place", [e.detail.value, locationCode, clientCode, place]);
    //history.push(`/dashboard/add-place-step-2/clientbuildingid/${props.match.params.buildingid}/clientbuildingpartid/${props.match.params.partid}`);
  }

  //Filter providers
  const filter = (e: any) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = Providers.filter((provider) => {
        return provider.name.toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundProviders(results);
    } else {
      setFoundProviders(Providers);
      // If the text field is empty, show all users
    }
    setName(keyword);
  };

  //Clear input
  const clearInput = (e: any) => {
    setName('');
  };

  //console.log(props.match.params.partid)

  //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/dashboardmain/")
    });
  });

  return (
    <IonPage>
      {/*<Header rightIcon={false} title={t('dashboard.addPlace.title')} backLink={`/dashboardmain/client-building/${props.match.params.buildingid}/part/${props.match.params.partid}`} titleColor="text-black-5 text-center" />*/}
      <IonContent fullscreen>
        <div className="h-full p-4 flex pt-8 justify-center rounded-t-2xl bg-white dark:bg-gray-900">

          {dataLoad ? (

            <div className="w-full mb-5">
              <StepsHeaderWithBack title={t('auth.register.step4TitleProvider')} backLink={`/dashboardmain/client-building/${buildingData?.clientBuildingId}/part/${buildingPartData?.clientBuildingPartId}`} />
              <UnitCard
                title={props.match.params.partid === "0" ? buildingData?.description : buildingPartData?.actualUse}
                address={`${buildingData?.address}`}
                type={buildingData?.hasApartments ? "SKYSCRAPER" : buildingData?.hasBuildingParts ? "HOMEMULTIPLE" : "HOME"}
                mapLat={buildingData?.gpsLat.toString()}
                mapLong={buildingData?.gpsLon.toString()}
              />

              {/*<div className="py-4">
                <InputSearch
                  type="text"
                  label={t('auth.register.searchAmongProviders')}
                  value={name}
                  onChange={filter}
                  noRightIcon={true}
                  onClear={clearInput}
                />
              </div>*/}

              <div className="mt-10 calc-height-10 overflow-auto">
                <IonList mode="md">
                  <IonRadioGroup value={provider} onIonChange={onIonChange}>
                    {foundProviders && foundProviders.length > 0 ? (
                      foundProviders.map((provider) => (
                        <IonItem key={provider.id} lines="none" className={Number(providerClass) === provider.id ? "opacity-50 border-b" : "border-b"}>
                          <IonLabel><div className="flex items-center"><img className="mr-6" width="44" height="44" src={provider.logo ? `data:image/jpeg;base64, ${provider.logo}` : '/assets/no-image.jpg'} />{provider.name}</div></IonLabel>
                          <IonRadio className="opacity-0 w-0 h-0 -ml-10" slot="start" value={provider.id} />
                        </IonItem>
                      ))
                    ) : (
                      <div className="text-gray-50 text-center mt-10">
                        {/*<div className="font-bold text-xl mt-2">{t('auth.register.noResults')} "{name}"</div>
                        <div className="text-sm px-8">{t('auth.register.step2Text')} <IonRouterLink className="font-bold ml-1 text-blue-15" routerLink="/auth/register-provider">{t('general.here')}</IonRouterLink>.</div>*/}
                      </div>
                    )}
                  </IonRadioGroup>
                </IonList>
              </div>

            </div>

          ) : (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
              <Loader />
            </div>
          )}


        </div>


      </IonContent>

      {<IonFooter mode="ios" className="ion-no-border p-4">
        <IonToolbar>
          {errors.provider && (
            <div className='text-red-500 text-center mb-2 text-sm'>{errors.provider}</div>
          )}
          <IonButton onClick={handleNextStep} color={(provider && provider !== null) ? "blue" : "graybgbut"} className="w-full normal-case font-semibold h-12 text-base"><span className={`${(provider && provider !== null) ? "text-white" : "dark:text-gray-900 text-gray-400"}`}>{t('auth.register.cta')}</span></IonButton>
        </IonToolbar>
      </IonFooter>}

    </IonPage>
  );
}

export default AddPlacePageStep1;
