import { Api } from '../api'; 

export type ClientPointActions =
  | { type: 'FETCH' }
  | { type: 'REJECT' }
  | { type: 'CANCEL' }
  | { type: 'RESOLVE' };

  export const defaultClientPointState = { status: 'idle' };

export type ClientPointState = {
  status: string;
};


export const editClientPoint = async (
  id?: number,
  creationTime?: string,
  creatorId?: string,
  lastModificationTime?: string,
  lastModifierId?: string,
  isDeleted?: boolean,
  deleterId?: string,
  deletionTime?: string,
  code?: string,
  customerCode?: string,
  externalId?: string,
  name?: string,
  address?: string, 
  description?: string,
  utilityId?: number,
  userId?: string,
  clientPointTypeId?: number
): Promise<any> => { 
  const api = new Api();

  try {
    const response = await api.request('PUT', 'app/end-user/client-point', true, { 
      id,
      creationTime,
      creatorId,
      lastModificationTime,
      lastModifierId,
      isDeleted,
      deleterId,
      deletionTime,
      code,
      customerCode,
      externalId,
      name,
      address,
      description,
      utilityId,
      userId,
      clientPointTypeId
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};