import {
    IonContent,
    IonPopover,
} from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
interface ContainerProps {
    consumptionStatus?: number;
}

const ConsumptionCard: React.FC<ContainerProps> = ({
    consumptionStatus
}) => {

    const { t } = useTranslation();

    const [popoverStateCard, setShowPopoverCard] = useState({
        showPopoverCard: 0,
        event: undefined,
    });

    return (
        <>
            {consumptionStatus === 1 && (
                <>
                    <div onClick={(e: any) => {
                        e.persist();
                        setShowPopoverCard({ showPopoverCard: 1, event: e });
                    }}
                        className='text-xs font-medium inline-flex items-center justify-center px-3 h-5 rounded-full text-red-900 bg-red-100'>
                        <div style={{ width: "6px", height: "6px" }} className='rounded-full bg-red-400 mr-2'></div>{t('dashboard.consumptionWasteful')}
                    </div>
                    <IonPopover
                        show-backdrop={false}
                        event={popoverStateCard.event}
                        isOpen={popoverStateCard.showPopoverCard == 1 && true}
                        onDidDismiss={() => setShowPopoverCard({ showPopoverCard: 0, event: undefined })}
                    >
                        <IonContent>
                            <div className='p-4 bg-gray-800 text-white text-2xs'>
                                <div className='font-bold mb-1 text-xs'>{t('dashboard.consumptionWasteful')}</div>
                                <div>{t('dashboard.consumptionWastefulDesc')}</div>
                            </div>
                        </IonContent>
                    </IonPopover>
                </>
            )}

            {consumptionStatus === 2 && (
                <>
                    <div
                        onClick={(e: any) => {
                            e.persist();
                            setShowPopoverCard({ showPopoverCard: 2, event: e });
                        }}
                        className='text-xs font-medium inline-flex items-center justify-center px-3 h-5 rounded-full text-yellow-900 bg-yellow-100'>
                        <div style={{ width: "6px", height: "6px" }} className='rounded-full bg-yellow-400 mr-2'></div>{t('dashboard.consumptionAvarage')}
                    </div>
                    <IonPopover
                        show-backdrop={false}
                        event={popoverStateCard.event}
                        isOpen={popoverStateCard.showPopoverCard == 2 && true}
                        onDidDismiss={() => setShowPopoverCard({ showPopoverCard: 0, event: undefined })}
                    >
                        <IonContent>
                            <div className='p-4 bg-gray-800 text-white text-2xs'>
                                <div className='font-bold mb-1 text-xs'>{t('dashboard.consumptionAvarage')}</div>
                                <div>{t('dashboard.consumptionAvarageDesc')}</div>
                            </div>
                        </IonContent>
                    </IonPopover>
                </>
            )}

            {consumptionStatus === 3 && (
                <>
                    <div
                        onClick={(e: any) => {
                            e.persist();
                            setShowPopoverCard({ showPopoverCard: 3, event: e });
                        }}
                        className='text-xs font-medium inline-flex items-center justify-center px-3 h-5 rounded-full text-green-900 bg-green-100'>
                        <div style={{ width: "6px", height: "6px" }} className='rounded-full bg-green-400 mr-2'></div>{t('dashboard.consumptionFrugal')}
                    </div>
                    <IonPopover
                        show-backdrop={false}
                        event={popoverStateCard.event}
                        isOpen={popoverStateCard.showPopoverCard == 3 && true}
                        onDidDismiss={() => setShowPopoverCard({ showPopoverCard: 0, event: undefined })}
                    >
                        <IonContent>
                            <div className='p-4 bg-gray-800 text-white text-2xs'>
                                <div className='font-bold mb-1 text-xs'>{t('dashboard.consumptionFrugal')}</div>
                                <div>{t('dashboard.consumptionFrugalDesc')}</div>
                            </div>
                        </IonContent>
                    </IonPopover>
                </>
            )}

        </>
    );
};
export default ConsumptionCard;




