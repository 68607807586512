import { Api } from '../api'; 

export type ClientPointActions =
  | { type: 'FETCH' }
  | { type: 'REJECT' }
  | { type: 'CANCEL' }
  | { type: 'RESOLVE' };

  export const defaultClientPointState = { status: 'idle' };

export type ClientPointState = {
  status: string;
};


export const editBuildingPart = async (
  //id?: number,
  clientBuildingPartId?: number,
  description?: string

): Promise<any> => { 
  const api = new Api();

  try {
    const response = await api.request('PUT', `app/client-building/client-building-part/${clientBuildingPartId}`, true, { 
      //id,
      clientBuildingPartId,
      description
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};