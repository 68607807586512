import { Api } from '../api';



export type GetKeyAccountsResponse = {
  token: string;
  phone: string | null;
  email: string | null;
};

export const getProviders = async (): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('GET', 'app/end-user/utilities', false); 
    return response;
  } catch (error) {
    throw error;
  }
};
