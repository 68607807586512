import { useTranslation } from 'react-i18next';
interface ContainerProps {
  className?: string;
  date: string;
  getDay?: boolean;
  getDayFull?: boolean;
  getMonth?: boolean;
  getMonthFull?: boolean;
  getYear: boolean;
}

const DateCard: React.FC<ContainerProps> = ({
  className,
  date,
  getDay,
  getMonth,
  getYear,
  getMonthFull,
  getDayFull
}) => {

  const { t } = useTranslation();

  const monthGet = new Date(date).getMonth();
  const month =
    monthGet == 0 ? "1" :
      monthGet == 1 ? "2" :
        monthGet == 2 ? "3" :
          monthGet == 3 ? "4" :
            monthGet == 4 ? "5" :
              monthGet == 5 ? "6" :
                monthGet == 6 ? "7" :
                  monthGet == 7 ? "8" :
                    monthGet == 8 ? "9" :
                      monthGet == 9 ? "10" :
                        monthGet == 10 ? "11" :
                          monthGet == 11 ? "12" : "";

  const monthGetFull = new Date(date).getMonth();
  const monthFull = monthGetFull == 0 ? t('general.months.jan') :
    monthGet == 1 ? t('general.monthsfull.feb') :
      monthGet == 2 ? t('general.monthsfull.mar') :
        monthGet == 3 ? t('general.monthsfull.apr') :
          monthGet == 4 ? t('general.monthsfull.may') :
            monthGet == 5 ? t('general.monthsfull.jun') :
              monthGet == 6 ? t('general.monthsfull.jul') :
                monthGet == 7 ? t('general.monthsfull.aug') :
                  monthGet == 8 ? t('general.monthsfull.sep') :
                    monthGet == 9 ? t('general.monthsfull.oct') :
                      monthGet == 10 ? t('general.monthsfull.nov') :
                        monthGet == 11 ? t('general.monthsfull.dec') : "";

  const dayGet = new Date(date).getDay();
  const day =
    dayGet == 1 ? t('general.daysfull.mon') :
      dayGet == 2 ? t('general.daysfull.tue') :
        dayGet == 3 ? t('general.daysfull.wed') :
          dayGet == 4 ? t('general.daysfull.thu') :
            dayGet == 5 ? t('general.daysfull.fri') :
              dayGet == 6 ? t('general.daysfull.sat') :
                dayGet == 7 ? t('general.daysfull.sun') : "";


  return (
    <div className={className}>{(
      getDayFull == true ? (day + ',') : '') + (getDay == true ? (new Date(date).getDate() + '.') : '') + (getMonth == true ? (month + '.') : '') + (getMonthFull == true ? (' ' + monthFull + '') : '') + (getYear == true ? (new Date(date).getFullYear()) : '')}</div>
  );
};
export default DateCard;