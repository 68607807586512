import { useState } from 'react';
import {
  IonContent,
  IonFooter,
  IonPage,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
import DashTabsNoIcons from '../components/DashTabsNoIcons';
import NotifiyCard from '../components/NotifiyCard';
import FooterTabs from '../components/dashboardSingle/FooterTabs';
import { getSingleArticle } from '../data/articles/getSingleArticle.api';
import moment from 'moment';

type Article = {
  articleId: number;
  dateFrom: string;
  dateTo: string;
  gursMunicipalityId: number;
  link: string;
  managerId: number;
  managerName: string;
  managerUserId: number;
  managerUserName: string;
  message: string;
  municipalityName: string;
  title: string;
  utilityId: number;
  utilityName: string;
};




const SingleNotification: React.FC = (props: any) => {
  const { t } = useTranslation();
  const [article, setArticle] = useState<Article>();

  useIonViewDidEnter(() => {
    let isMounted = true; // track whether component is mounted 

    return () => {
      //clean up
      isMounted = false;
    };
  }, []);

  useIonViewWillEnter(() => {
    let isMounted = true; // track whether component is mounted 


    //Get articles getGursMunicipalityIdForManager

    getSingleArticle({ articleId: props.match.params.id })
      .then((response) => {
        console.log(response);
        setArticle(response)
      })
      .catch((error) => console.log(error));


    return () => {
      //clean up
      isMounted = false;
    };
  }, [props.match.params.id]);


  return (
    <IonPage>
      <Header
        title={t('dashboard.notification')}
        backLink={`/notifications`}
        titleColor="text-black-5 dark:text-white text-left pl-12"
        className="bg-gray-45 dark:bg-gray-900 mt-0"
        bgColor="graybg"
      />
      <IonContent fullscreen color="graybg">

        <div className="p-8 bg-white dark:text-white dark:bg-gray-800 min-h-full">
          <div className='text-sm text-gray-600 dark:text-white mb-2 flex items-center'>{moment(article?.dateFrom).date() + "-" + (moment(article?.dateFrom).month() + 1) + "-" + moment(article?.dateFrom).year()}<span className="ml-4">{article?.utilityName}</span></div>
          <div className='text-xl text-gray-800 dark:text-white font-semibold mb-4'>{article?.title}</div>
          <div className='text-sm text-gray-600 dark:text-white single-notification' dangerouslySetInnerHTML={{ __html: article?.message ? article?.message : "" }}></div>
        </div>

      </IonContent>

      <IonFooter mode="ios" className="ion-no-border" color="graybg">
        <FooterTabs active={2} />
      </IonFooter>

    </IonPage>
  );
};

export default SingleNotification;
