import { Api } from '../api';

export type GetKeyAccountsResponse = {
  token: string;
  phone: string | null;
  newEmail: string | null;
};

export const changeEmail = async (
  newEmail: string,
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('POST', `app/account/change-email?newEmail=${newEmail}`, true, { 
    });
    return response;
  } catch (error) {
    throw error;
  }
};