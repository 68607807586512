import {
  IonContent,
  IonPage,
  IonButton,
  IonRouterLink,
  IonToolbar,
  IonFooter,
  IonIcon,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getStorage,
  setStorage,
} from '../../data/user/register.api';
import { getProviders } from '../../data/client-points/providers.api';
import UnitCard from '../../components/UnitCard';
import LocationCard from '../../components/LocationCard';
import { getBuildingSingle } from '../../data/client-points/getBuildingSingle.api';
import { getClientPoints } from '../../data/client-points/clientPoints.api';
import { getClientBuildingSingle } from '../../data/client-points/clientBuildingSingle.api';
import { searchBuildingPart } from '../../data/client-points/searchBuildingPart';
import Loader from '../../components/Loader';
import { getClientPointsForBuildingPart } from '../../data/client-points/clientPointsForBuildingPart.api';
import StepsHeaderWithBackCustom from '../../components/StepsHeaderWithBackCustom';
import { useIonRouter } from '@ionic/react';
import { Preferences } from '@capacitor/preferences';

type FormErrors = {
  locationCode: string[] | undefined;
  clientCode: string[] | undefined;
};

type BillData = {
  data: string;
  message: string;
};

type ProvidersData = {
  id: number;
  name: string;
  utilityTypeId: number;
  logo: string;
};

type SingleClientPoint = {
  clientBuildingId: number;
  clientBuildingPartId: number;
  clientPointActive: boolean;
  id: number;
  clientPointId: number;
  creationTime: string;
  creatorId: string;
  lastModificationTime: string;
  lastModifierId: string;
  isDeleted: boolean;
  deleterId: string;
  deletionTime: string;
  code: string;
  customerCode: string;
  externalId: string;
  name: string;
  address: string;
  clientPointDescription: string;
  utilityId: number;
  clientPointCode: string;
  clientPointCustomerCode: string;
  userId: string;
  clientPointTypeId: number;
  utilityTypeName: string;
  dailyReadingFrequency: boolean;
  hourlyReadingFrequency: boolean;
  monthlyReadingFrequency: boolean;
  readoutList: [
    {
      value: string;
      value2: string;
      valueTime: string;
      valueTimeWeekName: string;
      valueTimeMonthName: string;
    }
  ];
};

type ClientPoint = {
  clientPointDescription: string;
  clientPointTypeName: string;
  dailyReadingFrequency: boolean;
  utilityTypeName: 'WATER' | 'GAS' | 'ELECTRICITY' | 'HEAT';
  clientPointId: number;
  utilityName: string;
  utilityId: number;
  clientPointCode: string;
  clientPointCustomerCode: string;
  readoutList: [
    {
      value: string;
      value2: string;
      valueTime: string;
      valueTimeWeekName: string;
    }
  ];
};

type BuildingData = {
  addressId: number;
  buildingId: number;
  buildingNumber: number;
  distance: number;
  gpsLat: number;
  gpsLon: number;
  hasApartments: boolean;
  hasBuildingParts: boolean;
  houseNumber: string;
  municipality: string;
  post: string;
  settelment: string;
  street: string;
  description: string;
  address: string;
  partsList: [
    {
      clientBuildingId: number;
      clientBuildingPartId: number;
      gursBuildingPartId: number;
      userId: string;
      description: string;
      partNumber: number;
      roomNumber: number;
      actualUse: string;
    }
  ];
};

type BuildingParts = {
  clientBuildingId: number;
  clientBuildingPartId: number;
  gursBuildingPartId: number;
  userId: string;
  description: string;
  partNumber: number;
  roomNumber: number;
  actualUse: string;
};

type ClientPointForBuilding = {
  clientBuildingId: number;
  clientBuildingPartId: number;
  clientPointActive: boolean;
  clientPointAddress: string;
  clientPointCode: string;
  clientPointCustomerCode: string;
  clientPointDescription: string;
  clientPointId: number;
  clientPointName: string;
  clientPointTypeId: number;
  clientPointTypeName: string;
  dailyReadingFrequency: boolean;
  externalId: string;
  hourlyReadingFrequency: boolean;
  monthlyReadingFrequency: boolean;
  userId: string;
  utilityId: number;
  utilityName: string;
  utilityTypeName: 'WATER' | 'GAS' | 'ELECTRICITY' | 'HEAT';
  readoutList: [
    {
      isValid: boolean;
      value: string;
      value2: string;
      valueTime: string;
      valueTimeWeekName: string;
    }
  ];
};

const RegisterStep1E: React.FC = (props: any) => {
  const ionRouter = useIonRouter();
  const { t } = useTranslation();
  const history = useHistory();
  const [provider, setProvider] = useState();
  const [errors, setErrors] = useState<FormErrors>({
    locationCode: undefined,
    clientCode: undefined,
  });
  const [showModal, setShowModal] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buildingId, setBuildingId] = useState('');
  const [clientBuildingId, setClientBuildingId] = useState('');
  const [buildingAddressId, setBuildingAddressId] = useState('');
  const [hasApartments, setHasApartments] = useState(false);
  const [hasBuildingParts, setHasBuildingParts] = useState(false);
  const [buildingPartId, setBuildingPartId] = useState();
  const [buildingClientPartId, setBuildingClientPartId] = useState('');
  const [buildingPartName, setBuildingPartName] = useState('');
  const [buildingPartType, setBuildingPartType] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [buildingType, setBuildingType] = useState('');
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [place, setPlace] = useState('');
  const [unit, setUnit] = useState('');
  const [Bill, setBill] = useState<BillData>();
  const [clientPointsForBuilding, setClientPointsForBuilding] = useState<
    ClientPointForBuilding[]
  >([]);

  const [building, setBuilding] = useState();
  const [active, setActive] = useState<number>(0);

  const [clientPoint, setClientPoint] = useState<ClientPoint[]>([]);
  const [singleClientPoint, setSingleClientPoint] =
    useState<SingleClientPoint>();

  const [buildingData, setBuildingData] = useState<BuildingData>();
  const [clientBuildingData, setClientBuildingData] = useState<BuildingData>();
  const [buldingParts, setBuldingParts] = useState<BuildingParts[]>([]);
  const [Providers, setProviders] = useState<ProvidersData[]>([]);
  const [currentPartId, setCurrentPartId] = useState<ProvidersData[]>([]);
  const [dataLoad, setDataLoad] = useState<boolean>(false);

  const [name, setName] = useState('');

  const user = [
    username,
    email,
    password,
    buildingId,
    clientBuildingId,
    buildingAddressId,
    buildingName,
    buildingType,
    hasApartments,
    hasBuildingParts,
    buildingPartId,
    buildingClientPartId,
    buildingPartName,
    buildingPartType,
    provider,
    locationCode,
    clientCode,
    place,
  ];

  const handleNextStep = () => {
    //Clear storage
    setStorage('user', [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ]);
    //Redirect to dashboard
    history.push('/dashboardmain');
  };

  useIonViewWillEnter(() => {
    setDataLoad(false);
  }, [props]);

  useIonViewDidEnter(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    //Get local stored user data
    getStorage('user').then((value) => {
      setUsername(value[0]);
      setEmail(value[1]);
      setPassword(value[2]);
      setBuildingId(value[3]);
      setClientBuildingId(value[4]);
      setBuildingAddressId(value[5]);
      setBuildingName(value[6]);
      setBuildingType(value[7]);
      setHasApartments(value[8]);
      setHasBuildingParts(value[9]);
      setBuildingPartId(value[10]);
      setBuildingClientPartId(value[11]);
      setBuildingPartName(value[12]);
      setBuildingPartType(value[13]);
      setProvider(value[14]);
      setLocationCode(value[15]);
      setclientCode(value[16]);
      setPlace(value[17]);
      //console.log(value);
    });

    //Get single building
    if (props.match.params.buildingid & props.match.params.addressid) {
      onLoad(
        props.match.params.buildingid,
        props.match.params.addressid,
        props.match.params.clientbuildingid,
        props.match.params.partid,
        props.match.params.clientbuildingid
      );
    }

    //Get local stored user data
    Preferences.get({ key: 'user' }).then((storage: any) => {
      const getPlaceData = JSON.parse(storage.value || '{}');
      setProvider(getPlaceData[14]);

      if (getPlaceData[8] == null) {
        setLocationCode('');
      } else {
        setLocationCode(getPlaceData[15]);
      }
      if (getPlaceData[9] == null) {
        setclientCode('');
      } else {
        setclientCode(getPlaceData[16]);
      }
      setPlace(getPlaceData[17]);
      //console.log(getPlaceData);

      //Get bill image from api
      /*getBill({ id: getPlaceData[14].toString() })
        .then((response) => {
          //console.log(response);
          setBill(response);
        })
        .catch((error) => console.log(error));*/
    });
  }, [props]);

  //On Load
  const onLoad = (
    buildingid: any,
    addressid: any,
    ClientBuildingId: any,
    ClientBuildingPartId: any,
    id: any
  ) => {
    let isMounted = true; // track whether component is mounted
    //console.log(buildingid)
    setCurrentPartId(ClientBuildingPartId);
    //Get single client building
    getClientBuildingSingle({ id })
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setClientBuildingData(response);
          setBuldingParts(response.partsList);
          setDataLoad(true);
          //console.log(response.partsList);
        }
      })
      .catch((error) => console.log(error));
    //Get single building
    getBuildingSingle(buildingid, addressid)
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setBuildingData(response);
        }
      })
      .catch((error) => console.log(error));

    //Get client points for particular building
    getClientPointsForBuildingPart({ ClientBuildingId, ClientBuildingPartId })
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setClientPointsForBuilding(response);
        }
      })
      .catch((error) => console.log(error));

    //Get single building Parts
    searchBuildingPart(buildingid, buildingid, addressid)
      .then((response: any) => {
        // success
        //console.log(response);
        //setBuldingParts(response);
      })
      .catch((error) => {
        // failure
        console.log(error);
      });

    //Get client points
    getClientPoints()
      .then((response) => {
        //console.log(response);
        //setSingleClientPoint(response);
      })
      .catch((error) => console.log(error));

    //Get providers
    getProviders()
      .then((response) => {
        setProviders(response);
        //console.log(response);
      })
      .catch((error) => console.log(error));

    return () => {
      // clean up
      isMounted = false;
    };
  };

  const onClickCard = () => {
    //console.log('Add location!');
  };

  //Current provider
  const currentpPovider = Providers.filter(
    (items) => items.id === provider
  ).map((items: ProvidersData) => {
    return {
      ...items,
    };
  });

  const buildType =
    buildingData?.hasBuildingParts || buildingData?.hasApartments
      ? t('auth.register.union')
      : t('auth.register.house');
  const backLink =
    hasApartments || hasBuildingParts
      ? `/auth/register-step-1-d/buildingid/${buildingData?.buildingId}/addressid/${buildingData?.addressId}/hasbuildingparts/${buildingData?.hasBuildingParts}/hasapartments/${buildingData?.hasApartments}/clientbuildingid/${clientBuildingId}/clientbuildingpartid/0`
      : `/auth/register-step-1-b-multiple/buildingid/${clientBuildingData?.buildingId}/addressid/${clientBuildingData?.addressId}/hasbuildingparts/${clientBuildingData?.hasBuildingParts}/hasapartments/${clientBuildingData?.hasApartments}/clientbuildingid/${clientBuildingId}/clientbuildingpartid/${props.match.params.partid}`;


   //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/dashboardmain")
    });
  });

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="flex justify-center p-4 pt-8 bg-white rounded-t-2xl dark:bg-gray-900">
          <div className="w-full mb-5">
            <StepsHeaderWithBackCustom
              hideArrow={true}
              title={t('auth.register.step1ATitleMeter')}
              backLink="/dashboardmain"
            />
            {dataLoad ? (
              <>
                <UnitCard
                  //title={`${clientBuildingData ? clientBuildingData?.description : buildType}`}
                  title={buildingPartName ? buildingPartName : buildingName}
                  address={`${clientBuildingData
                    ? clientBuildingData?.address
                    : buildingData?.street +
                    '' +
                    buildingData?.houseNumber +
                    ', ' +
                    buildingData?.post
                    }`}
                  type={
                    buildingData?.hasBuildingParts &&
                      buildingData?.hasApartments
                      ? 'SKYSCRAPER'
                      : buildingData?.hasBuildingParts &&
                        !buildingData?.hasApartments
                        ? 'HOMEMULTIPLE'
                        : 'HOME'
                  }
                  mapLat={buildingData?.gpsLat.toString()}
                  mapLong={buildingData?.gpsLon.toString()}
                />

                {buldingParts[0] ? (
                  <>
                    {buldingParts
                      .filter(
                        (items) =>
                          items.clientBuildingPartId.toString() ===
                          currentPartId.toString()
                      )
                      .map((part, index) => {
                        return (
                          <div key={index}>
                            {/*<div className="mb-1 text-lg font-bold">{part.description}</div>*/}
                            {/*<div className="mb-6">{part.actualUse}</div>*/}
                            <div className="mt-8 -mb-2 font-bold text-black-5">
                              {t('auth.register.placesLocation')}
                            </div>

                            {clientPointsForBuilding.map(
                              (clientPoint, index) => {
                                //Current provider
                                const currentpPovider = Providers.filter(
                                  (items) => items.id === clientPoint.utilityId
                                ).map((items: ProvidersData) => {
                                  return {
                                    ...items,
                                  };
                                });
                                return (
                                  <div
                                    key={clientPoint.clientPointId.toString()}
                                  >
                                    {
                                      <LocationCard
                                        key={clientPoint.clientPointId.toString()}
                                        id={clientPoint.clientPointId.toString()}
                                        title={
                                          clientPoint.clientPointDescription
                                        }
                                        type={clientPoint.utilityTypeName}
                                        ShowHide=""
                                        consumptionPointTitle={t(
                                          'auth.register.consumptionPointTitle'
                                        )}
                                        user={t(
                                          'auth.register.clientCodePlaceholder'
                                        )}
                                        consumptionPointId={clientPoint.clientPointCode.toString()}
                                        userId={clientPoint.clientPointCustomerCode.toString()}
                                        className="my-5 bg-gray-45"
                                        icon={currentpPovider[0]?.logo}
                                      />
                                    }
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      })}
                  </>
                ) : (
                  <>
                    <div className="mt-8 mb-2 font-bold text-black-5 dark:text-white">
                      {t('auth.register.placesLocation')}
                    </div>
                    {clientPointsForBuilding.map((clientPoint, index) => {
                      //Current provider
                      const currentpPovider = Providers.filter(
                        (items) => items.id === clientPoint.utilityId
                      ).map((items: ProvidersData) => {
                        return {
                          ...items,
                        };
                      });
                      return (
                        <div key={clientPoint.clientPointId.toString()}>
                          {
                            <LocationCard
                              key={clientPoint.clientPointId.toString()}
                              id={clientPoint.clientPointId.toString()}
                              title={clientPoint.clientPointDescription}
                              type={clientPoint.utilityTypeName}
                              ShowHide=""
                              consumptionPointTitle={t(
                                'auth.register.consumptionPointTitle'
                              )}
                              user={t('auth.register.user')}
                              consumptionPointId={clientPoint.clientPointCode.toString()}
                              userId={clientPoint.clientPointCustomerCode.toString()}
                              className="bg-gray-45"
                              icon={currentpPovider[0]?.logo}
                            />
                          }
                        </div>
                      );
                    })}
                  </>
                )}

                {/*<LocationCard
              id="1"
              onClickCard={() => onClickCard()}
              singleLink={`/dashboard/single/`}
              title="GEN-i"
              type="ELECTRICITY"
              ShowHide=""
              consumptionPointTitle={t('auth.register.consumptionPointTitle')}
              user={t('auth.register.user')}
              consumptionPointId="01500000012"
              userId="E14XAF58UD"
              className="bg-blue-50"
            />

            <LocationCard
              id="1"
              onClickCard={() => onClickCard()}
              singleLink={`/dashboard/single/`}
              title="GEN-i"
              type="WATER"
              ShowHide=""
              consumptionPointTitle={t('auth.register.consumptionPointTitle')}
              user={t('auth.register.user')}
              consumptionPointId="01500000012"
              userId="E14XAF58UD"
              className="bg-blue-50"
            />*/}

                <div className="flex items-center justify-center text-sm text-center">
                  <IonRouterLink
                    className="font-bold text-gray-50"
                    href={`/auth/register-step-1-c/buildingid/${props.match.params.buildingid}/addressid/${props.match.params.addressid}/hasbuildingparts/${props.match.params.hasbuildingparts}/hasapartments/${props.match.params.hasapartments}/clientbuildingid/${props.match.params.clientbuildingid}/clientbuildingpartid/${props.match.params.partid}`}
                  >
                    <div className="flex items-center mt-7">
                      <IonIcon
                        src="/assets/icon/ic-plus-add-white.svg"
                        class="ios hydrated text-3xl w-3 h-3 bg-blue-40 rounded-full p-2"
                      ></IonIcon>
                      <span className="ml-3 text-gray-50 dark:text-white font-bold text-sm">{t('auth.register.addLocation')}</span>
                    </div>
                  </IonRouterLink>
                </div>
              </>
            ) : (
              <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
                <Loader />
              </div>
            )}
          </div>
        </div>
      </IonContent>
      <IonFooter
        mode="ios"
        className="p-4 bg-white ion-no-border dark:bg-gray-900"
      >
        <IonToolbar>
          {props.match.params.hasbuildingparts == 'true' ||
            (props.match.params.hasapartments == 'true' && (
              <IonRouterLink
                className="font-bold text-gray-50"
                href={`/auth/register-step-1-b-multiple/buildingid/${props.match.params.buildingid}/addressid/${props.match.params.addressid}/hasbuildingparts/${props.match.params.hasbuildingparts}/hasapartments/${props.match.params.hasapartments}/clientbuildingid/${props.match.params.clientbuildingid}/clientbuildingpartid/${props.match.params.partid}`}
              >
                <div className="flex items-center justify-center w-full h-12 mb-2 text-base font-semibold text-center text-white normal-case rounded-full bg-gray-55">
                  {t('auth.register.step1ATitle')}
                </div>
              </IonRouterLink>
            ))}
          <IonButton
            onClick={handleNextStep}
            color="blue"
            className="w-full h-12 text-base font-semibold normal-case"
          >
            {t('auth.register.save')}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default RegisterStep1E;
