import { Api } from '../api';


export type GetKeyAccountsResponse = {
  token: string;
  phone: string | null;
  email: string | null;
};

export const searchBuildingPart = async (
  clientBuildingId: string,
  gursBuildingId: string,
  gursAddressId: string,
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('POST', `app/client-building/search-building-part?clientBuildingId=${clientBuildingId}&gursBuildingId=${gursBuildingId}&gursAddressId=${gursAddressId}`, true, { 
    });
    return response;
  } catch (error) {
    throw error;
  }
};
