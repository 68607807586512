import { IonButton, IonCheckbox, IonItem, IonLabel, IonRouterLink, useIonViewWillEnter } from "@ionic/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { emailForNews } from "../data/user/emailForNews";
import { getProfile } from "../data/user/profile.api";
import Input from "./form/Input";

interface ContainerProps {
  gursAddressId?: number;
  predefinedemail?: string
}

type FormErrors = {
  email: string[] | undefined;
  gdpr: string[] | undefined;
};


const ServiceNotAvailable: React.FC<ContainerProps> = ({
  gursAddressId,
  predefinedemail
}) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(predefinedemail ? predefinedemail : "");
  const [gdpr, setGdpr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [classDanger, setClassDanger] = useState<string>("");
  const [errors, setErrors] = useState<FormErrors>({
    email: undefined,
    gdpr: undefined,
  });

  const emailRegex = /\S+@\S+\.\S+/;
  const history = useHistory();


  //Handle email input:
  const handleInputEmailChange = (e: any) => {
    if (e.target.value !== '' && emailRegex.test(email)) {
      setErrors({
        ...errors,
        email: undefined,
      });
    } else {
      setErrors({
        ...errors,
        email: ['Email is required'],
      });
    }
    setEmail(e.target.value);
  };

  const handleBack = () => {
    history.push('/dashboardmain');
  }

  const handleSubmit = () => {
    if (
      email !== '' &&
      emailRegex.test(email) &&
      gdpr === true
    ) {

      //Submit to api
      emailForNews(email, gursAddressId ? gursAddressId : 0)
        .then((response: any) => {
          // success
          setSuccess(true);
          //console.log(response);
          //console.log("Message sent")
          //console.log(email)
        })
        .catch((e) => {
          // failure
          setErrors({
            ...errors,
            email: ['Email is required'],
            gdpr: ['Gdpr is required'],
          });
          console.log(errors);
        });

    } else {
      //console.log("Not success")

      setErrors({
        email: ['Email is required'],
        gdpr: ['Gdpr is required'],
      });
    }
  }

  const handleGdpr = (e: any) => {
    if (gdpr == true) {
      setClassDanger("text-red-5")
    } else {
      setClassDanger("")
    }
    setGdpr(e.detail.checked)
  };

  return (
    <div>

      {success ? (
        <>
          <div className="font-bold mt-7 mb-3 text-gray-800">{t('notAvailable.successTitle')}</div>
          <div className="mb-2 text-gray-600 text-sm">{t('notAvailable.successDescription')}</div>
        </>
      ) : (

        <>
          <div className="font-bold mt-7 mb-3 text-gray-800">{t('notAvailable.title')}</div>
          <div className="mb-2 text-gray-600 text-sm">{t('notAvailable.descriptionOne')}</div>
          <div className="mb-8 text-gray-600 text-sm">{t('notAvailable.descriptionTwo')}</div>

          <div className="pb-5">
            <Input
              type="text"
              label={t('auth.accountSettings.emailPlaceholder')}
              error={errors.email}
              value={email}
              onChange={handleInputEmailChange}
            />
          </div>

          <div className="pt-2">
            {<IonItem lines="none" key="checkbox-notifications" className={`${classDanger} text-sm text-gray-55 dark:text-white -ml-3 flex items-center`}>
              <IonCheckbox className="" border-color="danger" mode="md" checked={gdpr} onIonChange={handleGdpr} />
              <IonLabel style={{ whiteSpace: "normal" }} className="ml-2 ">{t('auth.register.notifications')}</IonLabel>
            </IonItem>}
          </div>

          <IonButton onClick={() => handleSubmit()} color={"blue"} className="w-full normal-case font-semibold h-12 text-base"><span className={`${"text-white"} `}>{t('notAvailable.buttonOneLabel')}</span></IonButton>
          <IonButton onClick={() => handleBack()} color="graybgbut" className="w-full normal-case font-semibold h-12 text-base"><span className='text-gray-900 dark:text-white'>{t('notAvailable.buttonTwoLabel')}</span></IonButton>
        </>
      )}
    </div>
  );
};
export default ServiceNotAvailable;
