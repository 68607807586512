import {
  IonItem,
  IonLabel,
  IonHeader,
  IonButton,
  IonToolbar,
  IonTitle,
  IonRouterLink,
  IonList,
  IonSelect,
  IonSelectOption,
  IonModal,
  IonButtons,
  IonBackButton,
  IonIcon,
  useIonViewWillEnter,
} from '@ionic/react';
import { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { addClientPoint } from '../../data/client-points/addClientPoint.api';
import { editClientPoint } from '../../data/client-points/editClientPoint.api';
import Input from './../../components/form/Input';
import { useTranslation } from 'react-i18next';
import { deleteClientPoint } from '../../data/client-points/deleteClientPoint.api';
import { getCurrentUser } from '../../data/user/current-user.api';
import { chevronBackOutline } from 'ionicons/icons';
import { createBrowserHistory } from 'history';
import { editBuilding } from '../../data/client-points/editBuilding';


type CurrentUserlData = {
  isAuthenticated: boolean;
  id: string;
  userName: string;
  email: string;
};

interface ContainerProps {
  //onClick: () => void;
  onCancelEdit: () => void;
  onDidDismiss: () => void;
  onSuccessEdit: () => void;
  isOpen: boolean;
  id: string;
  cssClass?: string;
  inputtitle?: string;
  inputkoda?: string;
  inputkodaUser?: string;
  selectProvider?: string;
  UserIdGet?: string;
  page?: string;
  clientBuildingId?: number;
  clientBuildingPartId?: number;
}

const EditBuildingModal: React.FC<ContainerProps> = ({
  //onClick,
  onCancelEdit,
  onDidDismiss,
  onSuccessEdit,
  isOpen,
  id,
  cssClass,
  inputtitle,
  inputkoda,
  inputkodaUser,
  selectProvider,
  UserIdGet,
  page,
  clientBuildingId,
  clientBuildingPartId,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [errors, setErrors] = useState({ building: [] });
  const [koda, setKoda] = useState(inputkoda);
  const [uporabnik, setUporabnik] = useState(inputkodaUser);
  const [building, setBuilding] = useState(inputtitle);
  const [provider, setProvider] = useState<string>(selectProvider ?? '');
  const [currentUserId, setcurrentUserId] = useState<CurrentUserlData>();
  const [changeInput, setChangeInput] = useState(false);
  const [changeProvider, setChangeProvider] = useState(false);

  const description = building;

  //Edit building:
  const handleEditPoint = async (id: string) => {

    //Edit building from api:
    editBuilding(
      Number(id),
      description
    )
      .then((response) => {
        // success
        console.log('Deleted', id);
        console.log(response);
        // success
        //Close modal
        onDidDismiss();
        if (page == "single") {
          //Redirect to new client point
          history.push(`/dashboardmain/client-building/${clientBuildingId}/part/${clientBuildingPartId}`);
          //Refresh client points
          onSuccessEdit();
        } else {
          //Redirect to dashboard
          history.push(`/dashboardmain/`);
          //Refresh client points
          onSuccessEdit();
        }
        console.log('Building edited!');
        //console.log(response);
      })
      .catch((error) => {
        // failure
        console.log(error);
      });
    //If only input is changed edit client point
  };

  useIonViewWillEnter(() => {
    let isMounted = true; // track whether component is mounted
    //Get current user
    getCurrentUser()
      .then((response) => {
        //console.log(response);
        if (isMounted) {
          setcurrentUserId(response);
        }
      })
      .catch((error) => console.log(error));
    return () => {
      // clean up
      isMounted = false;
    };
  });



  //Handle Building title input
  const handleInputPlaceChange = (e: any) => {
    if (e.target.value === '') {
      setErrors({ building: [] });
    } else {
      setErrors({ building: [] });
    }
    setBuilding(e.target.value);
    setChangeInput(true);
  };


  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={() => onDidDismiss()}
      key="edit-modal"
    >
      <div className={`${cssClass} overflow-scroll px-4`} id={id}>
        <IonHeader mode="ios">
          <IonToolbar mode="ios">
            <IonButtons slot="start">
              <button
                className="flex items-center text-2xl text-gray-10"
                onClick={onCancelEdit}
              >
                <IonIcon class="ios hydrated" icon={chevronBackOutline} />
              </button>
            </IonButtons>
            <IonTitle className="dark:text-white">{t('dashboard.editBuilding')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div>
          {/*<form onSubmit={() => handleEditPoint(id.replace('-edit','').toString() ?? '')}>*/}
          <h2 className="p-4 text-base font-bold text-black-5 dark:text-white">
            {t('auth.register.buildingName')}
          </h2>
          <Input
            type="text"
            label={t('auth.register.buildingPlaceholder')}
            error={errors.building}
            value={building}
            onChange={handleInputPlaceChange}
          />
          <div className="p-4 mt-4">
            <IonButton
              onClick={() =>
                handleEditPoint(id.replace('-edit', '').toString() ?? '')
              }
              shape="round"
              color="orange"
              className="w-full h-12 text-base font-semibold normal-case"
            >
              {t('dashboard.ctaDone')}
            </IonButton>
            <IonRouterLink
              onClick={onCancelEdit}
              className="block w-full h-12 p-6 text-base text-center normal-case text-gray-5"
            >
              {t('dashboard.ctaCancel')}
            </IonRouterLink>
          </div>
          {/*</form>*/}
        </div>
      </div>
    </IonModal>
  );
};

export default EditBuildingModal;
