import {
  IonContent,
  IonPage,
  IonButton,
  useIonViewWillEnter,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillLeave,
} from '@ionic/react';
import { changeEmail } from '../../data/user/change-email.api';
import { getProfile } from '../../data/user/profile.api';
import { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Header from './../../components/Header';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';

type FormErrors = {
  email: string[] | undefined;
};

type ProfileData = {
  email: string;
  userName?: string;
};



function UsernameChange() {
  const { t } = useTranslation();
  const history = useHistory();
  const [Profile, setProfile] = useState<ProfileData>();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<FormErrors>({
    email: undefined
  });
  const [successEmailChange, setsuccessEmailChange] = useState<boolean>(false);

  const emailRegex = /\S+@\S+\.\S+/;


  useIonViewWillEnter(() => { //useEffect
    getProfile()
      .then((response) => {
        setProfile(response);
        setEmail(response.email);
      })
      .catch((error) => console.log(error));

  }, []);


  // ChangeEmail form
  const postChangeEmail = async () => {
    //Check if email is correct
    if (email !== '' && emailRegex.test(email)) {
      changeEmail(email)
        .then((response: any) => {
          // success
          setsuccessEmailChange(true);
          console.log(response);
        })
        .catch((e) => {
          // failure
          setErrors({
            ...errors,
            email: ['Email is required'],
          });
          console.log(errors);
        });

      setErrors({
        ...errors,
        email: undefined,
      });

    } else {
      setErrors({
        ...errors,
        email: ['Email is required'],
      });
    }
  };

  //Handle email input
  const handleInputEmailChange = (e: any) => {
    if (e.target.value !== '' && emailRegex.test(email)) {
      setErrors({
        ...errors,
        email: undefined,
      });
    } else {
      setErrors({
        ...errors,
        email: ['Email is required'],
      });
    }
    setEmail(e.target.value);
  };


  return (
    <IonPage>
      <Header rightIcon={true} titleColor="text-gray-20 dark:text-white text-center" backLink="/auth/profile" title={t('auth.accountSettings.title')} />
      <IonContent fullscreen>
        <div className="">
          <div className="text-gray-55 dark:text-white font-bold text-base px-4 mt-8">{t('auth.accountSettings.changeEmailLabel')}</div>
          <div className="flex justify-center px-4 mt-10">
            <div className="w-full">
              <div className="py-2">
                <Input
                  type="text"
                  label={t('auth.accountSettings.emailPlaceholder')}
                  error={errors.email}
                  value={email}
                  onChange={handleInputEmailChange}
                />
              </div>

              <div className={`text-blue-5 mt-6 text-center ${successEmailChange !== true && "hidden"}`}>{t('auth.accountSettings.changeEmailMessage')}</div>

              <IonButton onClick={postChangeEmail} color="blue" className="mt-16 w-full normal-case font-semibold h-12 text-base">{t('auth.accountSettings.ctaRequestChange')}</IonButton>

            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default UsernameChange;
