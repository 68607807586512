import {
  IonButton,
  IonRouterLink,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonTextarea,
  IonInput,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { chevronBackOutline } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import { getBill } from '../../data/client-points/bill.api';

interface ContainerProps {
  onCancelModal: () => void;
  onDidDismiss: () => void;
  id: string;
  data?: any;
  cssClass?: string;
}

type BillData = {
  data: string,
  message: string
};

const BillingCodesModal: React.FC<ContainerProps> = ({
  onCancelModal,
  onDidDismiss,
  id,
  cssClass,
  data
}) => {
  const { t } = useTranslation();
  const [Bill, setBill] = useState<BillData>();



  return (
    <div className={cssClass} id={id}>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <button
              className="flex items-center text-2xl text-gray-10"
              onClick={onCancelModal}
            >
              <IonIcon class="ios hydrated" icon={chevronBackOutline} />
            </button>
          </IonButtons>
          <IonTitle>{t('auth.register.billingCodeTitle')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div>


        <div className="p-4">
          <div className="text-center text-xl font-semibold mb-4">{t('auth.register.billingCodeTitle')}</div>
          <div className="text-center text-sm">{t('auth.register.billingCodeText')}</div>
          <div>
            {data != null ? (
              <div className="mt-8"><img className="mx-auto" src={`data:image/jpeg;base64, ${data}`} /></div>
            ) : (
              <div className="mt-8"><img className="mx-auto" src="/assets/poloznica.png" /></div>
            )
            }
          </div>
        </div>

        <div className="p-4">
          <IonRouterLink
            onClick={onCancelModal}
            className="block w-full h-12 p-6 text-base text-center normal-case text-gray-5"
          >
            {t('auth.register.close')}
          </IonRouterLink>
        </div>
      </div>
    </div>
  );
};

export default BillingCodesModal;
