import { IonIcon, IonRouterLink } from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getClientPointsForBuildingPart } from "../data/client-points/clientPointsForBuildingPart.api";

interface ContainerProps {
  ClientBuildingId: string;
  ClientBuildingPartId: string;
  className?: string;
  showMeterInfo?: Function;
}


const MeterInfo: React.FC<ContainerProps> = ({ ClientBuildingId, ClientBuildingPartId, className, showMeterInfo }) => {
  const { t } = useTranslation();
  const [hasClientPoints, setHasClientpoints] = useState(null);

  //Get client points for particular building
  getClientPointsForBuildingPart({ ClientBuildingId: ClientBuildingId, ClientBuildingPartId: ClientBuildingPartId })
    .then((response) => {
      //console.log(response.length);
      setHasClientpoints(response.length)
      if (response.length < 1) {
        showMeterInfo && showMeterInfo()
      }
    })
    .catch((error) => console.log(error));

  return (
    <>
      {!hasClientPoints && (
        <IonRouterLink
                className={`text-center w-full absolute top-0 left-0 z-40 ${className}`}
                routerLink={`/dashboard/add-place-step-1/clientbuildingid/${ClientBuildingId}/clientbuildingpartid/${ClientBuildingPartId}`}
              >
          <div className="text-white text-sm font-medium bg-red-5 rounded-xl px-2 h-6 inline-flex items-center">
            <IonIcon
              src={"/assets/icon/ic-warning-white.svg"}
              class="ios hydrated text-3xl w-4 h-4 mr-1"
            ></IonIcon>
            {t('dashboard.meter')}
            <IonIcon
              src={"/assets/icon/ic-help-white.svg"}
              class="ios hydrated text-3xl w-4 h-4 ml-1"
            ></IonIcon>
          </div>
          </IonRouterLink>
      )}
    </>

  );
};
export default MeterInfo;
