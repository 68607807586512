import { Api } from '../api';

export const forgotPassword = async (
  email: string,
): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('POST', 'app/account/forgot-password', false, {
      email,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
