import {
  IonContent,
  IonPage,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { changePassword } from '../../data/user/change-password.api';
import { Redirect, useHistory } from 'react-router-dom';
import { useState } from 'react';
import Header from '../../components/Header';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import Textarea from '../../components/form/textarea';
import { sendProposal } from '../../data/user/contact.api';

type FormErrors = {
  text: string[] | undefined;
};

const Contact: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [text, setText] = useState<string>('');
  const [errors, setErrors] = useState<FormErrors>({
    text: undefined,
  });

  const [successSent, setSuccessSent] = useState(false)


  //Handle place input
  const handleTextareaChange = (e: any) => {
    if (e.target.value === '') {
      setErrors({ text: [t('dashboard.reportIssue.errorTextArea')] });
    } else {
      setErrors({ text: [] });
    }
    setText(e.target.value);
  };

  const handleSubmit = () => {
    if (text !== '') {
      sendProposal(text)
        .then((response: any) => {
          // success
          setSuccessSent(true);
          console.log(response);
          console.log("Message sent")
        })
        .catch((e) => {
          // failure
          setErrors({
            ...errors,
            text: [t('dashboard.reportIssue.errorTextArea')],
          });
          console.log(errors);
        });
    }
  }


  return (
    <IonPage>
      <Header
        bgColor='graybg'
        titleColor="text-gray-20 dark:text-white text-center"
        backLink="/auth/profile"
        title={t('auth.accountSettings.contactLabel')}
        style={{ paddingTop: 0 }}
      />
      <IonContent fullscreen>
        <div className="bg-gray-45 dark:bg-gray-900 min-h-full">

          {successSent ? (
            <div className="w-full text-center pt-10 text-gray-600 dark:text-white text-sm">
              {t('auth.accountSettings.contactSuccess')}
            </div>
          ) : (
            <>
              <div className="text-gray-600 dark:text-white text-sm px-4 pt-6">{t('auth.accountSettings.contactText')}</div>
              <div className="flex justify-center px-4 mt-4">

                <div className="w-full">
                  <div className="text-sm text-gray-600">
                    <Textarea
                      value={text}
                      error={errors.text}
                      label=""
                      onChange={handleTextareaChange}
                      placeholder={t('auth.accountSettings.contactPlaceholder')}
                      rows={6}
                    >
                    </Textarea>
                    {errors.text && (
                      <div className="mt-2 text-red-5">{errors.text}</div>
                    )}
                  </div>
                  <div className="pt-4">
                    {text !== '' ? (
                      <IonButton
                        onClick={() => handleSubmit()}
                        color="blue"
                        className="w-full h-12 text-base font-semibold normal-case"
                      >
                        {t('auth.accountSettings.contactButton')}
                      </IonButton>
                    ) : (
                      <IonButton
                        color="graydisabled"
                        className="w-full h-12 text-base font-semibold normal-case"
                      >
                        {t('auth.accountSettings.contactButton')}
                      </IonButton>
                    )}


                  </div>
                </div>

              </div>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Contact;
