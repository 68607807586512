import {
  IonButton,
  IonRouterLink,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonItem,
  IonLabel,
  IonTextarea,
  IonInput,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { chevronBackOutline } from 'ionicons/icons';
import React, { useRef, useState } from 'react';
import Textarea from '../form/textarea';
import { reportIssue } from '../../data/client-points/reporIssue';

interface ContainerProps {
  onCancelReport: () => void;
  onDidDismiss: () => void;
  id: string;
  cssClass?: string;
}

const ReportPlaceModal: React.FC<ContainerProps> = ({
  onCancelReport,
  onDidDismiss,
  id,
  cssClass,
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({ text: [] });
  const [text, setText] = useState<string>('');
  const [file, setFile] = useState<string>();
  const [fileName, setFileName] = useState<string>();
  const [success, setSuccess] = useState(false);


  //Handle place input
  const handleTextareaChange = (e: any) => {
    if (e.target.value === '') {
      setErrors({ text: [t('dashboard.reportIssue.errorTextArea')] });
    } else {
      setErrors({ text: [] });
    }
    setText(e.target.value);
  };

  //Send report Client point
  const handleReportClientPoint = async (id: string) => {
    if (text !== '') {
      //Send report
      reportIssue(
        Number(id),
        text,
        file,
      )
        .then((response: any) => {
          // success
          //Close modal
          //onDidDismiss();
          //Success sent report
          setSuccess(true);
          console.log("Send report for client point id:", id);
          //console.log(response);
        })
        .catch((error: any) => {
          // failure
          console.log(error);
        });
      //End Send report
    }
  };

  //File upload
  // Create a reference to the hidden file input element
  const hiddenFileInput: any = useRef(null);

  // Programatically click the hidden file input element
  const openFileDialog = () => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };

  // to handle the user-selected file 
  const setImage = (event: any) => {
    const fileUploaded = event.target.files[0];
    setFileName(event.target.files[0].name.toString());
    //console.log(event.target.files[0].name);

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = function () {
      const str = reader.result?.toString();
      if (str != undefined) {
        //console.log("Base 64: ", str.substring(str.lastIndexOf(',') + 1)); //clean base64encoded string
        // Set base64 file to send to api
        setFile(str.substring(str.lastIndexOf(',') + 1));
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };

  };

  return (
    <div className={cssClass} id={id}>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons slot="start">
            <button
              className="flex items-center text-2xl text-gray-10"
              onClick={onCancelReport}
            >
              <IonIcon class="ios hydrated" icon={chevronBackOutline} />
            </button>
          </IonButtons>
          <IonTitle className="dark:text-white">{t('dashboard.reportIssue.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>

      {success ? (
        <div className='text-center text-gray-50 dark:text-white mt-10 px-5'>
          {t('dashboard.reportIssue.sentSuccess')}
          <IonRouterLink
            onClick={onCancelReport}
            className="w-full h-12 text-base font-semibold normal-case mt-10 bg-blue-40 text-white flex items-center justify-center rounded-full"
          >
            {t('dashboard.submitStatus.close')}
          </IonRouterLink>
        </div>
      ) : (
        <div>
          <div className="p-4 mt-6 text-sm text-gray-5">
            <Textarea
              value={text}
              error={errors.text}
              label=""
              onChange={handleTextareaChange}
              placeholder={t('dashboard.reportIssue.textareaPlaceholder')}
              rows={6}
            >
            </Textarea>
            <div className="mt-2 text-red-5">{errors.text}</div>
          </div>
          <div className="p-4 flex flex-wrap">
            <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M0.5 2C0.5 0.89543 1.39543 0 2.5 0H19.5C20.6046 0 21.5 0.89543 21.5 2V16C21.5 17.1046 20.6046 18 19.5 18H2.5C1.39543 18 0.5 17.1046 0.5 16V2ZM2.5 14.4142V16H19.5V14.4942L12.1438 8.86884L9.75926 11.6508C9.40842 12.0601 8.79627 12.1176 8.37531 11.7809L6.57422 10.34L2.5 14.4142ZM19.5 11.9764V2H2.5V11.5858L5.79289 8.29289C6.1532 7.93259 6.7268 7.90082 7.12469 8.21913L8.86985 9.61525L11.2407 6.84921C11.5861 6.44633 12.1859 6.38331 12.6075 6.70564L19.5 11.9764ZM6.25 5.5C6.38807 5.5 6.5 5.38807 6.5 5.25C6.5 5.11193 6.38807 5 6.25 5C6.11193 5 6 5.11193 6 5.25C6 5.38807 6.11193 5.5 6.25 5.5ZM4.5 5.25C4.5 4.2835 5.2835 3.5 6.25 3.5C7.2165 3.5 8 4.2835 8 5.25C8 6.2165 7.2165 7 6.25 7C5.2835 7 4.5 6.2165 4.5 5.25Z" fill="#718096" />
            </svg>
            <div onClick={openFileDialog} className="text-sm font-bold underline ml-3 text-gray-50">{t('dashboard.reportIssue.addAttachement')}</div>
            <input
              type="file"
              id="file-upload"
              style={{ display: "none" }}
              ref={hiddenFileInput}
              onChange={setImage}
            />
            <div className="w-full mt-4 overflow-hidden text-gray-50 font-bold">{fileName}</div>
          </div>
          <div className="p-4">
            <IonButton
              onClick={() => handleReportClientPoint(id)}
              color="blue"
              className="w-full h-12 text-base font-semibold normal-case"
            >
              {t('dashboard.reportIssue.send')}
            </IonButton>
            <IonRouterLink
              onClick={onCancelReport}
              className="block w-full h-12 p-6 text-base text-center normal-case text-gray-5"
            >
              {t('dashboard.ctaCancel')}
            </IonRouterLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportPlaceModal;
