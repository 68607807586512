import { useTranslation } from "react-i18next";
import AirSensorCard from "./AirSensorCard";
import EffusionSensorCard from "./EffusionSensorCard";
import VolumeSensorCard from "./VolumeSensorCard";

interface ContainerProps {
  type: "effusion" | "volume" | "air" | null;
  title?: string;
  online: boolean;
  className?: string;
  leakStatus?: boolean | null;
  leakName?: string | null;
  leakDescription?: string | null;
  temperature?: any | null;
  humidity?: any | null;
  cO2Gas?: any | null;
  volume?: any | null;
}

const SensorCard: React.FC<ContainerProps> = ({
  type,
  title,
  online,
  className,
  leakStatus,
  leakName,
  leakDescription,
  temperature,
  humidity,
  cO2Gas,
  volume
}) => {
  const { t } = useTranslation();

  return (
    <div className={`bg-white dark:bg-gray-800 rounded-lg mb-2 ${className}`}>
      <div className="w-full px-5 py-4">
        <div className="w-full flex items-center justify-between border-b border-gray-200 pb-3">
          <div className="text-gray-800 dark:text-white text-sm font-bold">{title}</div>
          <div className="text-gray-600 dark:text-gray-60 uppercase text-3xs flex items-center">{online ? t("dashboard.online") : t("dashboard.offline")} <div className={`ml-1 w-2 h-2 rounded-full ${online ? "bg-green-400" : "bg-gray-600"}`}></div></div>
        </div>
      </div>
      <div className="w-full px-5 pb-4">
        {type === "effusion" && (
          <EffusionSensorCard
            leakStatus={leakStatus}
            leakName={leakName}
            leakDescription={leakDescription}
          />
        )}
        {type === "air" && (
          <AirSensorCard
            temperature={temperature}
            humidity={humidity}
            cO2Gas={cO2Gas}
          />
        )}
        {type === "volume" && (
          <VolumeSensorCard
            volume={volume}
          />
        )}
      </div>
    </div >
  );
};
export default SensorCard;
