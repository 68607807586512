interface ContainerProps {
  className?: string;
  onClick: () => void;
}

const ArrowRightSlider: React.FC<ContainerProps> = ({
    className,
    onClick,
}) => {

  return (
     <button className={`cursor-pointer w-8 h-8 z-10 flex items-center justify-center ${className}`} onClick={() => onClick()}>
     <svg width="12" height="16" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path fillRule="evenodd" clipRule="evenodd" d="M0.861766 0.528758C1.12212 0.268409 1.54423 0.268409 1.80458 0.528758L5.80457 4.52876C6.06492 4.78911 6.06492 5.21122 5.80457 5.47157L1.80458 9.47157C1.54423 9.73192 1.12212 9.73192 0.861766 9.47157C0.601417 9.21122 0.601417 8.78911 0.861766 8.52876L4.39036 5.00016L0.861766 1.47157C0.601417 1.21122 0.601417 0.789108 0.861766 0.528758Z" fill="#718096"/>
     </svg>
   </button>
  );
};
export default ArrowRightSlider;
