import { Api } from '../api';

type inputParams = {
  ClientBuildingId: string;
  ClientBuildingPartId: string;
};

export type GetKeyAccountsResponse = {
  token: string;
  phone: string | null;
  email: string | null;
};

export const getBillsForBuildingPoints = async (params: inputParams): Promise<any> => {
  const api = new Api();

  try {
    const response = await api.request('GET', `app/end-user/bills-for-building-points?ClientBuildingId=${params.ClientBuildingId}&ClientBuildingPartId=${params.ClientBuildingPartId}`, true); 
    return response;
  } catch (error) {
    throw error;
  }
};
