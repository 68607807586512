import { Preferences } from '@capacitor/preferences';
const API_URL = `${process.env.REACT_APP_API_URL}/api`;

export class ApiException {
  name: string;
  message: string;
  response: any;

  constructor(message: string, response: any) {
    this.name = 'ApiException';
    this.message = message;
    this.response = response;
  }
}



export class Api {
  async request(
    type: string,
    endpoint: string,
    auth: boolean = false,
    body?: any
  ): Promise<any> {
    // Fetch it from storage, if route requires auth, if no token present, fail

   
    const headers = new Headers();
    //Get language from storage and send it in header
    const lang = await Preferences.get({ key: 'language' });
    headers.append('Accept-Language', `${lang.value}`);

    headers.append('Content-type', 'application/json');
    headers.append('Accept', '*/*');

    if (auth) {
      const token = await Preferences.get({ key: 'token' });
      headers.append('Authorization', `Bearer ${token.value}`);
    }

    return fetch(`${API_URL}/${endpoint}`, {
      method: type || 'GET',
      body: body ? JSON.stringify(body) : null,
      mode: 'cors',
      redirect: 'follow',
      headers,
    }).then(async (response: any) => {
      if (response.status === 401) {
        await Preferences.remove({ key: 'token' });
        await Preferences.clear();
        // TODO: Is this ok to do?
        //window.location.replace('/auth/login');
        window.location.replace('/errorpage');
        
      }
      if (response.status === 404) {
        await Preferences.remove({ key: 'token' });
        await Preferences.clear();
        // TODO: Is this ok to do?
        //window.location.replace('/auth/login');
        //window.location.replace('/errorpage');
      }

      if (!response.status.toString().startsWith('2')) {
        //window.location.replace('/errorpage');
        //throw new ApiException('error', await response.json());
       
      }

      if (response.status === 204) {
        return;
      }

      return response.json();
    });
  }
}
