import { IonIcon, IonModal } from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getWasteBinInformation } from '../data/client-points/wasteBinInformation';
interface ContainerProps {
  id?: string;
  active?: boolean;
  weekend?: boolean;
  day?: string;
  date?: string;
  waste: any;
}

const WasteCard: React.FC<ContainerProps> = ({
  id,
  active,
  weekend,
  day,
  date,
  waste
}) => {

  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [infoImage, setInfoImage] = useState("");

  const handleOpenPopup = (e: string) => {
    setShowModal(true)
    //Get waste list
    getWasteBinInformation({ wasteId: e })
      .then((response) => {
        //console.log(response.data);
        setInfoImage(response.data)

      })
      .catch((error) => console.log(error));
  }

  return (
    <div id={id} className={`w-full border border-gray-200 rounded-lg mt-2 flex h-16 ${weekend ? "bg-gray-200" : ""}`}>
      <div className={`w-12 text-center text-2xs font-bold bg-gray-45 rounded-lg flex items-center justfy-center px-3 ${weekend ? "bg-gray-400" : ""} ${active ? "text-white bg-blue-40" : ""}`}>
        <div className='uppercase'>
          {date}.<br />
          {day === "Monday" && (t('general.days.mon'))}
          {day === "Tuesday" && (t('general.days.tue'))}
          {day === "Wednesday" && (t('general.days.wed'))}
          {day === "Thursday" && (t('general.days.thu'))}
          {day === "Friday" && (t('general.days.fri'))}
          {day === "Saturday" && (t('general.days.sat'))}
          {day === "Sunday" && (t('general.days.sun'))}
        </div>
      </div>
      <div className='flex py-2 pl-1 overflow-hidden'>
        <div className='w-full flex overflow-x-scroll' >
          {waste.map((item: any, index: any) => (
            <div className='h-full flex' key={index}>
              {item.wasteColor === "Brown" &&
                (
                  <div onClick={() => handleOpenPopup(item.wasteTypeId)} className='rounded bg-brown text-white text-2xs font-bold flex items-center justfy-center px-3 mx-1'>{t('dashboard.waste.bio')}</div>
                )}
              {item.wasteColor === "Yellow" &&
                (
                  <div onClick={() => handleOpenPopup(item.wasteTypeId)} className='rounded bg-yellow-700 text-white text-2xs font-bold flex items-center justfy-center px-3 mx-1'>{t('dashboard.waste.packaging')}</div>
                )}
              {item.wasteColor === "Green" &&
                (
                  <div onClick={() => handleOpenPopup(item.wasteTypeId)} className='rounded bg-green-700 text-white text-2xs font-bold flex items-center justfy-center px-3 mx-1'>{t('dashboard.waste.glass')}</div>
                )}
              {item.wasteColor === "Blue" &&
                (
                  <div onClick={() => handleOpenPopup(item.wasteTypeId)} className='rounded bg-blue-45 text-white text-2xs font-bold flex items-center justfy-center px-3 mx-1'>{t('dashboard.waste.paper')}</div>
                )}
              {item.wasteColor === "Black" &&
                (
                  <div onClick={() => handleOpenPopup(item.wasteTypeId)} className='rounded bg-gray-300 text-white text-2xs font-bold flex items-center justfy-center px-3 mx-1'>{t('dashboard.waste.mixed')}</div>
                )}
            </div>
          ))}
        </div>
      </div>
      <IonModal
        isOpen={showModal}
        key="edit-modal"
      >
        <div className="w-full h-full bg-gray-65 overflow-scroll">
          <IonIcon
            onClick={() => setShowModal(false)}
            src={`/assets/icon/ic-close-white.svg`}
            class={`ios hydrated text-3xl fixed right-0 top-0 p-4 mt-8 z-50`}
          ></IonIcon>
          {infoImage.length != 0 && (
            <img className="w-full relative" src={infoImage ? `data:image/jpeg;base64, ${infoImage}` : '/assets/no-image.jpg'} />
          )}
        </div>
      </IonModal>
    </div>
  );
};
export default WasteCard;
