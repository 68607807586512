import { IonIcon, IonRouterLink } from '@ionic/react';
import { useState } from 'react';
import { getDocumentsDownloadLink } from '../data/client-points/documentsDownloadLink.api';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import { isPlatform } from '@ionic/react';

interface ContainerProps {
  id?: string;
  title?: string;
  ShowHide?: string;
}

const DocumentCard: React.FC<ContainerProps> = ({
  id,
  title,
  ShowHide,
}) => {

  const [url, setUrl] = useState('');

  //File download link handle 
  const handleDocumentDownload = (fileId: any, title: any) => {
    //Genereate document download link
    getDocumentsDownloadLink({ fileId })
      .then((response) => {
        //console.log(response.url);
        setUrl(response.url)

        //Detetect if ios
        if (isPlatform('ios')) {
          InAppBrowser.create(response.url)
        } else {
          window.open(response.url, "_blank")
        }


      })
      .catch((error) => console.log(error));
  }


  return (
    <div id={id} className={`px-4 mb-2 ${ShowHide}`} onClick={() => handleDocumentDownload(id, title)}>
      <div>
        <div id={id} className={`py-3 px-4 text-gray-55 dark:text-white text-sm flex items-center justify-between bg-white dark:bg-gray-800 rounded-lg`}>
          <div className="">
            {title}
          </div>
          <IonIcon
            src="/assets/icon/ic-download.svg"
            class="ios hydrated text-3xl w-4 h-4"
          ></IonIcon>
        </div>
      </div>
    </div>
  );
};
export default DocumentCard;
