import { useState, useRef, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonRouterLink,
  useIonViewWillEnter,
  IonModal,
  useIonActionSheet,
  IonIcon,
  IonFooter,
  useIonViewDidEnter,
  IonSlides,
  IonSlide,
} from '@ionic/react';
import { getProfile } from '../data/user/profile.api';
import { deleteClientPoint } from '../data/client-points/deleteClientPoint.api';
import { getClientPoints } from '../data/client-points/clientPoints.api';
import { getProviders } from '../data/client-points/providers.api';
import DashCard from './../components/DashCard'; //type: WATER, GAS, ELECTRICITY, HEAT
import EditPlaceModal from './../components/modals/editPlace';
import DeletePlaceModal from './../components/modals/deletePlace';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ReportPlaceModal from '../components/modals/reportPlace';
import DashTabsNoIcons from '../components/DashTabsNoIcons';
import InvoiceCard from '../components/InvoiceCard';
import DocumentCard from '../components/DocumentCard';
import { getClientBuildingSingle } from '../data/client-points/clientBuildingSingle.api';
import Header from '../components/Header';
import FooterTabs from '../components/dashboardSingle/FooterTabs';
import { getBillsForBuildingPoints } from '../data/client-points/billsForBuildingPoints.api';
import { deleteBuilding } from '../data/client-points/deleteBuilding.api';
import { deleteBuildingPart } from '../data/client-points/deleteBuildingPart.api';
import { getDocumentsForBuilding } from '../data/client-points/documentsForBuilding.api';
import Loader from '../components/Loader';
import { getClientPointsForBuildingPart } from '../data/client-points/clientPointsForBuildingPart.api';
import EditBuildingPartModal from '../components/modals/editBuildingPart';
import SendEmailModal from '../components/modals/sendEmailModal';
import ReportBuildingModal from '../components/modals/reportBuilding';
import { getWasteSchedule } from '../data/client-points/wasteSchedule';
import CommunalTab from '../components/CommunalTab';
import { Preferences } from '@capacitor/preferences';
import { useIonRouter } from '@ionic/react';
import { getClientSensorsForBuildings } from '../data/sensors/clientSensorsForBuilding.api';
import SensorCard from '../components/sensors/SensorCard';
import { getDocumentsForEmail } from '../data/client-points/documentsForEmail.api';
import InvoicePdfCard from '../components/InvoicePdfCard';
import { getCurrentUserManger } from '../data/user/current-user-manage.api';
import { getClientLevels } from '../data/client-points/client-levels';

type ProvidersData = {
  id: number;
  name: string;
  utilityTypeId: number;
  logo: string;
};
type ClientPointForBuilding = {
  clientBuildingId: number,
  clientBuildingPartId: number,
  clientPointActive: boolean;
  clientPointAddress: string;
  clientPointCode: string;
  clientPointCustomerCode: string;
  clientPointDescription: string;
  clientPointId: number;
  clientPointName: string;
  clientPointTypeId: number;
  clientPointTypeName: string;
  dailyReadingFrequency: boolean;
  externalId: string;
  hourlyReadingFrequency: boolean;
  monthlyReadingFrequency: boolean;
  consumptionStatus: number;
  hasAlarms: boolean;
  alarmsTitle: string;
  alarmsDescription: string;
  userId: string;
  utilityId: number;
  utilityName: string;
  utilityTypeName: 'WATER' | 'GAS' | 'ELECTRICITY' | 'HEAT';
  utilityUnit: string;
  utilityNoDecimalNumbers: boolean;
  readoutList: [
    {
      isValid: boolean,
      value: string;
      value2: string;
      valueTime: string;
      valueTimeWeekName: string;
    }
  ];
};

type DocumentsForBuilding = {
  buildingPartList: [
    {
      description: string;
      gursBuildingPartId: number;
      fileList: [
        {
          description: string;
          fileId: number;
          fileName: string;
          forEmail: boolean;
          clientLevelId: number;
          clientLevelName: string;
        }
      ]
    }
  ];
  description: string;
  fileList: [
    {
      description: string;
      fileId: number;
      fileName: string;
      forEmail: boolean;
      clientLevelId: number;
      clientLevelName: string;
    }
  ];
  gursAddressId: number;
  gursBuildingId: number;
}

type DocumentsForPart = {
  description: string;
  gursBuildingPartId: number;
  fileList: [
    {
      description: string;
      fileId: number;
      fileName: string;
      forEmail: boolean;
      clientLevelId: number;
      clientLevelName: string;
    }
  ]
}

type DocumentFiles = {
      description: string;
      fileId: number;
      fileName: string;
      forEmail: boolean;
      clientLevelId: number;
      clientLevelName: string;
}

type BillsForClientBuilding = {
  utilityId: number,
  utilityName: string,
  utilityTypeName: string,
  billList: [
    {
      reference: string,
      customerCode: string,
      billDate: string,
      deadline: string,
      value: number,
      payed: boolean,
      document: string
    }
  ]
};

type BillList = {
  reference: string,
  customerCode: string,
  deadline: string,
  value: number,
  payed: boolean
};

type WasteData = {
  collectionDateList: any,
  startTime: string,
  endTime: string,
  startTimeWeekName: string,
};

type ClientPoint = {
  clientBuildingId: number;
  clientBuildingPartId: number;
  clientPointDescription: string;
  clientPointTypeName: string;
  dailyReadingFrequency: boolean;
  utilityTypeName: 'WATER' | 'GAS' | 'ELECTRICITY' | 'HEAT';
  clientPointId: number;
  utilityName: string;
  utilityId: number;
  clientPointCode: string;
  clientPointCustomerCode: string;
  utilityUnit: string;
  readoutList: [
    {
      value: string;
      value2: string;
      valueTime: string;
      valueTimeWeekName: string;
    }
  ];
};
type BuildingData = {
  address: string;
  clientBuildingId: number;
  description: string;
  gursAddressId: number;
  gursBuildingId: number;
  hasApartments: boolean;
  hasBills: boolean;
  hasBuildingParts: boolean;
  hasDocuments: boolean;
  partsList: [];
  managed: boolean;
};

type BuildingPartData = {
  actualUse: string;
  clientBuildingId: number;
  clientBuildingPartId: number;
  description: string;
  gursBuildingPartId: number;
  partNumber: number;
  roomNumber: number;
  userId: string;
  managed: boolean;
};

type Sensors = {
  cO2Gas: string;
  code: string;
  description: string;
  humidity: {
    healthIndex: number;
    time: string;
    unit: string;
    value: number;
  },
  id: number;
  leakStatus: {
    active: boolean;
    description: string;
    name: string;
    time: string;
  };
  online: boolean;
  pressure: {
    healthIndex: number;
    time: string;
    unit: string;
    value: number;
  },
  sensorId: number;
  temperature: {
    healthIndex: number;
    time: string;
    unit: string;
    value: number;
  },
  voc: object;
  volume: {
    healthIndex: number;
    time: string;
    unit: string;
    value: number;
  },
};

const ClientBuilding: React.FC = (props: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [present, dismiss] = useIonActionSheet();
  const [presentSecond, dismissSecond] = useIonActionSheet();
  const [filteredItem, setfilteredItem] = useState<string>('services');
  const [Providers, setProviders] = useState<ProvidersData[]>([]);
  const [clientPoints, setClientPoints] = useState<ClientPoint[]>([]);
  const [clientPointsForBuilding, setClientPointsForBuilding] = useState<ClientPointForBuilding[]>([]);
  const [documentsForBuilding, setDocumentsForBuilding] = useState<DocumentsForBuilding>();
  const [documentsForPart, setDocumentsForPart] = useState<DocumentsForPart[]>([]);
  const [documentsForEmail, setDocumentsForEmail] = useState<DocumentFiles[]>([]);
  const [documentsForPartEmail, setDocumentsForPartEmail] = useState<DocumentFiles[]>([]);
  const [billsForBuildingPoints, setBillsForBuildingPoints] = useState<BillsForClientBuilding[]>([]);
  const [buildingData, setBuildingData] = useState<BuildingData>();
  const [buildingPartData, setBuildingPartData] = useState<BuildingPartData>();
  const [nonPayedBills, setNonPayedBills] = useState<BillList[]>([]);
  const [wasteAll, setWasteAll] = useState<WasteData[]>([]);
  const [sensorsAll, setSensorsAll] = useState<Sensors[]>([]);
  const [wasteStartDate, setWasteStartDate] = useState<string>('');
  const [wasteEndDate, setWasteEndtDate] = useState<string>('');
  const [dataLoad, setDataLoad] = useState<boolean>(false);
  const [allBills, setAllBills] = useState<BillList[]>([]);
  const [clientBuildId, setClientBuildId] = useState<string>('services');
  const [userEmail, setUserEmail] = useState<string>('');
  const [emailFileId, setEmailFileId] = useState<string>();
  const [cLientsOnBuilding, setCLientsOnBuilding] = useState<any[]>([]);
  const [cLientsOnBuildingPart, setCLientsOnBuildingPart] = useState<any[]>([]);
  const [clientLevels, setClientLevels] = useState<any[]>([]);

  //Modals
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalReport, setShowModalReport] = useState(false);
  const [showEditPlace, setShowEditPlace] = useState<number>();
  const [showModalEmail, setShowModalEmail] = useState(false);

  const [showModalEditBuildingPart, setShowModalEditBuildingPart] = useState(false);
  const [showModalDeleteBuilding, setShowModalDeleteBuilding] = useState(false);
  const [showModalReportBuildingPart, setShowModalReportBuildingPart] = useState(false);
  const [showEditBuilding, setShowEditBuilding] = useState<number>();
  const [showEditBuildingPart, setShowEditBuildingPart] = useState<number>();
  const [locale, setLocale] = useState<string>('');

  const uniqByProp = (prop: any) => (arr: any) =>
    Object.values(
      arr.reduce(
        (acc: any, item: any) =>
          item && item[prop]
            ? { ...acc, [item[prop]]: item } // just include items with the prop
            : acc,
        {}
      )
    );
  const uniqueByclientLevelName = uniqByProp("clientLevelName");

  const activeItemClass = 'bg-blue-40 text-white dark:text-gray-900 shadow-md';

  const sliderWaste = useRef<HTMLIonSlidesElement>(null);


  //Slider options:
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };


  //Slider button navigation:
  const swipeNext = () => {
    //console.log('next')
    sliderWaste.current?.slideNext();
  };
  const swipePrev = () => {
    sliderWaste.current?.slidePrev();
    //console.log('prev')
  };

  const data = {
    tabs: [
      {
        id: 'services',
        title: t('dashboard.tabs.storitve'),
        className: 'rounded-2xl py-1 px-3 text-center inline-block',
        OutherClass: '',
        new: false,
        //hideit: props.match.params.part === "0" && buildingData?.hasApartments ? true : false
        hideit: false
      },
      {
        id: 'komunala',
        title: t('dashboard.tabs.komunala'),
        className: 'flex items-center rounded-2xl py-1 px-3 text-center',
        OutherClass: 'px-1',
        //new: nonPayedBills.length > 0 && true,
        hideit: wasteAll.length != 0 ? false : true,
      },
      {
        id: 'senzorji',
        title: t('dashboard.tabs.sensors'),
        className: 'flex items-center rounded-2xl py-1 px-3 text-center',
        OutherClass: 'px-1',
        //new: nonPayedBills.length > 0 && true,
        hideit: sensorsAll.length != 0 ? false : true,
      },
      {
        id: 'invoices',
        title: t('dashboard.tabs.poloznice'),
        className: 'flex items-center rounded-2xl py-1 px-3 text-center',
        OutherClass: 'px-1',
        new: nonPayedBills.length > 0 && true,
        hideit: allBills.length || (props.match.params.part === "0" && documentsForEmail?.length) || (props.match.params.part !== "0" && documentsForPartEmail.length) ? false : true,
      },
      {
        id: 'documents',
        title: t('dashboard.tabs.dokumenti'),
        className: 'flex items-center rounded-2xl py-1 px-3 text-center',
        OutherClass: 'px-1',
        new: false,
        hideit: props.match.params.part === "0" && documentsForBuilding?.fileList.length || props.match.params.part !== "0" && documentsForPart.length ? false : true
      },
    ],
  };




  //Delete Client point
  const handleDeleteClientPoint = async (id: string) => {
    //Delete client point from api:
    deleteClientPoint({ id: id })
      .then((response) => {
        // success
        //console.log('Deleted', id);
        //console.log(response);
        setShowModalDelete(false);
        //Refresh client points
        onLoad(props.match.params.id, props.match.params.id, props.match.params.part);
        onSuccessEdit();
      })
      .catch((error) => {
        // failure
        console.log(error);
      });
  };

  //Delete Building
  const handleDeleteBuilding = async (id: string, partid: string) => {
    //Delete client point from api:
    //console.log('Delete', id);
    //console.log('Delete', partid);

    if (partid === "0") {
      deleteBuilding({ id: id })
        .then((response) => {
          // success
          //console.log('Deleted', id);
          //console.log(response);
          setShowModalDeleteBuilding(false);
          history.push(`/dashboardmain/`);
        })
        .catch((error) => {
          // failure
          console.log(error);
        });
    } else {
      deleteBuildingPart({ id: partid })
        .then((response) => {
          // success
          //console.log('Deleted', partid);
          //console.log(response);
          setShowModalDeleteBuilding(false);
          history.push(`/dashboardmain/`);
        })
        .catch((error) => {
          // failure
          console.log(error);
        });
    }

  };



  const refresh = (e: CustomEvent) => {
    setTimeout(() => {
      e.detail.complete();
    }, 3000);
  };


  //Tabs filtering:
  const onClickTab = (index: number, id: string) => {
    //setactiveItem(index);
    setfilteredItem(id);
    //Load komunala items
    /*if (id === "komunala") {
       console.log(clientBuildId)
    }*/
  };

  //Edit/delete Card
  const onClickCard = (index: number, managed: any) => {
    present({
      mode: 'ios',
      buttons: [
        {
          text: t('dashboard.ctaEdit'),
          cssClass: `important-text-gray-20 ${managed ? "hideit" : ""}}`,
          handler: () => {
            setShowEditPlace(index);
            if (index) {
              setShowModalEdit(true);
            }
          },
        },
        {
          text: t('dashboard.reportAnIssue'),
          cssClass: 'important-text-gray-20',
          handler: () => {
            setShowModalReport(true);
            setShowEditPlace(index);
          },
        },
        {
          text: t('dashboard.ctaDelete'),
          role: 'destructive',
          handler: () => {
            setShowModalDelete(true);
            setShowEditPlace(index);
          },
          cssClass: managed ? "hideit" : "",
        },
        {
          text: t('dashboard.ctaCancel'),
          cssClass: 'important-text-gray-20',
          role: 'cancel',
        },
      ],
    });
  };


  //Edit/delete Building
  const onClickCardBuilding = (index: any, managed: any) => {
    presentSecond({
      mode: 'ios',
      buttons: [
        {
          text: t('dashboard.ctaEdit'),
          cssClass: 'important-text-gray-20',
          handler: () => {
            //console.log(props.match.params.part)
            setShowEditBuildingPart(props.match.params.part);
            if (index) {
              setShowModalEditBuildingPart(true);
            }
          },
        },
        {
          text: t('dashboard.reportAnIssue'),
          handler: () => {
            setShowModalReportBuildingPart(true);
            setShowEditBuildingPart(props.match.params.part);
          },
          cssClass: managed ? "important-text-gray-20" : "hideit important-text-gray-20",
        },
        {
          text: t('dashboard.ctaDelete'),
          role: 'destructive',
          handler: () => {
            setShowModalDeleteBuilding(true);
            //setShowEditBuilding(index);
          },
          cssClass: managed ? "hideit" : "",
        },
        {
          text: t('dashboard.ctaCancel'),
          cssClass: 'important-text-gray-20',
          role: 'cancel',
        },
      ],
    });
  };


  //On success edit Client Point
  const onSuccessEdit = () => {
    getClientPoints()
      .then((response) => {
        setClientPoints(response);
        //console.log(response)
      })
      .catch((error) => console.log(error));
  }

  //On success edit Building part
  const onSuccessEditBuildingPart = () => {
    let isMounted = true; // track whether component is mounted 
    if (isMounted) {
      onLoad(props.match.params.id, props.match.params.id, props.match.params.part);
    }
    return () => {
      // clean up
      isMounted = false;
    };
  }


  //On Load
  const onLoad = (id: string, ClientBuildingId: string, ClientBuildingPartId: string) => {
    let isMounted = true; // track whether component is mounted 
    //Data loaded
    setDataLoad(false)
    setClientBuildId(ClientBuildingId)
    //let isMounted = true; // track whether component is mounted 
    //console.log(`Render for ID: ${props.match.params.id}`);
    //console.log(`Render for Part: ${props.match.params.part}`);
    //Get single building
    getClientBuildingSingle({ id })
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setBuildingData(response);
          const buildingParts = response.partsList.filter((item: any) => item.clientBuildingPartId === (Number(props.match.params.part))).map((items: BuildingPartData) => {
            return {
              ...items,
            }
          });
          setBuildingPartData(buildingParts[0]);
          //console.log(buildingParts[0].gursBuildingPartId.toString());

          //Get waste list
          getWasteSchedule({ gursAddressId: response.gursAddressId })
            .then((response) => {
              if (isMounted) {
                //console.log(response.periodList.length);
                setWasteStartDate((response && response.periodList.length > 0) ? response.periodList[0].startTime : '')
                setWasteEndtDate((response && response.periodList.length > 0) ? response.periodList.pop().endTime : '')
                setWasteAll(response.periodList);
              }
            })
            .catch((error) => console.log(error));

          //Data loaded
          setDataLoad(true)


            //Get documents for client building
            getDocumentsForBuilding({ ClientBuildingId })
            .then((response) => {
              if (isMounted) {
                //console.log(response.buildingPartList);
                setDocumentsForBuilding(response);

                const docForEmail = response ? response.fileList.filter((forEmail: any) => forEmail.forEmail === true) : response.map((items: any) => {
                  return {
                    ...items,
                  }
                });

                //console.log(docForEmail)

                setDocumentsForEmail(docForEmail);

                //Set Client levels from files
                const clientlevelsEmailBuilding = response.fileList.filter((forEmail: any) => forEmail.forEmail === true).map((items: any) => {
                  return {
                    clientLevelName: items.clientLevelName,
                  }
                });
                setCLientsOnBuilding(uniqueByclientLevelName(clientlevelsEmailBuilding))

                //Set document for current part
                const currentPartDocuments = response.buildingPartList.filter((items: any) => items.gursBuildingPartId.toString() === (buildingParts[0] ? buildingParts[0].gursBuildingPartId.toString() : "")).map((items: any) => {
                  return {
                    ...items,
                  }
                });

                //Set documents for part email
                const currentPartDocumentsEmail = currentPartDocuments[0] ? currentPartDocuments[0].fileList.filter((forEmail: any) => forEmail.forEmail === true) : currentPartDocuments.map((items: any) => {
                  return {
                    ...items,
                  }
                });

                //console.log(response.buildingPartList)
                //console.log(currentPartDocuments[0].fileList)
                //Set document for current part
                setDocumentsForPart(currentPartDocuments);
                setDocumentsForPartEmail(currentPartDocumentsEmail);


                //Set Client levels from files
                const clientlevelsEmailBuildingPart = currentPartDocuments.map((items: any) => {
                  return items.fileList.filter((forEmail: any) => forEmail.forEmail === true).map((subitems: any) => {
                    return {
                      clientLevelName: subitems.clientLevelName,
                    };
                  });
                });
                setCLientsOnBuildingPart(uniqueByclientLevelName(clientlevelsEmailBuildingPart.length ? clientlevelsEmailBuildingPart[0] : clientlevelsEmailBuildingPart))


              }
            })
            .catch((error) => console.log(error));



        }
      })
      .catch((error) => {
        // failure
        console.log(error);
        //Remove token if error
        Preferences.remove({ key: 'token' });
        Preferences.clear();
      });

    //Get client points for particular building
    getClientPointsForBuildingPart({ ClientBuildingId, ClientBuildingPartId })
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setClientPointsForBuilding(response);
        }
      })
      .catch((error) => console.log(error));

    

    //Get Client Levels
    getCurrentUserManger()
      .then((response) => {
        getClientLevels({ managerId: response.managerId })
          .then((responseClient) => {
            setClientLevels(responseClient)
          })
          .catch((error) => console.log(error));

      })
      .catch((error) => console.log(error));


    //Get documents for email
    /* getDocumentsForEmail({ ClientBuildingId })
       .then((response) => {
         if (isMounted) {
           setDocumentsForEmail(response);
 
           //Set document for current part
           const currentPartDocuments = response.buildingPartList.filter((items: any) => items.gursBuildingPartId === buildingPartData?.gursBuildingPartId).map((items: any) => {
             return {
               ...items,
             }
           });
           //console.log(currentPartDocuments)
           //Set document for current part
           setDocumentsForPartEmail(currentPartDocuments);
         }
       })
       .catch((error) => console.log(error));*/

    //Get sensors
    getClientSensorsForBuildings({ ClientBuildingId: ClientBuildingId, ClientBuildingPartId: ClientBuildingPartId })
      .then((response) => {
        //console.log(response)
        setSensorsAll(response)
      })
      .catch((error) => {
        // failure
        console.log(error);
      });

    //Get bills for client building
    //If invoices are new show new icon
    const days = 30;
    const currentTime = new Date(Date.now() - days * 24 * 60 * 60 * 1000)
    const currentTimeNew = (currentTime).toISOString().split('.')[0];

    //console.log(currentTimeNew);
    getBillsForBuildingPoints({ ClientBuildingId, ClientBuildingPartId })
      .then((response) => {
        if (isMounted) {
          //Get all invoices
          const allInvoices = response.map((items: BillsForClientBuilding) => {
            return items.billList.map((values: BillList) => {
              return {
                ...values,
              }
            });
          });
          //console.log(allInvoices.flat());
          //console.log(currentTimeNew);
          //setBillsForBuildingPoints(allInvoices.flat());
          setBillsForBuildingPoints(response);
          setAllBills(allInvoices.flat());
          //Get non payed invoices
          const billsList = response.map((items: BillsForClientBuilding) => {
            return items.billList.filter(items => items.payed !== true).map((values: BillList) => {
              return {
                ...values,
              }
            });
          });
          setNonPayedBills(billsList.flat())
          //console.log(billsList.flat())
        }
      })
      .catch((error) => console.log(error));
    return () => {
      // clean up
      isMounted = false;
    };

  };


  //console.log(clientPoints);

  //Delete card
  const tabAction = (index: number) => {
    //console.log(index);
  };
  const tabActionDelete = (index: number) => {
    //console.log(index);
  };

  const tabActionNotify = (index: number) => {
    //console.log(index);
  };



  useIonViewDidEnter(() => {
    let isMounted = true; // track whether component is mounted 
    //Get clientpoints
    onSuccessEdit();

    //Set active tab services
    setfilteredItem('services');

    //Get providers
    getProviders()
      .then((response) => {
        if (isMounted) {
          setProviders(response);
          //console.log(response);
        }
      })
      .catch((error) => console.log(error));
    return () => {
      //clean up
      isMounted = false;
    };

    /*onLoad(props.match.params.id, props.match.params.id, props.match.params.part);
    setShowEditBuilding(props.match.params.id);*/

  }, [props]);

  useIonViewWillEnter(() => {
    setDataLoad(false);
    //Get language from storage
    Preferences.get({ key: 'language' }).then((storage: any) => {
      setLocale(storage.value ?? '')
    });
  }, [props]);

  useIonViewDidEnter(() => {
    //console.log('Effect enter');
    onLoad(props.match.params.id, props.match.params.id, props.match.params.part);
    setShowEditBuilding(props.match.params.id);
    setShowEditBuildingPart(props.match.params.part);

    getProfile()
      .then((response) => {
        //console.log(response);
        setUserEmail(response.email)
        //Check for user data in old app
      })
      .catch((error) => console.log(error));

  }, [props, buildingPartData]);


  ///console.log("Documents" + documentsForBuilding)
  //console.log(props.match.params.part);

  //console.log(buildingData)
  //console.log(billsForBuildingPoints);

  //console.log(wasteAll)

  //Slide to specific slide
  const handlesetSlide = (e: any) => {
    sliderWaste.current?.slideTo(e);
  }


  //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/dashboardmain")
    });
  });

  //console.log(sensorsAll);

  //Send email invoice pdf
  const handleSendEmail = (e: any) => {
    console.log("Send email: ", e)
    setShowModalEmail(true)
    setEmailFileId(e)
  }


  return (
    <IonPage id="dashboard">
      {dataLoad ? (
        <>
          <Header
            title={props.match.params.part === "0" ? buildingData?.description : buildingPartData?.description}
            backLink={`/dashboardmain/${props.match.params.slide ? props.match.params.slide : 0}`}
            titleColor="text-black-5 dark:text-white text-left pl-12"
            className="bg-gray-45 dark:bg-gray-900 mt-0"
            bgColor="graybg"
            rightIcon={props.match.params.part == 0 ? false : true}
            onClickCard={() => onClickCardBuilding(buildingData?.clientBuildingId, props.match.params.part === "0" ? buildingData?.managed : buildingPartData?.managed)}
          />

          <IonContent fullscreen color="graybg">
            <div className="w-full overflow-x-auto">
              <div className="w-auto flex items-center justify-left text-sm p-4 text-gray-10">
                {data.tabs.map((tab, index) => {
                  return (
                    <DashTabsNoIcons
                      id={tab.id}
                      key={tab.id}
                      title={tab.title}
                      newItem={nonPayedBills.length > 0 && tab.new}
                      className={`mr-4 ${tab.className} ${tab.id === filteredItem ? activeItemClass : 'mr-4'
                        }`}
                      OutherClass={tab.OutherClass}
                      onClickTab={() => onClickTab(index, tab.id)}
                      hideit={tab.hideit}
                    />
                  );
                })}
              </div>
            </div>
            {/*<div className="p-4"> 
              <EULogos />
              </div>*/}

            {/** Komunala */}
            {filteredItem === 'komunala' && (
              <IonSlides pager={false} options={slideOpts} ref={sliderWaste}>
                {wasteAll.map((tab, index) => (
                  <IonSlide key={index}>
                    <CommunalTab
                      slideNext={swipeNext}
                      slidePrev={swipePrev}
                      data={tab.collectionDateList}
                      locale={locale}
                      startTime={tab.startTime}
                      endTime={tab.endTime}
                      id={index}
                      setSlide={handlesetSlide}
                      startCalendar={wasteStartDate}
                      endCalendar={wasteEndDate}
                      wasteAll={wasteAll}
                    />
                  </IonSlide>
                ))}
              </IonSlides>
            )}

            {/** Sensors */}
            {filteredItem === 'senzorji' && (
              <div className='px-4 w-full mt-4'>
                {sensorsAll.map((sensor, index) => (
                  <SensorCard
                    key={index}
                    className=""
                    type={sensor.leakStatus ? "effusion" : sensor.volume ? "volume" : sensor.temperature ? "air" : null}
                    title={sensor.description}
                    online={sensor.online}
                    leakStatus={sensor.leakStatus ? sensor.leakStatus.active : null}
                    leakName={sensor.leakStatus ? sensor.leakStatus.name : null}
                    leakDescription={sensor.leakStatus ? sensor.leakStatus.description : null}
                    temperature={sensor.temperature ? sensor.temperature : null}
                    humidity={sensor.humidity ? sensor.humidity : null}
                    cO2Gas={sensor.cO2Gas ? sensor.cO2Gas : null}
                    volume={sensor.volume ? sensor.volume : null}
                  />
                ))}
              </div>
            )}

            {/** Invoices */}
            {filteredItem === 'invoices' && (
              <>
                {documentsForEmail && (
                  <div>
                    {/** Invoice files on main building */}
                    {documentsForEmail && props.match.params.part === "0" ? (
                      
                      <>
                        {cLientsOnBuilding && (
                          <div className="px-4 mt-6 mb-2 font-bold text-gray-55 dark:text-white">
                            {cLientsOnBuilding.map((client, index) => (
                              <div key={index}>
                                <div key={index} className="mb-2">{client.clientLevelName}</div>
                                {
                                  documentsForEmail.map((file, index) => (
                                    client.clientLevelName === file.clientLevelName && (
                                      <div key={index}>
                                        <InvoicePdfCard
                                          key={file?.fileId.toString()}
                                          id={file?.fileId.toString()}
                                          title={file?.fileName}
                                          sendEmail={handleSendEmail}
                                        />
                                      </div>
                                    )

                                  )
                                  )
                                }

                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {documentsForPartEmail && (
                          <div className="px-4 mt-6 mb-2 font-bold text-gray-55 dark:text-white">
                            {cLientsOnBuildingPart.map((client, index) => (
                              <div key={index}>
                                <div key={index} className="mb-2">{client.clientLevelName}</div>
                                {
                                  documentsForPartEmail?.map((document: any, index: any) => (
                                      client.clientLevelName === document.clientLevelName && (
                                        <div key={index}>
                                          <InvoicePdfCard
                                            key={document?.fileId.toString()}
                                            id={document?.fileId.toString()}
                                            title={document?.fileName}
                                            sendEmail={handleSendEmail}
                                          />
                                        </div>

                                      )
                                    ))
                                }
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}

                  </div>
                )}

                {billsForBuildingPoints.map((bills, index) => {
                  return (
                    <div key={index}>
                      {bills.billList.length.toString() !== "0" && (
                        <div className="px-4 mt-6 mb-2 font-bold text-gray-55 dark:text-white">
                          {bills.utilityName} - {bills.utilityTypeName === 'WATER' && t('dashboard.tabs.water')}
                          {bills.utilityTypeName === 'ELECTRICITY' && t('dashboard.tabs.electricity')}
                          {bills.utilityTypeName === 'HEAT' && t('dashboard.tabs.heat')}
                          {bills.utilityTypeName === 'GAS' && t('dashboard.tabs.gas')}

                        </div>
                      )}

                      {bills.billList.sort((a: any, b: any) => b[bills.billList[0].deadline] - a[bills.billList[0].deadline]).map((bill, index) => {
                        return (
                          <div key={index}>
                            <InvoiceCard
                              key={index}
                              id={index.toString()}
                              title={`${t('dashboard.due_date')}: ${bill.deadline.split('T', 1)[0]}`}
                              cost={Number(bill.value).toFixed(2).toString()}
                              paid={bill.payed}
                              reference={`${t('dashboard.bill_number')} ${bill.document}`}
                              billDate={`${bill.billDate}`}
                              dueDate={`${bill.deadline}`}
                              ShowHide={
                                filteredItem === 'invoices'
                                  ? ''
                                  : 'hidden'
                              }
                            />
                          </div>
                        )
                      })}

                    </div>
                  )
                })}
              </>
            )}

            {/** Documents */}
            {filteredItem === 'documents' && (

              documentsForBuilding && (
                <div>

                  {/** Files on main building */}
                  {documentsForBuilding.fileList && props.match.params.part === "0" ? (
                    documentsForBuilding.fileList.filter((forEmail: any) => forEmail.forEmail !== true).map((file, index) => (
                      <div key={index}>
                        <div className="px-4 mt-6 mb-2 font-bold text-gray-55 dark:text-white">{file?.description ? file?.description : documentsForBuilding.description}</div>
                        <DocumentCard
                          id={file?.fileId.toString()}
                          title={file?.fileName}
                        />
                      </div>
                    )
                    )
                  ) : (
                    documentsForPart?.map((part: any, index) => (
                      part?.fileList.filter((forEmail: any) => forEmail.forEmail !== true).map((document: any, index: any) => {
                        return (
                          <div key={index}>
                            <div className="px-4 mt-6 mb-2 font-bold text-gray-55 dark:text-white">{document?.description ? document?.description : documentsForBuilding.description}</div>
                            <DocumentCard
                              id={document?.fileId.toString()}
                              title={document?.fileName}
                            />
                          </div>
                        )
                      })
                    ))
                  )}

                </div>
              )
            )}

            {clientPointsForBuilding && (

              clientPointsForBuilding.map((clientPoint,) => {
                //Current provider
                const currentpPovider = Providers.filter((items) => items.id === clientPoint.utilityId).map((items: ProvidersData) => {
                  return {
                    ...items,
                  }
                });
                return (
                  <div key={clientPoint.clientPointId.toString()}>
                    {/*<LocationCard
                key={clientPoint.clientPointId.toString()}
                id={clientPoint.clientPointId.toString()}
                title={currentpPovider[0]?.name}
                type={clientPoint.utilityTypeName}
                ShowHide=""
                consumptionPointTitle={t('auth.register.consumptionPointTitle')}
                user={t('auth.register.user')}
                consumptionPointId={clientPoint.clientPointId.toString()}
                userId={clientPoint.clientPointCustomerCode.toString()}
                className="bg-gray-45 my-5"
                icon={currentpPovider[0]?.logo}
              />*/}
                    <DashCard
                      NoDecimalNumbers={clientPoint.utilityNoDecimalNumbers}
                      key={clientPoint.clientPointId.toString()}
                      id={clientPoint.clientPointId.toString()}
                      onClickCard={() => onClickCard(clientPoint.clientPointId, props.match.params.part === "0" ? buildingData?.managed : buildingPartData?.managed)}
                      singleLink={`/dashboardmain/single/${clientPoint.clientPointId}/slide/${props.match.params.slide}`}
                      title={clientPoint.clientPointDescription}
                      type={clientPoint.utilityTypeName}
                      //measure={clientPoint.readoutList[0] !== undefined ? clientPoint.readoutList[0].vif.unit : ''}
                      /*measure={
                        clientPoint?.utilityTypeName === 'ELECTRICITY' ? 'kWh' : clientPoint?.utilityTypeName === 'HEAT' ? 'kW' : 'm3'
                      }*/
                      measure={clientPoint?.utilityUnit}
                      lowLabel={clientPoint.utilityTypeName}
                      highLabel={clientPoint.utilityTypeName}
                      lowNum={
                        clientPoint.readoutList.length.toString() !== "0" ? (
                          clientPoint.readoutList[0].value !== null ?
                            Number(clientPoint.readoutList[0].value).toFixed(2).toString()
                            : "hidden"
                        ) : "hidden"}
                      highNum={
                        clientPoint.readoutList.length.toString() !== "0" ? (
                          clientPoint.readoutList[0].value2 !== null
                            ? Number(clientPoint.readoutList[0].value2).toFixed(2).toString()
                            : "hidden"
                        ) : "hidden"}
                      ShowHide={
                        filteredItem === clientPoint.utilityTypeName ||
                          filteredItem === 'services'
                          ? ''
                          : 'hidden'
                      }
                      lastMonth={t('dashboard.lastMonthLabel')}
                      lowRate={t('dashboard.lowRateLabel')}
                      highRate={t('dashboard.highRateLabel')}
                      hasAlarms={clientPoint.hasAlarms}
                      alarmsTitle={clientPoint.alarmsTitle}
                      alarmsDescription={clientPoint.alarmsDescription}
                      consumptionStatus={clientPoint.consumptionStatus}
                    />
                  </div>)
              })

            )}

            {/*<IonRouterLink
          routerLink="/dashboard/add-place-step-1"
          className="fixed bottom-0 right-0 z-50 flex items-center justify-center w-12 h-12 mb-4 mr-4 rounded-3xl bg-gray-20"
        >
          <img src="/assets/icon/ic-plus-white.svg" />
        </IonRouterLink>*/}

            {clientPointsForBuilding
              .filter((clientPoint) => clientPoint.clientPointId === showEditPlace)
              .map((clientPoint) => (
                <EditPlaceModal
                  onDidDismiss={() => setShowModalEdit(false)}
                  isOpen={showModalEdit}
                  key={`${clientPoint.clientPointId.toString()}-edit`}
                  cssClass={
                    showEditPlace === clientPoint.clientPointId
                      ? 'opacity-100'
                      : 'hidden opacity-0'
                  }
                  id={`${clientPoint.clientPointId.toString()}-edit`}
                  selectProvider={clientPoint.utilityId.toString()}
                  inputkoda={clientPoint.clientPointCode.toString()}
                  inputkodaUser={clientPoint.clientPointCustomerCode.toString()}
                  inputPlace={clientPoint.clientPointDescription}
                  //onClick={() => handleEditPoint(clientPoint.clientPointId.toString())}
                  onCancelEdit={() => setShowModalEdit(false)}
                  ProvidersList={Providers}
                  onSuccessEdit={() => onLoad(props.match.params.id, props.match.params.id, props.match.params.part)}
                  clientBuildingId={clientPoint.clientBuildingId}
                  clientBuildingPartId={clientPoint.clientBuildingPartId}
                />
              ))}


            {filteredItem === 'services' && (
              <IonRouterLink
                className="flex items-center mt-6 justify-center"
                routerLink={`/dashboard/add-place-step-1/clientbuildingid/${props.match.params.id}/clientbuildingpartid/${props.match.params.part}`}
              >
                <div className="flex items-center justify-center mb-8 demo-hide-cont">
                  <IonIcon
                    src="/assets/icon/ic-plus-add-white.svg"
                    class="ios hydrated text-3xl w-3 h-3 bg-blue-40 rounded-full p-2"
                  ></IonIcon>
                  <span className="ml-3 text-gray-50 dark:text-white font-bold text-sm">{t('auth.register.addLocation')}</span>
                </div>
              </IonRouterLink>
            )}

            {/*filteredItem === 'services' && props.match.params.part != "0" && buildingData?.hasApartments && (
              <IonRouterLink
                className="flex items-center mt-6 justify-center"
                routerLink={`/dashboard/add-place-step-1/clientbuildingid/${props.match.params.id}/clientbuildingpartid/${props.match.params.part}`}
              >
                <div className="flex items-center justify-center mb-8">
                  <IonIcon
                    src="/assets/icon/ic-plus-add.svg"
                    class="ios hydrated text-3xl w-4 h-4"
                  ></IonIcon>
                  <span className="ml-3 text-gray-50 font-bold">{t('auth.register.addLocation')}</span>
                </div>
              </IonRouterLink>
            )*/}
            {/*filteredItem === 'services' && props.match.params.part == "0" && !buildingData?.hasApartments && (
              <IonRouterLink
                className="flex items-center mt-6 justify-center"
                routerLink={`/dashboard/add-place-step-1/clientbuildingid/${props.match.params.id}/clientbuildingpartid/${props.match.params.part}`}
              >
                <div className="flex items-center justify-center mb-8">
                  <IonIcon
                    src="/assets/icon/ic-plus-add.svg"
                    class="ios hydrated text-3xl w-4 h-4"
                  ></IonIcon>
                  <span className="ml-3 text-gray-50 font-bold">{t('auth.register.addLocation')}</span>
                </div>
              </IonRouterLink>
            )*/}


            <EditBuildingPartModal
              onDidDismiss={() => setShowModalEditBuildingPart(false)}
              isOpen={showModalEditBuildingPart}
              cssClass={showEditBuildingPart === 0 ? 'hidden' : ''}
              key={buildingPartData?.clientBuildingPartId.toString()}
              id={`${buildingPartData?.clientBuildingPartId.toString()}-edit`}
              page="single"
              inputtitle={buildingPartData?.description.toString()}
              //onClick= {() => handleEditPoint(singleClientPoint?.clientPointId.toString() ?? '')}
              onCancelEdit={() => setShowModalEditBuildingPart(false)}
              onSuccessEdit={() => onSuccessEditBuildingPart()}
              clientBuildingId={buildingPartData?.clientBuildingId}
              clientBuildingPartId={buildingPartData?.clientBuildingPartId}
            />



            <IonModal key="delete-modal" isOpen={showModalDelete}>
              {clientPointsForBuilding.map((clientPoint) => (
                <DeletePlaceModal
                  key={`${clientPoint.clientPointId.toString()}-delete`}
                  cssClass={
                    showEditPlace === clientPoint.clientPointId
                      ? 'opacity-100'
                      : 'hidden opacity-0'
                  }
                  id={`${clientPoint.clientPointId.toString()}-delete`}
                  onClick={() =>
                    handleDeleteClientPoint(clientPoint.clientPointId.toString())
                  }
                  onCancelDelete={() => setShowModalDelete(false)}
                />
              ))}
            </IonModal>

            <IonModal key="report-modal" isOpen={showModalReport}>
              {clientPointsForBuilding.map((clientPoint) => (
                <ReportPlaceModal
                  onDidDismiss={() => setShowModalReport(false)}
                  key={`${clientPoint.clientPointId.toString()}-report`}
                  cssClass={
                    showEditPlace === clientPoint.clientPointId
                      ? 'opacity-100'
                      : 'hidden opacity-0'
                  }
                  id={`${clientPoint.clientPointId.toString()}`}
                  /*onClick={() =>
                    handleReportClientPoint(clientPoint.clientPointId.toString())
                  }*/
                  onCancelReport={() => setShowModalReport(false)}
                />
              ))}
            </IonModal>


            <IonModal key="delete-modal-building" isOpen={showModalDeleteBuilding}>
              <DeletePlaceModal
                cssClass="opacity-100"
                id={`${props.match.partid === "0" ? buildingData?.clientBuildingId.toString() : buildingPartData?.clientBuildingPartId.toString()}-delete-building`}
                onClick={() =>
                  handleDeleteBuilding(props.match.params.id.toString(), props.match.params.part.toString())
                }
                onCancelDelete={() => setShowModalDeleteBuilding(false)}
              />
            </IonModal>


            <IonModal key="report-modal-building" isOpen={showModalReportBuildingPart}>
              <ReportBuildingModal
                onDidDismiss={() => setShowModalReportBuildingPart(false)}
                key={`${props.match.params.id.toString()}-report`}
                cssClass=""
                id={`${props.match.params.id.toString()}`}
                clientBuildingId={`${props.match.params.id.toString()}`}
                clientBuildingPartId={`${props.match.params.part.toString()}`}
                gursAddressId={`${buildingData?.gursAddressId.toString()}`}
                gursBuildingId={`${buildingData?.gursBuildingId.toString()}`}
                /*onClick={() =>
                  handleReportClientPoint(clientPoint.clientPointId.toString())
                }*/
                onCancelReport={() => setShowModalReportBuildingPart(false)}
              />
            </IonModal>


            <IonModal key="send-email-modal" isOpen={showModalEmail}>
              <SendEmailModal
                id={emailFileId ? Number(emailFileId) : 0}
                onDidDismiss={() => setShowModalEmail(false)}
                onCancelModal={() => setShowModalEmail(false)}
                email={userEmail}
              />
            </IonModal>


          </IonContent>

          <IonFooter mode="ios" className="ion-no-border" color="graybg">
            <FooterTabs
              tabAction={() => tabAction(0)}
              tabActionDelete={() => tabActionDelete(1)}
              tabActionNotify={() => tabActionNotify(2)}
            />
          </IonFooter>

        </>
      ) : (
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <Loader />
        </div>
      )
      }

    </IonPage >
  );
};

export default ClientBuilding;
