import {
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonButton,
  IonRouterLink,
  IonToolbar,
  IonFooter,
  IonList,
  IonRadioGroup,
  IonRadio,
  useIonViewWillEnter,
  useIonViewDidEnter,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useState} from 'react';
import { useTranslation } from 'react-i18next';
import { getStorage, setStorage } from '../../data/user/register.api';
import UnitCard from '../../components/UnitCard';
import { getBuildingSingle } from '../../data/client-points/getBuildingSingle.api';
import { getCurrentUser } from '../../data/user/current-user.api';
import { getClientBuildings } from '../../data/client-points/clientBuildings.api';
import { getClientBuildingSingle } from '../../data/client-points/clientBuildingSingle.api';
import Loader from '../../components/Loader';
import StepsHeaderWithBackCustom from '../../components/StepsHeaderWithBackCustom';
import { getProvidersByAddress } from '../../data/client-points/providersbyaddress.api';
import { useIonRouter } from '@ionic/react';

type FormErrors = {
  provider: string[] | undefined;
};
type ProvidersData = {
  id: number;
  name: string;
  utilityTypeId: number;
  logo: string;
};
type BuildingData = {
  clientBuildingId: number;
  addressId: number;
  buildingId: number;
  buildingNumber: number;
  distance: number;
  gpsLat: number;
  gpsLon: number;
  hasApartments: boolean;
  hasBuildingParts: boolean;
  houseNumber: string;
  municipality: string;
  post: string;
  settelment: string;
  street: string;
  description: string;
  address: string;
};

type CurrentUserlData = {
  isAuthenticated: boolean;
  id: string;
  userName: string;
  email: string;
};



const RegisterStep1C: React.FC = (props: any) => {
  const ionRouter = useIonRouter();
  const { t } = useTranslation();
  const history = useHistory();
  const [provider, setProvider] = useState("");
  const [providerClass, setProviderClass] = useState("");
  const [errors, setErrors] = useState<FormErrors>({
    provider: undefined,
  });
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buildingId, setBuildingId] = useState('');
  const [clientBuildingId, setClientBuildingId] = useState('');
  const [clientBuildingPartId, setClientBuildingParId] = useState('');
  const [buildingAddressId, setBuildingAddressId] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [buildingType, setBuildingType] = useState('');
  const [hasApartments, setHasApartments,] = useState(false);
  const [hasBuildingParts, setHasBuildingParts] = useState(false);
  const [buildingPartId, setBuildingPartId] = useState('');
  const [buildingClientPartId, setBuildingClientPartId] = useState('');
  const [buildingPartName, setBuildingPartName] = useState('');
  const [buildingPartType, setBuildingPartType] = useState('');
  const [locationCode, setLocationCode] = useState('');
  const [clientCode, setclientCode] = useState('');
  const [place, setPlace] = useState('');
  const [unit, setUnit] = useState('');
  const [dataLoad, setDataLoad] = useState<boolean>(false);

  const [building, setBuilding] = useState();
  const [active, setActive] = useState<number>(0);
  const [Providers, setProviders] = useState<ProvidersData[]>([]);
  const [countBuildings, setCountBuildings] = useState<[]>([]);
  const [countBuildingParts, setCountBuildingParts] = useState<[]>([]);
  const [currentUserId, setcurrentUserId] = useState<CurrentUserlData>();

  const [name, setName] = useState('');
  const [foundProviders, setFoundProviders] = useState(Providers);

  const [buildingData, setBuildingData] = useState<BuildingData>();
  const [clientBuildingData, setClientBuildingData] = useState<BuildingData>();

  const user = [
    username,
    email,
    password,
    buildingId,
    clientBuildingId,
    buildingAddressId,
    buildingName,
    buildingType,
    hasApartments,
    hasBuildingParts,
    buildingPartId,
    buildingClientPartId,
    buildingPartName,
    buildingPartType,
    provider,
    locationCode,
    clientCode,
    place,
  ];

  const idAdd = countBuildings.length + 1;
  const idAddParts = countBuildingParts.length + 1;
  const gursBuildingId = 123;
  const gursAddressId = 123;
  const description = buildingName;
  const creationTime = new Date().toISOString().toString();
  const creatorId = currentUserId?.id;
  const lastModificationTime = new Date().toISOString();
  const lastModifierId = currentUserId?.id;
  const isDeleted = true;
  const deleterId = currentUserId?.id;
  const deletionTime = new Date().toISOString();
  const userId = currentUserId?.id;

  //console.log(clientBuildingId);


  const data = {
    tabs: [
      {
        id: 'bivalni',
        title: t('auth.register.livingBuilding'),
        icon: '/assets/icon/ic-house.svg',
        iconActive: '/assets/icon/ic-house-white.svg',
        active: { active }
      },
      {
        id: 'poslovni',
        title: t('auth.register.officeBuilding'),
        icon: '/assets/icon/ic-business.svg',
        iconActive: '/assets/icon/ic-business-white.svg',
        active: { active }
      }
    ]
  };

  const handleNextStep = (buildingId: any, addressId: any, clientbuildingid: any) => {
    // Chek if selected fields are correct
    if (provider && provider !== null) {
      //Set user data to local storage
      setStorage("user", user);
      history.push(`/auth/register-step-1-d/buildingid/${buildingId}/addressid/${addressId}/hasbuildingparts/false/hasapartments/false/clientbuildingid/${clientbuildingid}/clientbuildingpartid/0`);
    } else {
      setErrors({
        ...errors,
        provider: [t('dashboard.reportIssue.errorTextArea')],
      });
      return;
    }

  };



  /*const handleAddBuildingPart = (id: any, clientBuildingId: any, gursBuildingPartId: any, description: any) => {
    console.log(userId);

    //Add new building
    addBuildingPart(
      idAddParts,
      clientBuildingId,
      gursBuildingPartId,
      description,
      creationTime,
      creatorId,
      lastModificationTime,
      lastModifierId,
      isDeleted,
      deleterId,
      deletionTime,
      userId
    )
      .then((response: any) => {
        // success
        console.log('Building part added!');
        console.log(response);
        history.push(`/dashboardmain`);
      })
      .catch((error: any) => {
        // failure
        console.log(error);
      });
    //End Add new building


  };*/


  const handleNextStepPart = (id: any, clientBuildingId: any, gursBuildingPartId: any, description: any, buildingId: any, addressId: any, hasBuildingParts: any, hasApartments: any, partid: any) => {
    //console.log(userId);
    if (provider && provider !== null) {
      setStorage("user", user);
      history.push(`/auth/register-step-1-d/buildingid/${buildingId}/addressid/${addressId}/hasbuildingparts/${hasBuildingParts}/hasapartments/${hasApartments}/clientbuildingid/${clientBuildingId}/clientbuildingpartid/${partid}`);
    } else {
      setErrors({
        ...errors,
        provider: [t('dashboard.reportIssue.errorTextArea')],
      });
      return;
    }

  };

  //console.log(provider);

  //Filter providers
  const filter = (e: any) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = Providers.filter((provider) => {
        return provider.name.toLowerCase().startsWith(keyword.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setFoundProviders(results);
    } else {
      setFoundProviders(Providers);
      // If the text field is empty, show all users
    }
    setName(keyword);
  };

  //Clear input
  const clearInput = (e: any) => {
    setName('');
  };

  useIonViewWillEnter(() => {
    setDataLoad(false);
    //Clear data
    setProvider("");
    setProviderClass("");
  }, [props]);

  useIonViewDidEnter(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    //Get local stored user data
    getStorage('user').then((value) => {
      setUsername(value[0]);
      setEmail(value[1]);
      setPassword(value[2]);
      setBuildingId(value[3]);
      setClientBuildingId(value[4]);
      setBuildingAddressId(value[5]);
      setBuildingName(value[6]);
      setBuildingType(value[7]);
      setHasApartments(value[8]);
      setHasBuildingParts(value[9]);
      setBuildingPartId(value[10]);
      setBuildingClientPartId(value[11]);
      setBuildingPartName(value[12]);
      setBuildingPartType(value[13]);
      //setProvider(value[14]);
      setLocationCode(value[15]);
      setclientCode(value[16]);
      setPlace(value[17]);
      //console.log(value); 
    });

    //Get current user
    getCurrentUser()
      .then((response) => {
        //console.log(response);
        setcurrentUserId(response);
      })
      .catch((error) => console.log(error));

    //Get single building
    if (props.match.params.buildingid & props.match.params.addressid) {
      onLoad(props.match.params.buildingid, props.match.params.addressid, props.match.params.clientbuildingid);
    }

  }, [props]);


  //On Load
  const onLoad = (buildingid: any, addressid: any, id: string) => {
    let isMounted = true; // track whether component is mounted 
    //console.log(buildingid)
    //Get single building
    getBuildingSingle(buildingid, addressid)
      .then((response) => {
        if (isMounted) {
          //console.log(response);

          //Get providers by address
          getProvidersByAddress(
            response.addressId
          )
            .then((response) => {
              setProviders(response);
              setFoundProviders(response);
              //console.log(response)
            })
            .catch((error) => console.log(error));

          setBuildingData(response);
          setDataLoad(true);
        }
      })
      .catch((error) => console.log(error));

    if (props.match.params.clientbuildingid.toString() != "0") {
      //Get single client building
      getClientBuildingSingle({ id })
        .then((response) => {
          if (isMounted) {
            //console.log(response);
            //console.log(response.partsList);
            setClientBuildingData(response);
            setCountBuildingParts(response.partsList.length + 1)
            setCountBuildingParts(response.partsList);
          }
        })
        .catch((error) => console.log(error));

      //Get all client buildings
      getClientBuildings()
        .then((response) => {
          if (isMounted) {
            //console.log(response);
            setCountBuildings(response);
            //Set the last client building id from all buildings
            setClientBuildingId(response.pop().clientBuildingId)
          }
        })
        .catch((error) => console.log(error));
    }


    //Get all client buildings
    getClientBuildings()
      .then((response) => {
        if (isMounted) {
          //console.log(response);
          setCountBuildings(response);
          //Set the last client building id from all buildings
          setClientBuildingId(response.pop().clientBuildingId)
        }
      })
      .catch((error) => console.log(error));

    return () => {
      // clean up
      isMounted = false;
    };

  };


  //Check if provider is selected
  const onIonChange = (e: any) => {
    if (e.target.value !== null) {
      setErrors({
        ...errors,
        provider: undefined,
      });
    } else {
      setErrors({
        ...errors,
        provider: [t('dashboard.reportIssue.errorTextArea')],
      });
    }
    setProvider(e.detail.value)
    setProviderClass(e.detail.value);

    setStorage("user",
      [username,
        email,
        password,
        buildingId,
        clientBuildingId,
        buildingAddressId,
        buildingName,
        buildingType,
        hasApartments,
        hasBuildingParts,
        buildingPartId,
        buildingClientPartId,
        buildingPartName,
        buildingPartType,
        e.detail.value,
        locationCode,
        clientCode,
        place]
    );

    /*foundProviders && foundProviders.length > 0 && (
      hasBuildingParts || hasApartments ? (
        //Part
        history.push(`/auth/register-step-1-d/buildingid/${props.match.params.buildingid}/addressid/${props.match.params.addressid}/hasbuildingparts/${props.match.params.hasbuildingparts}/hasapartments/${props.match.params.hasapartments}/clientbuildingid/${clientBuildingId}/clientbuildingpartid/${props.match.params.partid}`)
      ) : (
        //Single building
        history.push(`/auth/register-step-1-d/buildingid/${props.match.params.buildingid}/addressid/${props.match.params.addressid}/hasbuildingparts/false/hasapartments/false/clientbuildingid/${clientBuildingId}/clientbuildingpartid/0`)

      )

    )*/


  }



  //Set building part id when checking provider
  const handleCheckbox = () => {
    if (props.match.params.clientbuildingid !== "0") {
      setDataLoad(false);
      //Get all client buildings
      getClientBuildings()
        .then((response) => {
          setClientBuildingParId(response.pop().partsList.pop().clientBuildingPartId.toString())
          //console.log(response.pop().partsList.pop().clientBuildingPartId);
          //Set the last client building part id from last building in list
          setDataLoad(true);

        })
        .catch((error) => console.log(error));
    }
  }



  const buildType = buildingData?.hasBuildingParts || buildingData?.hasApartments ? t('auth.register.union') : t('auth.register.house');

  const backLink = hasApartments || hasBuildingParts ? `/auth/register-step-1-c/buildingid/${buildingData?.buildingId}/addressid/${buildingData?.addressId}/hasbuildingparts/${buildingData?.hasBuildingParts}/hasapartments/${buildingData?.hasApartments}/clientbuildingid/0/clientbuildingpartid/0` :
    `/auth/register-step-1-c/buildingid/${clientBuildingData?.buildingId}/addressid/${clientBuildingData?.addressId}/hasbuildingparts/${clientBuildingData?.hasBuildingParts}/hasapartments/${clientBuildingData?.hasApartments}/clientbuildingid/${clientBuildingData?.clientBuildingId}/clientbuildingpartid/0`;

  //console.log(provider);

 //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/dashboardmain")
    });
  });


  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="h-full p-4 flex pt-8 justify-center rounded-t-2xl bg-white dark:bg-gray-900">
          <div className="w-full mb-5">
            <StepsHeaderWithBackCustom hideArrow={true} title={t('auth.register.step4TitleProvider')} backLink="/dashboardmain" />
            {dataLoad ? (
              <>
                <UnitCard
                  //title={`${clientBuildingData ? clientBuildingData?.description : buildType}`}
                  title={buildingPartName ? buildingPartName : buildingName && !buildingPartName ? buildingName : clientBuildingData?.description}
                  address={`${clientBuildingData ? clientBuildingData?.address : buildingData?.street + ' ' + buildingData?.houseNumber + ', ' + buildingData?.post}`}
                  type={buildingData?.hasBuildingParts && buildingData?.hasApartments ? "SKYSCRAPER" : buildingData?.hasBuildingParts && !buildingData?.hasApartments ? "HOMEMULTIPLE" : "HOME"}
                  mapLat={buildingData?.gpsLat.toString()}
                  mapLong={buildingData?.gpsLon.toString()}
                />

                {/*<div className="py-4">
                  <InputSearch
                    type="text"
                    label={t('auth.register.searchAmongProviders')}
                    value={name}
                    onChange={filter}
                    noRightIcon={true}
                    onClear={clearInput}
                  />
                </div>*/}

                <div className="mt-10 calc-height-10 overflow-auto">
                  <IonList mode="md">
                    <IonRadioGroup value={provider} onIonChange={onIonChange}>
                      {foundProviders && foundProviders.length > 0 ? (
                        foundProviders.map((provider) => (
                          <IonItem key={provider.id} lines="none" className={Number(providerClass) === provider.id ? "opacity-50 border-b" : "border-b"}>
                            <IonLabel><div className="flex items-center"><img className="mr-6" width="44" height="44" src={provider.logo ? `data:image/jpeg;base64, ${provider.logo}` : '/assets/no-image.jpg'} />{provider.name}</div></IonLabel>
                            <IonRadio onClick={handleCheckbox} className="opacity-0 w-0 h-0 -ml-10" slot="start" value={provider.id} />
                          </IonItem>
                        ))
                      ) : (
                        <div className="text-gray-50 text-center mt-10">
                          <div className="font-bold text-xl mt-2">{t('auth.register.noResults')} "{name}"</div>
                          <div className="text-sm px-8">{t('auth.register.step2Text')} <IonRouterLink className="font-bold ml-1 text-blue-15" routerLink="/auth/register-provider">{t('general.here')}</IonRouterLink>.</div>
                        </div>
                      )}
                    </IonRadioGroup>
                  </IonList>
                </div>
              </>
            ) : (
              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <Loader />
              </div>
            )}

          </div>
        </div>
      </IonContent>
      <IonFooter mode="ios" className="ion-no-border p-4 bg-white dark:bg-gray-900">
        <IonToolbar>
          {errors.provider && (
            <div className='text-red-500 text-center mb-2 text-sm'>{errors.provider}</div>
          )}
          {foundProviders && foundProviders.length > 0 ? (
            <>
              {hasBuildingParts || hasApartments ? (
                <IonButton onClick={() => handleNextStepPart(countBuildings.length, props.match.params.clientbuildingid, buildingPartId, buildingPartName, props.match.params.buildingid, props.match.params.addressid, props.match.params.hasbuildingparts, props.match.params.hasapartments, props.match.params.partid)} color={(provider && provider !== null) ? "blue" : "graybgbut"} className="w-full normal-case font-semibold h-12 text-base"><span className={`${(provider && provider !== null) ? "text-white" : "dark:text-gray-900 text-gray-400"}`}>{t('auth.register.add')}</span></IonButton>
              ) : (
                <>
                  <IonButton onClick={() => handleNextStep(props.match.params.buildingid, props.match.params.addressid, props.match.params.clientbuildingid)} color={(provider && provider !== null) ? "blue" : "graybgbut"} className="w-full normal-case font-semibold h-12 text-base"><span className={`${(provider && provider !== null) ? "text-white" : "dark:text-gray-900 text-gray-400"}`}>{t('auth.register.add')}</span></IonButton>

                </>
              )}
            </>
          ) : (
            <>
              <IonButton color="gray" className="w-full normal-case font-semibold h-12 text-base">{t('auth.register.add')}</IonButton>
            </>
          )}
          {/*hasBuildingParts || hasApartments ? (
            <IonButton onClick={() => handleAddBuildingPart(countBuildings.length, props.match.params.clientbuildingid, buildingPartId, buildingPartName)} shape="round" color="orange" className="w-full normal-case font-semibold h-12 text-base">{t('auth.register.skip')}</IonButton>
          ) : (
            <IonButton onClick={() => handleAddBuilding(countBuildings.length, props.match.params.buildingid, props.match.params.addressid)} shape="round" color="orange" className="w-full normal-case font-semibold h-12 text-base">{t('auth.register.skip')}</IonButton>
          )*/}
        </IonToolbar>
      </IonFooter>
    </IonPage >
  );
}

export default RegisterStep1C;
