import React from 'react';
import cx from 'classnames';
import { IonIcon } from '@ionic/react';

type TextFieldTypes =
  | 'date'
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'text'
  | 'url'
  | 'time'
  | 'week'
  | 'month'
  | 'datetime-local';

interface ContainerProps {
  onChange?: Function;
  onPaste?: Function;
  onClear?: Function;
  type: TextFieldTypes;
  value?: string;
  label: string;
  className?: string;
  error?: string[];
  noRightIcon?: boolean;
}

const InputSearch: React.FC<ContainerProps> = ({
  onChange,
  onPaste,
  onClear,
  value,
  type,
  label,
  className,
  error,
  noRightIcon,
}) => {
  const errors = error?.length ?? 0;

  return (
    <div className={className}>
      <div
        className={cx(
          'relative px-12 pt-5 pb-1 text-base transition duration-300 rounded-3xl imb-input group bg-gray-15 dark:bg-gray-800 border-2 italic text-gray-50 dark:text-white focus-within:border-gray-15',
          {
            'border-gray-15 dark:border-gray-500': errors === 0,
            'border-red-5 bg-red-10': errors > 0,
          }
        )}
      >
        <IonIcon
          src="/assets/icon/ic-search.svg"
          class="ios hydrated text-xl absolute left-0 top-0 ml-4 mt-3"
        ></IonIcon>
        <input
          placeholder=" "
          className={cx('block w-full appearance-none focus:outline-none bg-gray-15 dark:bg-gray-800 text-gray-20 dark:text-white',
            {
              'border-gray-45 dark:border-gray-500': errors === 0,
              'bg-red-10': errors > 0,
            }
          )}
          value={value}
          type={type}
          onChange={(e) => (onChange ? onChange(e) : '')}
          onPaste={(e) => (onChange ? onChange(e) : '')}
        />
        {noRightIcon !== true ? (
          <IonIcon
            src="/assets/icon/ic-location.svg"
            class="ios hydrated text-xl absolute right-0 top-0 mr-4 mt-3"
          ></IonIcon>
        ) : (
          <>
            {value && (
              <IonIcon
                src="/assets/icon/ic-close-gray.svg"
                class="ios hydrated text-xl absolute right-0 top-0 mr-4 mt-3"
                onClick={(e) => (onClear ? onClear(e) : '')}
              ></IonIcon>
            )}
          </>
        )}
        <label className="absolute inset-0 px-12 py-3 transition-all duration-300 pointer-events-none">
          {label}
        </label>
      </div>
    </div>
  );
};

export default InputSearch;
