import { useTranslation } from "react-i18next";


const EULogos = () => {
    const { t } = useTranslation();
    return (
        <div className="mt-0">
            <div className="max-w-7xl mx-auto pb-2">
                <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                    <p className="max-w-md mx-auto text-xs text-gray-55 dark:text-gray-400 text-center lg:max-w-xl lg:text-left">
                        {t('general.euText')}
                    </p>
                    <div className="flow-root self-center mt-0">
                        <div className="flex items-center justify-between">
                            <div className="w-4/12 flex justify-center">
                                <img className="" src="/assets/logo-eu.svg" />
                            </div>
                            <div className="w-4/12 ml-2 flex justify-center">
                                <img className="" src="/assets/logo-slovenski-sklad.png" />
                            </div>
                            <div className="w-4/12 ml-2 flex justify-center">
                                <img className="" src="/assets/logo-gospodarstvo.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EULogos
