import { IonIcon, IonInfiniteScrollContent } from "@ionic/react";
import { useTranslation } from "react-i18next";

interface ContainerProps {
  temperature?: any | null;
  humidity?: any | null;
  cO2Gas?: any | null;
}

const AirSensorCard: React.FC<ContainerProps> = ({
  temperature,
  humidity,
  cO2Gas,

}) => {
  const { t } = useTranslation();


  return (
    <div>
      <div className="flex w-full items-center justify-between border-b border-gray-200 pb-2 mb-3">
        <div className="text-gray-600 dark:text-gray-60 uppercase font-semibold text-2xs w-4/12">{t("dashboard.cO2Gas")}</div>
        <div className="flex items-center justify-center w-4/12">
          <IonIcon
            src={`/assets/icon/${cO2Gas.healthIndex == 1 ? "air-bad-red.svg" : "air-bad.svg"}`}
            class="ios hydrated w-4 h-4 px-1"
          ></IonIcon>
          <IonIcon
            src={`/assets/icon/${(cO2Gas.healthIndex == 2) ? "air-neutral-yellow.svg" : "air-neutral.svg"}`}
            class="ios hydrated w-4 h-4 px-1"
          ></IonIcon>
          <IonIcon
            src={`/assets/icon/${cO2Gas.healthIndex == 3 ? "air-good-green.svg" : "air-good.svg"}`}
            class="ios hydrated w-4 h-4 px-1"
          ></IonIcon>
        </div>
        <div className="text-gray-700 dark:text-gray-60 text-sm font-bold w-4/12 text-right">
          {Math.round(cO2Gas.value)} {cO2Gas.unit}
        </div>
      </div>
      <div className="flex w-full items-center border-b border-gray-200 pb-2 mb-3">
        <div className="text-gray-600 dark:text-gray-60 uppercase font-semibold text-2xs w-4/12">{t("dashboard.humidity")}</div>
        <div className="flex items-center justify-center w-4/12">
          <IonIcon
            src={`/assets/icon/${humidity.healthIndex == 1 ? "air-bad-red.svg" : "air-bad.svg"}`}
            class="ios hydrated w-4 h-4 px-1"
          ></IonIcon>
          <IonIcon
            src={`/assets/icon/${(humidity.healthIndex == 2) ? "air-neutral-yellow.svg" : "air-neutral.svg"}`}
            class="ios hydrated w-4 h-4 px-1"
          ></IonIcon>
          <IonIcon
            src={`/assets/icon/${humidity.healthIndex == 3 ? "air-good-green.svg" : "air-good.svg"}`}
            class="ios hydrated w-4 h-4 px-1"
          ></IonIcon>
        </div>
        <div className="text-gray-700 dark:text-gray-60 text-sm font-bold w-4/12 text-right">
          {Math.round(humidity.value)} {humidity.unit}
        </div>
      </div>
      <div className="flex w-full items-center border-b border-gray-200 pb-2">
        <div className="text-gray-600 dark:text-gray-60 uppercase font-semibold text-2xs w-4/12">{t("dashboard.temperature")}</div>
        <div className="flex items-center justify-center w-4/12">
          <IonIcon
            src={`/assets/icon/${temperature.healthIndex == 1 ? "air-bad-red.svg" : "air-bad.svg"}`}
            class="ios hydrated w-4 h-4 px-1"
          ></IonIcon>
          <IonIcon
            src={`/assets/icon/${(temperature.healthIndex == 2) ? "air-neutral-yellow.svg" : "air-neutral.svg"}`}
            class="ios hydrated w-4 h-4 px-1"
          ></IonIcon>
          <IonIcon
            src={`/assets/icon/${temperature.healthIndex == 3 ? "air-good-green.svg" : "air-good.svg"}`}
            class="ios hydrated w-4 h-4 px-1"
          ></IonIcon>
        </div>
        <div className="text-gray-700 dark:text-gray-60 text-sm font-bold w-4/12 text-right">
          {Math.round(temperature.value)} °{temperature.unit}
        </div>
      </div>
    </div>
  );
};
export default AirSensorCard;
