import {
    IonButtons,
    IonBackButton,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';

interface ContainerProps {
    title: string;
    backHref: string;
}

const SubTitleWithBack: React.FC<ContainerProps> = ({
    title,
    backHref
}) => {
    return (
        <div className="flex items-center mb-4 mt-4 w-full text-left">
            <IonButtons slot="start">
                <IonBackButton
                    className="h-4"
                    mode="md"
                    defaultHref={backHref}
                    icon={chevronBackOutline}
                    class="text-gray-10"
                />
            </IonButtons>
            <div className="text-gray-55 dark:text-white font-bold text-xl">{title}</div>
        </div>
    );
};
export default SubTitleWithBack;
