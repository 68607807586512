import {
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterLink,
  IonList,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonIcon,
  useIonViewWillEnter,
  IonFooter,
  IonModal,
  isPlatform,
} from '@ionic/react';
import { getProfile } from '../../data/user/profile.api';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { documentTextOutline } from 'ionicons/icons';
import Header from './../../components/Header';
import { useTranslation } from 'react-i18next';
import { useAuth } from './../../context/Auth';
import i18n from '../../services/i18n';
import FooterTabs from '../../components/dashboardSingle/FooterTabs';
import DeleteProfileModal from '../../components/modals/deleteProfile';
import { getCurrentUser } from '../../data/user/current-user.api';
import { deleteUserAccount } from '../../data/client-points/deleteUserAccount.api';
import { useIonRouter } from '@ionic/react';
import { Preferences } from '@capacitor/preferences';
//Push notifications
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { getCurrentUserAccount } from '../../data/user/current-user-account.api';
import { setPushNotifications } from '../../data/user/set-push-notifications';
import { deviceToken } from '../../data/user/device-token';
import { App } from '@capacitor/app';

type CurrentUserlData = {
  isAuthenticated: boolean;
  id: string;
  userName: string;
  email: string;
};

type ProfileData = {
  email: string;
  userName: string;
  id: string;
};

const Profile: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [Profile, setProfile] = useState<ProfileData>();

  const [text, setText] = useState<string>();
  const [Language, setLanguage] = useState<string>('en');
  const [theme, setTheme] = useState<string>('light');
  const [pushNotify, setPushNotify] = useState<string>('yes');
  const [version, setVersion] = useState<string>('');
  const [showModalDeleteProfile, setShowModalDeleteProfile] = useState(false);
  const [currentUserId, setcurrentUserId] = useState<CurrentUserlData>();
  const ionRouter = useIonRouter();

  const { setAuthToken } = useAuth();

  const handleLogout = async () => {
    console.log('logout');
    await Preferences.remove({ key: 'token' });
    await Preferences.remove({ key: 'demo' });
    setAuthToken('');

    if (document.body.parentElement?.classList.contains('demo')) {
      document.body.parentElement?.classList.remove('demo');
    }

    if (document.body.classList.contains('demo')) {
      document.body.classList.remove('demo');
    }

    history.push('/auth/login');

    // Exit app on logout or reload on web - temporary solution
    /*if (isPlatform('cordova')) {
      history.push('/auth/login');
      Plugins.App.exitApp();
    } else {
      history.push('/auth/login');
      window.location.reload();
    }*/
  };

  useIonViewWillEnter(() => {
    getProfile()
      .then((response) => {
        //console.log(response);
        setProfile(response);
      })
      .catch((error) => {
        console.log(error)
        //Exit app if failure
        if (isPlatform('cordova')) {
          App.exitApp();
        } else {
          window.location.reload();
        }
      });
    //Get language from storage
    Preferences.get({ key: 'language' }).then((storage: any) => {
      setLanguage(storage.value ?? '');
    });
    //Get theme from storage
    Preferences.get({ key: 'theme' }).then((storage: any) => {
      setTheme(storage.value ?? '');
    });
    Preferences.get({ key: 'version' }).then((storage: any) => {
      setVersion(storage.value ?? '2.6');
    });

  }, []);

  //Language change
  const handleLanguageChange = (e: any) => {
    if (e.target.value == 'en') {
      Preferences.set({
        key: 'language',
        value: 'en',
      });
      i18n.changeLanguage(e.detail.value);
    } else if (e.target.value == 'sl') {
      Preferences.set({
        key: 'language',
        value: 'sl',
      });
      i18n.changeLanguage(e.detail.value);
    }
    setLanguage(e.detail.value);
    //console.log(e.target.value);
    //console.log(i18n.language);
  };

  //Theme change
  const handleThemeChange = (e: any) => {
    Preferences.set({
      key: 'theme',
      value: e.target.value,
    });
    document.body?.classList.remove('light');
    document.body?.classList.remove('dark');
    document.body?.classList.add(e.target.value);
    document.body.parentElement?.classList.remove('light');
    document.body.parentElement?.classList.remove('dark');
    document.body.parentElement?.classList.add(e.target.value);
    setTheme(e.detail.value);

    //console.log(e.target.value);
  };
  //Push notifications
  const handlePushNotify = (e: any) => {
    if (Capacitor.isNativePlatform()) {
      if (e.detail.value === "yes") {
        //Set push in database user
        setPushNotifications(
          true
        )
          .then((response) => {
            // success
            //console.log(response);
          })
          .catch((error) => {
            // failure
            console.log(error);
          });
        //Push notifications
        PushNotifications.requestPermissions().then(() => {
          PushNotifications.register();
        });
        FCM.subscribeTo({ topic: 'general' }).catch((err) => console.log(err));

        PushNotifications.addListener('registration', (token) => {
          console.log('Push registration success, token: ' + token, token.value);
          //Add user token:
          deviceToken(
            token.value
          )
            .then((response) => {
              // success
              console.log(response);
              console.log("Token updated");
            })
            .catch((error) => {
              // failure
              console.log(error);
              console.log("Token not updated");
            });
        });
      } else if (e.detail.value === "no") {
        //Set push in database user
        setPushNotifications(
          false
        )
          .then((response) => {
            // success
            console.log(response);
          })
          .catch((error) => {
            // failure
            console.log(error);
          });
        //Push notifications
        FCM.unsubscribeFrom({ topic: 'general' })
          .then(() => console.log(`unsubscribed from topic`))
          .catch((err) => console.log(err));
      }
    }
    //console.log(e.detail.value);
    setPushNotify(e.detail.value);

    //console.log(e.target.value);
  };


  //Get user id
  useIonViewWillEnter(() => {
    let isMounted = true; // track whether component is mounted
    //Get current user
    getCurrentUser()
      .then((response) => {
        //console.log(response);
        if (isMounted) {
          setcurrentUserId(response);
        }
      })
      .catch((error) => console.log(error));
    getCurrentUserAccount()
      .then((response) => {
        //console.log(response);
        if (isMounted) {
          //Set push notifications
          if (response.pushNotifications === true) {
            setPushNotify("yes")
          } else {
            setPushNotify("no")
          }
        }
      })
      .catch((error) => console.log(error));
    return () => {
      // clean up
      isMounted = false;
    };
  });

  //Delete user profile
  const handleDeleteProfile = (e: any) => {
    e.preventDefault();
    setShowModalDeleteProfile(true);
  };

  const handleDeleteProfileAction = () => {
    //Get curent user
    getCurrentUser()
      .then((response) => {
        //console.log(response.id);
        //Delete account
        deleteUserAccount({ id: response.id.toString() })
          .then((response) => {
            // success
            console.log(response);
            setShowModalDeleteProfile(false);
            Preferences.remove({ key: 'token' });
            setAuthToken('');
            history.push('/auth/login');
          })
          .catch((error) => {
            // failure
            //console.log(error);
          });
      })
      .catch((error) => console.log(error));
  };

  //Go back on back button
  document.addEventListener('ionBackButton', (ev: any) => {
    ev.detail.register(10, () => {
      //ionRouter.goBack();
      history.push("/dashboardmain")
    });
  });

  return (
    <IonPage>
      <Header
        title={t('auth.accountSettings.title')}
        backLink="/dashboardmain"
        titleColor="text-gray-20 dark:text-white text-center"
        rightIcon={false}
      />
      <IonContent fullscreen>
        <div className="bg-gray-45 dark:bg-gray-900">
          <h2 className="flex items-center p-4 pt-6 mt-4 text-base font-bold text-black-5 dark:text-white">
            <IonIcon
              src="/assets/icon/ic-user.svg"
              class="ios hydrated text-2xl mr-3"
            ></IonIcon>
            {t('auth.accountSettings.personalInfoLabel')}
          </h2>
          <div className="flex justify-center">
            <div className="w-full">
              <IonList mode="md" className="p-0 bg-gray-45 dark:bg-gray-900">
                <IonItem
                  lines="none"
                  class="border-gray-45 dark:border-gray-800 border-b-2 text-gray-50 dark:text-gray-15 text-sm"
                >
                  <IonLabel className="dark:text-gray-15">
                    {t('auth.accountSettings.emailPlaceholder')}
                  </IonLabel>
                  <IonInput
                    disabled
                    value={Profile?.email}
                    placeholder=""
                    class="text-right text-gray-55 dark:text-white text-base"
                  ></IonInput>
                </IonItem>
                <IonItem
                  lines="none"
                  className="text-sm border-b-2 border-gray-45 dark:border-gray-800 text-gray-50"
                >
                  <IonLabel className="dark:text-gray-15">
                    {' '}
                    {t('auth.accountSettings.passwordPlaceholder')}
                  </IonLabel>
                  <IonInput
                    disabled
                    type="password"
                    value="1234567890"
                    placeholder=""
                    class="text-right text-gray-55 dark:text-white text-base"
                  ></IonInput>
                </IonItem>
                <IonItem lines="none" className="text-sm text-gray-50">
                  <IonLabel>{t('general.languageLabel')}</IonLabel>
                  <IonSelect
                    className="text-base text-gray-55 dark:text-white"
                    value={Language}
                    okText={t('general.okay')}
                    cancelText={t('general.dismiss')}
                    onIonChange={handleLanguageChange}
                  >
                    <IonSelectOption value="en">
                      {t('general.langEnglish')}
                    </IonSelectOption>
                    <IonSelectOption value="sl">
                      {t('general.langSlovenian')}
                    </IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem lines="none" className="text-sm text-gray-50">
                  <IonLabel>{t('general.themeLabel')}</IonLabel>
                  <IonSelect
                    className="text-base text-gray-55 dark:text-white"
                    value={theme}
                    okText={t('general.okay')}
                    cancelText={t('general.dismiss')}
                    onIonChange={handleThemeChange}
                  >
                    <IonSelectOption value="light">
                      {t('general.themeLight')}
                    </IonSelectOption>
                    <IonSelectOption value="dark">
                      {t('general.themeDark')}
                    </IonSelectOption>
                  </IonSelect>
                </IonItem>
                <IonItem lines="none" className="text-sm text-gray-50 demo-hide-cont">
                  <IonLabel>{t('auth.accountSettings.pushNotifications')}</IonLabel>
                  <IonSelect
                    className="text-base text-gray-55 dark:text-white"
                    value={pushNotify}
                    okText={t('general.okay')}
                    cancelText={t('general.dismiss')}
                    onIonChange={handlePushNotify}
                  >
                    <IonSelectOption value="yes">
                      {t('auth.accountSettings.yes')}
                    </IonSelectOption>
                    <IonSelectOption value="no">
                      {t('auth.accountSettings.no')}
                    </IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonList>
              <div className="justify-between mx-4 demo-hide-cont">
              <div className="text-left">
                  <IonRouterLink
                    routerLink="/auth/contact"
                    className="block mx-auto mt-6 text-sm font-bold text-left text-gray-50"
                  >
                    {t('auth.accountSettings.contactLabel')}
                  </IonRouterLink>
                </div>
                <div className="text-left">
                  <IonRouterLink
                    routerLink="/auth/password-change"
                    className="block mx-auto mt-6 text-sm font-bold text-left text-gray-50"
                  >
                    {t('auth.accountSettings.changePasswordLabel')}
                  </IonRouterLink>
                </div>
                <div className="text-right">
                  <IonRouterLink
                    routerLink="/auth/username-change"
                    className="block mx-auto mt-6 text-sm font-bold text-left text-gray-50"
                  >
                    {t('auth.accountSettings.changeEmailLabel')}
                  </IonRouterLink>
                </div>
              </div>

              <div className="justify-between mx-4">
                <div className="cursor-pointer bg-gray-45 dark:bg-gray-900">
                  <a
                    onClick={() => handleLogout()}
                    className="block mx-auto mt-6 text-sm font-bold text-left text-gray-50"
                  >
                    {t('auth.accountSettings.ctaLogout')}
                  </a>
                </div>

                <div className="text-right cursor-pointer bg-gray-45 dark:bg-gray-900 demo-hide-cont">
                  <a
                    onClick={handleDeleteProfile}
                    className="block mx-auto mt-6 text-sm font-bold text-left text-red-5"
                  >
                    {t('auth.accountSettings.deleteProfile')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between p-4 mt-6 bg-white dark:bg-gray-900 text-gray-50">
            <div className="text-sm">
              {t('auth.accountSettings.versionLabel')}
            </div>
            <div>{version}</div>
          </div>
          <div className="flex items-center px-4 pt-1 pb-5 text-sm bg-white text-gray-50 dark:bg-gray-900">
            <a
              className="underline"
              target="_blank"
              href={`${t('auth.register.termsAgreeLinkUrl')}`}
            >
              <span className="flex items-center">
                <IonIcon
                  icon={documentTextOutline}
                  class="ios hydrated mr-2 text-xl text-gray-50"
                ></IonIcon>
                <span>{t('auth.accountSettings.terms')}</span>
              </span>
            </a>
            ,{' '}
            <a
              className="mx-1 underline"
              target="_blank"
              href={`${process.env.REACT_APP_API_URL}${t(
                'auth.register.privacyLinkUrl'
              )}`}
            >
              {t('auth.register.privacyLinkTitle')}
            </a>{' '}
            {t('auth.register.termsAgreeAnd')}{' '}
            <a
              className="ml-1 underline"
              target="_blank"
              href={`${process.env.REACT_APP_API_URL}${t(
                'auth.register.eulaAgreeLinkUrl'
              )}`}
            >
              {t('auth.register.eulaLinkTitle')}
            </a>
            .
          </div>
          <div className="px-4 py-6 text-sm text-gray-50">
            {/*<p>{t('auth.accountSettings.aboutText')}</p>*/}
            <p className="mt-8">{t('auth.accountSettings.copytext')}</p>
          </div>
        </div>

        <IonModal key="delete-modal-building" isOpen={showModalDeleteProfile}>
          <DeleteProfileModal
            cssClass="opacity-100"
            id={`delete-profile`}
            onClick={() => handleDeleteProfileAction()}
            onCancelDelete={() => setShowModalDeleteProfile(false)}
          />
        </IonModal>
      </IonContent>

      <IonFooter mode="ios" className="ion-no-border" color="graybg">
        <FooterTabs active={1} />
      </IonFooter>
    </IonPage>
  );
};

export default Profile;
