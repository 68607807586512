import { Api } from '../api'; 

export type ClientPointActions =
  | { type: 'FETCH' }
  | { type: 'REJECT' }
  | { type: 'CANCEL' }
  | { type: 'RESOLVE' };

  export const defaultClientPointState = { status: 'idle' };

export type ClientPointState = {
  status: string;
};


export const utilitySignUp = async (
  emailAddress?: string,
  phoneNumber?: string,
  companyName?: string,
  address?: string,
  country?: string,
): Promise<any> => { 
  const api = new Api();

  try {
    const response = await api.request('POST', 'app/account/utility-sign-up', false, { 
      emailAddress,
      phoneNumber,
      companyName,
      address,
      country,
    });
    return response; 
  } catch (error) {
    throw error; 
  }
};